import PublicIcon from "@mui/icons-material/Public";
import {
  Chip,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@mui/material";
import {green, lightBlue} from "@mui/material/colors";
import {styled} from "@mui/material/styles";
import {useDialog} from "app/providers/dialog";
import {useAttorneyHubDispatch} from "app/realtime-store/redux-store";
import Guid from "common/values/guid/guid";
import parse from "html-react-parser";
import {
  getIndividualByUserId,
  populateIndividuals
} from "marketplace/entities/individual/realtime-store/individuals-redux-slice";
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import IndividualAvatar from "marketplace/view/individual-avatar";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import {useSession} from "users/session/session-context";
import Comment from "work/entities/comment/comment";


const SystemCommentContainer = styled('div')(
  ({theme}) => ({
    '@container (min-width: 800px)': {
      width: '75%'
    },
    '@container (min-width: 0px)': {
      width: '100%'
    },
    margin: theme.spacing(
      0,
      'auto',
      4,
      'auto'
    ),
    width: '80%'
  }));
const SystemCommentInfo = styled('div')(
  ({theme}) => ({
    alignItems: 'baseline',
    display: 'flex',
    marginBottom: theme.spacing(0.5),
  }));
const SystemCommentTime = styled(Typography)(
  ({theme}) => ({
    fontSize: '0.8em',
    marginLeft: theme.spacing(1.32)
  }));
const CommentListItem = styled(
  ListItem,
  {
    shouldForwardProp: (prop) => prop !== "pending" && prop !== "unread",
  }
)<{ pending: boolean, unread: boolean }>(
  ({pending, unread}) => ({
    opacity: pending ? 0.66 : 1,
    '& .MuiTypography-root': {
      fontWeight: unread ? 500 : 'normal'
    }
  }));
const NameLink = styled(
  Link,
  {
    shouldForwardProp: (prop) => prop !== "unread" && prop !== "disabled"
  }
)<{ disabled: boolean, unread: boolean }>(
  ({theme, disabled, unread}) => ({
    display: "inline-block",
    cursor: disabled ? "default" : "pointer",
    textDecoration: disabled ? "none" : "underline",
    "&::before": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "50%",
      content: '""',
      display: "inline-block",
      height: unread ? "8px" : 0,
      marginRight: unread ? "8px" : 0,
      verticalAlign: "middle",
      width: unread ? "8px" : 0
    }
  }));
const CommentDate = styled(Typography)(
  ({theme}) => ({
    color: theme.palette.text.secondary,
    display: "block",
  }));
const ExternalChip = styled(Chip)(
  ({theme}) => ({
    border: 0,
    marginLeft: theme.spacing(0.5),
  }));
const PendingChip = styled(Chip)(
  () => ({
    border: 0,
  }));
const CommentContent = styled("span")(
  ({theme}) => ({
    width: '100%',
    '& span.action': {
      fontWeight: 500,
      '&.accepted': {
        color: theme.palette.success.main
      },
      '&.rejected': {
        color: theme.palette.error.main
      }
    },
    '& > span.comment-redline': {
      width: '100%',
      '& > span.change-group': {
        whiteSpace: 'nowrap',
        '& .added': {
          '&.resolved': {
            color: theme.palette.text.primary
          },
          backgroundColor: 'unset',
          color: theme.palette.text.primary
        }
      },
      '& span.added': {
        backgroundColor: green[300],
        color: theme.palette.text.primary,
        '&.resolved': {
          backgroundColor: 'unset',
          color: lightBlue[700]
        }
      },
      '& span.removed': {
        textDecoration: 'line-through',
        textDecorationColor: theme.palette.error.main,
        '&.resolved': {
          textDecorationColor: lightBlue[700]
        }
      },
      '& span.unchanged': {
        color: theme.palette.text.primary
      }
    }
  }));
const SystemMessageContent = styled('div')(
  ({theme}) => ({
    backgroundColor: '#e0e0e0',
    borderRadius: theme.spacing(0.66),
    display: 'block',
    textAlign: 'center',
    padding: theme.spacing(2),
    '& > p': {
      margin: 0
    }
  }));

type CommentItemReadonlyProps = {
  className?: string;
  comment: Comment;
};

export default function CommentItemReadonly(props: Readonly<CommentItemReadonlyProps>) {
  const {
    className,
    comment
  } = props;

  const session = useSession();
  const dispatch = useAttorneyHubDispatch();
  const commenter = getIndividualByUserId(comment.senderId);
  if (!commenter && comment.senderId) {
    dispatch(populateIndividuals({session, userIds: [comment.senderId]}))
  }

  const {openDialog} = useDialog();

  function getPendingStatus(comment: Comment): string {
    if (comment.isDeleted) return "Pending Deletion";
    if (comment.markedForEdit) return "Pending Edit";
    return "Pending";
  }

  function getCommentContent(comment: Comment) {
    if (comment.isDeleted && !comment.markedForDeletion) {
      return (<i>This comment was deleted</i>)
    }
    return (
      <CommentContent>
        {parse(comment.content)}
      </CommentContent>
    );
  }

  function handleViewCommenterProfile() {
    if (!commenter) {
      console.warn("Commenter not found");
      return;
    }

    openDialog({
      component: <ViewIndividualProfile profile={commenter.profile}/>,
      titleStyle: {
        position: "absolute",
        right: 0,
        top: 0,
      },
      contentSxProps: {
        display: "flex",
        overflowX: "hidden",
      },
      MuiProps: {
        maxWidth: "lg",
        fullWidth: true,
      },
    });
    setTimeout(
      () => adjustDialogPosition(false),
      500
    );
  }


  function adjustDialogPosition(panelOpen: boolean) {
    for (const dialog of document.getElementsByClassName("MuiDialog-root")) {
      dialog.setAttribute(
        "style",
        `padding-right: ${panelOpen ? "0px" : "400px"
        }; transition: padding-right 225ms;`
      );
    }
  }

  return comment.senderId ? (
    <CommentListItem
      className={className}
      key={comment.id?.value ?? Guid.generate().value}
      pending={comment.isPending}
      unread={false}
      disableGutters
      alignItems="flex-start"
    >
      <ListItemAvatar>
        <IndividualAvatar
          avatarId={commenter?.profile?.avatarId}
          individualId={commenter?.id}
          session={session}
        />
      </ListItemAvatar>

      {(comment.senderId) && (
        <ListItemText
          primary={
            <>
              <NameLink
                disabled={!commenter?.isVisible}
                unread={false}
                onClick={(event) => {
                  event.preventDefault();
                  if (!commenter?.isVisible) return;
                  handleViewCommenterProfile();
                }}
              >
                {commenter?.getFullName()}
              </NameLink>
              {comment.thread.isExternal && (
                <ExternalChip
                  label="External"
                  size="small"
                  icon={<PublicIcon/>}
                  variant="outlined"
                />
              )}
              {comment.isPending && (
                <PendingChip
                  label={getPendingStatus(comment)}
                  size="small"
                  color="warning"
                  variant="outlined"
                />
              )}
              <CommentDate variant="caption">
                {comment.published?.format("MMM D, YYYY h:mm A") ??
                  moment().format("MMM D, YYYY h:mm A")}
              </CommentDate>
            </>
          }
          secondary={getCommentContent(comment)}
          slotProps={{
            primary: {
              fontWeight: 500
            },
            secondary: {
              color: "textPrimary"
            }
          }}
        />
      )}
    </CommentListItem>
  ) : (
    <SystemCommentContainer>
      <SystemCommentInfo style={{justifyContent: 'center'}}>
        <SystemCommentTime>
          <time>
            {comment.published?.format('MM/DD/YY hh:mm A')}
          </time>
        </SystemCommentTime>
      </SystemCommentInfo>
      <SystemMessageContent>
        <ReactMarkdown>{comment.content}</ReactMarkdown>
      </SystemMessageContent>
    </SystemCommentContainer>
  );
}
