import {createAsyncThunk, createSlice, PayloadAction,} from "@reduxjs/toolkit";
import PaginatedResponse from "common/contracts/paginated-response";
import Document from "documents/entities/document/document";
import DocumentAPIService, {
  DocumentAPIServiceError,
  DocumentQuery
} from "documents/entities/document/api/document-api-service";
import Session from "users/session/session";
import Guid from "common/values/guid/guid";
import UnavailableDocument from "documents/values/unavailable-document";
import {RootState} from "app/realtime-store/redux-store";
import {useSelector} from "react-redux";
import {NotificationsAPIServiceError} from "notifications/entities/notification/api/notifications-api-service";

type DocumentStoreState = {
  byQuery: {
    entries: Record<string, PaginatedResponse<Document>>;
    loading: Record<string, boolean>;
    error: Record<string, string | null>;
  };
  byId: {
    entries: Record<string, Document | UnavailableDocument>;
    loading: Record<string, boolean>;
    error: Record<string, string | null>;
  };
  interfaceQueries: {
    entries: Record<string, DocumentQuery>;
  };
};

const initialState: DocumentStoreState = {
  byQuery: {
    entries: {},
    loading: {},
    error: {},
  },
  byId: {
    entries: {},
    loading: {},
    error: {},
  },
  interfaceQueries: {
    entries: {},
  },
};

export const populateDocumentsByQuery = createAsyncThunk<
  PaginatedResponse<Document>,
  { session: Session; query: DocumentQuery }
>(
  "documents/getDocumentsByQuery",
  async (
    {session, query}: { session: Session; query: DocumentQuery },
    thunkAPI
  ) => {
    try {
      const apiService = new DocumentAPIService(session);
      return await apiService.getDocumentsInfo(
        query
      );
    } catch (error) {
      const apiError = error as DocumentAPIServiceError;
      return thunkAPI.rejectWithValue(apiError?.error?.response?.data ?? error?.toString() ?? "An error" +
        " occurred.");
    }
  }
);

export const populateDocumentsByIds = createAsyncThunk<
  (Document | UnavailableDocument | undefined)[],
  { session: Session; ids: Guid[] }
>(
  "documents/getDocumentsByIds",
  async ({session, ids}: { session: Session; ids: Guid[] }, thunkAPI) => {
    try {
      const apiService = new DocumentAPIService(session);
      return await apiService.getDocumentsInfoByIds(
        ids,
        undefined
      );
    } catch (error) {
      const apiError = error as NotificationsAPIServiceError;
      if (apiError.error?.statusCode === 404) {
        return thunkAPI.rejectWithValue("Not found.")
      }
      return thunkAPI.rejectWithValue(apiError?.error?.response?.data ?? error?.toString() ?? "An error occurred.");
    }
  }
);
export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    addDocument: (state, action: PayloadAction<Document | UnavailableDocument>) => {
      state.byId.entries[action.payload.id.value] = action.payload;
      state.byQuery = {
        entries: {},
        loading: {},
        error: {},
      };
    },
    removeDocument: (state, action: PayloadAction<Document | UnavailableDocument | Guid>) => {
      let documentId: Guid | undefined = undefined;
      if (action.payload instanceof Guid) {
        documentId = action.payload;
      } else {
        documentId = action.payload.id;
      }
      if (!documentId) {
        throw new Error("Document id not found");
      }
      delete state.byId.entries[documentId.value];
      state.byQuery = {
        entries: {},
        loading: {},
        error: {},
      };
    },
    updateDocumentsInterfaceQuery: (
      state,
      action: PayloadAction<{
        interfaceName: string;
        query: DocumentQuery;
      }>
    ) => {
      state.interfaceQueries.entries[action.payload.interfaceName] =
        action.payload.query;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      populateDocumentsByIds.pending,
      (state, action) => {
        const documentIds = action.meta.arg.ids;
        if (!documentIds.length) {
          return;
        }
        documentIds.forEach((id) => {
          state.byId.loading[id.value] = true;
          state.byId.error[id.value] = null;
        });
      }
    );
    builder.addCase(
      populateDocumentsByIds.fulfilled,
      (state, action) => {
        const documentIds = action.meta.arg.ids;
        if (!documentIds.length) {
          return;
        }

        documentIds.forEach((id) => {
          state.byId.loading[id.value] = false;
          const document = action.payload.find(
            (doc) => doc?.id.value === id.value
          );
          if (!document) {
            state.byId.error[id.value] = "Document not found";
          } else {
            state.byId.error[id.value] = null;
            state.byId.entries[id.value] = document;
            if (!(document instanceof Document)) {
              return;
            }
            for (const query in state.byQuery.entries) {
              state.byQuery.entries[query].data = state.byQuery.entries[
                query
                ].data.filter((doc) => doc.id.value !== id.value);
              state.byQuery.entries[query].data.push(document);
            }
          }
        });
      }
    );

    builder.addCase(
      populateDocumentsByIds.rejected,
      (state, action) => {
        const documentIds = action.meta.arg.ids;
        if (!documentIds.length) {
          return;
        }
        documentIds.forEach((id) => {
          state.byId.loading[id.value] = false;
          state.byId.error[id.value] = action.payload as string;
        });
      }
    );

    builder.addCase(
      populateDocumentsByQuery.pending,
      (state, action) => {
        const query = action.meta.arg.query.asSearchParams().toString();
        state.byQuery.loading[query] = true;
        state.byQuery.error[query] = null;
      }
    );
    builder.addCase(
      populateDocumentsByQuery.fulfilled,
      (state, action) => {
        const query = action.meta.arg.query.asSearchParams().toString();
        state.byQuery.entries[query] = action.payload;
        state.byQuery.loading[query] = false;
      }
    );
    builder.addCase(
      populateDocumentsByQuery.rejected,
      (state, action) => {
        const query = action.meta.arg.query.asSearchParams().toString();
        state.byQuery.loading[query] = false;
        state.byQuery.error[query] = action.payload as string;
      }
    );
  },
});

export const {addDocument, removeDocument, updateDocumentsInterfaceQuery} = documentsSlice.actions;

export const getDocumentsByQuery = (query: DocumentQuery) => {
  return useSelector((state: RootState) => {
    if (!query) {
      return undefined;
    }
    return state.documents.byQuery.entries[
      query.asSearchParams().toString()
      ];
  });
};
export const getIsLoadingDocumentsByQuery = (query: DocumentQuery) =>
  useSelector((state: RootState) => {
    if (query === undefined) {
      return undefined;
    }
    return state.documents.byQuery.loading[
      query.asSearchParams().toString()
      ] ?? false;
  });
export const getErrorLoadingDocumentsByQuery = (
  query: DocumentQuery
) =>
  useSelector(
    (state: RootState) => {
      if (!query) return false;
      return state.documents.byQuery.error[query.asSearchParams().toString()] ?? false;
    }
  );
export const getDocumentById = (id: Guid) =>
  (state: RootState) => state.documents.byId.entries[id.value];
export const getDocumentIsLoading = (id: Guid) =>
  (state: RootState) => state.documents.byId.loading[id.value];
export const getDocumentError = (id: Guid) =>
  (state: RootState) => state.documents.byId.error[id.value];

export const getDocumentsQueryByInterface = (interfaceName: string) =>
  useSelector(
    (state: RootState) =>
      state.documents.interfaceQueries.entries[interfaceName]
  );