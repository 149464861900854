import BadgeIcon from "@mui/icons-material/Badge";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import MessageIcon from "@mui/icons-material/Message";
import {
  Avatar,
  Button,
  Chip,
  Container,
  Divider,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useDialog} from "app/providers/dialog";
import {CanceledError} from "axios";
import Loader from "common/components/loader";
import {downloadFile, formatCurrency} from "common/helpers/utils";
import {Currency} from "common/values/currency/currency";
import Guid from "common/values/guid/guid";
import Money from "common/values/money/money";
import IndividualAPIService, {
  IndividualHiddenError,
} from "marketplace/entities/individual/api/individual-api-service";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";
import {Chat} from "messaging/components";
import Forum from "messaging/entities/forum/forum";
import {enqueueSnackbar} from "notistack";
import {useEffect, useState} from "react";
import UserNetworkConnectionAPIService
  from "users/entities/user-network-connection/api/user-network-connection-api-service";
import NetworkButton from "users/entities/user-network-connection/view/components/network-button";
import {useSession} from "users/session/session-context";

const MainContainer = styled("div")(
  ({theme}) => ({
    display: "grid",
    gridTemplateRows: "auto 1fr",
    width: "100%",
  }));
const HeaderContainer = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(
        3,
        0
      ),
    },
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    gridColumn: "1 / auto",
    gridRowStart: "1",
    justifyContent: "center",
    padding: theme.spacing(3),
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 10,
  }));
const ChatContainer = styled(Container)(
  ({theme}) => ({
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      padding: 0,
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "500px",
      padding: theme.spacing(
        0,
        1
      ),
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "500px",
      padding: theme.spacing(
        0,
        4
      ),
    },
    gridColumn: "1 / auto",
    gridRowStart: "2",
    gridRowEnd: "none",
  }));
const ContentContainer = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down("sm")]: {
      columnCount: 1,
    },
    [theme.breakpoints.up("md")]: {
      columnCount: 2,
      margin: theme.spacing(
        0,
        1
      ),
    },
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(
        0,
        4
      ),
    },
    columnFill: "balance",
    columnGap: theme.spacing(1),
    gridColumn: "1 / auto",
    gridRowStart: "2",
  }));
const HeaderInnerContainer = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down("sm")]: {
      justifyItems: "center",
    },
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "min-content auto",
      gridTemplateRows: "min-content auto",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "min-content auto 1fr",
    },
    alignItems: "flex-start",
    gridColumnGap: theme.spacing(2),
    display: "grid",
    width: "100%",
  }));
const TitleContainer = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    display: "flex",
    flexDirection: "column",
    height: "100%",
  }));
const ButtonContainer = styled("div")(
  ({theme}) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: theme.spacing(1),
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  }));
const GridContainer = styled("div")(
  ({theme}) => ({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "100%",
  }));
const StyledAvatar = styled(Avatar)(
  ({theme}) => ({
    [theme.breakpoints.down("sm")]: {
      height: theme.spacing(7),
      width: theme.spacing(7),
    },
    height: theme.spacing(15),
    width: theme.spacing(15),
  }));
const FieldTitle = styled(Typography)(
  ({theme}) => ({
    fontWeight: 500,
  }));
const ProfileTypeChip = styled(Chip)(
  ({theme}) => ({
    color: theme.palette.text.disabled,
    margin: theme.spacing(0.25),
  }));
const NameChipContainer = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    alignItems: "flex-start",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
  }));
const DescriptionContainer = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "center",
      margin: theme.spacing(
        1,
        0
      ),
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      gridColumn: "1 / span 2",
      gridRow: "2",
      margin: theme.spacing(
        2,
        0
      ),
    },
    [theme.breakpoints.up("lg")]: {
      gridColumn: "3",
      gridRow: "1",
      margin: 0,
    },
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    margin: 0,
    textAlign: "left",
    width: "100%",
  }));
const DescriptionText = styled(Typography)(
  ({theme}) => ({
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    fontSize: "1.1em",
    fontStyle: "italic",
    lineHeight: "1.1em",
  }));
const DescriptionDivider = styled(Divider)(
  ({theme}) => ({
    marginTop: theme.spacing(1),
    width: "100%",
  }));
const ResumeLink = styled(Link)(
  ({theme}) => ({
    cursor: "pointer",
  }));
const MessageButtonContainer = styled("span")<{ networked: boolean }>(
  ({networked: inNetwork, theme}) => ({
    cursor: inNetwork ? "pointer" : "not-allowed",
  })
);

export interface ViewIndividualProfileProps {
  individualId?: Guid;
  profile?: IndividualProfile;
  companyId?: Guid;
  onNetworkStatusUpdated?: () => void;
}

export default function ViewIndividualProfile(
  props: Readonly<ViewIndividualProfileProps>
) {
  const {individualId, onNetworkStatusUpdated} = props;

  const [avatar, setAvatar] = useState<string>();
  const [resume, setResume] = useState<File | Guid | undefined>();
  const [forum, setForum] = useState<Forum | undefined>(undefined);
  const [individualProfile, setIndividualProfile] = useState<
    IndividualProfile | undefined
  >(props.profile);

  const [loading, setLoading] = useState(false);
  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const [isNetworkedWithUser, setIsNetworkedWithUser] =
    useState<boolean>(false);
  const [viewingOwnProfile, setViewingOwnProfile] = useState<boolean>(false);

  const session = useSession();
  const {closeDialog} = useDialog();

  async function loadProfile(abortController: AbortController) {
    try {
      setLoading(true);

      if (!individualId) return;

      const service = new IndividualAPIService(session);
      const returnedIndividual = await service.getIndividualById(
        individualId,
        abortController
      );

      if (!returnedIndividual) throw new Error("Individual not found");

      if (!returnedIndividual.profile)
        throw new Error("Individual profile not found");

      if (returnedIndividual.profile.avatarId) {
        const returnedAvatar = await service.getIndividualAvatar(
          individualId,
          abortController
        );
        setAvatar(returnedAvatar);
      }
      if (returnedIndividual.profile.resumeId) {
        setResume(returnedIndividual.profile.resumeId);
      }
      await initChat(
        returnedIndividual.userId,
        abortController
      );
      if (session.user?.id?.isEqualTo(returnedIndividual.userId)) {
        setViewingOwnProfile(true);
      }
      setIndividualProfile(returnedIndividual.profile);
    } catch (error: any) {
      if (error instanceof CanceledError) return;
      if (error instanceof IndividualHiddenError) {
        enqueueSnackbar(
          "Individual does not have a visible profile",
          {
            variant: "info",
          }
        );
        closeDialog();
      } else {
        console.error(error);
        enqueueSnackbar(
          "Unable to load profile",
          {variant: "error"}
        );
      }
    }
    setLoading(false);
  }

  useEffect(
    () => {
      if (individualProfile && !individualProfile.incomplete) return;
      let abortController = new AbortController();

      loadProfile(abortController);

      return () => {
        abortController.abort();
        abortController = new AbortController();
      };
    },
    []
  );

  async function initChat(userId?: Guid, abortController?: AbortController) {
    if (!userId || !session.isNetworkedWith(userId)) return;
    setIsNetworkedWithUser(true);
    const networkService = new UserNetworkConnectionAPIService(session);
    const returnedForum =
      await networkService.getChatForumForNetworkConnection(
        userId,
        abortController
      );
    setForum(returnedForum);
  }

  async function downloadResume() {
    if (!resume || !individualId) return;

    try {
      const service = new IndividualAPIService(session);
      const response = await service.downloadIndividualResume(
        individualId,
        new AbortController()
      );
      downloadFile(response);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(
        "Unable to download resume",
        {variant: "error"}
      );
    }
  }

  function hideIfNotIPAttorney() {
    return {
      display: individualProfile?.categories?.includes("IPAttorney")
        ? "grid"
        : "none",
    };
  }

  return (
    <MainContainer>
      <HeaderContainer>
        <HeaderInnerContainer>
          <StyledAvatar src={avatar?.toString()}/>
          <TitleContainer>
            <NameChipContainer>
              <Typography variant="h4">
                {individualProfile?.firstName} {individualProfile?.lastName}
              </Typography>
              <span>
                {individualProfile?.categories?.map((category) => (
                  <ProfileTypeChip
                    key={category}
                    label={category}
                    size="small"
                  />
                ))}
              </span>
            </NameChipContainer>
            {individualProfile?.userId && (
              <ButtonContainer>
                <NetworkButton
                  userId={individualProfile?.userId}
                  onNetworkStatusUpdated={async (isNetworked: boolean) => {
                    onNetworkStatusUpdated?.();
                    if (isNetworked) {
                      setIsNetworkedWithUser(true);
                      await initChat(individualProfile?.userId);
                    } else {
                      setIsNetworkedWithUser(false);
                    }
                  }}
                />
                <Tooltip
                  title={(function () {
                    if (chatOpen) return "Back to profile";
                    if (isNetworkedWithUser) return "Send message";
                    if (viewingOwnProfile)
                      return "You can't send a message to yourself";
                    return "You must be in the user's network to send a message";
                  })()}
                >
                  <MessageButtonContainer networked={isNetworkedWithUser}>
                    <Button
                      variant="contained"
                      startIcon={chatOpen ? <BadgeIcon/> : <MessageIcon/>}
                      disableElevation
                      disabled={!isNetworkedWithUser || viewingOwnProfile}
                      color="primary"
                      onClick={() => setChatOpen((prevValue) => !prevValue)}
                    >
                      {chatOpen ? "Back to profile" : "Message"}
                    </Button>
                  </MessageButtonContainer>
                </Tooltip>
              </ButtonContainer>
            )}
          </TitleContainer>
          <DescriptionContainer>
            <DescriptionText variant="subtitle1">
              {individualProfile?.description}
            </DescriptionText>
            <DescriptionDivider/>
          </DescriptionContainer>
        </HeaderInnerContainer>
      </HeaderContainer>
      <ChatContainer>
        {loading && <Loader/>}
        {chatOpen && forum && (
          <Chat forums={[forum]}/>
        )}
      </ChatContainer>
      <ContentContainer>
        {!chatOpen && !loading && (
          <>
            {individualProfile?.professionalRegistration && (
              <GridContainer sx={hideIfNotIPAttorney()}>
                <FieldTitle>Professional Registration #</FieldTitle>
                <Typography>
                  {individualProfile?.professionalRegistration?.value}
                </Typography>
              </GridContainer>
            )}
            {individualProfile?.regionsRegistered &&
              individualProfile.regionsRegistered.length > 0 && (
                <GridContainer sx={hideIfNotIPAttorney()}>
                  <FieldTitle>Regions Registered</FieldTitle>
                  <Typography>
                    {individualProfile?.regionsRegistered?.join(", ")}
                  </Typography>
                </GridContainer>
              )}
            {individualProfile?.technicalBackground && (
              <GridContainer>
                <FieldTitle>Technical Background</FieldTitle>
                <Typography>
                  {individualProfile?.technicalBackground?.value}
                </Typography>
              </GridContainer>
            )}
            {individualProfile?.school && (
              <GridContainer>
                <FieldTitle>Undergraduate School / Law School</FieldTitle>
                <Typography>{individualProfile?.school}</Typography>
              </GridContainer>
            )}
            {individualProfile?.degree && (
              <GridContainer>
                <FieldTitle>Degree(s)</FieldTitle>
                <Typography>{individualProfile?.degree}</Typography>
              </GridContainer>
            )}
            {individualProfile?.numberOfApplicationsDraftedProsecuted && (
              <GridContainer sx={hideIfNotIPAttorney()}>
                <FieldTitle>
                  Number Applications Drafted / Prosecuted
                </FieldTitle>
                <Typography>
                  {individualProfile?.numberOfApplicationsDraftedProsecuted}
                </Typography>
              </GridContainer>
            )}
            {individualProfile?.numberOfAllowedApplications && (
              <GridContainer sx={hideIfNotIPAttorney()}>
                <FieldTitle>Number Allowed Applications</FieldTitle>
                <Typography>
                  {individualProfile?.numberOfAllowedApplications}
                </Typography>
              </GridContainer>
            )}
            {individualProfile?.barAdmissionYear && (
              <GridContainer sx={hideIfNotIPAttorney()}>
                <FieldTitle>Year Of Bar Admission</FieldTitle>
                <Typography>{individualProfile?.barAdmissionYear}</Typography>
              </GridContainer>
            )}
            {individualProfile?.fluentLanguages &&
              individualProfile.fluentLanguages.length > 0 && (
                <GridContainer>
                  <FieldTitle>Fluent Languages</FieldTitle>
                  <Typography>
                    {individualProfile?.fluentLanguages?.join(", ")}
                  </Typography>
                </GridContainer>
              )}
            {individualProfile?.technicalLanguages &&
              individualProfile.technicalLanguages.length > 0 && (
                <GridContainer>
                  <FieldTitle>Technical Languages</FieldTitle>
                  <Typography>
                    {individualProfile?.technicalLanguages?.join(", ")}
                  </Typography>
                </GridContainer>
              )}
            {individualProfile?.regionsLicensed &&
              individualProfile.regionsLicensed.length > 0 && (
                <GridContainer sx={hideIfNotIPAttorney()}>
                  <FieldTitle>States Licensed In</FieldTitle>
                  <Typography>
                    {individualProfile?.regionsLicensed?.join(", ")}
                  </Typography>
                </GridContainer>
              )}
            {individualProfile?.insured &&
              individualProfile.insured.insuredAmount > 0 && (
                <GridContainer>
                  <FieldTitle>Insured Amount</FieldTitle>
                  <Typography>
                    {formatCurrency(
                      new Money(
                        Number.parseFloat(
                          individualProfile?.insured?.insuredAmount.toString() ??
                          "0"
                        ),
                        Currency.USD
                      )
                    )}
                  </Typography>
                </GridContainer>
              )}
            {individualProfile?.billingRate?.billingRate !== "" && (
              <GridContainer>
                <FieldTitle>Billing Rate</FieldTitle>
                <Typography>
                  {formatCurrency(
                    new Money(
                      Number.parseFloat(
                        individualProfile?.billingRate?.billingRate ?? "0"
                      ),
                      Currency.USD
                    )
                  )}
                </Typography>
                <FieldTitle>Billing Unit</FieldTitle>
                <Typography>
                  {individualProfile?.billingRate?.billingUnit ?? "N/A"}
                </Typography>
              </GridContainer>
            )}
            {individualProfile?.phoneNumber?.value !== "" && (
              <GridContainer>
                <FieldTitle>Phone Number</FieldTitle>
                <Link href={`tel:${individualProfile?.phoneNumber?.value}`}>
                  {individualProfile?.phoneNumber?.value}
                </Link>
              </GridContainer>
            )}
            {individualProfile?.email?.value !== "" && (
              <GridContainer>
                <FieldTitle>Email Address</FieldTitle>
                <Link href={`mailto:${individualProfile?.email?.value}`}>
                  {individualProfile?.email?.value}
                </Link>
              </GridContainer>
            )}
            <GridContainer>
              <FieldTitle>On Call</FieldTitle>
              {individualProfile?.isOnCall ? "Yes" : "No"}
            </GridContainer>
            <GridContainer>
              <FieldTitle>Potential Diversity Candidate</FieldTitle>
              {individualProfile?.potentialDiversityCandidate ? (
                "Yes"
              ) : (
                "No"
              )}
            </GridContainer>
            <GridContainer>
              <FieldTitle>Collaborates With Other Individuals</FieldTitle>
              {individualProfile?.collaborates ? "Yes" : "No"}
            </GridContainer>
            {individualProfile?.professionalPublications &&
              individualProfile?.professionalPublications.length > 0 && (
                <GridContainer>
                  <FieldTitle>Professional Publications</FieldTitle>
                  {individualProfile?.professionalPublications?.map(
                    (publication) => (
                      <Link
                        href={publication}
                        key={publication}
                        target="_blank"
                        rel="noopener"
                      >
                        {publication}
                      </Link>
                    )
                  )}
                </GridContainer>
              )}
            {individualProfile?.sampleApplications &&
              individualProfile?.sampleApplications.length > 0 && (
                <GridContainer>
                  <FieldTitle>Sample Applications</FieldTitle>
                  {individualProfile?.sampleApplications?.map((application) => (
                    <Link
                      href={application}
                      key={application}
                      target="_blank"
                      rel="noopener"
                    >
                      {application}
                    </Link>
                  ))}
                </GridContainer>
              )}
            {individualProfile?.links &&
              individualProfile?.links.length > 0 && (
                <GridContainer>
                  <FieldTitle>Other Links</FieldTitle>
                  {individualProfile?.links?.map((link) => (
                    <Link href={link} key={link} target="_blank" rel="noopener">
                      {link}
                    </Link>
                  ))}
                </GridContainer>
              )}
            {resume && (
              <GridContainer>
                <FieldTitle>Resume</FieldTitle>
                <ResumeLink onClick={downloadResume}>View resume</ResumeLink>
              </GridContainer>
            )}
          </>
        )}
      </ContentContainer>
    </MainContainer>
  );
}
