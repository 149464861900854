import Notification from 'notifications/entities/notification/notification';
import MessageNotification from 'notifications/entities/message-notification/message-notification';
import Action from "notifications/values/action/action";
import TopicCategory from "notifications/values/topic-category/topic-category";
import * as Constants from 'common/helpers/constants';


export type NotificationType = Notification & MessageNotification;

export function NotificationLink(notification: NotificationType): string {
    const id = notification.topic?.id;
    const category = notification.topic?.category ?? '';
    let url = Constants.notificationLinks[category as keyof typeof Constants.notificationLinks];

    if (!id) return url;

    if (notification.messageInfo) {
        return `/communications/forums/view/${notification.messageInfo.forum.id}`;
    }

    const action = notification.actionRequired;
    let actionType: 'view' | 'edit' | 'revise' | 'review' = 'view';
    let pageTab = 'active';
    let tab = '';

    switch (action) {
        case Action.DraftProposalReview:
            pageTab = 'drafts';
            actionType = 'review';
            break;
        case Action.DraftProposalReviewApproved:
            pageTab = 'drafts';
            actionType = 'edit';
            break;
        case Action.ProposalReview:
            actionType = 'review';
            break;
        case Action.Review:
        case Action.ProposalRevisionReview:
        case Action.ProposalReviewApproved:
        case Action.HireOrCancel:
            actionType = 'revise';
            break;
    }

    url = url.replace('{pageTab}', pageTab);
    url = url.replace('{action}', actionType);
    url = url.replace('{topicId}', id.value);
    return url.replace('{tab}', tab);
}