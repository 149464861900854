import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  styled,
  Switch,
  Tooltip, Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  ConfirmResponse,
  useConfirmDialog,
} from "app/providers/confirm-dialog";
import {CanceledError} from "axios";
import {AccountType} from "common/values/account-type/account-type";
import AHBoolean from "common/values/boolean/boolean";
import Guid from "common/values/guid/guid";
import _ from "lodash";
import Individual from "marketplace/entities/individual/individual";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";
import {enqueueSnackbar} from "notistack";
import React, {useEffect} from "react";
import {useSession} from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import {
  ProposalField,
  ProposalFieldCategory,
} from "work/entities/proposal/proposal";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import TeamTemplateAPIService from "work/entities/team-template/api/team-template-service";
import TeamTemplate from "work/entities/team-template/team-template";
import ProposalIssues, {ProposalIssueLevel} from "work/values/proposal-issues/proposal-issues";
import DetailedTeam from "work/values/team/detailed-team";
import {TeamMember} from "work/values/team/team-member";
import EditTeam from "work/values/team/view/edit-team";
import Company from "marketplace/entities/company/company";
import {useDialog} from "app/providers/dialog";

const ListContainer = styled(Grid)(
  () => ({
    minHeight: "20rem",
  }));
const TemplateContainer = styled(Grid)(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  }));
const TemplateList = styled(List)(
  ({theme}) => ({
    marginRight: theme.spacing(2),
    maxHeight: "25rem",
    minWidth: "20rem",
    overflowY: "auto",
  }));
const TemplateLoader = styled(CircularProgress)(
  ({theme}) => ({
    color: theme.palette.primary.main,
    margin: theme.spacing(2),
  }));
const TeamSelect = styled(EditTeam)(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(2),
    },
    marginLeft: theme.spacing(2),
  }));
const HeadControls = styled("div")(
  ({theme}) => ({
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
  }));
const IssueContainer = styled("div")(
  ({theme}) => ({
    marginBottom: theme.spacing(3),
  }));

type TeamTabProps = {
  entityId: Guid;
  activeTab: ProposalFieldCategory;
  proposalBuilder: ProposalBuilder;
  issues?: ProposalIssues;
  disableEditing?: boolean;
  onProposalBuilderUpdated: (newProposalBuilder: ProposalBuilder) => void;
  commentThreads: CommentThread[];
  commentsToolTip: string;
  onCommentsClicked?: (
    field: ProposalField,
    name?: string
  ) => void;
};

export default function TeamTab(props: Readonly<TeamTabProps>) {
  const {
    entityId,
    activeTab,
    proposalBuilder,
    disableEditing,
    onProposalBuilderUpdated,
    commentThreads,
    commentsToolTip,
    onCommentsClicked,
  } = props;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [templateTeams, setTemplateTeams] = React.useState<TeamTemplate[]>([]);
  const [selectedLeader, setSelectedLeader] = React.useState<Individual | null>(
    null
  );
  const [selectedMembers, setSelectedMembers] = React.useState<Individual[]>(
    []
  );

  const issues = React.useMemo(
    () => props.issues?.entries.filter(issue => [
      ProposalField.Team,
      ProposalField.TeamRestriction,
      ProposalField.TeamLeader,
      ProposalField.TeamMember(issue.field.id ?? Guid.empty)
    ].includes(issue.field)),
    [props.issues]
  );

  const {popDialog} = useDialog();
  const confirm = useConfirmDialog();
  const session = useSession();
  const isLargeDownDisplay = useMediaQuery((theme: any) =>
    theme.breakpoints.down("lg")
  );

  useEffect(
    () => {
      if (activeTab !== ProposalFieldCategory.Team) return;
      let abortController = new AbortController();
      getTemplateTeams(abortController);

      return () => {
        abortController.abort();
        abortController = new AbortController();
      };
    },
    [activeTab]
  );

  useEffect(
    () => {
      setDefaultProposalTeamLeader();
      setSelectedLeader(proposalBuilder.currentSpec.team?.leader ?? null);
      setSelectedMembers(proposalBuilder.currentSpec.team?.members ?? []);
    },
    [proposalBuilder]
  );

  function setDefaultProposalTeamLeader() {
    if (
      !proposalBuilder.currentSpec.team?.leader &&
      session.accountType === AccountType.Vendor &&
      session.context?.isVendorRepresentative &&
      session.user?.id &&
      session.user.companyEntityId &&
      session.user.individualId &&
      session.user.asVendorRepresentative
    ) {
      const company = new Company(
        session.company?.id ?? Guid.generate(),
        session.user.companyEntityId
      );
      const defaultTeam = new DetailedTeam(
        new Individual(
          session.user.individualId,
          session.user.id,
          new IndividualProfile(
            session.user.id,
            session.user.name.firstName ?? "",
            session.user.name.lastName ?? ""
          ),
          company
        ),
        []
      );
      onProposalBuilderUpdated(proposalBuilder.setTeam(defaultTeam));
    }
  }

  async function getTemplateTeams(
    abortController: AbortController
  ): Promise<void> {
    if (!entityId || !session.user?.isCompanyManager) return;

    try {
      setIsLoading(true);
      const teamTemplateService = new TeamTemplateAPIService(session);
      const templates = await teamTemplateService.getTeamTemplates(
        entityId,
        session.context?.viewingAsVendor
          ? AccountType.Vendor
          : AccountType.Client,
        abortController
      );
      setTemplateTeams(templates);
    } catch (error) {
      if (error instanceof CanceledError) return;
      console.error(error);
    }
    setIsLoading(false);
  }

  function updateTeamOnProposalBuilder(
    leader?: Individual,
    members?: Individual[]
  ) {
    const team = new DetailedTeam(
      leader,
      members ?? []
    );
    onProposalBuilderUpdated(proposalBuilder.setTeam(team));
    onCommentsClicked?.(
      ProposalField.Team,
      "Team"
    );
  }

  function handleTemplateExpanded(template: TeamTemplate) {
    const templates = [...templateTeams];
    let targetTemplate = templates.find((team) =>
      team.id?.isEqualTo(template.id)
    );
    template.isExpanded = !targetTemplate?.isExpanded;

    setTemplateTeams(templates);
  }

  async function handleTemplateSelectionToggled(
    template: TeamTemplate
  ): Promise<void> {
    try {
      if (!template.id) {
        return Promise.reject(new Error("No template selected"));
      }
      let updatedSelectedTemplateIds = session.context?.viewingAsVendor
        ? proposalBuilder.currentSpec.vendorTeamTemplateIds.map((id) =>
          id.clone()
        )
        : proposalBuilder.currentSpec.clientTeamTemplateIds.map((id) =>
          id.clone()
        );

      const isAlreadySelected = updatedSelectedTemplateIds.some((id) =>
        id.isEqualTo(template.id)
      );

      let updatedTeam = proposalBuilder.currentSpec.team;
      if (isAlreadySelected) {
        updatedSelectedTemplateIds = updatedSelectedTemplateIds.filter(
          (id) => !id.isEqualTo(template.id)
        );
      } else {
        updatedSelectedTemplateIds.push(template.id);

        const newLeader = await getNewLeaderFromTemplate(template);
        const members = getNewMembersFromTemplate(template);
        setSelectedLeader(newLeader);
        setSelectedMembers(members);
        updatedTeam = new DetailedTeam(
          newLeader,
          members
        );
      }
      let updatedProposalBuilder = session.context?.viewingAsVendor
        ? proposalBuilder.setVendorTeamTemplateIds(updatedSelectedTemplateIds)
        : proposalBuilder.setClientTeamTemplateIds(updatedSelectedTemplateIds);
      if (updatedTeam) {
        updatedProposalBuilder = updatedProposalBuilder.setTeam(updatedTeam);
      }

      onProposalBuilderUpdated(updatedProposalBuilder);
      onCommentsClicked?.(
        ProposalField.Team,
        "Team"
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to apply template. Please try again",
        {
          variant: "error",
        }
      );
    }
  }

  function getNewMembersFromTemplate(template: TeamTemplate) {
    return _.uniqWith(
      [
        ...template.members,
        ...selectedMembers
      ],
      (a, b) =>
        (!a.userId && !b.userId) || (a.userId?.isEqualTo(b.userId) ?? false)
    );
  }

  async function getNewLeaderFromTemplate(
    template: TeamTemplate
  ): Promise<Individual> {
    if (!selectedLeader) return template.leader;

    const templateLeaderAlreadySelected = selectedLeader.userId?.isEqualTo(
      template.leader.userId
    );
    if (templateLeaderAlreadySelected) {
      return template.leader;
    } else {
      const response = await confirm({
        title: "Replace leader?",
        message:
          "Do you want to replace the current team leader with the one from the template?",
        okButtonText: "Replace",
        cancelButtonText: "Keep Current Leader",
      });

      if (response !== ConfirmResponse.Ok) {
        return selectedLeader;
      }
    }
    return template.leader;
  }

  function handleTeamLeaderUpdated(leader: Individual | null): void {
    if (getIsMemberAlreadySelected(leader)) return;
    if (!leader) {
      setSelectedLeader(null);
      updateTeamOnProposalBuilder(
        undefined,
        selectedMembers
      );
      return;
    }

    if (!leader.entityId) {
      enqueueSnackbar(
        "Selected leader is not associated with an entity",
        {
          variant: "error",
        }
      );
      return;
    }

    setSelectedLeader(leader);
    updateTeamOnProposalBuilder(
      leader,
      selectedMembers
    );
  }

  function handleTemplateLeaderToggled(leader: Individual): void {
    const isAlreadySelected = getIsMemberAlreadySelected(leader);

    let teamLeader: Individual | null = leader;
    if (isAlreadySelected) {
      teamLeader = null;
    } else if (!teamLeader.entityId) {
      enqueueSnackbar(
        "Selected leader is not associated with an entity",
        {
          variant: "error",
        }
      );
      return;
    }

    setSelectedLeader(teamLeader);
    updateTeamOnProposalBuilder(
      teamLeader ?? undefined,
      selectedMembers
    );
  }

  function handleTeamMemberAdded(member: Individual) {
    const isAlreadySelected = getIsMemberAlreadySelected(member);

    if (isAlreadySelected) {
      handleTeamMemberRemoved(member.userId);
    } else {
      let updatedMembers = [
        ...selectedMembers,
        member
      ];
      setSelectedMembers(updatedMembers);
      updateTeamOnProposalBuilder(
        selectedLeader ?? undefined,
        updatedMembers
      );
    }
  }

  function getIsMemberAlreadySelected(
    member: TeamMember | Individual | null
  ): boolean {
    if (!member) return false;
    return (
      (selectedLeader?.userId?.isEqualTo(member.userId) ?? false) ||
      selectedMembers.some((m) => m.userId?.isEqualTo(member.userId))
    );
  }

  function handleTeamMemberRemoved(memberUserId?: Guid) {
    if (!memberUserId) {
      // Remove all members
      setSelectedMembers([]);
      updateTeamOnProposalBuilder(
        selectedLeader ?? undefined,
        []
      );
      return;
    }

    const updatedMembers = selectedMembers.filter(
      (m) => !m.userId?.isEqualTo(memberUserId)
    );
    setSelectedMembers(updatedMembers);
    updateTeamOnProposalBuilder(
      selectedLeader ?? undefined,
      updatedMembers
    );
  }

  async function handleMarketplaceTeamSelected(team: MarketplaceTeam) {
    if (selectedLeader || selectedMembers.length > 0) {
      const response = await confirm({
        title: "Replace Team?",
        message: "Are you sure you want to replace the current team?",
        okButtonText: "Replace",
      });

      if (response === ConfirmResponse.Cancel) return;
    }

    if (!team.leader.profile?.firstName || !team.leader.profile?.lastName) {
      throw new Error("Team leader profile is undefined");
    }

    setSelectedLeader(team.leader);
    setSelectedMembers(team.memberships);
    updateTeamOnProposalBuilder(
      team.leader,
      team.memberships
    );
    popDialog();
  }

  function renderTemplates() {
    const selectedTemplateIds = session.context?.viewingAsVendor
      ? proposalBuilder.currentSpec.vendorTeamTemplateIds
      : proposalBuilder.currentSpec.clientTeamTemplateIds;
    return (
      <TemplateList subheader={<ListSubheader>Templates</ListSubheader>}>
        {templateTeams.length === 0 && (
          <>
            {isLoading ? (
              <TemplateLoader size={32} thickness={4}/>
            ) : (
              <ListItem key="noTemplates">
                <ListItemText>No Templates</ListItemText>
              </ListItem>
            )}
          </>
        )}
        {templateTeams.map((template: TeamTemplate) => {
          const isSelected = selectedTemplateIds.some((id) =>
            id.isEqualTo(template.id)
          );

          return (
            <div key={template.id?.toString()}>
              <ListItem
                secondaryAction={
                  <Tooltip
                    title={`${template.isExpanded ? "Hide" : "Show"} Members`}
                  >
                    <span>
                      <IconButton
                        edge="end"
                        onClick={() => handleTemplateExpanded(template)}
                        size="medium"
                      >
                        {template.isExpanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                      </IconButton>
                    </span>
                  </Tooltip>
                }
              >
                <ListItemIcon>
                  <Tooltip
                    title={
                      !isSelected ? "Select Template" : "Deselect Template"
                    }
                  >
                    <span>
                      <Checkbox
                        edge="start"
                        color="primary"
                        checked={isSelected}
                        indeterminate={
                          !isSelected &&
                          (template.userIsReferenced(selectedLeader?.userId) ||
                            selectedMembers.some((member) =>
                              template.userIsReferenced(member.userId)
                            ))
                        }
                        tabIndex={-1}
                        disableRipple
                        onChange={() =>
                          handleTemplateSelectionToggled(template)
                        }
                      />
                    </span>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>{template.name.value}</ListItemText>
              </ListItem>
              <Collapse
                in={template.isExpanded}
                timeout="auto"
                sx={{backgroundColor: "#FAFAFA"}}
              >
                <Divider/>
                <List
                  disablePadding
                  dense
                  subheader={<ListSubheader>Leader</ListSubheader>}
                >
                  {renderTemplateLeader(template)}
                </List>
                <List
                  disablePadding
                  dense
                  subheader={<ListSubheader>Members</ListSubheader>}
                >
                  {renderTemplateMembers(template)}
                </List>
                <Divider/>
              </Collapse>
            </div>
          );
        })}
      </TemplateList>
    );
  }

  function renderTemplateLeader(template: TeamTemplate) {
    return (
      <ListItem
        key="teamLeader"
        secondaryAction={
          <Checkbox
            edge="end"
            color="primary"
            checked={
              selectedLeader?.userId?.isEqualTo(template?.leader?.userId) ??
              false
            }
            tabIndex={-1}
            disableRipple
            onClick={() => handleTemplateLeaderToggled(template?.leader)}
          />
        }
      >
        <ListItemText>{template.leader.getFullName()}</ListItemText>
      </ListItem>
    );
  }

  function renderTemplateMembers(template: TeamTemplate) {
    return template?.members?.map((member: Individual) => (
      <ListItem
        key={member.userId?.value ?? Guid.generate().value}
        secondaryAction={
          <Checkbox
            edge="end"
            color="primary"
            checked={selectedMembers?.some((m) =>
              m.userId?.isEqualTo(member.userId)
            )}
            tabIndex={-1}
            disableRipple
            onClick={() => {
              if (
                selectedMembers.some((m) => m.userId?.isEqualTo(member.userId))
              ) {
                handleTeamMemberRemoved(member.userId);
              } else {
                handleTeamMemberAdded(member);
              }
            }}
          />
        }
        onClick={() => handleTeamMemberAdded(member)}
      >
        <ListItemText
          style={{whiteSpace: "pre-line"}}
          primary={`${member.profile?.firstName} ${member.profile?.lastName}`}
        />
      </ListItem>
    ));
  }

  if (activeTab !== ProposalFieldCategory.Team) return null;

  return (
    <>
      <HeadControls>
        {(issues?.length ?? 0) > 0 && (
          <IssueContainer>
            {issues?.map((issue) => (
              <Typography
                key={issue.field?.key}
                color={issue.level === ProposalIssueLevel.Critical ? "error" : "warning"}
                variant="body1"
              >
                {issue.description}
              </Typography>
            ))}
          </IssueContainer>
        )}
        <FormControlLabel
          checked={proposalBuilder.currentSpec.teamRestricted.value}
          control={
            <Switch
              disabled={disableEditing}
              checked={proposalBuilder.currentSpec.teamRestricted.value}
              onChange={(_event, checked) => {
                onProposalBuilderUpdated(
                  proposalBuilder.setTeamRestricted(new AHBoolean(checked))
                );
                onCommentsClicked?.(
                  ProposalField.Team,
                  "Team Restricted"
                );
              }}
              color="primary"
            />
          }
          label={
            proposalBuilder.currentSpec.teamRestricted.value ? (
              <strong>Team Restricted</strong>
            ) : (
              "Team Not Restricted"
            )
          }
        />
      </HeadControls>
      <ListContainer container direction="row">
        {!disableEditing && (
          <>
            <TemplateContainer>
              {renderTemplates()}
              {isLargeDownDisplay && <Divider/>}
            </TemplateContainer>
            {!isLargeDownDisplay && (
              <Grid>
                <Divider orientation="vertical"/>
              </Grid>
            )}
          </>
        )}
        <Grid size="grow">
          <TeamSelect
            leader={selectedLeader}
            members={selectedMembers}
            commentThreads={commentThreads}
            commentsToolTip={commentsToolTip}
            disableEditing={disableEditing}
            onCommentsClicked={onCommentsClicked}
            onLeaderUpdated={handleTeamLeaderUpdated}
            onMemberAdded={handleTeamMemberAdded}
            onMemberRemoved={handleTeamMemberRemoved}
            onMarketplaceTeamSelected={handleMarketplaceTeamSelected}
          />
        </Grid>
      </ListContainer>
    </>
  );
}
