import Proposal, { CompleteProposalSpec, ProposalMetaInfo } from "work/entities/proposal/proposal";
import ProposalAPIService from "work/entities/proposal/api/proposal-api-service";
import Session from "users/session/session";
import User from "users/entities/user/user";


export default class ProposalFactory {
    public static createProposal(spec: CompleteProposalSpec, currentUser: User, session: Readonly<Session>, metaInfo?: ProposalMetaInfo): Proposal {
        return new Proposal(new ProposalAPIService(session), currentUser, spec, undefined, metaInfo, undefined, undefined, false);
    }

    public static updateProposal(oldProposal: Proposal, currentUser: User, spec: CompleteProposalSpec, session: Readonly<Session>): Proposal {
        return new Proposal(new ProposalAPIService(session), currentUser, spec, oldProposal, oldProposal.metaInfo, undefined, undefined, false);
    }
}