import Guid from "common/values/guid/guid";
import moment from "moment";
import ForumAPIResponse from "messaging/entities/forum/api/response-contracts/forum-api-response";
import MessageFileInfoAPIResponse
  from "messaging/values/attachment/api/response-contracts/message-file-info-api-response";
import Message from "messaging/entities/message/message";
import ReadReceiptAPIResponse from "messaging/values/read-receipts/api/response-contracts/read-receipt-api-response";

export default class MessageAPIResponse {
  id?: string;
  senderId?: string;
  forum?: ForumAPIResponse;
  content?: string;
  subject?: string;
  created?: string;
  published?: string;
  attachments?: MessageFileInfoAPIResponse[];
  issues?: string[];
  readReceipts?: ReadReceiptAPIResponse[];
  deleted?: string;
  isAutoGenerated?: boolean;

  deserialize(): Message {
    if (!this.id) throw new Error("Message id not returned.");
    if (!this.created) throw new Error("Message created date not returned.")
    if (!this.content && !this.attachments) throw new Error("No message content or attachment returned.")
    if (!this.forum) throw new Error("Message forum not returned.")

    const forum = Object.assign(
      new ForumAPIResponse(),
      this.forum
    ).deserialize();
    const attachments = this.attachments?.map((file: MessageFileInfoAPIResponse) => {
      return Object.assign(
        new MessageFileInfoAPIResponse(),
        file
      ).deserialize();
    });

    return new Message(
      forum,
      this.content ?? "",
      this.senderId ? new Guid(this.senderId) : null,
      this.subject ?? "",
      moment(this.created),
      this.published ? moment(this.published) : undefined,
      attachments,
      new Guid(this.id),
      this.issues,
      this.readReceipts?.map(
        (readReceipt) => Object.assign(
          new ReadReceiptAPIResponse(),
          readReceipt
        ).deserialize()
      ).filter((readReceipt) => readReceipt !== undefined) ?? [],
      this.deleted ? moment(this.deleted) : undefined,
      this.isAutoGenerated ?? false
    );
  }
}
