import Guid from "common/values/guid/guid";
import DocumentInfoAPIResponse from "work/entities/proposal/api/response-contracts/document-info-api-response";
import WorkDocument, { WorkDocumentType } from "work/values/work-document/work-document";

export default class ReplaceableDocumentAPIResponse {
  documentId?: string;
  replacesId?: string;
  documentInfo?: DocumentInfoAPIResponse;

  public deserialize(documentType: WorkDocumentType): WorkDocument {
    if (!this.documentId) throw new Error('documentId is required, but not returned from the API');
    const workDocument = Object.assign(new DocumentInfoAPIResponse(), this.documentInfo).deserialize();

    return new WorkDocument(
      workDocument.id,
      workDocument.name,
      workDocument.creatorId,
      workDocument.fileSize,
      workDocument.fileType,
      workDocument.created,
      undefined,
      undefined,
      workDocument.topics,
      workDocument.templateIds,
      workDocument.hasSystemPermissions,
      documentType,
      this.replacesId ? new Guid(this.replacesId) : undefined
    );
  }
}
