import AddIcon from "@mui/icons-material/Add";
import MessageIcon from "@mui/icons-material/Message";
import ShareIcon from "@mui/icons-material/Share";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Link,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useConfirmDialog } from "app/providers/confirm-dialog";
import { Hit } from "common/contracts/search-results";
import { MarketplaceResultType } from "common/helpers/constants";
import Guid from "common/values/guid/guid";
import MarketplaceSearchInfoAPIResponse from "marketplace/api/response-contracts/marketplace-search-info-api-response";
import MarketplaceTeamAPIService from "marketplace/entities/marketplace-team/api/marketplace-team-api-service";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import { BookmarkType } from "marketplace/values/bookmark/bookmark";
import ViewCompanyProfile from "marketplace/values/company-profile/view/view-company-profile";
import MarketplaceIndividualInfoAPIResponse from "marketplace/values/individual-profile/api/response-contracts/marketplace-individual-info-api-response";
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import MarketplaceTeamInfoAPIResponse from "marketplace/values/team-profile/api/response-contracts/marketplace-team-info-api-response";
import ViewTeamProfile from "marketplace/values/team-profile/view/view-team-profile";
import BookmarkButton from "marketplace/view/bookmark-button";
import Forum from "messaging/entities/forum/forum";
import Chat from "messaging/entities/forum/view/components/chat";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { NavigateFunction } from 'react-router';
import NetworkButton from "users/entities/user-network-connection/view/components/network-button";
import Session from "users/session/session";
import DirectProposalButton from "work/view/components/direct-proposal-button";

const ResultCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    display: "block",
  },
  display: "flex",
  flex: "1 0 auto",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  "&:first-of-type": {
    marginTop: 0,
  },
}));
const ResultHeader = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "grid",
  columnGap: theme.spacing(2),
  gridTemplateColumns: "auto 1fr",
}));
const ResultCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: "inherit",
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(3),
}));
const ResultCardActions = styled(CardActions)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    alignContent: "flex-end",
    alignItems: "unset",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.down("lg")]: {
    alignContent: "flex-start",
    alignItems: "unset",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  alignContent: "center",
  alignItems: "flex-end",
  flex: 0,
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "space-evenly",
  paddingRight: theme.spacing(3),
}));
const ResultName = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  textDecoration: "underline",
  "& em": {
    backgroundColor: "rgba(255, 255, 0, 0.25)",
    fontStyle: "normal",
  },
}));
const TypeChip = styled(Chip)(({ theme }) => ({
  borderColor: "#0a0",
  borderStyle: "dashed",
  color: "#0a0",
  opacity: 0.66,
}));
const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2),
  minWidth: theme.spacing(30),
}));
const StyledNetworkButton = styled(NetworkButton)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2),
  minWidth: theme.spacing(30),
}));
const StyledBookmarkButton = styled(BookmarkButton)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2),
  minWidth: theme.spacing(30),
}));
const Subtitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));
const ChatCardContent = styled(CardContent)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  width: "100%",
}));
const Details = styled("div")(({ theme }) => ({
  [theme.breakpoints.up(1920)]: {
    columnCount: 2,
  },
  [theme.breakpoints.down(1920)]: {
    columnCount: 1,
  },
  [theme.breakpoints.down("xl")]: {
    columnCount: 2,
  },
  [theme.breakpoints.down("lg")]: {
    columnCount: 2,
  },
  [theme.breakpoints.down("md")]: {
    columnCount: 1,
  },
  columnGap: theme.spacing(2),
  columnFill: "auto",
  marginTop: theme.spacing(2),
  "& em": {
    backgroundColor: "rgba(255, 255, 0, 0.25)",
    fontStyle: "normal",
  },
}));
const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.black,
  cursor: "pointer",
  textDecoration: "underline",
})) as typeof Link;
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  cursor: "pointer",
  height: theme.spacing(10),
  width: theme.spacing(10),
}));
const ResultAvatarCardMedia = styled(CardMedia)(({ theme }) => ({
  cursor: "pointer",
  height: "auto",
  width: "15vw",
})) as typeof CardMedia;
const ResultAvatar = styled(Avatar)(({ theme }) => ({
  cursor: "pointer",
  height: "auto",
  width: "15vw",
}));
const GridRow = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  marginBottom: theme.spacing(1),
}));
const MemberContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(2),
  gridAutoFlow: "row",
}));
const MemberName = styled("div")(() => ({
  whiteSpace: "nowrap",
}));
const SelectTeamButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));
const NameContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

export interface TeamResultProps {
  hit: Hit<MarketplaceSearchInfoAPIResponse>;
  result: MarketplaceTeamInfoAPIResponse;
  embedded: boolean;
  isNetworked: boolean;
  session: Readonly<Session>;
  navigate?: NavigateFunction;
  getText: (
    result: Hit<MarketplaceSearchInfoAPIResponse>,
    fieldNames: string[],
    base?: unknown,
    noCommas?: boolean
  ) => { __html: string | TrustedHTML };
  handleResultClicked: (
    event: React.MouseEvent,
    profileId: Guid,
    profileType: MarketplaceResultType,
    component: React.JSX.Element,
    fullWidth?: boolean
  ) => void;
  onTeamSelected?: (team: MarketplaceTeam) => void;
}

export default function TeamResult(props: Readonly<TeamResultProps>) {
  const {
    hit,
    result,
    embedded,
    isNetworked,
    session,
    navigate,
    getText,
    handleResultClicked,
    onTeamSelected,
  } = props;

  const team = result?.deserializeToTeam();
  const [avatar, setAvatar] = React.useState<string>();
  const [showChat, setShowChat] = React.useState<boolean>(false);
  const [forum, setForum] = React.useState<Forum>();

  const isXlDownDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl")
  );
  const isXlUpDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("xl")
  );

  const confirm = useConfirmDialog();

  const leaderProfileLink = getProfileLink(
    ["firstName", "lastName"],
    result?.leader,
    result?.leader?.id,
    ViewIndividualProfile,
    true
  );
  const leaderCompanyProfileLink = getProfileLink(
    ["companyName"],
    result?.leader,
    result?.leader?.companyId,
    ViewCompanyProfile,
    true,
    true
  );
  const memberProfileLink = (member: MarketplaceIndividualInfoAPIResponse) =>
    getProfileLink(
      ["firstName", "lastName"],
      member,
      member?.id,
      ViewIndividualProfile,
      true
    );
  const memberCompanyProfileLink = (
    member: MarketplaceIndividualInfoAPIResponse
  ) =>
    getProfileLink(
      ["companyName"],
      member,
      member?.companyId,
      ViewCompanyProfile,
      true,
      true
    );

  React.useEffect(() => {
    async function loadAvatar() {
      if (!result?.id) return;
      try {
        const teamId = new Guid(result?.id);
        const service = new MarketplaceTeamAPIService(session);
        const abortController = new AbortController();
        const avatar = await service.getTeamAvatar(teamId, abortController);
        setAvatar(avatar);
      } catch (error: any) {
        console.error(error);
      }
    }

    if (result?.avatar) loadAvatar();
  }, [result]);

  async function contactTeam() {
    if (!result?.id) return;
    try {
      const teamId = new Guid(result?.id);
      const service = new MarketplaceTeamAPIService(session);
      const forum = await service.contactTeam(teamId);
      setForum(forum);
      setShowChat(true);
    } catch (error: any) {
      console.error(error);
    }
  }

  function getAddButton(result: MarketplaceTeamInfoAPIResponse) {
    if (!onTeamSelected) return null;

    return (
      <SelectTeamButton
        variant="outlined"
        color="primary"
        aria-label="Select Team"
        startIcon={<AddIcon />}
        disableElevation
        onClick={() => onTeamSelected(result.deserializeToTeam())}
      >
        Select Team
      </SelectTeamButton>
    );
  }

  function getProfileLink(
    fields: string[],
    base: unknown,
    profileId: string | undefined,
    ProfileComponent:
      | typeof ViewIndividualProfile
      | typeof ViewCompanyProfile
      | typeof ViewTeamProfile,
    noCommas: boolean = false,
    secondary: boolean = false
  ) {
    if (!profileId) return null;
    return (
      <StyledLink
        style={{
          fontSize: secondary ? "0.85em" : "1em",
          opacity: secondary ? 0.6 : 1,
        }}
        component={Typography}
        dangerouslySetInnerHTML={getText(hit, fields, base, noCommas)}
        onClick={(event) => openProfile(event, profileId)}
      />
    );
  }

  function openProfile(event: React.MouseEvent, id: string | undefined) {
    if (!id) return;
    const profileId = new Guid(id);
    handleResultClicked(
      event,
      profileId,
      MarketplaceResultType.Team,
      <ViewTeamProfile
        teamId={profileId}
        confirmDialog={confirm}
      />,
      true
    );
  }

  function showProposalButtons(): boolean {
    if (!navigate) return false;
    return result?.id !== undefined && result?.leader?.userId !== session?.user?.id?.value;
  }

  function handleSendProposalToResult(targetUrl: URL): void {
    navigate?.(targetUrl.pathname + targetUrl.search);
  }

  return (
    <React.Fragment>
      {showChat && (
        <ResultCard raised={false} variant="outlined">
          <ChatCardContent>
            <Chat
              forums={forum ? [forum] : []}
              autoFocus={true}
              onClose={() => setShowChat(false)}
            />
          </ChatCardContent>
        </ResultCard>
      )}
      {!showChat && (
        <ResultCard raised={false} variant="outlined">
          {isXlUpDisplaySize && (
            <>
              <ResultAvatarCardMedia
                style={{ display: !avatar ? "none" : undefined }}
                component="img"
                image={avatar}
                onClick={(event) =>
                  openProfile(event, result?.id)
                }
              />
              <ResultAvatar
                style={{ display: avatar ? "none" : undefined }}
                variant="square"
                onClick={(event) =>
                  openProfile(event, result?.id)
                }
              />
            </>
          )}
          <ResultCardContent>
            <ResultHeader>
              {isXlDownDisplaySize && (
                <span>
                  <SmallAvatar
                    style={{ display: !avatar ? "none" : undefined }}
                    variant="circular"
                    src={avatar}
                    onClick={(event) =>
                      openProfile(event, result?.id)
                    }
                  />
                  <SmallAvatar
                    style={{ display: avatar ? "none" : undefined }}
                    variant="circular"
                    onClick={(event) =>
                      openProfile(event, result?.id)
                    }
                  />
                </span>
              )}
              <span>
                <NameContainer>
                  <ResultName
                    variant="h5"
                    dangerouslySetInnerHTML={getText(
                      hit,
                      ["name"],
                      result,
                      true
                    )}
                    onClick={(event) =>
                      openProfile(event, result?.id)
                    }
                  />
                  {embedded && getAddButton(result)}
                </NameContainer>
                <TypeChip label="Team" variant="outlined" size="small" />
              </span>
            </ResultHeader>
            <Details>
              {result?.description && (
                <GridRow>
                  <Subtitle variant="body1">About Us:</Subtitle>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: (() => {
                        const description = getText(hit, ["description"]).__html as string;
                        return description.length > 250
                          ? `${description.substring(0, 250)}...`
                          : description;
                      })(),
                    }}
                  />
                </GridRow>
              )}
              {result?.leader?.firstName && result?.leader?.lastName && (
                <GridRow>
                  <Subtitle variant="body1">Leader:</Subtitle>
                  {leaderProfileLink} {leaderCompanyProfileLink}
                </GridRow>
              )}
              <GridRow>
                <Subtitle variant="body1">
                  {`${result.members?.length.toString() ?? "0"} Members:`}
                </Subtitle>
                <MemberContainer>
                  {result?.members?.map(
                    (member: MarketplaceIndividualInfoAPIResponse) => (
                      <MemberName key={member.id?.toString()}>
                        {memberProfileLink(member)}{" "}
                        {memberCompanyProfileLink(member)}
                      </MemberName>
                    )
                  )}
                </MemberContainer>
              </GridRow>
            </Details>
          </ResultCardContent>
          <ResultCardActions disableSpacing>
            {!embedded && (
              <>
                <StyledNetworkButton
                  userId={team.leader.userId}
                />
                <StyledBookmarkButton
                  type={BookmarkType.Team}
                  vendorId={result?.id ? new Guid(result?.id) : undefined}
                />
                <Tooltip
                  title={
                    !isNetworked
                      ? "You must be in a team's network before contacting them"
                      : ""
                  }
                >
                  <div style={{ display: "contents", width: "100%" }}>
                    <StyledButton
                      startIcon={<MessageIcon />}
                      variant="outlined"
                      disabled={!isNetworked}
                      onClick={contactTeam}
                    >
                      Send Message
                    </StyledButton>
                  </div>
                </Tooltip>
                <StyledButton
                  aria-label="share"
                  variant="outlined"
                  startIcon={<ShareIcon />}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}/marketplace/team/${result?.id}`
                    );
                    enqueueSnackbar("Link copied to clipboard", {
                      variant: "info",
                    });
                  }}
                >
                  Share
                </StyledButton>
                {showProposalButtons() &&
                  <>
                    <DirectProposalButton
                      targetTeamId={new Guid(result?.id!)}
                      targetTeamName={result?.name}
                      onClick={handleSendProposalToResult}
                      useStyledButton={true}
                      action={"create"}
                    />
                    <DirectProposalButton
                      targetTeamId={new Guid(result?.id!)}
                      targetTeamName={result?.name}
                      onClick={handleSendProposalToResult}
                      useStyledButton={true}
                      action={"request"}
                    />
                  </>}
              </>
            )}
          </ResultCardActions>
        </ResultCard>
      )}
    </React.Fragment>
  );
}
