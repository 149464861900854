import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { createContext, useCallback, useContext, useState } from "react";

export interface ConfirmDialogProps {
  title?: string;
  message?: string;
  okButtonText?: string;
  cancelButtonText?: string;
  alternativeOkText?: string;
}
export enum ConfirmResponse {
  Ok,
  AltOk,
  Cancel
}
export type ConfirmDialogType = (props: ConfirmDialogProps) => Promise<ConfirmResponse>;

export const ConfirmDialogContext = createContext<ConfirmDialogType>(undefined!);

export default function ConfirmDialogProvider({ children }: Readonly<{ children: React.ReactNode }>) {
  const [promise, setPromise] = useState<{ resolve: (value: ConfirmResponse) => void } | null>(null);
  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [okButtonText, setOkButtonText] = useState<string>('Ok');
  const [cancelButtonText, setCancelButtonText] = useState<string>('Cancel');
  const [alternativeOkText, setAlternativeOkText] = useState<string>('');

  const confirm = useCallback((props: ConfirmDialogProps) => new Promise((resolve: (value: ConfirmResponse) => void, reject) => {
    setTitle(props.title ?? 'Confirm');
    setMessage(props.message ?? 'Are you sure?');
    setOkButtonText(props.okButtonText ?? 'Ok');
    setCancelButtonText(props.cancelButtonText ?? 'Cancel');
    setAlternativeOkText(props.alternativeOkText ?? '');
    setPromise({ resolve });
  }), []);

  const handleClose = () => {
    setPromise(null);
    setTitle('');
    setMessage('');
    setOkButtonText('Ok');
    setCancelButtonText('Cancel');
    setAlternativeOkText('');
  };

  const handleConfirm = () => {
    promise?.resolve(ConfirmResponse.Ok);
    handleClose();
  };

  const handleAltConfirm = () => {
    promise?.resolve(ConfirmResponse.AltOk);
    handleClose();
  }

  const handleCancel = () => {
    promise?.resolve(ConfirmResponse.Cancel);
    handleClose();
  };

  return (
    <ConfirmDialogContext.Provider value={confirm}>
      {children}
      <Dialog open={promise !== null} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>{okButtonText}</Button>
          {alternativeOkText.length > 0 && (
            <Button onClick={handleAltConfirm}>{alternativeOkText}</Button>
          )}
          <Button onClick={handleCancel}>{cancelButtonText}</Button>
        </DialogActions>
      </Dialog>
    </ConfirmDialogContext.Provider>
  );
}

export function useConfirmDialog() {
  const context = useContext(ConfirmDialogContext);
  if (context === undefined) {
    throw new Error("useConfirmDialog must be used within a ConfirmDialogProvider");
  }
  return context;
}
