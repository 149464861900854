import axios, { AxiosHeaders } from "axios";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import { ExpiredTokenError } from "users/entities/user/api/auth-email-api-service";
import Session from "users/session/session";
import UserInformationAPIResponse from "users/values/user-information/api/response-contract/user-information-api-response";
import UserInformation from "users/values/user-information/user-information";


export default class UserInformationAPIService {
    private readonly headers: AxiosHeaders = new AxiosHeaders();

    constructor(session: Session) {
        if (!session.authToken)
             throw new Error("Cannot create UserInformationApiService without session.");
        this.headers.set("Authorization", `Bearer ${session.authToken.value}`);
    }

    async getUserInformation(session: Readonly<Session>, abortController: AbortController): Promise<UserInformation> {
        if (!session.authToken)
          throw new Error("No auth token found in session");
        try {
          const url = new URL("/users/me", AttorneyHubAPIService.apiBaseUrl);
          const response = await axios.get(
            url.toString(),
            {
              headers: this.headers,
              signal: abortController.signal
            }
          );
          const userInformation = Object.assign(new UserInformationAPIResponse(), response.data);
          const info = userInformation.deserialize();
          return info;
        } catch (error: any) {
          if (error?.response?.status === 401) {
            throw new ExpiredTokenError();
          }
          throw new UserInformationApiServiceError("getUserInformation", error);
        }
      }
};

export class UserInformationApiServiceError extends Error {
    method: string;
    error: any;
    constructor(methodName: string, error: any) {
        super(`Error in ${methodName}: ${error.message}`);

        this.method = methodName;
        this.error = error;
    }
}