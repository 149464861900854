import Message from "messaging/entities/message/message";

export enum CreateMessageParam {
  contentPlainText = "contentPlainText",
  subject = "subject",
  attachments = "attachments",
  isAutoGenerated = "isAutoGenerated",
  publish = "publish"
};

export default class CreateMessageAPIRequest {
  public formData: FormData;

  constructor(message: Message) {
    this.formData = new FormData();
    this.formData.append(
      CreateMessageParam.contentPlainText,
      message.content
    );
    this.formData.append(
      CreateMessageParam.subject,
      message.subject?.toString() ?? ""
    );
    this.formData.append(
      CreateMessageParam.isAutoGenerated,
      message.isAutoGenerated?.toString() ?? "false"
    );
    this.formData.append(
      CreateMessageParam.publish,
      message.markedForPublish?.toString() ?? "false"
    )
    message.attachments.forEach((attachment) => {
      if (attachment.rawData === undefined) return;
      this.formData.append(
        CreateMessageParam.attachments,
        attachment.rawData,
        attachment.name
      );
    });
  }
}
