import { Box, CircularProgress, Container, createTheme, ThemeProvider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import AttorneyHubLogo from "app/assets/ipah_logo.svg";
import Guid from "common/values/guid/guid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserEntityMemberInvitationAPIService from "../api/user-entity-member-invitation-api-service";
import UserEntityMemberInvitation, { UserMemberInvitationNotFoundError } from "../user-entity-member-invitation";
import UserEntityMemberInvitationAcceptanceForm from "./components/user-entity-member-invitation-acceptance-form";
import { useSession } from "users/session/session-context";

const FormContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#404558',
  boxSizing: 'border-box',
  height: '100%',
  overflow: 'auto',
  padding: theme.spacing(5, 1),
  textAlign: 'center',
  width: '100%'
}));
const Logo = styled('img')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  maxWidth: '90%'
}));
const InvitationForm = styled(Container)(({ theme }) => ({
  color: theme.palette.common.white,
  padding: theme.spacing(2),
  position: 'relative',
  width: '100%'
}));
const InvitationFormTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));
const InvitationFormInstructions = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  marginBottom: theme.spacing(2),
  "& a": {
    color: theme.palette.common.white,
    textDecoration: 'underline'
  }
}));
const LoadingContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  margin: theme.spacing(4, 0)
}));
const LoadingIcon = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.common.white,
  height: '64px',
  marginRight: theme.spacing(1.5),
  width: '64px'
}));

type UserMemberInvitationPageProps = {
  className?: string;
  invitationId?: Guid;
};

export default function UserMemberInvitationPage(props: Readonly<UserMemberInvitationPageProps>) {
  const { className } = props;

  const [invitation, setInvitation] = useState<UserEntityMemberInvitation>();
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams<{ invitationId: string }>();
  const theme = createTheme();
  const session = useSession();

  async function loadInvitation() {
    setLoading(true);

    try {
      let invitationId: Guid | undefined = undefined;

      if (props.invitationId)
        invitationId = props.invitationId;
      else if (params.invitationId)
        invitationId = new Guid(params.invitationId);

      if (!invitationId) 
        throw new Error("Invitation ID was not provided");
      const service = new UserEntityMemberInvitationAPIService(session);
      const abortController = new AbortController();
      const returnedInvitation = await service.getUserEntityMemberInvitationById(invitationId, abortController);
      setInvitation(returnedInvitation);
    } catch (error: any) {
      if (error instanceof UserMemberInvitationNotFoundError) {
        console.warn(error.message);
      } else {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadInvitation();
  }, []);

  function invitationNotFoundMessage() {
    return (
      <InvitationForm className={className}>
        <InvitationFormTitle variant='h4'>
          Invitation Not Found
        </InvitationFormTitle>
        <InvitationFormInstructions>
          The invitation couldn't be found.<br />
          If you believe this is in error please contact <a href="mailto:support@attorneyhub.com" target="_blank" rel="noreferrer">support@attorneyhub</a>.<br />
          You can close this tab
        </InvitationFormInstructions>
      </InvitationForm>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <FormContainer className={className}>
        <Logo src={AttorneyHubLogo} alt="AttorneyHub Logo" />
        {loading && (
          <LoadingContainer>
            <LoadingIcon />
            <Typography variant="h4">
              Loading...
            </Typography>
          </LoadingContainer>
        )}
        {!invitation && !loading && invitationNotFoundMessage()}
        <UserEntityMemberInvitationAcceptanceForm invitation={invitation} />
      </FormContainer>
    </ThemeProvider>
  )
}
