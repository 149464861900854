import {ProposalField} from "work/entities/proposal/proposal";
import TextChange from "work/entities/proposal/redlining/_diff/text-change";

export class RedlineChange {
  field: ProposalField | null;
  isResolved: boolean;
  action: RedlineAction;
  textChanges?: TextChange[]

  constructor(
    field?: ProposalField, isResolved?: boolean, action?: RedlineAction, textChanges?: TextChange[],
    commentFieldOverride?: ProposalField,
  ) {
    this.field = field ?? null;
    this.isResolved = isResolved ?? false;
    this.action = action ?? RedlineAction.Edit;
    this.textChanges = textChanges ?? [];
  }

  static fromJSON(object: any): RedlineChange {
    const redlineChange = new RedlineChange();
    const field = ProposalField.fromJSON(object.field);
    if (field) {
      redlineChange.field = field;
    }
    redlineChange.isResolved = object.isResolved;
    redlineChange.action = RedlineAction.fromJSON(object.action);
    redlineChange.textChanges = object.textChanges.map((change: any) => TextChange.fromJSON(change));
    return redlineChange;
  }

  public toJSON(): object {
    return {
      field: this.field?.toJSON(),
      isResolved: this.isResolved,
      action: this.action.toString(),
      textChanges: this.textChanges?.map(change => change.toJSON())
    }
  }
}

export class RedlineAction {
  // The present tense of the action MUST match the readonly property name
  static readonly Accept: RedlineAction = new RedlineAction(
    'Accept',
    'Accepted'
  );
  static readonly Reject: RedlineAction = new RedlineAction(
    'Reject',
    'Rejected'
  );
  static readonly UndoEdit: RedlineAction = new RedlineAction(
    'UndoEdit',
    'Undid edit'
  );
  static readonly UndoRemove: RedlineAction = new RedlineAction(
    'UndoRemove',
    'Undid removal of'
  );
  static readonly UndoAccept: RedlineAction = new RedlineAction(
    'UndoAccept',
    'Undid acceptance of'
  );
  static readonly UndoReject: RedlineAction = new RedlineAction(
    'UndoReject',
    'Undid rejection of'
  );
  static readonly Edit: RedlineAction = new RedlineAction(
    'Edit',
    'Edited'
  );
  static readonly ReEdit: RedlineAction = new RedlineAction(
    'ReEdit',
    'Re-edited'
  );
  static readonly Add: RedlineAction = new RedlineAction(
    'Add',
    'Added'
  );
  static readonly Remove: RedlineAction = new RedlineAction(
    'Remove',
    'Removed'
  );

  private constructor(private readonly presentTense: string, public readonly actionDescription: string) {
  }

  toString() {
    return this.presentTense;
  }

  public static fromJSON(obj: keyof typeof RedlineAction): RedlineAction {
    const newAction = RedlineAction[obj] as RedlineAction;
    return newAction;
  }
}