import CheckIcon from '@mui/icons-material/Check';
import { Container, styled, Typography } from '@mui/material';
import { useState } from 'react';

import EmailAddress from 'common/values/email-address/email-address';
import EmailAddressInput from 'common/values/email-address/view/email-address-input';

import LoadingButton from 'common/components/loading-button';
import OpenEntityMemberInvitationAPIService, { InvalidInvitationError } from 'legal-entities/entities/open-entity-member-invitation/api/open-entity-member-invitation-api-service';
import OpenEntityMemberInvitation from 'legal-entities/entities/open-entity-member-invitation/open-entity-member-invitation';
import { useSession } from 'users/session/session-context';

const InvitationForm = styled(Container)(({ theme }) => ({
  color: theme.palette.common.white,
  padding: theme.spacing(2),
  position: 'relative',
  width: '100%'
}));
const InvitationFormTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));
const InvitationFormInstructions = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  marginBottom: theme.spacing(2),
  "& a": {
    color: theme.palette.common.white,
    textDecoration: 'underline'
  }
}));
const InvitationFormTitleError = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: theme.palette.error.main
}));
const InvitationEmailInput = styled(EmailAddressInput)(({ theme }) => ({
  "& .MuiFilledInput-root": {
    backgroundColor: theme.palette.common.white
  },
  "& .MuiFilledInput-root:hover": {
    backgroundColor: theme.palette.common.white
  },
  "& .MuiFilledInput-root.Mui-focused": {
    backgroundColor: theme.palette.common.white
  }
}));
const InvitationAcceptButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: '#E19941',
  color: theme.palette.common.white,
  marginTop: theme.spacing(2),
  "&.MuiButton-root": {
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#FFB949',
    },
    "&.Mui-disabled": {
      color: "rgba(255, 255, 255, 0.3)",
      boxShadow: "none",
      backgroundColor: "rgba(255, 255, 255, 0.12)"
    }
  }
}));

type OpenEntityMemberInvitationAcceptanceFormProps = {
  className?: string;
  invitation?: OpenEntityMemberInvitation;
};

export default function OpenEntityMemberInvitationAcceptanceForm(
  props: Readonly<OpenEntityMemberInvitationAcceptanceFormProps>
) {
  const { className, invitation } = props;

  const [saving, setSaving] = useState<boolean>(false);
  const [invitationEmail, setInvitationEmail] = useState<EmailAddress>();
  const [accepted, setAccepted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const session = useSession();

  async function handleAcceptInvitation() {
    if (!invitation || !invitationEmail) return;

    setSaving(true);
    setErrorMessage(undefined);

    try {
      const service = new OpenEntityMemberInvitationAPIService(session);
      await service.acceptOpenEntityMemberInvitationAsync(
        invitation,
        invitationEmail
      );
      setAccepted(true);
    } catch (error: any) {
      if (error instanceof InvalidInvitationError) {
        setErrorMessage(
          `There was an error joining ${invitation.entity.name} with email address ${invitationEmail}: ` 
          + error.message
        );
      } else {
        console.error(error);
        setErrorMessage('An error occurred while accepting the invitation. Please try again later.');
      }
    } finally {
      setSaving(false);
    }
  }

  function invitationAcceptedMessage() {
    return (
      <InvitationForm className={className}>
        <InvitationFormTitle variant='h4'>
          Invitation Accepted
        </InvitationFormTitle>
        <InvitationFormInstructions>
          Check your email inbox for a confirmation email.
          <p>You can close this tab</p>
        </InvitationFormInstructions>
      </InvitationForm>
    )
  }

  function invitationExpiredMessage() {
    return (
      <InvitationForm className={className}>
        <InvitationFormTitleError variant='h4'>
          Invitation Expired
        </InvitationFormTitleError>
        <InvitationFormInstructions>
          The invitation has expired.<br />
          Please contact {invitation?.entity.name?.value ?? 'the sender'} to request a new invitation.
          <p>You can close this tab</p>
        </InvitationFormInstructions>
      </InvitationForm>
    )
  }

  function invitationErrorMessage() {
    return (
      <InvitationForm className={className}>
        <InvitationFormTitleError variant='h4'>
          Invitation Error
        </InvitationFormTitleError>
          <InvitationFormInstructions>
            {errorMessage}
          </InvitationFormInstructions>
      </InvitationForm>
    )
  }

  if (!invitation) return null;
  if (errorMessage) return invitationErrorMessage();
  if (accepted) return invitationAcceptedMessage();
  if (invitation.expires?.isAfter()) return invitationExpiredMessage();

  return (
    <InvitationForm className={className}>
      <InvitationFormTitle variant="h4">
        Accept Invitation
      </InvitationFormTitle>
      <InvitationFormInstructions>
        Enter your email address to accept this invitation from {invitation.entity.name?.value}
      </InvitationFormInstructions>
      <form>
        <InvitationEmailInput
          variant='filled'
          emailAddress={invitationEmail}
          onChange={(updatedEmailAddress?: EmailAddress) => {
            if (!updatedEmailAddress) return;
            setInvitationEmail(updatedEmailAddress);
          }}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleAcceptInvitation();
            }
          }}
        />
      </form>
      <InvitationAcceptButton
        variant="contained"
        color="primary"
        disabled={saving || !invitationEmail}
        loading={saving}
        startIcon={<CheckIcon />}
        onClick={handleAcceptInvitation}>
        Accept Invitation
      </InvitationAcceptButton>
    </InvitationForm>
  )
}
