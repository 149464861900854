import {Libraries} from '@react-google-maps/api/dist/utils/make-load-script-url';
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Paper, {PaperProps} from '@mui/material/Paper';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import Address from "common/values/address/address";
import {SyntheticEvent, useEffect, useState, useRef, useCallback} from "react";
import {useLoadScript} from '@react-google-maps/api';
import _ from 'lodash';

interface AutocompleteRequest {
  input: string;
  locationRestriction?: { west: number; north: number; east: number; south: number };
  origin?: { lat: number; lng: number };
  includedPrimaryTypes?: string[];
  language?: string;
  region?: string;
  sessionToken?: any;
}

interface Suggestion {
  placePrediction: any;
}

interface AddressComponent {
  longText: string;
  shortText: string;
  types: string[];
}

interface PlaceAutocompleteProps {
  className?: string;
  label: string;
  value: Address | null;
  variant?: 'outlined' | 'standard';
  onSelected: (value: Address | null) => void;
}

export default function PlaceAutocomplete(props: Readonly<PlaceAutocompleteProps>) {
  const [inputValue, setInputValue] = useState(props.value?.toString());
  const [options, setOptions] = useState<Suggestion[]>([]);
  const [value, setValue] = useState<Suggestion | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Backoff configuration
  const backoffRef = useRef({
    attempts: 0,
    maxAttempts: 5,
    baseDelay: 300,
  });

  const libraries = useRef<Libraries>(['places']);
  const {isLoaded: placesApiLoaded, loadError: placesApiLoadError} = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_PLACES_API_KEY,
    libraries: libraries.current,
  });

  // Keep the request object in a ref so it persists between renders
  const requestRef = useRef<AutocompleteRequest>({
    input: '',
    language: 'en-US',
  });

  // Use a ref to track the latest request ID to avoid race conditions
  const newestRequestId = useRef(0);

  // Once the API is loaded, refresh the token.
  useEffect(() => {
    if (placesApiLoaded && window.google) {
      refreshToken();
    }
  }, [placesApiLoaded]);

  // Update inputValue when props.value changes
  useEffect(() => {
    if (props.value) {
      setInputValue(props.value.toString());
    } else {
      setInputValue('');
    }
  }, [props.value]);

  // Refresh the session token
  const refreshToken = () => {
    requestRef.current.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
  };

  const isValidCoordinate = (value: number): boolean => {
    return !Number.isNaN(value) && Number.isFinite(value);
  };

  // Highlight matching text
  const highlightMatches = (text: string, query: string) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, 'gi');
    return text.split(regex).map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? <strong key={index}>{part}</strong> : part
    );
  };

  // Helper function to safely extract address components
  const getAddressComponent = (components: AddressComponent[], type: string): string => {
    const component = components.find(comp => comp.types.includes(type));
    return component?.shortText || '';
  };

  const fetchSuggestions = useCallback(async (value: string) => {
    if (value === '') {
      setOptions([]);
      return;
    }

    // Update the request with the current input
    requestRef.current.input = value;
    const currentRequestId = ++newestRequestId.current;

    setIsLoading(true);
    try {
      const {suggestions} = await google.maps.places.AutocompleteSuggestion.fetchAutocompleteSuggestions(
        requestRef.current
      );

      // If a newer request has been made, ignore this response
      if (currentRequestId !== newestRequestId.current) return;

      setOptions(suggestions);
      setErrorMessage(null);
    } catch (error: any) {
      console.error('Error fetching suggestions:', error);
      // If a newer request has been made, ignore this error
      if (currentRequestId !== newestRequestId.current) return;

      if (error.code === 'OVER_QUERY_LIMIT') {
        console.warn('Google Places API rate limit exceeded');
        setErrorMessage('Too many requests. Please try again in a moment.');
        if (backoffRef.current.attempts < backoffRef.current.maxAttempts) {
          backoffRef.current.attempts++;
          const delay = backoffRef.current.baseDelay * Math.pow(2, backoffRef.current.attempts);
          console.log(`Retrying in ${delay}ms (attempt ${backoffRef.current.attempts})`);
          setTimeout(() => fetchSuggestions(value), delay);
        } else {
          console.error('Maximum retry attempts reached');
          backoffRef.current.attempts = 0;
        }
        console.error('Google Places API request denied - check API key configuration');
        setErrorMessage('Location service is temporarily unavailable');
      }
      setOptions([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debounceFetchSuggestions = useRef(
    _.debounce((value: string) => fetchSuggestions(value), 300)
  ).current;

  // Handle input changes from the Autocomplete component
  const handleInputChange = (
    event: SyntheticEvent,
    newInputValue: string,
    reason: string
  ) => {

    if (reason === 'input') {
      if (!newInputValue || newInputValue === '') {
        setOptions([]);
        setValue(null);
        props.onSelected(null);
      }
      setInputValue(newInputValue);
      debounceFetchSuggestions(newInputValue);
    }
  };

  // When a suggestion is selected, fetch additional place fields
  const onPlaceSelected = async (suggestion: Suggestion | null) => {
    if (!suggestion) return;

    const placePrediction = suggestion.placePrediction;
    const place = placePrediction.toPlace();
    await place.fetchFields({
      fields: ['addressComponents', 'location'],
    });

    const streetNumber = getAddressComponent(place.addressComponents, 'street_number');
    const route = getAddressComponent(place.addressComponents, 'route');

    // Format street address properly handling empty components
    const streetAddress = [streetNumber, route].filter(Boolean).join(' ') || '';

    const lat = place.location.lat();
    const lng = place.location.lng();

    if (!isValidCoordinate(lat) || !isValidCoordinate(lng)) {
      console.warn('Invalid coordinates received from Google Places API', {lat, lng});
    }

    const newAddress = new Address(
      streetAddress,
      '', '', '', '', '',
      {
        latitude: isValidCoordinate(lat) ? lat : 0,
        longitude: isValidCoordinate(lng) ? lng : 0
      }
    );

    const componentMapping = {
      'locality': 'city',
      'administrative_area_level_1': 'state',
      'country': 'country',
      'postal_code': 'postalCode'
    };

    for (const component of place.addressComponents) {
      for (const [type, addressField] of Object.entries(componentMapping)) {
        if (component.types.includes(type)) {
          newAddress[addressField as keyof Address] = component.shortText;
        }
      }
    }

    props.onSelected(newAddress);

    setOptions([]);
    setInputValue(suggestion.placePrediction.text.toString());
    refreshToken();
  };

  // Handle selection change from the Autocomplete component
  const handleChange = (event: SyntheticEvent, newValue: Suggestion | null) => {
    setValue(newValue);
    setOptions(newValue ? [newValue, ...options] : options);
    onPlaceSelected(newValue);
  };

  // Convert a suggestion to a label string
  const getOptionLabel = (option: Suggestion) => {
    return option.placePrediction.text.toString();
  };

  const renderPlacesApiLoadError = () => {
    console.error('Google Maps API failed to load:', placesApiLoadError);
    return (
      <TextField
        className={props.className}
        fullWidth
        label={props.label}
        variant={props.variant}
        disabled
        error
        helperText="Location service is temporarily unavailable"
      />
    );
  };

  if (placesApiLoadError) return renderPlacesApiLoadError();
  if (!placesApiLoaded) return (
    <TextField
      className={props.className}
      fullWidth
      label={props.label}
      variant={props.variant}
      disabled
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      slotProps={{
        input: {
          endAdornment: <CircularProgress color="inherit" size={20}/>,
        }
      }}
    />
  );

  return (
    <Autocomplete
      getOptionLabel={getOptionLabel}
      filterOptions={(x) => x}
      slots={{
        paper: PlaceResultsPaper,
      }}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      loading={isLoading}
      inputValue={inputValue}
      noOptionsText="No locations"
      onChange={handleChange}
      clearOnBlur={true}
      clearOnEscape={true}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          className={props.className}
          fullWidth
          label={props.label}
          variant={props.variant}
          slotProps={{
            inputLabel: {
              shrink: Boolean(inputValue || value),
            }
          }}
        />
      )}
      renderOption={(props, option) => {
        const {key, ...optionProps} = props;
        const name = option.placePrediction.text.toString();
        const description = option.placePrediction.description;

        return (
          <li key={key} {...optionProps}>
            <Grid container sx={{alignItems: 'center'}}>
              <Grid sx={{display: 'flex', width: 44}}>
                <LocationOnIcon sx={{color: 'text.secondary'}}/>
              </Grid>
              <Grid sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                <Typography variant="body1" noWrap>
                  {highlightMatches(name, inputValue ?? '')}
                </Typography>
                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

function PlaceResultsPaper(props: PaperProps) {
  const theme = useTheme();

  return (
    <Paper {...props}>
      {props.children}
      {/* Legal requirement https://developers.google.com/maps/documentation/javascript/policies#logo */}
      <Box
        sx={(staticTheme) => ({
          display: 'flex',
          justifyContent: 'flex-end',
          p: 1,
          pt: '1px',
          ...staticTheme.applyStyles('dark', {
            opacity: 0.8,
          }),
        })}
      >
        <img
          src={
            theme.palette.mode === 'dark'
              ? 'https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-non-white3_hdpi.png'
              : 'https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png'
          }
          alt="Powered by Google"
          width="120"
          height="14"
        />
      </Box>
    </Paper>
  );
}
