import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import UndoIcon from "@mui/icons-material/Undo";
import {Box, Button, IconButton, Tooltip} from "@mui/material";
import {styled} from "@mui/material/styles";
import {ConfirmResponse, useConfirmDialog,} from "app/providers/confirm-dialog";
import {useDialog} from "app/providers/dialog";
import Guid from "common/values/guid/guid";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from "material-react-table";
import {enqueueSnackbar} from "notistack";
import CommentThread from "work/entities/comment-thread/comment-thread";
import {ProposalField} from "work/entities/proposal/proposal";
import FeeRedlineInput from "work/entities/proposal/redlining/fee-redline/view/fee-redline-input";
import {
  FeeScheduleCategoryRedline,
  FeeScheduleRedline,
} from "work/entities/proposal/redlining/fee-schedule-redline/fee-schedule-redline";
import FieldRedline from "work/entities/proposal/redlining/field-redline";
import TextRedlineInput from "work/entities/proposal/redlining/view/redline-field-inputs/text-redline-input";
import FeeScheduleBillingCode from "work/values/fee-schedule-billing-code/fee-schedule-billing-code";
import FeeScheduleCategoryDescription
  from "work/values/fee-schedule-category-description/fee-schedule-category-description";
import FeeScheduleCategoryName from "work/values/fee-schedule-category-name/fee-schedule-category-name";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import FeeScheduleCategoryForm from "work/values/fee-schedule-category/view/fee-schedule-category-form";
import Fee from "work/values/fee/fee";
import CommentsButton from "work/entities/comment-thread/view/comments-button";

const ActionsContainer = styled(Box)(
  ({theme}) => ({
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  }));
const RedlineText = styled(TextRedlineInput)(
  () => ({
    margin: 0,
    minWidth: "unset",
    width: "100%",
  }));
const ActionButton = styled(IconButton)(
  ({theme}) => ({
    padding: theme.spacing(0.5),
  }));

type FeeScheduleRedlineSelectionProps = {
  className?: string;
  feeScheduleRedline: FeeScheduleRedline;
  disableEditing?: boolean;
  commentThreads: CommentThread[];
  activeReviewField: ProposalField | undefined;
  onCommentsClicked: (
    field: ProposalField,
    name?: string
  ) => void;
  hideCommentButton?: boolean;
  onCategoryClicked?: (categoryId: Guid) => void;
  onFeeScheduleRedlineChange: (
    newRedline: FeeScheduleRedline,
    traversalFieldOverride?: ProposalField | null
  ) => void;
};

const FeeScheduleRedlineSelection = (
  props: Readonly<FeeScheduleRedlineSelectionProps>
) => {
  const {
    className,
    feeScheduleRedline,
    commentThreads,
    disableEditing,
    activeReviewField,
    onCommentsClicked,
    onCategoryClicked,
    onFeeScheduleRedlineChange,
    hideCommentButton
  } = props;

  const confirm = useConfirmDialog();
  const {openDialog, popDialog} = useDialog();

  function handleRemoveClicked(categoryId: Guid) {
    const newRedline = feeScheduleRedline.removeEntryByFieldId(categoryId);
    onFeeScheduleRedlineChange(
      newRedline,
      newRedline.field
    );
    onCommentsClicked?.(
      ProposalField.FeeSchedule,
      undefined
    );
  }

  function renderNameRedline(rowData: FeeScheduleCategoryRedline) {
    if (!rowData.field.id) {
      console.error(
        "Category Redline ID is malformed",
        rowData
      );
      return;
    }
    const categoryRedline = Object.assign(
      new FeeScheduleCategoryRedline(
        rowData.field.id,
        null,
        null
      ),
      rowData
    );
    if (!categoryRedline.nameRedline) {
      console.error(
        "Category Redline Name is malformed",
        categoryRedline
      );
      return;
    }
    return categoryRedline.isAccepted && categoryRedline.isRemoved ? (
      "Removed"
    ) : (
      <RedlineText
        bold={true}
        variant="standard"
        size="small"
        margin="none"
        hideLabel
        showPlaceholder
        hideUndoButton={true}
        hideAcceptRejectButtons={true}
        fullWidth
        originalTextFieldRedline={categoryRedline.nameRedline}
        onTextFieldRedlineChange={(
          newTextRedline: FieldRedline<FeeScheduleCategoryName>,
          traversalFieldOverride?: ProposalField | null
        ) => {
          const duplicateName = feeScheduleRedline.redlines.find((redline) => {
            if (!redline.nameRedline) {
              console.error(
                "Category Redline Name is malformed",
                redline
              );
              return false;
            }
            return (
              redline.nameRedline.currentEntry === newTextRedline.currentEntry
            );
          });
          if (duplicateName) {
            enqueueSnackbar(
              "A category with the same name already exists",
              {
                variant: "warning",
              }
            );
            return;
          }

          const existingCategory =
            categoryRedline.currentEntry ?? categoryRedline.revisedEntry;
          if (!existingCategory || !newTextRedline.currentEntry) {
            console.error(
              "category redline is malformed",
              categoryRedline
            );
            return;
          }
          const updatedCategory = new FeeScheduleCategory(
            newTextRedline.currentEntry,
            existingCategory.description,
            existingCategory.fee,
            existingCategory.billingCode,
            existingCategory.id
          );

          const newRedline = feeScheduleRedline.replaceEntryById(
            updatedCategory.id,
            updatedCategory
          );
          onFeeScheduleRedlineChange(
            newRedline,
            traversalFieldOverride
          );
          onCommentsClicked?.(
            ProposalField.FeeSchedule,
            undefined
          );
        }}
        readOnly={disableEditing || categoryRedline.isRemoved}
        hideComments={true}
      />
    );
  }

  function renderDescriptionRedline(rowData: FeeScheduleCategoryRedline) {
    if (!rowData.field.id) {
      console.error(
        "Category Redline ID is malformed",
        rowData
      );
      return;
    }
    const categoryRedline = Object.assign(
      new FeeScheduleCategoryRedline(
        rowData.field.id,
        null,
        null
      ),
      rowData
    );
    if (!categoryRedline.descriptionRedline) {
      console.error(
        "Category Redline description is malformed",
        categoryRedline
      );
      return;
    }
    return (
      <RedlineText
        variant="standard"
        size="small"
        margin="none"
        fullWidth
        hideLabel
        showPlaceholder
        hideUndoButton={true}
        hideAcceptRejectButtons={true}
        originalTextFieldRedline={categoryRedline.descriptionRedline}
        onTextFieldRedlineChange={async (
          newTextRedline: FieldRedline<FeeScheduleCategoryDescription>,
          traversalFieldOverride?: ProposalField | null
        ) => {
          const existingCategory =
            categoryRedline.currentEntry ?? categoryRedline.revisedEntry;
          if (!existingCategory || !newTextRedline.currentEntry) {
            console.error(
              "category redline is malformed",
              categoryRedline
            );
            return;
          }
          const updatedCategory = new FeeScheduleCategory(
            existingCategory.name,
            newTextRedline.currentEntry,
            existingCategory.fee,
            existingCategory.billingCode,
            existingCategory.id
          );

          const newRedline = feeScheduleRedline.replaceEntryById(
            updatedCategory.id,
            updatedCategory
          );
          onFeeScheduleRedlineChange(
            newRedline,
            traversalFieldOverride
          );
          onCommentsClicked?.(
            ProposalField.FeeSchedule,
            undefined
          );
        }}
        readOnly={disableEditing || categoryRedline.isRemoved}
        hideComments={true}
      />
    );
  }

  function renderFeeRedline(rowData: FeeScheduleCategoryRedline) {
    if (!rowData.field.id) {
      console.error(
        "Category Redline ID is malformed",
        rowData
      );
      return;
    }
    const categoryRedline = Object.assign(
      new FeeScheduleCategoryRedline(
        rowData.field.id,
        null,
        null
      ),
      rowData
    );
    if (!categoryRedline.feeRedline) {
      console.error(
        "Category Redline fee is malformed",
        categoryRedline
      );
      return;
    }
    return (
      <FeeRedlineInput
        variant="standard"
        size="small"
        margin="none"
        categoryRedline={categoryRedline}
        readOnly={disableEditing || categoryRedline.isRemoved}
        onFeeChange={(newFee: Fee) => {
          const existingCategory =
            categoryRedline.currentEntry ?? categoryRedline.revisedEntry;
          if (!existingCategory) {
            console.error(
              "category redline is malformed",
              categoryRedline
            );
            return;
          }
          const updatedCategory = new FeeScheduleCategory(
            existingCategory.name,
            existingCategory.description,
            newFee,
            existingCategory.billingCode,
            existingCategory.id
          );
          const newRedline = feeScheduleRedline.replaceEntryById(
            updatedCategory.id,
            updatedCategory
          );
          onFeeScheduleRedlineChange(newRedline);
          onCommentsClicked?.(
            ProposalField.FeeSchedule,
            undefined
          );
        }}
      />
    );
  }

  function renderBillingCodeRedline(rowData: FeeScheduleCategoryRedline) {
    if (!rowData.field.id) {
      console.error(
        "Category Redline ID is malformed",
        rowData
      );
      return;
    }
    const categoryRedline = Object.assign(
      new FeeScheduleCategoryRedline(
        rowData.field.id,
        null,
        null
      ),
      rowData
    );
    if (!categoryRedline.billingCodeRedline) {
      console.error(
        "Category Redline billing code is malformed",
        categoryRedline
      );
      return;
    }
    return (
      <RedlineText
        variant="standard"
        size="small"
        margin="none"
        fullWidth
        hideLabel
        showPlaceholder
        originalTextFieldRedline={categoryRedline.billingCodeRedline}
        readOnly={disableEditing || categoryRedline.isRemoved}
        hideUndoButton={true}
        hideAcceptRejectButtons={true}
        hideComments={true}
        onTextFieldRedlineChange={async (
          newTextRedline: FieldRedline<FeeScheduleBillingCode>,
          traversalFieldOverride?: ProposalField | null
        ) => {
          const existingCategory =
            categoryRedline.currentEntry ?? categoryRedline.revisedEntry;
          if (!existingCategory || !newTextRedline.currentEntry) {
            console.error(
              "category redline is malformed",
              categoryRedline
            );
            return;
          }
          const updatedCategory = new FeeScheduleCategory(
            existingCategory.name,
            existingCategory.description,
            existingCategory.fee,
            newTextRedline.currentEntry,
            existingCategory.id
          );

          const newRedline = feeScheduleRedline.replaceEntryById(
            updatedCategory.id,
            updatedCategory
          );
          onFeeScheduleRedlineChange(
            newRedline,
            traversalFieldOverride
          );
          onCommentsClicked?.(
            ProposalField.FeeSchedule,
            undefined
          );
        }}
      />
    );
  }

  const columnHelper = createMRTColumnHelper<FeeScheduleCategoryRedline>();
  const columns = [
    columnHelper.accessor(
      renderNameRedline,
      {
        header: "Name",
        id: "Name",
      }
    ),
    columnHelper.accessor(
      renderDescriptionRedline,
      {
        header: "Description",
        id: "Description",
      }
    ),
    columnHelper.accessor(
      renderFeeRedline,
      {
        header: "Fee",
        id: "fee",
      }
    ),
    columnHelper.accessor(
      renderBillingCodeRedline,
      {
        header: "Billing Code",
        id: "billingCode",
      }
    ),
  ];

  /**
   * Handles when the add button is clicked
   */
  function beginAddCategory(): void {
    openDialog({
      title: "Create Fee Schedule Category",
      component: (
        <FeeScheduleCategoryForm
          onCategoryAdded={async (newCategory: FeeScheduleCategory) => {
            let newRedline: FeeScheduleRedline;
            if (!newCategory) return;

            const matchingRedline =
              feeScheduleRedline.getMatchingCategoryRedline(newCategory);
            if (matchingRedline) {
              const response = await confirm({
                title: "Overwrite Category?",
                message:
                  "A category with the same name already exists. Do you want to overwrite it?",
                okButtonText: "Overwrite",
              });

              if (response === ConfirmResponse.Cancel) {
                return;
              }
              newRedline = feeScheduleRedline.replaceEntryById(
                newCategory.id,
                newCategory
              );
            } else {
              newRedline = feeScheduleRedline.addEntry(newCategory);
            }

            onFeeScheduleRedlineChange(
              newRedline,
              ProposalField.FeeScheduleCategory(newCategory.id)
            );
            onCommentsClicked?.(
              ProposalField.FeeSchedule,
              undefined
            );
          }}
          popDialog={popDialog}
        />
      ),
    });
  }

  const table = useMaterialReactTable({
    columns,
    data: feeScheduleRedline.redlines,
    enableKeyboardShortcuts: false,
    enableRowSelection: false,
    enableTableHead: true,
    getRowId: (row) => row.field.id?.toString() ?? Guid.generate().toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {right: ["mrt-row-actions"]},
    },
    enableColumnPinning: true,
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualPagination: true,
    manualSorting: true,
    enableRowActions: true,
    muiTableProps: ({table}) => ({
      sx: {
        borderCollapse: table
          .getRowModel()
          .rows.find((row) => row.id === activeReviewField?.id?.toString())
          ? "collapse"
          : "separate",
      },
    }),
    muiTableBodyRowProps: ({row}) => ({
      onClick: () => {
        if (!row.original.field.id) return;
        onCategoryClicked?.(row.original.field.id);
      },
      sx: {
        border: row.original.field.id?.isEqualTo(activeReviewField?.id)
          ? "2px solid #1976d2"
          : "none",
      },
    }),
    renderTopToolbarCustomActions: () => {
      return (
        <ActionsContainer>
          {!disableEditing && (
            <>
              <Button
                startIcon={<AddIcon/>}
                onClick={beginAddCategory}
                variant="outlined"
              >
                Add New Category
              </Button>
              {!feeScheduleRedline.isResolved && (
                <>
                  <IconButton
                    onClick={() => {
                      onFeeScheduleRedlineChange(feeScheduleRedline.acceptAll());
                      onCommentsClicked(
                        ProposalField.FeeSchedule,
                        undefined
                      );
                    }}
                  >
                    <CheckIcon color="success"/>
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      onFeeScheduleRedlineChange(feeScheduleRedline.rejectAll());
                      onCommentsClicked(
                        ProposalField.FeeSchedule,
                        undefined
                      );
                    }}
                  >
                    <CloseIcon color="error"/>
                  </IconButton>
                </>
              )}
              {feeScheduleRedline.canBeUndone && (
                <IconButton
                  onClick={() => {
                    onFeeScheduleRedlineChange(
                      feeScheduleRedline.undoAll(),
                      null
                    );
                    onCommentsClicked(
                      ProposalField.FeeSchedule,
                      undefined
                    );
                  }}
                >
                  <UndoIcon/>
                </IconButton>
              )}
            </>
          )}
        </ActionsContainer>
      );
    },
    renderToolbarInternalActions: ({table}) => (
      <Box>
        <MRT_ToggleGlobalFilterButton table={table}/>
        <MRT_ToggleFiltersButton table={table}/>
        <MRT_ShowHideColumnsButton table={table}/>
        <MRT_ToggleDensePaddingButton table={table}/>
        <MRT_ToggleFullScreenButton table={table}/>
        <CommentsButton
          commentThreads={commentThreads}
          field={ProposalField.FeeSchedule}
          toolTip="Comments"
          onCommentsClicked={() => {
            onCommentsClicked?.(ProposalField.FeeSchedule);
          }}
        />
      </Box>
    ),
    renderRowActions: ({row}) => {
      if (!row.original.field.id) return null;
      const category = Object.assign(
        new FeeScheduleCategoryRedline(
          row.original.field.id,
          null,
          null
        ),
        row.original
      );

      if (!category) return null;
      if (disableEditing) {
        return <CommentsButton
          commentThreads={commentThreads}
          field={category.field}
          toolTip="Comments"
          onCommentsClicked={() => {
            onCommentsClicked?.(
              category.field,
              category?.currentEntry?.name?.value ?? category?.revisedEntry?.name?.value
            );
          }}
        />
      }

      return (
        <>
          {category.isResolved &&
            !category.isRemoved &&
            !category.isNewlyAdded && (
              <Tooltip title="Remove Fee">
                <span>
                  <ActionButton
                    onClick={() =>
                      category.field.id &&
                      handleRemoveClicked(category.field.id)
                    }
                  >
                    <DeleteOutlineIcon/>
                  </ActionButton>
                </span>
              </Tooltip>
            )}
          {!category.isResolved && (
            <>
              <Tooltip title="Accept Changes">
                <span>
                  <ActionButton
                    onClick={() => {
                      onFeeScheduleRedlineChange(
                        feeScheduleRedline.updateRedline(category.accept())
                      );
                      onCommentsClicked(
                        ProposalField.FeeSchedule,
                        undefined
                      );
                    }}
                  >
                    <CheckIcon color="success"/>
                  </ActionButton>
                </span>
              </Tooltip>
              <Tooltip title="Reject Changes">
                <span>
                  <ActionButton
                    onClick={() => {
                      onFeeScheduleRedlineChange(
                        feeScheduleRedline.updateRedline(category.reject())
                      );
                      onCommentsClicked(
                        ProposalField.FeeSchedule,
                        undefined
                      );
                    }}
                  >
                    <CloseIcon color="error"/>
                  </ActionButton>
                </span>
              </Tooltip>
            </>
          )}
          {category.canBeUndone && (
            <Tooltip title="Undo Changes">
              <span>
                <ActionButton
                  onClick={() => {
                    if (category.field.id) {
                      onFeeScheduleRedlineChange(
                        feeScheduleRedline.undoRedlineById(category.field.id),
                        category.field
                      );
                      onCommentsClicked?.(
                        ProposalField.FeeSchedule,
                        undefined
                      );
                    }
                  }}
                >
                  <UndoIcon/>
                </ActionButton>
              </span>
            </Tooltip>
          )}
          <CommentsButton
            commentThreads={commentThreads}
            field={category.field}
            toolTip="Comments"
            onCommentsClicked={() => {
              onCommentsClicked?.(
                category.field,
                category?.currentEntry?.name?.value ?? category?.revisedEntry?.name?.value
              );
            }}/>
        </>
      );
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        height: "100%",
      },
    },
  });

  return (
    <div className={className}>
      <MaterialReactTable table={table}/>
    </div>
  );
};

export default FeeScheduleRedlineSelection;
