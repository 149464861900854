import {
  styled
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import CommentThread from "work/entities/comment-thread/comment-thread";
import Proposal, {
  ProposalField,
  ProposalFieldCategory,
} from "work/entities/proposal/proposal";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import FeeScheduleCategoriesReadonly from "work/values/fee-schedule-category/view/fee-schedule-categories-readonly";

const ListContainer = styled(Grid)(
  () => ({
    minHeight: "20rem",
  }));
const CategorySelection = styled(FeeScheduleCategoriesReadonly)(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(2),
    },
    height: "100%",
    marginLeft: theme.spacing(2),
  }));

type FeeScheduleArchivedProps = {
  activeTab: ProposalFieldCategory;
  proposal: Proposal;
  commentThreads?: CommentThread[];
  onCommentsClicked: (field: ProposalField, name?: string) => void;
};

export default function FeeScheduleArchivedTab(
  props: Readonly<FeeScheduleArchivedProps>
) {
  const {
    activeTab,
    proposal,
    commentThreads,
    onCommentsClicked
  } = props;

  const [selectedCategories, setSelectedCategories] = useState<FeeScheduleCategory[]>([]);

  useEffect(
    () => {
      if (activeTab !== ProposalFieldCategory.FeeSchedule) return;

      setSelectedCategories(proposal.feeSchedule ?? []);
    },
    [
      proposal,
      activeTab
    ]
  );

  if (activeTab !== ProposalFieldCategory.FeeSchedule) return null;

  return (
    <ListContainer container direction="row">
      <Grid size="grow">
        <CategorySelection
          categories={selectedCategories}
          commentThreads={commentThreads}
          onCommentsClicked={onCommentsClicked}
        />
      </Grid>
    </ListContainer>
  );
}
