import Guid from "common/values/guid/guid";

export enum DocumentOrderField {
  Name = 'name',
  Created = 'created'
}

export class DocumentTopicParameters {
  entityClass?: string;
  entityId?: string;
  context?: string;

  constructor(
    entityClass?: string,
    entityId?: Guid,
    context?: string
  ) {
    this.entityClass = entityClass;
    this.entityId = entityId?.toString();
    this.context = context;
  }

  public asSearchParams(): URLSearchParams {
    const searchParams = new URLSearchParams();
    if (this.entityClass) searchParams.append(
      "entityClass",
      this.entityClass
    );
    if (this.entityId) searchParams.append(
      "entityId",
      this.entityId
    );
    if (this.context) searchParams.append(
      "context",
      this.context
    );
    return searchParams;
  }

  public isEqualTo(other: DocumentTopicParameters): boolean {
    return this.entityClass === other.entityClass &&
      this.entityId === other.entityId &&
      this.context === other.context;

  }
}

export class DocumentOrderParameters {
  field: DocumentOrderField;
  direction: 'asc' | 'desc';

  constructor(
    field: DocumentOrderField,
    direction: 'asc' | 'desc'
  ) {
    this.field = field;
    this.direction = direction;
  }

  public asSearchParams(): URLSearchParams {
    const params = new URLSearchParams();
    params.append(
      "orderBy",
      this.field
    );
    params.append(
      "orderDir",
      this.direction
    );
    return params;
  }

  public isEqualTo(other: DocumentOrderParameters): boolean {
    if (!other) return false;
    return this.field === other.field && this.direction === other.direction;
  }
}

export class DocumentFilterParameters {
  name?: string;
  minCreatedDate?: string;
  maxCreatedDate?: string;

  constructor(
    name?: string,
    minCreatedDate?: string,
    maxCreatedDate?: string
  ) {
    this.name = name;
    this.minCreatedDate = minCreatedDate;
    this.maxCreatedDate = maxCreatedDate;
  }

  public asSearchParams(): URLSearchParams {
    const searchParams = new URLSearchParams();
    if (this.name) searchParams.append(
      "name",
      this.name
    );
    if (this.minCreatedDate) searchParams.append(
      "minCreatedDate",
      this.minCreatedDate
    );
    if (this.maxCreatedDate) searchParams.append(
      "maxCreatedDate",
      this.maxCreatedDate
    );
    return searchParams;
  }

  public isEqualTo(other: DocumentFilterParameters): boolean {
    if (!other) return false;
    return this.name === other.name &&
      this.minCreatedDate === other.minCreatedDate &&
      this.maxCreatedDate === other.maxCreatedDate;
  }
}


export default class DocumentParameters {
  topicParameters: DocumentTopicParameters;
  orderParameters: DocumentOrderParameters;
  filterParameters: DocumentFilterParameters;


  constructor(
    topicParameters: DocumentTopicParameters,
    orderParameters: DocumentOrderParameters,
    filterParameters: DocumentFilterParameters
  ) {
    this.topicParameters = topicParameters;
    this.orderParameters = orderParameters;
    this.filterParameters = filterParameters;
  }

  public asSearchParams(): URLSearchParams {
    return new URLSearchParams([
      ...this.topicParameters.asSearchParams(),
      ...this.orderParameters.asSearchParams(),
      ...this.filterParameters.asSearchParams()
    ]);

  }

  public isEqualTo(other: DocumentParameters | undefined): boolean {
    if (!other) {
      return false;
    }

    return this.orderParameters.isEqualTo(other.orderParameters) &&
      this.topicParameters.isEqualTo(other.topicParameters) &&
      this.filterParameters.isEqualTo(other.filterParameters);
  }
}
