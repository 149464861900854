import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import {downloadFile} from "common/helpers/utils";
import Guid from "common/values/guid/guid";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import {enqueueSnackbar} from "notistack";
import React from "react";
import {useSession} from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import Proposal, {
  ProposalField,
  ProposalFieldCategory,
} from "work/entities/proposal/proposal";
import DocumentListReadonly from "work/values/work-document/view/document-list-readonly";
import {
  WorkDocumentType,
} from "work/values/work-document/work-document";
import CommentsButton from "work/entities/comment-thread/view/comments-button";

const ListContainer = styled(Grid)(
  () => ({
    flexWrap: "nowrap",
    minHeight: "20rem",
    width: "100%",
  }));
const DocumentList = styled(DocumentListReadonly)(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(2),
    },
    maxHeight: "60vh",
    marginLeft: theme.spacing(2),
    overflowY: "auto",
  }));
const HeadControls = styled("div")(
  ({theme}) => ({
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  }));
const HelperText = styled(Typography)(
  ({theme}) => ({
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

type PolicyArchivedProps = {
  activeTab: ProposalFieldCategory;
  proposal: Proposal;
  commentThreads?: CommentThread[];
  onCommentsClicked: (field: ProposalField, name?: string) => void;
};

export default function PoliciesArchivedTab(props: Readonly<PolicyArchivedProps>) {
  const {
    activeTab,
    proposal,
    commentThreads,
    onCommentsClicked
  } = props;

  const [isDownloadingFile, setIsDownloadingFile] = React.useState<Guid | null>(null);

  const session = useSession();

  async function handleDownloadDocumentById(
    documentId: Guid,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    event?.stopPropagation();
    try {
      setIsDownloadingFile(documentId);
      const documentApiService = new DocumentAPIService(session);
      const policyDocument = await documentApiService.downloadDocument(
        documentId
      );
      downloadFile(policyDocument);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to download the document. Please try again later.",
        {
          variant: "error",
        }
      );
    } finally {
      setIsDownloadingFile(null);
    }
  }

  function getContextField(documentId?: Guid): ProposalField {
    const isVendorView = session.context?.viewingAsVendor ?? false;

    if (documentId) {
      return isVendorView
        ? ProposalField.VendorPolicyDocument(documentId)
        : ProposalField.ClientPolicyDocument(documentId);
    }
    return isVendorView
      ? ProposalField.VendorPolicies
      : ProposalField.ClientPolicies;
  }

  if (activeTab !== ProposalFieldCategory.Policies) return null;

  const selectedDocuments = session.context?.viewingAsVendor
    ? proposal.vendorPolicyDocuments
    : proposal.clientPolicyDocuments;

  return (
    <>
      <HeadControls>
        <CommentsButton
          commentThreads={commentThreads}
          hideUnreadCount={true}
          field={ProposalField.Discount}
          toolTip={commentThreads?.some((thread) =>
            thread.field.isEqualTo(ProposalField.Discount)) ? "Comments" : "No Comments"}
          onCommentsClicked={
            onCommentsClicked ? () => {
              onCommentsClicked(
                ProposalField.Discount
              );
            } : undefined
          }
        />
      </HeadControls>
      <ListContainer container direction="row">
        <Grid size={4}>
          {selectedDocuments.length === 0 &&
              <HelperText>
                  No policy documents for this archived proposal.
              </HelperText>
          }{selectedDocuments.length > 0 &&
            <DocumentList
                documents={selectedDocuments}
                documentType={
                  session.context?.viewingAsVendor
                    ? WorkDocumentType.VendorPolicy
                    : WorkDocumentType.ClientPolicy
                }
                downloadingFileId={isDownloadingFile}
                commentThreads={commentThreads}
                onCommentsClicked={onCommentsClicked}
                onDownload={handleDownloadDocumentById}
            />
        }
        </Grid>
      </ListContainer>
    </>
  );
}
