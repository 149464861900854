import {Link, Theme, useMediaQuery} from "@mui/material";
import {styled} from '@mui/material/styles';
import {useDialog} from "app/providers/dialog";
import Loader from "common/components/loader";
import _ from "lodash";
import Individual from "marketplace/entities/individual/individual";
import {MouseEvent, useState} from "react";
import ProposalAPIService from "work/entities/proposal/api/proposal-api-service";
import Proposal from "work/entities/proposal/proposal";
import RedlinePopover from "work/entities/proposal/redlining/view/proposal-redline-popover";
import DetailsTab from "work/entities/proposal/draft/view/tabs/details-tab";
import {TeamMember} from "work/values/team/team-member";
import ViewTeam from "work/values/team/view/view-team";
import Session from "users/session/session";

const StyledLink = styled(
  Link,
  {shouldForwardProp: prop => prop !== 'revised'}
)<{ revised: boolean }>(
  ({theme, revised}) => ({
    alignItems: 'center',
    color: '#000',
    cursor: 'pointer',
    display: 'flex',
    overflow: 'hidden',
    textDecoration: revised ? 'underline dotted red' : 'underline',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }));

type TeamLinkProps = {
  proposal: Proposal;
  session: Readonly<Session>;
};

export default function TeamLink(props: Readonly<TeamLinkProps>) {
  const {
    proposal,
    session
  } = props;

  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);

  const isXlDisplaySize = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));
  const {openDialog, closeDialog} = useDialog();

  async function getDetailedProposal(): Promise<Proposal | undefined> {
    try {
      if (!proposal.id) throw new Error('Proposal ID is required');

      const proposalService = new ProposalAPIService(session);
      const {proposal: detailedProposal} = await proposalService.getProposalById(proposal.id);
      return detailedProposal;
    } catch (error) {
      console.error(error);
    }
  }

  async function handleClick(event: MouseEvent) {
    if (!proposal?.team) return;

    event.stopPropagation();

    openDialog({
      title: 'Team Members',
      component: <Loader/>
    });

    const detailedProposal = await getDetailedProposal();
    if (!detailedProposal) return;

    const leaderIndividual = detailedProposal?.details?.team?.leader;
    if (!leaderIndividual) throw new Error("Leader is required");

    const memberIndividuals = detailedProposal?.details?.team?.members;

    const leader: TeamMember = {
      individualId: leaderIndividual.id,
      userId: leaderIndividual.userId ?? undefined,
      entityId: leaderIndividual.company?.entityId,
      firstName: leaderIndividual.profile?.firstName ?? '',
      lastName: leaderIndividual.profile?.lastName ?? '',
      avatarId: leaderIndividual.profile?.avatarId
    };

    const members: TeamMember[] = memberIndividuals?.map((member: Individual) => ({
      individualId: member.id,
      userId: member.userId ?? undefined,
      entityId: member.company?.entityId,
      firstName: member.profile?.firstName ?? '',
      lastName: member.profile?.lastName ?? '',
      avatarId: member.profile?.avatarId
    })) ?? [];

    closeDialog();

    openDialog({
      title: `${DetailsTab.name.toString()} Team`,
      MuiProps: {maxWidth: 'md'},
      component: (
        <ViewTeam
          leader={leader}
          members={members}
        />
      )
    });
  }

  return (
    <>
      <StyledLink
        revised={
          proposal?.supersedes !== null &&
          !_.isEqual(
            proposal?.team,
            proposal?.supersedes?.team
          )
        }
        onMouseEnter={(event) => setPopoverAnchor(event.currentTarget)}
        onMouseLeave={() => setPopoverAnchor(null)}
        onClick={handleClick}>
        View
      </StyledLink>
      <RedlinePopover
        columnName='team'
        proposal={proposal}
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
      />
    </>
  );
}
