import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SnippetFolderIcon from "@mui/icons-material/Folder";
import { Box, Button, Icon, IconButton, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ConfirmResponse, useConfirmDialog, } from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import Guid from "common/values/guid/guid";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from "material-react-table";
import { MouseEvent, useEffect, useState } from "react";
import { useSession } from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import CommentsButton from "work/entities/comment-thread/view/comments-button";
import FeeScheduleTemplateAPIService from "work/entities/fee-schedule-template/api/fee-schedule-template-api-service";
import FeeScheduleTemplate from "work/entities/fee-schedule-template/fee-schedule-template";
import { ProposalField } from "work/entities/proposal/proposal";
import FeeScheduleCategoryName from "work/values/fee-schedule-category-name/fee-schedule-category-name";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import FeeScheduleCategoryForm from "work/values/fee-schedule-category/view/fee-schedule-category-form";
import FeeScheduleExistingCategories from "work/values/fee-schedule-category/view/fee-schedule-existing-categories";
import FeeScheduleExistingTemplate from "work/values/fee-schedule-category/view/fee-schedule-existing-template";

const ActionsContainer = styled(Box)(
  ({theme}) => ({
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  }));
const RowTitle = styled(Typography)(
  () => ({
    fontWeight: "500",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }));
const RowActionsContainer = styled("div")(
  () => ({
    whiteSpace: 'nowrap'
  }));

type FeeScheduleCategoriesProps = {
  className?: string;
  categories: Array<FeeScheduleCategory>;
  disableEditing?: boolean;
  disableCategorySelection?: boolean;
  hideFromTemplateButton?: boolean;
  hideAddButton?: boolean;
  hideExistingButton?: boolean;
  hideCommentButton?: boolean;
  hideActionsColumn?: boolean;
  commentThreads?: CommentThread[];
  commentsToolTip: string;
  onCommentsClicked?: (field: ProposalField, name?: string) => void;
  onCategoryAdded?: (category: FeeScheduleCategory) => void;
  onCategoryUpdated?: (
    originalName: FeeScheduleCategoryName | null,
    updatedCategory: FeeScheduleCategory
  ) => void;
  onCategoryRemoved?: (name: FeeScheduleCategoryName) => void;
  onExistingTemplateSelected?: (
    template: FeeScheduleTemplate,
    replace: boolean
  ) => void;
};

export default function FeeScheduleCategories(
  props: Readonly<FeeScheduleCategoriesProps>
) {
  const {
    className,
    categories,
    disableEditing,
    disableCategorySelection,
    hideFromTemplateButton,
    hideAddButton,
    hideExistingButton,
    hideCommentButton,
    hideActionsColumn,
    commentThreads,
    commentsToolTip,
    onCommentsClicked,
    onCategoryAdded,
    onCategoryUpdated,
    onCategoryRemoved,
    onExistingTemplateSelected,
  } = props;

  const [existingTemplates, setExistingTemplates] = useState<
    FeeScheduleTemplate[]
  >([]);
  const [existingCategories, setExistingCategories] = useState<
    FeeScheduleCategory[]
  >([]);

  const session = useSession();
  const confirm = useConfirmDialog();
  const {openDialog, popDialog} = useDialog();

  useEffect(
    () => {
      getExistingTemplatesAndCategories();
    },
    []
  );

  async function getExistingTemplatesAndCategories() {
    if (!session.user?.isCompanyManager) return;
    try {
      const existingCategories: FeeScheduleCategory[] = [];
      const entityId = session.currentEntity.entityId;
      const accountType = session.accountType;
      const feeScheduleService = new FeeScheduleTemplateAPIService(session);
      const abortController = new AbortController();
      const templates = await feeScheduleService.getFeeScheduleTemplates(
        entityId,
        accountType,
        abortController
      );

      templates.forEach((template) => {
        template.categories.forEach((category) => {
          existingCategories.push(category);
        });
      });

      setExistingTemplates(templates);
      setExistingCategories(existingCategories);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleRemoveButtonClicked(
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    category: FeeScheduleCategory
  ) {
    event.stopPropagation();

    if (!category) return;

    const response = await confirm({
      title: "Delete Fee Schedule Category?",
      message: `Delete the ${category.name} fee schedule category?`,
      okButtonText: "Delete",
      cancelButtonText: "Cancel",
    });

    if (response === ConfirmResponse.Cancel) return;

    onCategoryRemoved?.(category.name);
  }

  function beginAddCategory() {
    openDialog({
      title: "Create Fee Schedule Category",
      component: (
        <FeeScheduleCategoryForm
          onCategoryAdded={onCategoryAdded}
          popDialog={popDialog}
        />
      ),
    });
  }

  function beginEditCategory(category: FeeScheduleCategory | undefined | null) {
    if (!category || disableEditing) return;

    openDialog({
      title: "Edit Fee Schedule Category",
      component: (
        <FeeScheduleCategoryForm
          category={category}
          onCategoryUpdated={onCategoryUpdated}
          popDialog={popDialog}
        />
      ),
    });
  }

  function handleExistingCategoryClicked() {
    openDialog({
      title: "Add Existing Fee Schedule Category",
      component: (
        <FeeScheduleExistingCategories
          categories={existingCategories}
          disabledCategories={categories}
          onCategoriesSelected={handleExistingCategoriesSelected}
        />
      ),
    });
  }

  function handleExistingTemplateClicked() {
    openDialog({
      title: "Select Existing Fee Schedule Template",
      component: (
        <FeeScheduleExistingTemplate
          onTemplateSelected={handleExistingTemplateSelected}
        />
      ),
    });
  }

  async function handleExistingTemplateSelected(template: FeeScheduleTemplate) {
    let replace: boolean = false;

    if (categories.length > 0) {
      const response = await confirm({
        title: "Replace Or Add Categories?",
        message: `Do you want to replace the current categories with the selected template's categories or add the selected template's categories to the current categories?`,
        okButtonText: "Replace",
        alternativeOkText: "Add",
        cancelButtonText: "Cancel",
      });

      if (response === ConfirmResponse.Cancel) return;

      replace = response === ConfirmResponse.Ok;
    }

    popDialog();
    onExistingTemplateSelected?.(
      template,
      replace
    );
  }


  function handleExistingCategoriesSelected(categories: FeeScheduleCategory[]) {
    popDialog();
    categories.forEach((category) => {
      onCategoryAdded?.(category);
    });
  }

  const columnHelper = createMRTColumnHelper<FeeScheduleCategory>();
  const columns = [
    columnHelper.accessor(
      (category) => <RowTitle>{category.name.value}</RowTitle>,
      {
        header: "Name",
        id: "name",
      }
    ),
    columnHelper.accessor(
      (category) => category.description?.value,
      {
        header: "Description",
        id: "description",
      }
    ),
    columnHelper.accessor(
      (category) => (!category?.fee?.unit ? "Yes" : "No"),
      {
        header: "Fixed?",
        id: "fixedFee",
        size: 50,
      }
    ),
    columnHelper.accessor(
      (category) =>
        !category.fee
          ? <Typography color="warning">{`Deferred to ${session.context?.viewingAsVendor ? "client" : "vendor"
          }`}</Typography>
          : new Intl.NumberFormat(
            navigator.language,
            {
              style: "currency",
              currency: category.fee?.rate?.currency.toString() ?? "USD",
            }
          ).format(category.fee?.rate?.amount ?? 0),
      {
        header: "Fee",
        id: "fee",
        size: 50
      }
    ),
    columnHelper.accessor(
      (category) => category.fee?.unit ?? <Icon>remove</Icon>,
      {
        header: "Per",
        id: "unit",
        size: 50
      }
    ),
    columnHelper.accessor(
      (category) =>
        category.billingCode?.value && category.billingCode.value.length > 0 ? (
          category.billingCode.value
        ) : (
          <Icon>remove</Icon>
        ),
      {
        header: "Code",
        id: "billingCode",
        size: 50
      }
    ),
  ];
  const table = useMaterialReactTable({
    columns,
    data: categories,
    enableRowSelection: false,
    enableTableHead: true,
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {right: ['mrt-row-actions']}
    },
    enableColumnPinning: true,
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualPagination: true,
    manualSorting: true,
    enableRowActions: !hideActionsColumn,
    muiTableBodyRowProps: ({row}) => !disableCategorySelection ? ({
      onClick: () => beginEditCategory(row.original),
      sx: {cursor: "pointer"},
    }) : {},
    renderTopToolbarCustomActions: () => {
      return (
        <ActionsContainer>
          {!disableEditing && !hideAddButton && (
            <Button
              startIcon={<AddIcon/>}
              onClick={beginAddCategory}
              variant="outlined"
            >
              Add New Category
            </Button>
          )}
          {!disableEditing && !hideExistingButton && (
            <Button
              startIcon={<SnippetFolderIcon/>}
              disabled={existingCategories.length === 0}
              onClick={handleExistingCategoryClicked}
              variant="outlined"
            >
              Add Existing Category
            </Button>
          )}
          {!disableEditing && !hideFromTemplateButton && (
            <Button
              startIcon={<FileCopyIcon/>}
              disabled={existingTemplates.length === 0}
              onClick={handleExistingTemplateClicked}
              variant="outlined"
            >
              From Existing Template
            </Button>
          )}
        </ActionsContainer>
      );
    },
    renderToolbarInternalActions: ({table}) => (
      <Box>
        <MRT_ToggleGlobalFilterButton table={table}/>
        <MRT_ToggleFiltersButton table={table}/>
        <MRT_ShowHideColumnsButton table={table}/>
        <MRT_ToggleDensePaddingButton table={table}/>
        <MRT_ToggleFullScreenButton table={table}/>
        {!hideCommentButton && (
          <CommentsButton
            commentThreads={commentThreads}
            field={ProposalField.FeeSchedule}
            toolTip={commentsToolTip}
            onCommentsClicked={onCommentsClicked ? () => {
              onCommentsClicked?.(ProposalField.FeeSchedule);
            } : undefined}
          />
        )}
      </Box>
    ),
    renderRowActions: ({row}) => {
      return (
        <RowActionsContainer>
          {!disableEditing && (
            <Tooltip title="Remove Category">
            <span>
              <IconButton
                onClick={(event) => handleRemoveButtonClicked(
                  event,
                  row.original
                )}
              >
                <DeleteIcon color="error"/>
              </IconButton>
            </span>
            </Tooltip>)}
          <CommentsButton
            field={ProposalField.FeeScheduleCategory(row.original.id)}
            commentThreads={commentThreads}
            toolTip={commentsToolTip}
            onCommentsClicked={onCommentsClicked ? () => {
              onCommentsClicked(
                ProposalField.FeeScheduleCategory(row.original.id),
                row.original.name.value
              );
            } : undefined}
          />
        </RowActionsContainer>
      );
    },
    muiTableContainerProps: ({table}) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        height: "100%",
      },
    },
  });

  return (
    <div className={className}>
      <MaterialReactTable table={table}/>
    </div>
  );
}
