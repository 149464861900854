import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SaveIcon from "@mui/icons-material/Save";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Currency from "common/components/currency";
import { Currency as CurrencyType } from "common/values/currency/currency";
import Guid from "common/values/guid/guid";
import Money from "common/values/money/money";
import { useState } from "react";
import { useSession } from "users/session/session-context";
import FeeScheduleBillingCode from "work/values/fee-schedule-billing-code/fee-schedule-billing-code";
import FeeScheduleCategoryDescription from "work/values/fee-schedule-category-description/fee-schedule-category-description";
import FeeScheduleCategoryName from "work/values/fee-schedule-category-name/fee-schedule-category-name";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import Fee from "work/values/fee/fee";

const FormContainer = styled("div")(() => ({}));
const NameTextField = styled(TextField)(({ theme }) => ({
  margin: 0,
  marginBottom: theme.spacing(2),
  width: "100%",
}));
const DescriptionTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: "100%",
}));
const PerSymbol = styled(Grid)(({ theme }) => ({
  fontSize: "1.5em",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));
const BillingCodeField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  width: "100%",
}));
const FeeRate = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
const ButtonContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(4),
  "& > button:not(:last-of-type)": {
    marginRight: theme.spacing(2),
  },
}));

type FeeScheduleCategoryProps = {
  category?: FeeScheduleCategory;
  onCategoryAdded?: (category: FeeScheduleCategory) => void;
  onCategoryUpdated?: (
    originalName: FeeScheduleCategoryName | null,
    category: FeeScheduleCategory
  ) => void;
  popDialog?: () => void;
};

export default function FeeScheduleCategoryForm(
  props: Readonly<FeeScheduleCategoryProps>
) {
  const {
    category,
    onCategoryAdded,
    onCategoryUpdated,
    popDialog
  } = props;

  const [formDirty, setFormDirty] = useState(false);
  const [formName, setFormName] = useState<string>(
    category?.name?.value ?? ""
  );
  const [formDescription, setFormDescription] = useState<string>(
    category?.description?.value ?? ""
  );
  const [formBillingCode, setFormBillingCode] = useState<string>(
    category?.billingCode?.value ?? ""
  );

  const [formFeeRate, setFormFeeRate] = useState<Money>(
    category?.fee?.rate ?? new Money(0, CurrencyType.USD)
  );
  const [formFeeUnit, setFormFeeUnit] = useState<string>(
    category?.fee?.unit ?? ""
  );
  const [isFixedFee, setIsFixedFee] = useState<boolean>(
    Boolean(category && !category.fee?.unit)
  );
  const [isDeferred, setIsDeferred] = useState<boolean>(!category?.fee);

  const session = useSession();

  function formValid(): boolean {
    return Boolean(
      formName &&
        formDescription &&
        (isDeferred ||
          (formFeeRate?.amount >= 0 && (isFixedFee || formFeeUnit?.length > 0)))
    );
  }

  function handleAddCategoryClicked(): void {
    const newCategory = getStateAsFeeScheduleCategory();
    if (!newCategory) return;
    onCategoryAdded?.(newCategory);
    popDialog?.();
  }

  function handleSaveCategoryClicked(
    originalName: FeeScheduleCategoryName | null
  ): void {
    if (!category) return;

    const updatedCategory = getStateAsFeeScheduleCategory();
    if (!updatedCategory) return;
    onCategoryUpdated?.(originalName, updatedCategory);
    popDialog?.();
  }

  function getStateAsFeeScheduleCategory(): FeeScheduleCategory | undefined {
    if (!formName || !formDescription) return;
    let fee: Fee | null;
    if (isDeferred) {
      fee = null;
    } else if (isFixedFee) {
      fee = new Fee(formFeeRate, null);
    } else {
      fee = new Fee(formFeeRate, formFeeUnit);
    }

    return new FeeScheduleCategory(
      new FeeScheduleCategoryName(formName),
      new FeeScheduleCategoryDescription(formDescription),
      fee,
      new FeeScheduleBillingCode(formBillingCode),
      category?.id ?? Guid.generate()
    );
  }

  function getDeferredLabel(): string {
    const accountType = session.context?.viewingAsVendor ? "client" : "vendor";
    if (!isDeferred) return `Let ${accountType} set fee?`;
    return `${accountType[0].toUpperCase() + accountType.slice(1)} to set fee`;
  }

  return (
    <FormContainer>
      <NameTextField
        label="Category"
        sx={{ marginTop: 1 }}
        value={formName}
        required
        onChange={(event) => {
          setFormName(event.target.value);
          setFormDirty(true);
        }}
      />
      <DescriptionTextField
        variant="outlined"
        label="Description Of Work"
        value={formDescription}
        multiline
        minRows={5}
        required
        onChange={(event) => {
          setFormDescription(event.target.value);
          setFormDirty(true);
        }}
      />
      <FormControlLabel
        label={getDeferredLabel()}
        control={
          <Checkbox
            color="primary"
            checked={isDeferred}
            onChange={() => {
              setIsDeferred((prevValue) => !prevValue);
              setFormDirty(true);
            }}
          />
        }
      />
      <BillingCodeField
        label="Billing Code (optional)"
        variant="outlined"
        value={formBillingCode ?? ""}
        onChange={(event) => {
          setFormBillingCode(event.target.value);
          setFormDirty(true);
        }}
      />
      {!isDeferred && (
        <div>
          <FormControlLabel
            label={isFixedFee ? "Fixed fee" : "Fixed Fee?"}
            labelPlacement="end"
            control={
              <Checkbox
                color="primary"
                icon={<CheckBoxOutlineBlankIcon />}
                checked={isFixedFee}
                onChange={() => {
                  setIsFixedFee((prevValue) => !prevValue);
                  setFormDirty(true);
                }}
              />
            }
          />
          <FeeRate container direction="row" alignItems="center">
            <Grid size="grow">
              <Currency
                value={formFeeRate}
                onChange={(currency: CurrencyType, amount: number) => {
                  setFormFeeRate(new Money(amount, currency ?? "USD"));
                  setFormDirty(true);
                }}
              />
            </Grid>
            {!isFixedFee && (
              <>
                <PerSymbol>/</PerSymbol>
                <Grid size="grow">
                  <TextField
                    label="Unit"
                    value={formFeeUnit}
                    onChange={(event) => {
                      setFormFeeUnit(event.target.value);
                      setFormDirty(true);
                    }}
                  />
                </Grid>
              </>
            )}
          </FeeRate>
        </div>
      )}
      <ButtonContainer>
        <Button
          color="primary"
          variant="contained"
          disabled={!formDirty || !formValid()}
          startIcon={<SaveIcon />}
          onClick={
            !category
              ? handleAddCategoryClicked
              : () => handleSaveCategoryClicked(category.name)
          }
        >
          {category ? "Save" : "Add"}
        </Button>
      </ButtonContainer>
    </FormContainer>
  );
}
