import AddIcon from '@mui/icons-material/Add';
import MessageIcon from '@mui/icons-material/Message';
import ShareIcon from '@mui/icons-material/Share';
import {
  Avatar, Button, Card, CardActions, CardContent,
  CardMedia, Chip,
  Link, Theme, Tooltip, Typography, useMediaQuery
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Hit} from 'common/contracts/search-results';
import {MarketplaceResultType} from 'common/helpers/constants';
import Guid from "common/values/guid/guid";
import Name from 'common/values/name/name';
import MarketplaceSearchInfoAPIResponse from 'marketplace/api/response-contracts/marketplace-search-info-api-response';
import IndividualAPIService from 'marketplace/entities/individual/api/individual-api-service';
import Individual from 'marketplace/entities/individual/individual';
import {BookmarkType} from 'marketplace/values/bookmark/bookmark';
import MarketplaceIndividualInfoAPIResponse
  from 'marketplace/values/individual-profile/api/response-contracts/marketplace-individual-info-api-response';
import ViewIndividualProfile from 'marketplace/values/individual-profile/view/view-individual-profile';
import BookmarkButton from 'marketplace/view/bookmark-button';
import Forum from 'messaging/entities/forum/forum';
import Chat from 'messaging/entities/forum/view/components/chat';
import {enqueueSnackbar} from 'notistack';
import React from 'react';
import {NavigateFunction} from 'react-router';
import NetworkButton from 'users/entities/user-network-connection/view/components/network-button';
import Session from 'users/session/session';
import DirectProposalButton from 'work/view/components/direct-proposal-button';

const ResultCard = styled(Card)(
  ({theme}) => ({
    [theme.breakpoints.down('xl')]: {
      display: 'block',
    },
    display: 'flex',
    flex: '1 0 auto',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    '&:first-of-type': {
      marginTop: 0
    }
  }));
const ResultHeader = styled('div')(
  ({theme}) => ({
    alignItems: 'center',
    display: 'grid',
    columnGap: theme.spacing(2),
    gridTemplateColumns: 'auto 1fr'
  }));
const ResultCardContent = styled(CardContent)(
  ({theme}) => ({
    flexGrow: 'inherit',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3)
  }));
const ResultCardActions = styled(CardActions)(
  ({theme}) => ({
    [theme.breakpoints.down('xl')]: {
      alignContent: 'flex-end',
      alignItems: 'unset',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('lg')]: {
      alignContent: 'flex-start',
      alignItems: 'unset',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(2)
    },
    alignContent: 'center',
    alignItems: 'flex-end',
    flex: 0,
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    paddingRight: theme.spacing(3)
  }));
const ResultName = styled(Typography)(
  ({theme}) => ({
    cursor: 'pointer',
    textDecoration: 'underline',
    '& em': {
      backgroundColor: 'rgba(255, 255, 0, 0.25)',
      fontStyle: 'normal'
    }
  }));
const TypeChip = styled(Chip)(
  ({theme}) => ({
    borderColor: '#33c',
    borderStyle: 'dashed',
    color: '#33c',
    opacity: 0.66
  }));
const StyledButton = styled(Button)(
  ({theme}) => ({
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    minWidth: theme.spacing(30)
  }));
const StyledNetworkButton = styled(NetworkButton)(
  ({theme}) => ({
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    minWidth: theme.spacing(30)
  }));
const StyledBookmarkButton = styled(BookmarkButton)(
  ({theme}) => ({
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    minWidth: theme.spacing(30)
  }));
const Subtitle = styled(Typography)(
  ({theme}) => ({
    fontWeight: theme.typography.fontWeightMedium
  }));
const ChatCardContent = styled(CardContent)(
  ({theme}) => ({
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0
    },
    width: '100%'
  }));
const Details = styled('div')(
  ({theme}) => ({
    [theme.breakpoints.up(1920)]: {
      columnCount: 2
    },
    [theme.breakpoints.down(1920)]: {
      columnCount: 1
    },
    [theme.breakpoints.down('xl')]: {
      columnCount: 2
    },
    [theme.breakpoints.down('lg')]: {
      columnCount: 2
    },
    [theme.breakpoints.down('md')]: {
      columnCount: 1
    },
    columnGap: theme.spacing(2),
    columnFill: 'balance',
    marginTop: theme.spacing(2),
    '& em': {
      backgroundColor: 'rgba(255, 255, 0, 0.25)',
      fontStyle: 'normal'
    }
  }));
const StyledLink = styled(Link)(
  ({theme}) => ({
    color: theme.palette.common.black,
    cursor: 'pointer',
    textDecoration: 'underline'
  })) as typeof Link;
const SmallAvatar = styled(Avatar)(
  ({theme}) => ({
    cursor: 'pointer',
    height: theme.spacing(10),
    width: theme.spacing(10)
  }));
const ResultAvatarCardMedia = styled(CardMedia)(
  ({theme}) => ({
    cursor: 'pointer',
    height: 'auto',
    width: '15vw'
  })) as typeof CardMedia;
const ResultAvatar = styled(Avatar)(
  ({theme}) => ({
    cursor: 'pointer',
    height: 'auto',
    width: '15vw'
  }));
const GridRow = styled('div')(
  ({theme}) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    marginBottom: theme.spacing(1)
  }));
const SelectMemberButton = styled(Button)(
  ({theme}) => ({
    marginLeft: theme.spacing(2)
  }));
const NameContainer = styled('div')(
  ({theme}) => ({
    display: 'flex',
    flexDirection: 'row'
  }));

export enum TeamMemberType {
  Leader = 'leader',
  Member = 'member'
}

type IndividualResultProps = {
  selectingAsLeader?: boolean;
  selectedTeamMemberUserIds?: Guid[];
  hit: Hit<MarketplaceSearchInfoAPIResponse>;
  result: MarketplaceIndividualInfoAPIResponse;
  embedded: boolean;
  session: Session;
  navigate?: NavigateFunction;
  getText: (
    result: Hit<MarketplaceSearchInfoAPIResponse>,
    fieldNames: string[],
    base?: unknown,
    noCommas?: boolean
  ) => { __html: string | TrustedHTML };
  handleResultClicked: (
    event: React.MouseEvent,
    profileId: Guid,
    profileType: MarketplaceResultType,
    component: React.JSX.Element,
    fullWidth?: boolean
  ) => void;
  onTeamMemberSelected?: (teamMember: Individual) => void;
}

export default function IndividualResult(props: Readonly<IndividualResultProps>) {
  const {
    hit,
    selectingAsLeader,
    selectedTeamMemberUserIds,
    result,
    embedded,
    session,
    navigate,
    getText,
    handleResultClicked,
    onTeamMemberSelected
  } = props;

  const [avatar, setAvatar] = React.useState<string>();
  const [showChat, setShowChat] = React.useState<boolean>(false);
  const [forum, setForum] = React.useState<Forum>();
  const individual = result.deserialize();

  const isXlDownDisplaySize = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));
  const isXlUpDisplaySize = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  const isNetworked = session.isNetworkedWith(individual.userId);
  const individualProfile = result?.deserialize();

  React.useEffect(
    () => {
      async function loadAvatar() {
        if (!result?.id) return;
        try {
          const individualId = new Guid(result.id);
          const service = new IndividualAPIService(session);
          const abortController = new AbortController();
          const avatar = await service.getIndividualAvatar(
            individualId,
            abortController
          );
          setAvatar(avatar);
        } catch (error: any) {
          console.error(error);
        }
      }

      if (result?.avatar) loadAvatar();
    },
    [result]
  );

  async function contactIndividual() {
    try {
      if (!result?.id) throw new Error("Individual ID was not provided");
      const service = new IndividualAPIService(session);
      const forumResult = await service.contactIndividual(new Guid(result.id));
      const forum = forumResult.deserialize();
      setForum(forum);
      setShowChat(true);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(
        "Couldn't find user",
        {variant: 'error'}
      );
    }
  }

  function getAddButton(result: MarketplaceIndividualInfoAPIResponse) {
    let tooltipTitle = "";
    let buttonLabel = "Add To Team";

    const alreadyOnTeam = selectedTeamMemberUserIds?.some(id =>
      id.isEqualTo(new Guid(result.userId!)));
    const isDisabled = (selectingAsLeader && !result.isVendorRep) ||
      alreadyOnTeam;
    const profileNotVisible = result.isVisible === false;

    if (profileNotVisible) {
      tooltipTitle = "Publish your profile to the marketplace to add to team";
      // buttonLabel = "Publish Profile";
    }
    if (alreadyOnTeam) {
      tooltipTitle = "Already on team";
      buttonLabel = "On Team";
    }

    return (
      <Tooltip title={tooltipTitle}>
        <span>
          <SelectMemberButton
            variant="outlined"
            color="primary"
            aria-label={buttonLabel}
            startIcon={<AddIcon/>}
            disableElevation
            disabled={isDisabled}
            onClick={() => handleSelectMemberButtonClicked(result)}>
            {buttonLabel}
          </SelectMemberButton>
        </span>
      </Tooltip>
    )
  }

  function openProfile(event: React.MouseEvent, id: string | undefined) {
    if (!id) return;
    const profileId = new Guid(id);
    handleResultClicked(
      event,
      profileId,
      MarketplaceResultType.Individual,
      <ViewIndividualProfile
        individualId={profileId}
      />,
      true
    )
  }

  async function handleSelectMemberButtonClicked(result: MarketplaceIndividualInfoAPIResponse) {
    const individual = result.deserialize();
    if (result.isVisible === false) {
      await publishProfile(individual);
      return;
    }
    onTeamMemberSelected?.(individual);
  }

  async function publishProfile(individual: Individual) {
    try {
      const updatedIndividual = structuredClone(individual);
      updatedIndividual.isVisible = !individual.isVisible;
      const service = new IndividualAPIService(session);
      await service.updateIndividual(
        individual,
        updatedIndividual
      );
      enqueueSnackbar(
        "Profile published",
        {variant: 'success'}
      );
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(
        "Couldn't publish profile. Please try again",
        {variant: 'error'}
      );
    }
  }

  function showProposalButtons() {
    //if user is not a representative, do not render
    if (!session.isContextRepresentative) return false;

    //if target is not a representative, do not render
    if (!(result.isVendorRep || result.isClientRep)) return false;

    //do not render button if result is self
    const resultUserId = result?.userId ? new Guid(result.userId) : undefined;
    if (!resultUserId || session.user?.id?.isEqualTo(resultUserId)) return false;

    const resultEntityId = result?.companyEntityId ? new Guid(result.companyEntityId) : undefined;
    if (!resultEntityId) return false;

    //do not render button if user is same entity as target
    const userEntityId = session.entities?.[0]?.id;
    return userEntityId?.isEqualTo(resultEntityId) === false;
  }

  function handleSendProposalToResult(targetUrl: URL): void {
    navigate?.(targetUrl.pathname + targetUrl.search);
  }

  return (
    <React.Fragment>
      <ResultCard raised={false} variant="outlined">
        {isXlUpDisplaySize && (
          <>
            <ResultAvatarCardMedia
              style={{display: !avatar ? 'none' : undefined}}
              component="img"
              image={avatar}
              onClick={(event) => openProfile(
                event,
                result?.id
              )}
            />
            <ResultAvatar
              style={{display: avatar ? 'none' : undefined}}
              variant="square"
              onClick={(event) => openProfile(
                event,
                result?.id
              )}
            />
          </>
        )}
        <ResultCardContent>
          <ResultHeader>
            {isXlDownDisplaySize && (
              <span>
                  <SmallAvatar
                    style={{display: !avatar ? 'none' : undefined}}
                    variant="circular"
                    src={avatar}
                    onClick={(event) => openProfile(
                      event,
                      result?.id
                    )}
                  />
                  <SmallAvatar
                    style={{display: avatar ? 'none' : undefined}}
                    variant="circular"
                    onClick={(event) => openProfile(
                      event,
                      result?.id
                    )}
                  />
                </span>
            )}
            <span>
                <NameContainer>
                  <ResultName
                    variant="h5"
                    dangerouslySetInnerHTML={getText(
                      hit,
                      [
                        'firstName',
                        'lastName'
                      ],
                      result,
                      true
                    )}
                    onClick={(event) => openProfile(
                      event,
                      result?.id
                    )}
                  />
                  {embedded && getAddButton(result)}
                </NameContainer>
                <TypeChip label="Individual" variant="outlined" size="small"/>
              </span>
          </ResultHeader>
          <Details>
            {result?.companyName && (
              <GridRow>
                <Subtitle variant="body1">
                  Company:
                </Subtitle>
                <StyledLink
                  component={Typography}
                  dangerouslySetInnerHTML={getText(
                    hit,
                    ['companyName']
                  )}
                  onClick={(event) => openProfile(
                    event,
                    result?.companyId
                  )}>
                </StyledLink>
              </GridRow>
            )}
            {result?.technicalBackground && (
              <GridRow>
                <Subtitle variant="body1">
                  Technical Background:
                </Subtitle>
                <Typography dangerouslySetInnerHTML={getText(
                  hit,
                  ['technicalBackground']
                )}/>
              </GridRow>
            )}
            {result?.barAdmissionYear && (
              <GridRow>
                <Subtitle variant="body1">
                  Bar Admission Year:
                </Subtitle>
                <Typography dangerouslySetInnerHTML={getText(
                  hit,
                  ['barAdmissionYear']
                )}/>
              </GridRow>
            )}
            {result?.location && (
              <GridRow>
                <Subtitle variant="body1">
                  Location:
                </Subtitle>
                <Typography dangerouslySetInnerHTML={getText(
                  hit,
                  ['location']
                )}/>
              </GridRow>
            )}
            {result?.billingRate && (
              <GridRow>
                <Subtitle variant="body1">
                  Billing Rate:
                </Subtitle>
                <Typography dangerouslySetInnerHTML={getText(
                  hit,
                  ['billingRate']
                )}/>
              </GridRow>
            )}
            {result?.regionsRegistered && result?.regionsRegistered?.length > 0 && (
              <GridRow>
                <Subtitle variant="body1">
                  Regions Registered In:
                </Subtitle>
                <Typography
                  dangerouslySetInnerHTML={getText(
                    hit,
                    ['regionsRegistered']
                  )}
                />
              </GridRow>
            )}
          </Details>
        </ResultCardContent>
        <ResultCardActions disableSpacing>
          {!embedded && (
            <>
              <StyledNetworkButton
                userId={individualProfile.userId}
              />
              <StyledBookmarkButton
                type={BookmarkType.Individual}
                vendorId={individual.id}
              />
              <Tooltip title={!isNetworked ? "You must be in a individual's network before contacting them" : ''}>
                <div style={{display: 'contents', width: '100%'}}>
                  <StyledButton
                    startIcon={<MessageIcon/>}
                    variant="outlined"
                    disabled={!isNetworked}
                    onClick={contactIndividual}>
                    Send Message
                  </StyledButton>
                </div>
              </Tooltip>
              <StyledButton
                aria-label="share"
                variant="outlined"
                startIcon={<ShareIcon/>}
                onClick={() => {
                  navigator.clipboard.writeText(`${window.location.origin}/marketplace/individual/${result?.id}`);
                  enqueueSnackbar(
                    "Link copied to clipboard",
                    {variant: 'info'}
                  );
                }}>
                Share
              </StyledButton>
              {showProposalButtons() &&
                  <>
                      <DirectProposalButton
                          targetUserId={new Guid(result.userId!)}
                          targetEntityId={new Guid(result.companyEntityId!)}
                          targetName={new Name(
                            result.firstName,
                            result.lastName
                          )}
                          targetIsVendorRep={result.isVendorRep}
                          targetIsClientRep={result.isClientRep}
                          onClick={handleSendProposalToResult}
                          useStyledButton={true}
                          action={"create"}
                      />
                      <DirectProposalButton
                          targetUserId={new Guid(result.userId!)}
                          targetEntityId={new Guid(result.companyEntityId!)}
                          targetName={new Name(
                            result.firstName,
                            result.lastName
                          )}
                          targetIsVendorRep={result.isVendorRep}
                          targetIsClientRep={result.isClientRep}
                          onClick={handleSendProposalToResult}
                          useStyledButton={true}
                          action={"request"}/>
                  </>}
            </>
          )}
        </ResultCardActions>
      </ResultCard>
      {showChat && (<ChatCardContent>
        <Chat
          forums={forum ? [forum] : []}
          autoFocus={true}
          onClose={() => setShowChat(false)}
        />
      </ChatCardContent>)}
    </React.Fragment>
  );
}
