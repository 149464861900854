import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { UseSearchParams } from 'common/hooks/use-search-params';
import _ from 'lodash';
import FilterAutocomplete from 'marketplace/view/filter-autocomplete';
import { FilterChips, FilterChipType } from 'marketplace/view/filter-chips';
import FilterRating from 'marketplace/view/filter-rating';
import FilterSlider from 'marketplace/view/filter-slider';
import FilterTextField from 'marketplace/view/filter-textfield';
import React, { useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import { ArrayChip, FilterChipValueType, RangeChip, RatingChip, TextChip } from 'marketplace/view/filter-chip';
import FilterNumeric from 'marketplace/view/filter-numeric';

const FilterContainer = styled(Paper, { shouldForwardProp: (prop) => prop !== 'dialog' })<{ dialog: boolean }>(({ dialog, theme }) => ({
  maxWidth: 'initial',
  paddingLeft: !dialog ? theme.spacing(2) : 'unset'
}));
const Title = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(2)
}));

export enum ControlType {
  Autocomplete = 'autocomplete',
  Slider = 'slider',
  Switch = 'switch',
  Text = 'text',
  Rating = 'rating',
  Numeric = 'numeric'
};

type FilterListProps = {
  filters: FilterChips;
  dialog: boolean;
  onChange: (filter: FilterChipType) => void;
  onAdded: (filter: FilterChipType) => void;
  onRemoved: (filter: FilterChipType) => void;
};

export default function FilterList(props: Readonly<FilterListProps>) {
  const { filters, dialog, onChange, onAdded, onRemoved } = props;

  const searchParams = React.useRef<URLSearchParams>(null);
  const setSearchParams = React.useRef<SetURLSearchParams>(null);

  useEffect(() => {
    initFromQueryString();
  }, []);

  useEffect(() => {
    checkIfFilterDisabled(filters);
  }, [filters]);

  function initFromQueryString() {
    const params = searchParams.current;

    if (!params) return;

    for (let [name, value] of params.entries()) {
      let filter: FilterChipType | undefined = filters.chips.find(f => f.filterName === name);

      if (!filter) continue;

      let parsedValue: FilterChipValueType = value;

      if (Array.isArray(filter.default)) {
        parsedValue = value.split(',');
      } else if (typeof filter.default === 'boolean') {
        parsedValue = Boolean(value);
      } else if (typeof filter.default === 'number') {
        parsedValue = Number(value);
      }

      updateFilterValue(name, parsedValue);
      toggleFilter(name, true);
    }
  }

  function checkIfFilterDisabled(filters: FilterChips) {
    filters.chips.forEach(prevChip => {
      const currentChip = filters.chips.find(chip => chip.filterName === prevChip.filterName);

      if (currentChip?.enabled !== prevChip.enabled && currentChip?.enabled === false) {
        toggleFilter(currentChip.filterName, false);
        updateFilterValue(currentChip.filterName, currentChip.default);
        onRemoved(currentChip);
      }
    })
  }

  function toggleFilter(filterName: string, enabled: boolean) {
    let updatedfilters = [...filters.chips];
    let index = updatedfilters.findIndex(f => f.filterName === filterName);

    if (index < 0) return;

    updatedfilters[index].enabled = enabled;

    onChange(updatedfilters[index]);
  }

  function updateFilterValue(filterName: string, value: FilterChipValueType): FilterChipType | undefined {
    let updatedfilters = [...filters.chips];
    let index = updatedfilters.findIndex(f => f.filterName === filterName);

    if (index < 0) return;

    updatedfilters[index].value = value;

    onChange(updatedfilters[index]);

    return updatedfilters[index];
  }

  function getControl(filter: FilterChipType) {
    let parsedFilter = filter;

    switch (filter.controlType) {
      case ControlType.Autocomplete:
        parsedFilter = filter as ArrayChip;
        return (
          <FilterAutocomplete
            filterName={parsedFilter.filterName}
            placeholder={parsedFilter.title}
            value={parsedFilter.value ?? []}
            onChange={updateFilterValue}
            options={parsedFilter.options}
            freeSolo={parsedFilter.freeSolo ?? false}
            multiple={parsedFilter.multiple ?? false}
          />
        )
      case ControlType.Slider: {
        parsedFilter = filter as RangeChip;
        let value = _.isEmpty(parsedFilter.value) ? parsedFilter.default : parsedFilter.value;

        if (Array.isArray(value)) {
          value = value.map(v => Number(v));
        } else {
          value = Number(value);
        }

        return (
          <FilterSlider
            filterName={parsedFilter.filterName}
            value={value}
            min={parsedFilter.min}
            max={parsedFilter.max}
            step={parsedFilter.steps}
            onChange={updateFilterValue}
          />
        );
      }
      case ControlType.Text:
        parsedFilter = filter as TextChip;
        return (
          <FilterTextField
            filterName={parsedFilter.filterName}
            placeholder={parsedFilter.title}
            value={parsedFilter.value?.toString() ?? ''}
            onChange={updateFilterValue}
          />
        );
      case ControlType.Rating:
        parsedFilter = filter as RatingChip;
        return (
          <FilterRating
            filterName={parsedFilter.filterName}
            value={Number(parsedFilter.value)}
            precision={parsedFilter?.precision ?? 1}
            onChange={updateFilterValue}
          />
        );
      case ControlType.Numeric:
        parsedFilter = filter as TextChip;
        return (
          <FilterNumeric
            filterName={parsedFilter.filterName}
            value={Number(parsedFilter.value)}
            onChange={updateFilterValue}
          />
        );
      default:
        return null;
    }
  }

  return (
    <React.Fragment>
      <UseSearchParams onSearchParams={(params, setParams) => {
        searchParams.current = params;
        setSearchParams.current = setParams;
      }} />
      <FilterContainer dialog={dialog} elevation={0}>
        <Title variant="h6">Filters</Title>
        <List>
          {filters?.chips.map((filter: FilterChipType) => {
            if (filter.hidden) return null;
            return (
              <span key={filter.filterName} >
                <ListItem>
                  <ListItemText primary={filter.title} />
                  <Switch
                    checked={filter.enabled}
                    color="primary"
                    onChange={(event) => {
                      if (filter.controlType === ControlType.Switch) {
                        updateFilterValue(filter.filterName, event.target.checked);
                      }
                      toggleFilter(filter.filterName, !filter.enabled);
                      if (event.target.checked) {
                        onAdded(filter);
                      } else {
                        onRemoved(filter)
                      }
                    }}
                  />
                </ListItem>
                <Collapse in={filter.enabled && getControl(filter) !== null} timeout="auto" unmountOnExit>
                  <List disablePadding={true}>
                    <ListItem key={`${filter.filterName}-collapse`}>
                      {getControl(filter)}
                    </ListItem>
                  </List>
                </Collapse>
              </span>
            )
          })}
        </List>
      </FilterContainer>
    </React.Fragment >
  )
}
