export default class Percent {
  _value: number;

  constructor(value: number) {
    Percent.validate(value);
    this._value = value;
  }

  get isEmpty(): boolean {
    return this._value === 0;
  }

  get numeral(): number {
    return this._value;
  }

  public toString(): string {
    return `${(this._value * 100).toFixed(2)}%`;
  }

  public isEqualTo(other?: Percent | null): boolean {
    if (other === undefined || other === null) return false;
    return this._value === other._value;
  }

  public static validate(value: number) {
    if (value < 0 || value > 1) {
      throw new Error('Percent value must be between 0 and 1');
    }
  }

  public get value(): number {
    return this._value;
  }

  public static get Prototype(): Percent {
    return new Percent(0);
  }

  public fromJSON(obj: any): Percent | null {
    if (!obj) return new Percent(0);
    return new Percent(obj);
  }

  public toJSON(): any {
    return this._value;
  }

  public clone(): Percent {
    return new Percent(this._value);
  }

}