import Text from 'common/values/text/text';
import {object} from "prop-types";

export default class ProjectDescription extends Text {
  public static get Prototype(): ProjectDescription {
    return new ProjectDescription('Prototype');
  }

  public get isEmpty(): boolean {
    return this.value === '';
  }

  public static fromJSON(object: any): ProjectDescription {
    if (!object) return object;
    if (typeof object !== 'string') throw new Error('ProjectDescription.fromJSON requires a string');
    return new ProjectDescription(object);
  }

  public override fromJSON(object: any): ProjectDescription | null {
    return ProjectDescription.fromJSON(object);
  }

  public toJSON(): any {
    return this.value;
  }
}