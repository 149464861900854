import {HttpTransportType, HubConnectionBuilder, LogLevel,} from "@microsoft/signalr";
import * as Constants from "common/helpers/constants";
import reduxSignalRMiddleware, {withCallbacks,} from "common/realtime/redux-signalr-middleware";
import Guid from "common/values/guid/guid";
import Session from "users/session/session";
import ProposalAPIService from "work/entities/proposal/api/proposal-api-service";
import {
  addProposal,
  removeProposal,
  replaceProposalBuilder,
  updateAllQueriesLoading
} from "work/entities/proposal/store/proposals-redux-slice";
import Date from "common/values/date/date";
import Proposal from "work/entities/proposal/proposal";
import moment from "moment";

export const proposalHubConnection = new HubConnectionBuilder()
  .configureLogging(LogLevel.Debug)
  .withUrl(
    `${Constants.apiBaseUrl}/proposalHub`,
    {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
      accessTokenFactory: () =>
        Session.loadFromStorage(() => {
        }).authToken?.value ?? "",
    }
  )
  .withAutomaticReconnect()
  .build();

const callbacks = withCallbacks()
  .add(
    "proposal-created",
    (apiResponseObject: any) => (dispatch, getState) => {
      const session = Session.loadFromStorage(() => {
      });
      const existingProposal =
        getState().proposals.byId.entries[apiResponseObject.id];
      if (existingProposal) return;
      dispatch(updateAllQueriesLoading(true));
      const apiService = new ProposalAPIService(session);
      const proposalId = new Guid(apiResponseObject.id);
      apiService
        .getProposalById(proposalId)
        .then(({proposal, userProposalBuilder, userRedlining}) => {
          dispatch(addProposal(proposal))
          if (userProposalBuilder) {
            dispatch(
              replaceProposalBuilder({
                proposalId: proposalId,
                builder: userProposalBuilder,
              })
            );
          }
        })
        .finally(() => dispatch(updateAllQueriesLoading(false)));
    }
  )
  .add(
    "proposal-updated",
    (apiResponseObject: any) => (dispatch, getState) => {
      const lastUpdated = new Date(moment(apiResponseObject.lastUpdated));
      if (!apiResponseObject.id) {
        return;
      }
      const existingProposal: Proposal | undefined = getState().proposals.byId.entries[apiResponseObject.id];
      if (existingProposal && existingProposal.lastUpdated && !lastUpdated.isAfter(existingProposal.lastUpdated)) {
        return;
      }

      if (existingProposal && apiResponseObject.replacedBy) {
        existingProposal.replacedBy = new Guid(apiResponseObject.replacedBy);
        dispatch(addProposal(existingProposal));
        return;
      }

      const session = Session.loadFromStorage(() => {
      });
      const apiService = new ProposalAPIService(session);
      const proposalId = new Guid(apiResponseObject.id);

      apiService
        .getProposalById(proposalId)
        .then(({proposal, userProposalBuilder, userRedlining}) => {
          if (!proposal.id) {
            console.error(
              "Proposal does not have an id",
              proposal
            );
            return;
          }

          const existingProposalBuilder = proposal.id?.value
            ? getState().proposals.builders.entries[proposal.id?.value ?? "none"]
            : undefined;

          if (userProposalBuilder && existingProposalBuilder) {
            const builderToMerge = userProposalBuilder.lastUpdated.isAfter(existingProposalBuilder.lastUpdated)
              ? userProposalBuilder : existingProposalBuilder;
            dispatch(
              replaceProposalBuilder({
                proposalId: proposalId,
                builder: builderToMerge.mergeUpdatedSpec(proposal.spec)
              })
            );
          } else if (existingProposalBuilder) {
            dispatch(
              replaceProposalBuilder({
                proposalId: proposal.id,
                builder: existingProposalBuilder.mergeUpdatedSpec(proposal.spec),
              })
            );
          } else if (userProposalBuilder) {
            dispatch(
              replaceProposalBuilder({
                proposalId: proposal.id,
                builder: userProposalBuilder.mergeUpdatedSpec(proposal.spec),
              })
            );
          }

          let mergedProposal: Proposal = proposal;

          if (
            !userRedlining &&
            existingProposal?.redline &&
            !proposal.details?.redlining
          ) {
            mergedProposal.updateRedline(existingProposal.redline);
          } else if (
            userRedlining &&
            existingProposal?.redline?.lastUpdated?.isAfter(userRedlining.lastUpdated) &&
            !proposal.details?.redlining
          ) {
            mergedProposal.updateRedline(existingProposal.redline);
          } else if (
            userRedlining &&
            !existingProposal?.redline?.lastUpdated?.isAfter(userRedlining.lastUpdated) &&
            !proposal.details?.redlining
          ) {
            mergedProposal.updateRedline(userRedlining);
          } else if (
            !userRedlining &&
            existingProposal?.redline &&
            proposal.details?.redlining
          ) {
            mergedProposal = mergedProposal.mergeSharedRedline(
              existingProposal.redline,
              existingProposal.redline.lastUpdated.isAfter(mergedProposal.details?.redliningUpdated ||
                new Date(moment()
                  .subtract(
                    1000,
                    'years'
                  )))
            );
          } else if (
            userRedlining &&
            existingProposal?.redline?.lastUpdated?.isAfter(userRedlining.lastUpdated) &&
            proposal.details?.redlining
          ) {
            mergedProposal = mergedProposal.mergeSharedRedline(
              existingProposal.redline,
              existingProposal.redline.lastUpdated.isAfter(mergedProposal.details?.redliningUpdated ||
                new Date(moment()
                  .subtract(
                    1000,
                    'years'
                  )))
            );
          } else if (
            userRedlining &&
            !existingProposal?.redline?.lastUpdated?.isAfter(userRedlining.lastUpdated) &&
            proposal.details?.redlining
          ) {
            mergedProposal = mergedProposal.mergeSharedRedline(
              userRedlining,
              userRedlining.lastUpdated.isAfter(mergedProposal.details?.redliningUpdated || new Date(moment()
                .subtract(
                  1000,
                  'years'
                )))
            );
          }

          mergedProposal.updateLastSavedRedline()
          dispatch(addProposal(mergedProposal));
        });
    }
  )
  .add(
    "proposal-deleted",
    (apiResponseObject: any) => (dispatch) => {
      const id = new Guid(apiResponseObject.id);
      dispatch(removeProposal(id));
    }
  );

export const proposalsSignalRReduxMiddleware = reduxSignalRMiddleware({
  callbacks,
  connection: proposalHubConnection,
  shouldConnectionStartImmediately: true,
});
