import MessageInfo from "notifications/entities/message-info/message-info";

import MessageNotification from "notifications/entities/message-notification/message-notification";
import Guid from "common/values/guid/guid";
import ForumInfo from "notifications/entities/forum-info/forum-info";
import MessageContent from "notifications/values/message-content/message-content";
import MessageSubject from "notifications/values/message-subject/message-subject";
import RecipientName from "notifications/values/recipient-name/recipient-name";
import SenderName from "notifications/values/sender-name/sender-name";

import ForumName from "notifications/values/forum-name/forum-name";
import ForumEntityClass from "notifications/values/forum-entity-class/forum-entity-class";
import MessageInfoAPIResponse
  from "notifications/entities/message-info/api/response-contracts/message-info-api-response";
import NotificationAPIResponse
  from "notifications/entities/notification/api/response-contracts/notification-api-response";

export default class MessageNotificationAPIResponse extends NotificationAPIResponse {
  messageInfo?: MessageInfoAPIResponse;

  constructor(data?: Partial<MessageNotificationAPIResponse>) {
    super(data);
    Object.assign(
      this,
      data
    );
  }

  deserializeAsMessageNotification(): MessageNotification {
    if (!this.messageInfo?.id) throw new Error("MessageNotification messageInfo id not returned.");
    if (!this.messageInfo?.senderId) throw new Error("MessageNotification messageInfo senderId not returned.");
    if (!this.messageInfo?.recipientId) throw new Error("MessageNotification messageInfo recipientId not returned.");
    if (!this.messageInfo.forum?.id) throw new Error("MessageNotification messageInfo forum id not returned.");
    if (!this.messageInfo.recipientName) throw new Error("MessageNotification messageInfo recipientName not returned.");
    if (!this.messageInfo.forum?.name) throw new Error("MessageNotification messageInfo forum name not returned.");
    if (!this.messageInfo.forum?.entityClass) throw new Error("MessageNotification messageInfo forum entityClass not returned.");

    const notification = super.deserialize();

    return new MessageNotification(
      notification.id,
      notification.recipientUserId,
      notification.senderUserId,
      notification.message,
      notification.topic,
      notification.actionRequired,
      notification.seenAt,
      notification.createdDate,
      notification.isSeen,
      new MessageInfo(
        new Guid(this.messageInfo?.id),
        new RecipientName(this.messageInfo?.recipientName),
        new ForumInfo(
          new Guid(this.messageInfo.forum?.id),
          new ForumName(this.messageInfo?.forum?.name),
          new ForumEntityClass(this.messageInfo?.forum?.entityClass),
          this.messageInfo?.forum?.entityId ? new Guid(this.messageInfo?.forum?.entityId) : null
        ),
        this.messageInfo.content ? new MessageContent(this.messageInfo?.content) : undefined,
        this.messageInfo?.subject ? new MessageSubject(this.messageInfo?.subject) : undefined,
        new Guid(this.messageInfo?.senderId),
        this.messageInfo?.senderName ? new SenderName(this.messageInfo?.senderName) : undefined,
        new Guid(this.messageInfo?.recipientId)
      )
    );
  }
}
