import AddBoxIcon from "@mui/icons-material/AddBox";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

import UnarchiveIcon from "@mui/icons-material/Unarchive";
import {
  Box,
  Button,
  Chip,
  Container,
  Icon,
  IconButton,
  styled,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { ConfirmResponse, useConfirmDialog } from "app/providers/confirm-dialog";
import { useDialog } from "app/providers/dialog";
import { PageTab } from "app/routes/fee-schedules/tabs";
import Guid from "common/values/guid/guid";

import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable
} from "material-react-table";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Params, useLoaderData, useNavigate } from "react-router-dom";
import { useSession } from "users/session/session-context";
import FeeScheduleTemplateAPIService from "work/entities/fee-schedule-template/api/fee-schedule-template-api-service";
import FeeScheduleTemplate from "work/entities/fee-schedule-template/fee-schedule-template";
import CreateTemplateFeeScheduleDialog
  from "work/entities/fee-schedule-template/view/create-template-fee-schedule-dialog";

const ToolbarButtonsContainer = styled("div")(
  ({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    display: "flex",
    alignItems: "end",
    flexDirection: "column",
    gap: theme.spacing(1),
  }));
const ToolbarButtons = styled("div")(
  ({ theme }) => ({
    display: "flex",
    alignItems: "end",
    flexWrap: "nowrap"
  }));
const TagChip = styled(Chip)(
  ({ theme }) => ({
    color: '#777',
    marginRight: theme.spacing(0.4),
  }));
const TableTopToolbarContainer = styled("div")(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
  }));
const LowerTopToolbarContainer = styled("div")(
  ({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
    },
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    width: "100%",
  }));
const TableTitle = styled(Typography)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(1),
  }));
const RowTitle = styled(Typography)(
  ({ theme }) => ({
    fontSize: "1.1em",
    fontWeight: "600",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }));
const RowActionsContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    width: "inherit"
  }));
const NewButton = styled(IconButton)(
  ({ theme }) => ({
    padding: 0
  }));

type FeeSchedulesProps = {};

export default function FeeSchedules(props: Readonly<FeeSchedulesProps>) {
  const isDirtyRef = React.useRef<boolean>(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isRefetching, setIsRefetching] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [feeScheduleTemplates, setFeeScheduleTemplates] = React.useState<FeeScheduleTemplate[]>([]);
  const [rowCount, setRowCount] = React.useState(0);
  const [wasErrorLoading, setWasErrorLoading] = React.useState(false);
  const [columnFilters, setColumnFilters] = React.useState<MRT_ColumnFiltersState>(
    []
  );
  const [sorting, setSorting] = React.useState<MRT_SortingState>([]);
  const [pagination, setPagination] = React.useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [activeTab, setActiveTab] = React.useState<PageTab>(PageTab.Templates);

  const routeParams = useLoaderData() as Params<string>;
  const navigate = useNavigate();
  const session = useSession();
  const { openDialog, closeDialog } = useDialog();
  const confirm = useConfirmDialog();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  // Handle URL changes
  useEffect(
    () => {
      if (!session.user?.companyEntityId || !session.user?.isCompanyManager) return;

      if (routeParams.tab) {
        setActiveTab(routeParams.tab as PageTab);
      } else {
        navigate(`/fee-schedules/${PageTab.Templates}`);
      }

      if (routeParams.action === 'create') {
        beginCreateFeeScheduleTemplate();
        return;
      }

      if (routeParams.tab === PageTab.Templates && routeParams.id) {
        handleEditFeeScheduleTemplate(new Guid(routeParams.id));
      }

      fetchData(routeParams.tab as PageTab);
    },
    [routeParams]
  );

  useEffect(
    () => {
      if (!session.user?.companyEntityId || !session.user?.isCompanyManager) return;
      fetchData();
    },
    [
      columnFilters,
      query,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ]
  );

  const fetchData = async (tab?: PageTab) => {
    if (!feeScheduleTemplates.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      setIsLoading(true);

      let feeSchedules: FeeScheduleTemplate[] = [];
      const feeScheduleService = new FeeScheduleTemplateAPIService(session);
      const abortController = new AbortController();
      if (session.hasEntity) {
        if ((tab ?? activeTab) !== PageTab.Archived) {
          feeSchedules = await feeScheduleService.getFeeScheduleTemplates(
            session.entities[0]?.entityId,
            session.accountType,
            abortController
          );
        } else {
          feeSchedules = await feeScheduleService.getArchivedFeeScheduleTemplates(
            session.entities[0]?.entityId,
            session.accountType,
            abortController
          );
        }
      }

      setWasErrorLoading(false);
      setIsLoading(false);
      setIsRefetching(false);
      setFeeScheduleTemplates(feeSchedules);
      setRowCount(feeSchedules.length);
    } catch (error) {
      console.error(error);
      setWasErrorLoading(true);
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  function beginCreateFeeScheduleTemplate() {
    const parentPath = new URL(
      ".",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: 'Create Template Fee Schedule',
        MuiProps: { maxWidth: 'xl' },
        isDirtyRef: isDirtyRef,
        disableSave: true,
        component: (
          <CreateTemplateFeeScheduleDialog
            entityId={session.entities[0]?.entityId}
            onDirty={(dirty) => {
              isDirtyRef.current = dirty;
            }}
            onSave={() => {
              isDirtyRef.current = false;
              closeDialog();
              navigate(parentPath.pathname);
              fetchData();
            }}
          />
        ),
        onClose: () => {
          isDirtyRef.current = false;
          closeDialog();
        }

      },
      () => navigate(parentPath.pathname),
    );
  }

  function handleEditFeeScheduleTemplate(id: Guid) {
    const template = feeScheduleTemplates.find(template => template.id?.isEqualTo(id));

    if (!template) {
      enqueueSnackbar(
        'Fee schedule template not found',
        { variant: 'error' }
      );
      return;
    }

    const parentPath = new URL(
      ".",
      window.location.origin + window.location.pathname
    );

    openDialog(
      {
        title: 'Edit Template Fee Schedule',
        MuiProps: { fullWidth: isMediumDisplaySize, maxWidth: 'xl' },
        isDirty: isDirtyRef.current,
        disableSave: true,
        component: (
          <CreateTemplateFeeScheduleDialog
            entityId={session.entities[0].entityId}
            feeSchedule={template}
            onDirty={(dirty) => {
              isDirtyRef.current = dirty;
            }}
            onSave={() => {
              isDirtyRef.current = false;
              closeDialog();
              navigate(parentPath.pathname);
              fetchData();
            }}
          />
        ),
        onClose: () => {
          isDirtyRef.current = false;
          closeDialog();
        }
      },
      () => navigate(parentPath.pathname)
    );
  }

  /**
   * Archive or Unarchive the specified fee schedule template
   * @param id fee schedule id to archive / unarchive
   * @param archive if true, archive, else unarchive
   */
  async function handleArchiveFeeScheduleTemplate(id: Guid | undefined, archive: boolean): Promise<void> {
    if (!id) {
      Promise.reject(new Error("No fee schedule template id provided"));
      return;
    }

    const action = archive ? "Archive" : "Unarchive";

    try {
      const response = await confirm({
        title: action,
        message: `${action} fee schedule template?`,
        okButtonText: action,
        cancelButtonText: "Cancel",
      });

      if (response === ConfirmResponse.Cancel) return;

      const feeScheduleService = new FeeScheduleTemplateAPIService(session);

      if (archive)
        await feeScheduleService.archiveFeeScheduleTemplate(id);
      else
        await feeScheduleService.unarchiveFeeScheduleTemplate(id);
      enqueueSnackbar(
        `${action} fee schedule`,
        { variant: "success" }
      );
      fetchData();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        `Failed to ${action} fee schedule. Please try again`,
        {
          variant: "error",
        }
      );
    }
  }

  /**
   * Deletes the specified fee schedule template
   * @param id The fee schedule template id
   */
  async function handleDeleteFeeScheduleTemplate(id: Guid | undefined): Promise<void> {
    if (!id) {
      Promise.reject(new Error("No fee schedule template id provided"));
      return;
    }

    try {
      const response = await confirm({
        title: "Delete?",
        message: `Delete fee schedule template?`,
        okButtonText: "Delete",
        cancelButtonText: "Cancel",
      });

      if (response === ConfirmResponse.Cancel)
        return;

      const feeScheduleService = new FeeScheduleTemplateAPIService(session);
      await feeScheduleService.deleteFeeScheduleTemplate(id);
      enqueueSnackbar(
        "Deleted fee schedule",
        { variant: "success" }
      );
      fetchData();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "Failed to delete fee schedule. Please try again",
        {
          variant: "error",
        }
      );
    }
  }

  function renderTagsColumn(template: FeeScheduleTemplate) {
    if (!template.tags.length) return <Icon>remove</Icon>;
    return (
      <div>
        {template.tags.map((tag) => (
          <TagChip key={tag.value as React.Key} label={tag.value} size="small" />
        ))}
      </div>
    )
  }

  function renderCreatedDateColumn(template: FeeScheduleTemplate) {
    return (
      <time>{template.createdDate?.format('MM/DD/YYYY hh:mm A')}</time>
    )
  }

  const columnHelper = createMRTColumnHelper<FeeScheduleTemplate>();

  const columns = [
    columnHelper.accessor(
      (feeScheduleTemplate) => feeScheduleTemplate.name,
      {
        header: "Name",
        id: "name",
        Cell: ({ row }) => (
          <RowTitle>{row.original.name}</RowTitle>
        ),
      }
    ),
    columnHelper.accessor(
      renderTagsColumn,
      {
        header: "Tags",
        id: "tags",
      }
    ),
    columnHelper.accessor(
      renderCreatedDateColumn,
      {
        header: "Created",
        id: "createdDate",
      }
    ),
  ];

  const table = useMaterialReactTable({
    columns,
    data: feeScheduleTemplates,
    enableRowSelection: false,
    enableTableHead: true,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        if (!row.original.id) return;
        handleEditFeeScheduleTemplate(row.original.id);
      },
      sx: { cursor: "pointer" },
    }),
    renderToolbarInternalActions: ({ table }) => (
      <>
        {!isMediumDisplaySize && (
          <ToolbarButtonsContainer>
            <ToolbarButtons>
              <MRT_ToggleGlobalFilterButton table={table} />
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </ToolbarButtons>
            <Button
              startIcon={<AddBoxIcon />}
              onClick={() => navigate(`/fee-schedules/${PageTab.Templates}/create`)}>
              Create Fee Schedule
            </Button>
          </ToolbarButtonsContainer>
        )}
        {isMediumDisplaySize && (
          <NewButton
            color="primary"
            onClick={() => navigate(`/fee-schedules/${PageTab.Templates}/create`)}
          >
            <AddBoxIcon fontSize="large" />
          </NewButton>
        )}
      </>
    ),
    renderTopToolbarCustomActions: () => {
      return (
        <TableTopToolbarContainer>
          <TableTitle variant="h4">
            Fee Schedules
          </TableTitle>
          <LowerTopToolbarContainer>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_event, newValue) => navigate(`/fee-schedules/${newValue}/`)}
            >
              <Tab value={PageTab.Templates} label='Templates' />
              <Tab value={PageTab.Active} label='Active' />
              <Tab value={PageTab.Archived} label="Archived" />
            </Tabs>
          </LowerTopToolbarContainer>
          {isMediumDisplaySize && (
            <ToolbarButtonsContainer>
              <ToolbarButtons>
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_ToggleFullScreenButton table={table} />
              </ToolbarButtons>
            </ToolbarButtonsContainer>
          )}
        </TableTopToolbarContainer>
      );
    },
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    enableRowActions: activeTab !== PageTab.Active,
    enableColumnPinning: true,
    initialState: {
      columnPinning: { right: ['mrt-row-actions'] }
    },
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualPagination: true,
    manualSorting: true,
    renderRowActions: ({ row }) => {
      return (
        <RowActionsContainer>
          {activeTab !== PageTab.Active && (
            <Tooltip title={!row.original.archivedDate ? "Archive Template" : "Un-Archive Template"}>
              <span>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    if (!row.original.id) return;
                    handleArchiveFeeScheduleTemplate(
                      row.original.id,
                      !row.original.archivedDate
                    );
                  }}
                >
                  {!row.original.archivedDate ? <ArchiveIcon /> : <UnarchiveIcon />}
                </IconButton>
              </span>
            </Tooltip>
          )}
          {activeTab === PageTab.Templates && (
            <Tooltip title="Delete Template">
              <span>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    if (!row.original.id) return;
                    handleDeleteFeeScheduleTemplate(row.original.id);
                  }}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </RowActionsContainer>
      );
    },
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: wasErrorLoading
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setQuery,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter: query,
      isLoading,
      pagination,
      showAlertBanner: wasErrorLoading,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  if (!session.user?.companyEntityId || !session.user?.isCompanyManager) {
    return (
      <Container sx={{ height: '100%', textAlign: 'center' }}>
        <Typography sx={{ alignContent: 'center', height: '100%' }} variant="h4">
          You are not authorized to view this page
        </Typography>
      </Container>
    );
  }

  return (
    <MaterialReactTable table={table} />
  );
}
