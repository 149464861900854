import {JsonPatchOperation} from "common/services/api/attorney-hub-api-service";
import Guid from "common/values/guid/guid";
import _ from "lodash";
import CompanyProfile from "marketplace/values/company-profile/company-profile";

export default class UpdateMarketplaceCompanyAPIRequest {
  payload: JsonPatchOperation[] = []

  constructor(
    isVisible: boolean,
    contactId?: Guid,
    originalProfile?: CompanyProfile,
    updatedProfile?: CompanyProfile
  ) {
    this.payload.push({
      op: "replace",
      path: "/isVisible",
      value: isVisible
    });
    if (contactId) {
      this.payload.push({
        op: "replace",
        path: "/contactId",
        value: contactId?.toString()
      });
    }

    if (originalProfile && updatedProfile) {
      if (!updatedProfile.name) throw new Error("Company name is required");
      if (updatedProfile.name !== originalProfile.name) {
        this.payload.push({
          op: "replace",
          path: "/name",
          value: updatedProfile.name
        });
      }
      if (updatedProfile.yearEstablished !== originalProfile.yearEstablished) {
        this.payload.push({
          op: "replace",
          path: "/yearEstablished",
          value: updatedProfile.yearEstablished
        });
      }
      if (updatedProfile.numberOfEmployees !== originalProfile.numberOfEmployees) {
        this.payload.push({
          op: "replace",
          path: "/numberOfEmployees",
          value: updatedProfile.numberOfEmployees
        });
      }
      if (updatedProfile.description !== originalProfile.description) {
        this.payload.push({
          op: "replace",
          path: "/description",
          value: updatedProfile.description
        });
      }
      if (updatedProfile.location !== originalProfile.location) {
        this.payload.push({
          op: "replace",
          path: "/location",
          value: updatedProfile.location
        });
      }
      if (updatedProfile.website?.toString() !== originalProfile.website?.toString()) {
        this.payload.push({
          op: "replace",
          path: "/website",
          value: updatedProfile.website?.toString()
        });
      }
      if (!_.isEqual(updatedProfile.categories, originalProfile.categories)) {
        this.payload.push({
          op: "replace",
          path: "/categories",
          value: updatedProfile.categories
        });
      }
    }
  }
}
