import { formatCurrency } from "common/helpers/utils";
import Guid from "common/values/guid/guid";
import FeeScheduleBillingCode from "work/values/fee-schedule-billing-code/fee-schedule-billing-code";
import FeeScheduleCategoryDescription
  from "work/values/fee-schedule-category-description/fee-schedule-category-description";
import FeeScheduleCategoryName from "work/values/fee-schedule-category-name/fee-schedule-category-name";
import Fee from "work/values/fee/fee";

export default class FeeScheduleCategory {
  id: Guid;
  name: FeeScheduleCategoryName;
  description: FeeScheduleCategoryDescription | null;
  fee: Fee | null;
  billingCode: FeeScheduleBillingCode | null;

  constructor(
    name: FeeScheduleCategoryName,
    description: FeeScheduleCategoryDescription | null,
    fee: Fee | null,
    billingCode: FeeScheduleBillingCode | null,
    id: Guid
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.fee = fee;
    this.billingCode = billingCode;
  }

  public isEqualTo(other: FeeScheduleCategory | null | undefined): boolean {
    if (!other) return false;
    return (Boolean(
      this.name?.isEqualTo(other.name) &&
      this.description?.isEqualTo(other.description) &&
      ((!this.fee && !other.fee) || this.fee?.isEqualTo(other.fee)) &&
      (this.billingCode ? this.billingCode.isEqualTo(other.billingCode) : !other.billingCode)
    ));
  }

  public clone(): FeeScheduleCategory {
    return new FeeScheduleCategory(
      this.name,
      this.description,
      this.fee,
      this.billingCode,
      this.id
    );
  }

  public static fromJSON(obj: any): FeeScheduleCategory | null {
    if (!obj) return null;
    const name = FeeScheduleCategoryName.prototype.fromJSON(obj.name);
    const description = FeeScheduleCategoryDescription.prototype.fromJSON(obj.description);
    const fee = Fee.prototype.fromJSON(obj.fee);
    const billingCode = FeeScheduleBillingCode.prototype.fromJSON(obj.billingCode);
    const id = Guid.fromJSON(obj.id);

    if (!id) throw new Error('Cannot create FeeScheduleCategory without id');
    if (!name) throw new Error('Cannot create FeeScheduleCategory without name');
    if (!description) throw new Error('Cannot create FeeScheduleCategory without description');
    return new FeeScheduleCategory(
      name,
      description,
      fee,
      billingCode,
      id
    );
  }

  public fromJSON(obj: any): FeeScheduleCategory | null {
    return FeeScheduleCategory.fromJSON(obj);
  }

  toString(): string {
    let feeString: string;
    if (!this.fee) {
      feeString = (this.description ? `${this.name.value}: ${this.description.toString()}\n` : "") + '(Deferred Fee)';
    }
    else {
      feeString = `${this.name.value}: ${this.description?.toString()}\n
      ${!this.fee?.unit ? 'Fee' : 'Rate'}: ${formatCurrency(this.fee?.rate)} ${this?.fee?.rate?.currency.toString() ??
        ''} ${this.fee?.unit ? '/ ' + this.fee.unit : ''}`;
    }
    if (this.billingCode) {
      feeString += `, Billing Code: ${this.billingCode.toString()}`;
    }
    return feeString;
  }

  public toJSON(): object {
    let billingCode = this.billingCode?.toJSON();
    if (!billingCode) billingCode = null;
    return {
      id: this.id.toJSON(),
      name: this.name.toJSON(),
      description: this.description?.toJSON() ?? "",
      fee: this.fee ? this.fee.toJSON() : null,
      billingCode: billingCode
    };
  }

  get feeString(): string {
    if (!this.fee)
      return `${'(Deferred Fee)'}`;
    return `${!this.fee?.unit ? 'Fee'
      : 'Rate'}: ${formatCurrency(this.fee?.rate)} ${this?.fee?.rate?.currency.toString() ?? ''} ${this.fee?.unit
        ? '/ ' + this.fee?.unit : ''}`;
  }

  public static get Prototype() {
    return new FeeScheduleCategory(
      FeeScheduleCategoryName.Prototype,
      FeeScheduleCategoryDescription.Prototype,
      null,
      null,
      Guid.generate()
    );
  }
}
