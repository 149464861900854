import { Box, CssBaseline } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAttorneyHubDispatch } from "app/realtime-store/redux-store";

import Footer from "common/components/footer";
import Header from "common/components/header";
import SideNav from "common/components/side-nav";
import Individual from "marketplace/entities/individual/individual";
import { addIndividual } from "marketplace/entities/individual/realtime-store/individuals-redux-slice";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";
import React, {useEffect} from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useSession } from "users/session/session-context";
import {useDialog} from "../providers/dialog";

const Root = styled(Box)(() => ({
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));
const MainContainer = styled('main')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(7, 0, 0, 0)
  },
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'hidden',
  padding: theme.spacing(8, 0, 0, 0),
  width: '100%',
}));
const PageContent = styled('section')(() => ({
  flexGrow: 1,
  overflow: 'hidden',
}));

export default function Main() {
  const [showNav, setShowNav] = React.useState(false);

  const session = useSession();
  const dispatch = useAttorneyHubDispatch();
  const location = useLocation();
  const { numDialogs, closeDialog } = useDialog();

  // Close all dialogs when the route changes
  useEffect(() => {
    if (numDialogs) closeDialog();
  }, [location]);

  useEffect(() => {
    if (session.user?.individualId && session.user.id){
      const userIndividual = new Individual(session.user.individualId, session.user.id, new IndividualProfile(
        session.user.id,
        session.user.name.firstName ?? "",
        session.user.name.lastName ?? "",
        true
      ));
      dispatch(addIndividual(userIndividual));
    }
  }, []);

  return (
    <Root>
      <CssBaseline />
      {/* <SystemNotificationBanner /> */}
      <Header toggleNavMenuClicked={() => setShowNav(!showNav)} />
      <SideNav
        showNav={showNav}
        navMenuClosed={() => setShowNav(false)}
      />
      <MainContainer>
        <PageContent>
          <Outlet />
        </PageContent>
        <Footer />
      </MainContainer>
    </Root>
  )
}
