import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import { styled, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import SearchResults from "common/contracts/search-results";
import Guid from "common/values/guid/guid";
import _, { Dictionary } from "lodash";
import MarketplaceAPIService from "marketplace/api/marketplace-api-service";
import MarketplaceSearchAPIRequest from "marketplace/api/request-contracts/marketplace-search-api-request";
import MarketplaceSearchInfoAPIResponse from "marketplace/api/response-contracts/marketplace-search-info-api-response";
import IndividualAPIService from "marketplace/entities/individual/api/individual-api-service";
import Individual from "marketplace/entities/individual/individual";
import MarketplaceTeamAPIService from "marketplace/entities/marketplace-team/api/marketplace-team-api-service";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import MarketplaceIndividualInfoAPIResponse from "marketplace/values/individual-profile/api/response-contracts/marketplace-individual-info-api-response";
import MarketplaceTeamInfoAPIResponse from "marketplace/values/team-profile/api/response-contracts/marketplace-team-info-api-response";
import ResultSkeleton from "marketplace/view/result-skeleton";
import ResultsList from "marketplace/view/results-list";
import { enqueueSnackbar } from "notistack";
import React, { ChangeEvent, useEffect } from "react";
import { NavigateFunction, SetURLSearchParams } from "react-router-dom";
import Session from "users/session/session";
import MarketplaceIndividualAPIResponse
  from "../entities/individual/api/response-contracts/marketplace-individual-api-response";

const ComponentContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    paddingRight: 0,
  },
  display: "flex",
}));
const ResultCount = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    marginLeft: theme.spacing(2),
  },
}));
const LowerPagination = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: theme.spacing(1),
}));
const UpperPagination = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

type ResultsProps = {
  session: Readonly<Session>;
  searchTerm: string;
  category: string;
  filters: Dictionary<string>;
  sortBy: string;
  embedded?: boolean;
  selectingTeamLeader?: boolean;
  selectingTeamMember?: boolean;
  selectingTeam?: boolean;
  selectedTeamMemberUserIds?: Guid[];
  navigate?: NavigateFunction;
  searchParams?: URLSearchParams;
  setSearchParams?: SetURLSearchParams;
  onResults: (results: number | undefined) => void;
  onTeamMemberSelected?: (teamMember: Individual) => void;
  onTeamSelected?: (team: MarketplaceTeam) => void;
};

export default function Results(props: Readonly<ResultsProps>) {
  const {
    session,
    searchTerm,
    category,
    filters,
    sortBy,
    embedded,
    selectingTeamLeader,
    selectingTeamMember,
    selectingTeam,
    selectedTeamMemberUserIds,
    searchParams,
    setSearchParams,
    navigate,
    onResults,
    onTeamMemberSelected,
    onTeamSelected,
  } = props;

  const [results, setResults] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(25);
  const [numResults, setNumResults] = React.useState<number>(0);

  const isMediumDisplaySize = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down("md"),
    { matchMedia: window.matchMedia }
  );

  async function fetchResultList(
    searchTerm: string,
    filters: Dictionary<string> | undefined,
    abortController: AbortController
  ) {
    try {
      setLoading(true);
      let response: SearchResults<
        MarketplaceSearchInfoAPIResponse | MarketplaceIndividualInfoAPIResponse | MarketplaceTeamInfoAPIResponse
      > | SearchResults<MarketplaceIndividualAPIResponse> = { hits: [], total: 0 };

      let request = new MarketplaceSearchAPIRequest(filters);
      request.from = page && rowsPerPage ? (page - 1) * rowsPerPage : 0;
      request.size = rowsPerPage;
      request.query = searchTerm;
      request.category = category;
      request.sortBy = sortBy;

      const individualApiService = new IndividualAPIService(session);
      const teamApiService = new MarketplaceTeamAPIService(session);

      if (selectingTeam) {
        response = await teamApiService.searchTeams(request, abortController);
      } else if (selectingTeamLeader) {
        response = await individualApiService.searchIndividuals(
          request,
          abortController,
          true
        );
      } else if (selectingTeamMember) {
        response = await individualApiService.searchIndividuals(
          request,
          abortController
        );
      } else {
        const marketplaceApiService = new MarketplaceAPIService(session);
        response = await marketplaceApiService.searchAll(
          request,
          abortController
        );
      }

      let hits = response.hits ? [...response.hits] : [];

      // Add self to the results list if the user is selecting a team leader
      if (
        selectingTeamLeader &&
        session.user?.individualId &&
        !response.hits?.some(hit => hit.data.id === session.user?.individualId?.value)
      ) {
        const self = await individualApiService.getMarketplaceIndividualById(
          session.user.individualId,
          abortController
        );
        const selfResult = { data: self, score: 0, highlight: {} };
        if (self) {
          hits = [selfResult, ...hits];
        }
      }

      setResults(hits);
      setNumResults(hits.length ?? 0);
      setPage(response.from ?? 0);
      setRowsPerPage(response.size ?? 25);

      onResults(hits.length);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't get results. Please try again", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function checkQueryParams() {
      let abortController = new AbortController();

      if (
        !searchParams?.has("query") &&
        !searchParams?.has("id") &&
        !searchParams?.has("type")
      ) {
        await fetchResultList(searchTerm, filters, abortController);
        return;
      }

      const id = searchParams?.get("id") ?? "";
      const type = searchParams?.get("type") ?? "";

      if (id !== "" && type !== "") {
        navigate?.(`/marketplace/${type}/${id}`);
        return;
      }

      const term = searchParams?.get("query") ?? searchTerm ?? "";
      let queryFilters = _.fromPairs(Array.from(searchParams?.entries()));

      setPage(parseInt(searchParams?.get("pageIndex") ?? "0"));
      await fetchResultList(term, queryFilters, abortController);

      return () => {
        abortController.abort();
        abortController = new AbortController();
      };
    }
    checkQueryParams();
  }, [searchTerm, category, sortBy, searchParams]);

  async function handlePageChange(_event: ChangeEvent<unknown>, page: number) {
    setResults([]);
    setPage(page);

    const params = searchParams;

    if (params) {
      params.set("pageIndex", page.toString());
      setSearchParams?.(params);
    }

    let abortController = new AbortController();
    try {
      await fetchResultList(searchTerm, filters, abortController);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar("Couldn't get results. Please try again", {
        variant: "error",
      });
    }
  }

  return (
    <ComponentContainer>
      <Grid container direction="column" size="grow">
        <Grid>
          {loading && <ResultSkeleton />}
          {!loading && (
            <UpperPagination
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid>
                <ResultCount variant="h6">
                  {numResults} Result
                  {numResults < 1 || numResults > 1 ? "s" : ""}
                </ResultCount>
              </Grid>
              {numResults > rowsPerPage && (
                <Grid>
                  <Pagination
                    count={Math.ceil(numResults / rowsPerPage)}
                    page={page}
                    size={isMediumDisplaySize ? "medium" : "large"}
                    onChange={handlePageChange}
                  />
                </Grid>
              )}
            </UpperPagination>
          )}
        </Grid>
        {!loading && (
          <ResultsList
            results={results}
            embedded={embedded}
            selectingTeamLeader={selectingTeamLeader}
            selectingTeamMember={selectingTeamMember}
            selectingTeam={selectingTeam}
            selectedTeamMemberUserIds={selectedTeamMemberUserIds}
            navigate={navigate}
            onTeamMemberSelected={onTeamMemberSelected}
            onTeamSelected={onTeamSelected}
          />
        )}
        {numResults > rowsPerPage && !loading && (
          <LowerPagination size="grow">
            <Pagination
              count={Math.ceil(numResults / rowsPerPage)}
              page={page}
              disabled={Math.ceil(numResults / rowsPerPage) === 1}
              size={isMediumDisplaySize ? "medium" : "large"}
              onChange={handlePageChange}
            />
          </LowerPagination>
        )}
      </Grid>
    </ComponentContainer>
  );
}
