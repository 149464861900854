import axios, { AxiosHeaders, CanceledError } from "axios";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import { AccountType } from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import Session from "users/session/session";
import CreateFeeScheduleTemplateAPIRequest from "work/entities/fee-schedule-template/api/request-contracts/create-fee-schedule-template-api-request";
import UpdateFeeScheduleTemplateAPIRequest from "work/entities/fee-schedule-template/api/request-contracts/update-fee-schedule-template-api-request";
import TemplateWorkFeeScheduleAPIResponse from "work/entities/fee-schedule-template/api/response-contracts/template-work-fee-schedule-api-response";
import FeeScheduleTemplate from "work/entities/fee-schedule-template/fee-schedule-template";

export default class FeeScheduleTemplateAPIService {

  private readonly authHeaders: AxiosHeaders = new AxiosHeaders();

  private authHeadersWithJson(): AxiosHeaders {
    return this.authHeaders.concat({ 'Content-Type': 'application/json' });
  }

  constructor(session: Readonly<Session>) {
    if (!session.authToken?.value)
      throw new Error("Session must have an authToken to create a FeeScheduleTemplateAPIService");
    this.authHeaders.set("Authorization", `Bearer ${session.authToken.value}`);
  }

  async getFeeScheduleTemplates(
    entityId: Guid,
    creatorType: AccountType = AccountType.Client,
    abortController?: AbortController
  ): Promise<FeeScheduleTemplate[]> {
    const url = new URL(
      `/work/fee-schedule-templates/?entityId=${entityId.value}&creatorType=${creatorType.toLowerCase()}`,
      AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(url.toString(), {
        headers: this.authHeaders,
        signal: abortController?.signal
      });
      const templates: Array<FeeScheduleTemplate> = [];
      response.data.forEach((template: any) => {
        const templateResponse = Object.assign(
          new TemplateWorkFeeScheduleAPIResponse(),
          template
        );
        templates.push(templateResponse.deserialize());
      });
      return templates;
    }
    catch (error: any) {
      if(error instanceof CanceledError)
        throw error;
      throw new FeeScheduleTemplateAPIServiceError("getFeeScheduleTemplates", error);
    }
  }

  async getArchivedFeeScheduleTemplates(
    entityId: Guid,
    creatorType: AccountType = AccountType.Client,
    abortController?: AbortController
  ): Promise<FeeScheduleTemplate[]> {
    const url = new URL(
      `/work/fee-schedule-templates/archived/?entityId=${entityId.value}&creatorType=${creatorType.toLowerCase()}`,
      AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(url.toString(), {
        headers: this.authHeaders,
        signal: abortController?.signal
      });
      const templates: Array<FeeScheduleTemplate> = [];
      response.data.forEach((template: any) => {
        const templateResponse = Object.assign(
          new TemplateWorkFeeScheduleAPIResponse(),
          template
        );
        templates.push(templateResponse.deserialize());
      });
      return templates;
    } catch (error: any) {
      if(error instanceof CanceledError)
        throw error;
      throw new FeeScheduleTemplateAPIServiceError("getArchivedFeeScheduleTemplates", error);
    }
  }

  async createFeeScheduleTemplate(
    template: FeeScheduleTemplate,
    creatorType: string = "client"
  ): Promise<FeeScheduleTemplate> {
    const url = new URL(`/work/fee-schedule-templates`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        new CreateFeeScheduleTemplateAPIRequest(template, creatorType),
        {
          headers: this.authHeadersWithJson()
        }
      );
      const responseData = Object.assign(
        new TemplateWorkFeeScheduleAPIResponse(),
        response.data
      );
      return responseData.deserialize();
    } catch (error: any) {
      throw new FeeScheduleTemplateAPIServiceError("createFeeScheduleTemplate", error);
    }
  }

  async updateFeeScheduleTemplate(
    originalTemplate: FeeScheduleTemplate,
    updatedTemplate: FeeScheduleTemplate
  ): Promise<FeeScheduleTemplate> {
    const url = new URL(
      `/work/fee-schedule-templates/${originalTemplate.id?.value}`, 
      AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.patch(
        url.toString(),
        new UpdateFeeScheduleTemplateAPIRequest(originalTemplate, updatedTemplate).payload,
        {
          headers: this.authHeadersWithJson()
        }
      );
      const responseData = Object.assign(
        new TemplateWorkFeeScheduleAPIResponse(),
        response.data
      );
      return responseData.deserialize();
    } catch (error: any) {
      throw new FeeScheduleTemplateAPIServiceError("updateFeeScheduleTemplate", error);
    }
  }

  async deleteFeeScheduleTemplate(id: Guid): Promise<void> {
    const url = new URL(
      `/work/fee-schedule-templates/${id.value}`, 
      AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.delete(url.toString(), {
        headers: this.authHeaders
      });
    } catch (error: any) {
      throw new FeeScheduleTemplateAPIServiceError("deleteFeeScheduleTemplate", error);
    }
  }

  async archiveFeeScheduleTemplate(id: Guid): Promise<FeeScheduleTemplate> {
    const url = new URL(
      `/work/fee-schedule-templates/${id.value}/archive`, 
      AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(url.toString(), {}, {
        headers: this.authHeaders
      });
      const responseData = Object.assign(
        new TemplateWorkFeeScheduleAPIResponse(),
        response.data
      );
      return responseData.deserialize();
    } catch (error: any) {
      throw new FeeScheduleTemplateAPIServiceError("archiveFeeScheduleTemplate", error);
    }
  }

  async unarchiveFeeScheduleTemplate(id: Guid): Promise<FeeScheduleTemplate> {
    const url = new URL(
      `/work/fee-schedule-templates/${id.value}/unarchive`, 
      AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(url.toString(), {}, {
        headers: this.authHeaders
      });
      const responseData = Object.assign(
        new TemplateWorkFeeScheduleAPIResponse(),
        response.data
      );
      return responseData.deserialize();
    } catch (error: any) {
      throw new FeeScheduleTemplateAPIServiceError("unarchiveFeeScheduleTemplate", error);
    }
  }
}

export class FeeScheduleTemplateAPIServiceError extends Error {
  public method: string;
  public error: any;
  constructor(method: string, error: any) {
    super(`Error in FeeScheduleTemplateAPIService.${method}: ${error.message}`);
    this.method = method;
    this.error = error;
  }
}