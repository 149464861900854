
import BillingRateInfo from "marketplace/values/billing-rate-info/billing-rate-info";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";
import InsuranceInfo from "marketplace/values/insurance-info/insurance-info";
import MarketplaceIndividualInfoAPIResponse from "marketplace/values/individual-profile/api/response-contracts/marketplace-individual-info-api-response";
import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import PhoneNumber from "common/values/phone-number/phone-number";
import TechnicalBackgroundInfo from "marketplace/values/technical-background-info/technical-background-info";
import ProfessionalRegistration from "marketplace/values/registration-number/registration-number";
export default class MarketplaceLeaderInfoAPIResponse extends MarketplaceIndividualInfoAPIResponse {
    entityName?: string;

    deserializeToProfile(): IndividualProfile {
        if (!this.userId) throw new Error("userId is required");
        if (!this.firstName) throw new Error("firstName is required");
        if (!this.lastName) throw new Error("lastName is required");

        const profile = new IndividualProfile(
            new Guid(this.userId),
            this.firstName,
            this.lastName
        );

        profile.entityId = this.entityId ? new Guid(this.entityId) : undefined;
        profile.entityName = this.entityName;
        profile.description = this.description;
        profile.location = this.location ? this.location.deserialize() : undefined;
        profile.phoneNumber = this.phoneNumber ? new PhoneNumber(this.phoneNumber) : undefined;
        profile.avatarId = this.avatar ? new Guid(this.avatar) : undefined;
        profile.email = this.emailAddress ? new EmailAddress(this.emailAddress) : undefined;
        profile.school = this.school;
        profile.resumeId = this.resume ? new Guid(this.resume) : undefined;
        profile.professionalRegistration = this.professionalRegistration ? new ProfessionalRegistration(this.professionalRegistration) : undefined;
        profile.technicalBackground = this.technicalBackground ? new TechnicalBackgroundInfo(this.technicalBackground): undefined;
        profile.numberOfApplicationsDraftedProsecuted = this.numberOfApplicationsDraftedProsecuted;
        profile.numberOfAllowedApplications = this.numberOfAllowedApplications;
        profile.barAdmissionYear = this.barAdmissionYear;
        profile.isOnCall = this.isOnCall ?? false;
        profile.insured = this.insuredAmount ? new InsuranceInfo(this.insuredAmount) : undefined;
        profile.billingRate = this.billingRate && this.billingUnit ? new BillingRateInfo(this.billingRate, this.billingUnit) : undefined;
        profile.potentialDiversityCandidate = this.potentialDiversityCandidate ?? false;
        profile.collaborates = this.collaborates ?? false;
        profile.additionalInfo = this.additionalInfo;
        profile.categories = this.categories ?? [];
        profile.links = this.links ?? [];
        profile.professionalPublications = this.professionalPublications ?? [];
        profile.sampleApplications = this.sampleApplications ?? [];
        profile.fluentLanguages = this.fluentLanguages ?? [];
        profile.technicalLanguages = this.technicalLanguages ?? [];
        profile.regionsRegistered = this.regionsRegistered ?? [];
        profile.regionsLicensed = this.regionsLicensed ?? [];
        return profile;
    }
}
