import {TextField} from "@mui/material";
import {styled} from "@mui/material/styles";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import CommentThread from "work/entities/comment-thread/comment-thread";
import CommentsButton from "work/entities/comment-thread/view/comments-button";
import Proposal, {ProposalField, ProposalFieldCategory,} from "work/entities/proposal/proposal";

const ProposalContainer = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
    },
    display: "grid",
    gap: theme.spacing(6),
    gridTemplateColumns: "auto auto",
    width: "100%",
  }));
const GeneralSection = styled("div")(
  () => ({
    display: "flex",
    flexDirection: "column",
  }));
const DateSection = styled("div")(
  ({theme}) => ({
    display: "flex",
    flexDirection: "column",
    "& .MuiFormControl-root": {
      marginTop: theme.spacing(1),
    },
  }));
const ClientTextField = styled(TextField)(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
      width: "100%",
    },
    margin: theme.spacing(
      1,
      0
    ),
    minWidth: theme.spacing(50),
  }));
const NameTextField = styled(TextField)(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
      width: "100%",
    },
    margin: theme.spacing(
      1,
      0
    ),
    minWidth: theme.spacing(50),
  }));
const DescriptionTextField = styled(TextField)(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
      width: "100%",
    },
    margin: theme.spacing(
      1,
      0
    ),
    minWidth: theme.spacing(50),
  }));
const DateContainer = styled("div")(
  ({theme}) => ({
    display: "flex",
    margin: theme.spacing(
      1,
      0
    ),
    width: "100%",
  }));

type DetailsArchivedTabProps = {
  activeTab: ProposalFieldCategory;
  proposal: Proposal;
  commentThreads: CommentThread[];
  onCommentsClicked: (
    field: ProposalField,
    name?: string
  ) => void;
};

export default function DetailsArchivedTab(props: Readonly<DetailsArchivedTabProps>) {
  const {
    activeTab,
    proposal,
    commentThreads,
    onCommentsClicked
  } = props;

  if (activeTab !== ProposalFieldCategory.Details) return null;

  return (
    <ProposalContainer>
      <GeneralSection>
        <ClientTextField
          disabled={true}
          label={
            proposal.client?.name.toString()
              ? "Client Representative"
              : "Select Client Representative"
          }
          error={false}
          required
          value={proposal.client?.name.toString() ?? ""}
          slotProps={{
            input: {
              endAdornment:
                <CommentsButton
                  commentThreads={commentThreads}
                  hideUnreadCount={true}
                  field={ProposalField.Client}
                  toolTip={commentThreads?.some((thread) =>
                    thread.field.isEqualTo(ProposalField.Client)) ? "Comments" : "No Comments"}
                  onCommentsClicked={onCommentsClicked ? () => {
                    onCommentsClicked(ProposalField.Client)
                  } : undefined}
                />
            },
          }}
        />
        <NameTextField
          label="Proposal Name"
          required={true}
          disabled={true}
          value={proposal.name?.value ?? ""}
          error={false}
          slotProps={{
            input: {
              endAdornment: <CommentsButton
                field={ProposalField.Name}
                hideUnreadCount={true}
                commentThreads={commentThreads}
                onCommentsClicked={onCommentsClicked ? () => {
                  onCommentsClicked(ProposalField.Name);
                } : undefined}
                toolTip={commentThreads?.some((thread) =>
                  thread.field.isEqualTo(ProposalField.Name)) ?
                  "Comments" : "No Comments."}
              />
            },
          }}
        />
        <DescriptionTextField
          label="Description"
          required={true}
          multiline={true}
          rows={3}
          disabled={true}
          value={proposal.description?.value ?? ""}
          error={false}
          slotProps={{
            input: {
              endAdornment: (
                <CommentsButton
                  commentThreads={commentThreads}
                  hideUnreadCount={true}
                  field={ProposalField.Description}
                  toolTip={commentThreads?.some((thread) =>
                    thread.field.isEqualTo(ProposalField.Description)) ? "Comments" : "No Comments"}
                  onCommentsClicked={
                    onCommentsClicked ? () => {
                      onCommentsClicked(ProposalField.Description);
                    } : undefined
                  }
                />
              ),
            },
          }}
        />
      </GeneralSection>
      <DateSection>
        <DateContainer>
          <DatePicker
            disabled={true}
            value={proposal.responseDueBy?.value}
            label="Response Due Date"
            slots={{
              inputAdornment: () => (
                <CommentsButton
                  commentThreads={commentThreads}
                  hideUnreadCount={true}
                  field={ProposalField.ResponseDueBy}
                  toolTip={commentThreads?.some((thread) =>
                    thread.field.isEqualTo(ProposalField.ResponseDueBy)) ? "Comments" : "No Comments"}
                  onCommentsClicked={
                    onCommentsClicked ? () => {
                      onCommentsClicked(
                        ProposalField.ResponseDueBy
                      );
                    } : undefined
                  }
                />
              )
            }}
          />

        </DateContainer>
        <DateContainer>
          <DatePicker
            disabled={true}
            value={proposal.startDate?.value}
            label={"Start Date"}
            slots={{
              inputAdornment: () => (
                <CommentsButton
                  commentThreads={commentThreads}
                  hideUnreadCount={true}
                  field={ProposalField.StartDate}
                  toolTip={commentThreads?.some((thread) =>
                    thread.field.isEqualTo(ProposalField.StartDate)) ? "Comments" : "No Comments"}
                  onCommentsClicked={
                    onCommentsClicked ? () => {
                      onCommentsClicked(ProposalField.StartDate);
                    } : undefined
                  }
                />
              )
            }}
          />
        </DateContainer>
        <DateContainer>
          <DatePicker
            disabled={true}
            value={proposal.endDate?.value}
            label="End Date"
            slots={{
              inputAdornment: () => (
                <CommentsButton
                  commentThreads={commentThreads}
                  hideUnreadCount={true}
                  field={ProposalField.EndDate}
                  toolTip={commentThreads?.some((thread) =>
                    thread.field.isEqualTo(ProposalField.EndDate)) ? "Comments" : "No Comments"}
                  onCommentsClicked={
                    onCommentsClicked ? () => {
                      onCommentsClicked(ProposalField.EndDate);
                    } : undefined
                  }
                />
              )
            }}
          />
        </DateContainer>
      </DateSection>
    </ProposalContainer>
  );
}
