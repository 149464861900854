import Guid from "common/values/guid/guid";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import DetailedTeam from "./detailed-team";

export default class Team {
  leader: EntityVendorRepresentative | undefined;
  memberUserIds: Guid[];
  marketplaceId?: Guid;

  constructor(leader: EntityVendorRepresentative | undefined, memberUserIds: Guid[], marketplaceId?: Guid) {
    this.leader = leader;
    this.memberUserIds = memberUserIds;
    this.marketplaceId = marketplaceId;
  }

  public static FromMarketplaceTeam(team: MarketplaceTeam): Team | undefined {
    if (!team.leader?.userId || !team.leader?.entityId) return;
    const leader = new EntityVendorRepresentative(
      team.leader.userId,
      team.leader.entityId
    );
    const members: Guid[] = [];
    for (const member of team.memberships) {
      if (member.userId) {
        members.push(member.userId);
      }
    }
    return new Team(
      leader,
      members,
      team.id
    );
  }

  public isEqualTo(other?: any): boolean {
    if (!other || !(other instanceof Team)) {
      return false;
    }
    if (!this.leader && other.leader) {
      return false;
    }
    if (this.leader && !other.leader) {
      return false;
    }
    if (this.leader && !this.leader?.isEqualTo(other.leader)) {
      return false;
    }
    if (this.memberUserIds.length !== other.memberUserIds.length) {
      return false;
    }
    const sortedMemberUserIds = this.memberUserIds.toSorted((a, b) => a.value.localeCompare(b.value));
    const sortedOtherMemberUserIds = other.memberUserIds.toSorted((a, b) => a.value.localeCompare(b.value));
    for (let i = 0; i < sortedMemberUserIds.length; i++) {
      if (!sortedMemberUserIds[i].isEqualTo(sortedOtherMemberUserIds[i])) {
        return false;
      }
    }

    return true;
  }

  clone(): Team {
    return new Team(
      this.leader?.clone(),
      this.memberUserIds,
      this.marketplaceId
    );
  }

  toJSON(): any {
    return {
      leader: this.leader ? {
        entityId: this.leader.entityId.value,
        userId: this.leader.userId.value
      } : undefined,
      memberUserIds: this.memberUserIds.map(id => id.value),
      marketplaceId: this.marketplaceId?.value
    }
  }
}