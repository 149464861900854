import {
  FormControlLabel,
  Switch,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import {downloadFile} from "common/helpers/utils";
import Guid from "common/values/guid/guid";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import {enqueueSnackbar} from "notistack";
import React from "react";
import {useSession} from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import CommentsButton from "work/entities/comment-thread/view/comments-button";
import Proposal, {
  ProposalField,
  ProposalFieldCategory,
} from "work/entities/proposal/proposal";
import DocumentListReadonly from "work/values/work-document/view/document-list-readonly";
import {
  WorkDocumentType,
} from "work/values/work-document/work-document";

const ListContainer = styled(
  Grid,
  {
    shouldForwardProp: (prop) => prop !== "waived",
  }
)<{ waived: boolean }>(
  ({waived}) => ({
    flexWrap: "nowrap",
    minHeight: "20rem",
    opacity: waived ? 0.25 : 1,
    pointerEvents: waived ? "none" : "auto",
    width: "100%",
  }));
const DocumentList = styled(DocumentListReadonly)(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(2),
    },
    maxHeight: "60vh",
    marginLeft: theme.spacing(2),
    overflowY: "auto",
  }));
const HeadControls = styled("div")(
  ({theme}) => ({
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
  }));
const HelperText = styled(Typography)(
  ({theme}) => ({
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

type ConflictsArchivedProps = {
  activeTab: ProposalFieldCategory;
  proposal: Proposal;
  commentThreads?: CommentThread[];
  onCommentsClicked: (
    field: ProposalField,
    name?: string
  ) => void;
};

export default function ConflictsArchivedTab(props: Readonly<ConflictsArchivedProps>) {
  const {
    proposal,
    activeTab,
    commentThreads,
    onCommentsClicked,
  } = props;

  const [isDownloadingFile, setIsDownloadingFile] = React.useState<Guid | null>(null);

  const session = useSession();

  async function handleDownloadDocumentById(
    documentId: Guid,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    event?.stopPropagation();
    try {
      setIsDownloadingFile(documentId);
      const documentApiService = new DocumentAPIService(session);
      const conflictsDocument = await documentApiService.downloadDocument(
        documentId
      );
      downloadFile(conflictsDocument);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to download document. Please try again later.",
        {variant: "error"}
      );
    } finally {
      setIsDownloadingFile(null);
    }
  }

  if (activeTab !== ProposalFieldCategory.Conflicts) return null;

  return (
    <>
      <HeadControls>
        <FormControlLabel
          control={
            <Switch
              disabled={true}
              checked={proposal?.conflictsCheckWaived.value ?? false}
              color="primary"
            />
          }
          label={
            proposal?.conflictsCheckWaived.value ? (
              <strong>Conflicts Check Waived</strong>
            ) : (
              "Conflicts Check Not Waived"
            )
          }
        />
        <CommentsButton
          commentThreads={commentThreads}
          hideUnreadCount={true}
          field={ProposalField.Conflicts}
          toolTip={commentThreads?.some((thread) =>
            thread.field.isEqualTo(ProposalField.Conflicts)) ? "Comments" : "No Comments"}
          onCommentsClicked={
            onCommentsClicked ? () => {
              onCommentsClicked(
                ProposalField.Conflicts
              );
            } : undefined
          }
        />
      </HeadControls>
      <ListContainer
        waived={proposal?.conflictsCheckWaived.value ?? false}
        container
        direction="row"
      >
        <Grid size={4}>
          {proposal.conflictsDocuments.length === 0 &&
              <HelperText>
                  No conflicts documents for this archived proposal.
              </HelperText>
          }{proposal.conflictsDocuments.length > 0 &&
            <DocumentList
                documents={proposal?.conflictsDocuments ?? []}
                documentType={WorkDocumentType.Conflicts}
                downloadingFileId={isDownloadingFile}
                commentThreads={commentThreads}
                onCommentsClicked={onCommentsClicked}
                disableCommentsPane={true}
                onDownload={handleDownloadDocumentById}
            />}
        </Grid>
      </ListContainer>
    </>
  );
}
