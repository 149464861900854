import SaveIcon from "@mui/icons-material/Save";
import {Tooltip, useMediaQuery} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {styled, Theme} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import {ConfirmResponse, useConfirmDialog} from "app/providers/confirm-dialog";
import LoadingButton from "common/components/loading-button";
import {AccountType} from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import Individual from "marketplace/entities/individual/individual";
import MarketplaceTeam from "marketplace/entities/marketplace-team/marketplace-team";
import {enqueueSnackbar} from "notistack";
import React, {useEffect} from "react";
import {NavigateFunction} from "react-router-dom";
import {useSession} from "users/session/session-context";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import TeamTemplateAPIService from "work/entities/team-template/api/team-template-service";
import TeamTemplate from "work/entities/team-template/team-template";
import TeamName from "work/values/team-name/team-name";
import Team from "work/values/team/team";
import EditTeam from "work/values/team/view/edit-team";

const Wrapper = styled("main")(
  ({theme}) => ({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr auto",
    paddingTop: theme.spacing(1),
    width: "100%",
  }));
const HeadSection = styled("section")(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      gap: theme.spacing(1),
    },
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    gridColumnStart: 1,
    gridRowStart: 1,
    paddingTop: 0,
    width: "100%",
  }));
const HeadInputFields = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
  }));
const TeamNameField = styled(TextField)(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      marginRight: 0,
      width: "100%",
    },
    marginBottom: 0,
    marginRight: theme.spacing(1),
    width: "100%",
  }));
const TeamRoster = styled(EditTeam)(
  ({theme}) => ({
    marginTop: theme.spacing(3)
  }));
const SaveButton = styled(LoadingButton)(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
    width: "fit-content",
  }));

type TeamTemplateFormProps = {
  template?: TeamTemplate;
  name?: string;
  leader: Individual | null;
  members: Individual[];
  navigate: NavigateFunction;
  onFormDirty: (dirty?: boolean) => void;
  onSave?: (team: TeamTemplate) => void;
};

export default function TeamTemplateForm(props: Readonly<TeamTemplateFormProps>) {
  const {
    template,
    name,
    leader,
    members,
    navigate,
    onFormDirty,
    onSave
  } = props;

  const [formTeamName, setFormTeamName] = React.useState<string>(name ?? "");
  const [formSelectedTeamLeader, setFormSelectedTeamLeader] = React.useState<Individual | null>(leader);
  const [formSelectedTeamMembers, setFormSelectedTeamMembers] = React.useState<Individual[]>(members ?? []);
  const [formSelectedMarketplaceId, setFormSelectedMarketplaceId] = React.useState<Guid | undefined>(template?.marketplaceId);
  const [formRegisterToMarketplace, setFormRegisterToMarketplace] = React.useState(template?.marketplaceId !==
    undefined);

  const [saving, setSaving] = React.useState(false);
  const [formValidated, setFormValidated] = React.useState(false);

  const session = useSession();
  const confirm = useConfirmDialog();
  const isLargeDownDisplay = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  useEffect(
    () => {
      function validateForm() {
        setFormValidated(
          formSelectedTeamLeader !== undefined && formTeamName !== ""
        );
      }

      validateForm();
    },
    [
      formSelectedTeamLeader,
      formSelectedTeamMembers,
      formSelectedMarketplaceId,
      formTeamName
    ]
  );

  useEffect(
    () => {
      setFormTeamName(name ?? "");
      setFormSelectedTeamLeader(leader);
      setFormSelectedTeamMembers(members ?? []);
    },
    [
      name,
      leader,
      members
    ]
  );

  function handleLeaderReplaced(leader: Individual | null) {
    setFormSelectedTeamLeader(leader);
    onFormDirty(true);
  }

  function handleMemberAdded(member: Individual) {
    setFormSelectedTeamMembers([
      ...formSelectedTeamMembers,
      member
    ]);
    onFormDirty(true);
  }

  function handleMemberRemoved(userId?: Guid) {
    if (!userId) {
      setFormSelectedTeamMembers([]);
    } else {
      setFormSelectedTeamMembers(
        formSelectedTeamMembers.filter((m) => !m.userId?.isEqualTo(userId))
      );
    }
    onFormDirty(true);
  }

  async function handleMarketplaceTeamSelected(team: MarketplaceTeam) {
    if (formSelectedTeamLeader !== null || formSelectedTeamMembers.length > 0) {
      const response = await confirm({
        title: "Replace Team",
        message: "Are you sure you want to replace the current team?",
        okButtonText: "Replace"
      });

      if (response === ConfirmResponse.Cancel) return;
    }

    if (!team.leader.profile?.firstName || !team.leader.profile?.lastName) {
      throw new Error("Team leader profile is undefined");
    }

    setFormSelectedMarketplaceId(team.id);
    setFormSelectedTeamLeader(team.leader);
    setFormSelectedTeamMembers(team.memberships);
    setFormTeamName(team.profile.name);

    onFormDirty(true);
  }

  async function handleSaveTeamClick() {
    if (!formSelectedTeamLeader?.userId || !formSelectedTeamLeader?.entityId) return;

    try {
      setSaving(true);

      const leader = new EntityVendorRepresentative(
        formSelectedTeamLeader.userId,
        formSelectedTeamLeader.entityId
      );
      const newTeam = new Team(
        leader,
        formSelectedTeamMembers.filter(m => m.userId).map((m) => m.userId),
        formSelectedMarketplaceId
      );

      let returnedTemplate: TeamTemplate;
      const teamService = new TeamTemplateAPIService(session);
      if (template?.id) {
        returnedTemplate = await teamService.updateTeamTemplate(
          template,
          newTeam,
          new TeamName(formTeamName)
        );
      } else {
        returnedTemplate = await teamService.createTeamTemplate(
          session.currentEntity.id,
          newTeam,
          new TeamName(formTeamName),
          session.accountType
        );
      }

      try {
        if (formRegisterToMarketplace && !template?.marketplaceId) {
          const response =
            await teamService.registerTeamTemplateToMarketplace(
              returnedTemplate.id
            );
          navigate(`/marketplace/profiles/teams/edit/${response.marketplaceId?.value}`);
        }
      } catch (error) {
        console.error("Failed to register team template to marketplace: " + error);
        enqueueSnackbar(
          "Failed to register team template to marketplace. Please try again.",
          {
            variant: "error",
          }
        );
      }

      enqueueSnackbar(
        "Saved team template",
        {variant: "success"}
      );
      onSave?.(returnedTemplate);
    } catch (error: any) {
      // 409 Conflict is for duplicate team names for calling user
      if (error.response?.status === 409) {
        enqueueSnackbar(
          error.response.data,
          {variant: "warning"}
        );
      } else if (error.response?.status === 400) {
        enqueueSnackbar(
          error.response.data,
          {variant: "error"}
        );
      } else {
        console.error("Failed to save team template: " + error);
        enqueueSnackbar(
          "Failed to save team template. Please try again.",
          {
            variant: "error",
          }
        );
      }
    } finally {
      setSaving(false);
    }
  }

  function isCurrentTeamLeader(): boolean {
    return session.user?.id?.isEqualTo(formSelectedTeamLeader?.userId) ?? false;
  }

  return (
    <Wrapper>
      <HeadSection>
        <HeadInputFields>
          <TeamNameField
            value={formTeamName}
            onChange={(event) => {
              setFormTeamName(event.target.value);
              onFormDirty(true);
            }}
            label="Team Name"
            autoFocus={true}
            required
          />
          {session.accountType === AccountType.Vendor && (
            <Tooltip title={function () {
              if (!isCurrentTeamLeader()) return "Only the team leader can register the team on the marketplace";
              if (template?.marketplaceId !== undefined) return "Team is already registered on the marketplace";
              return "";
            }()}>
              <span>
                <FormControlLabel
                  label="Register Team On Marketplace"
                  labelPlacement={isLargeDownDisplay ? "end" : "start"}
                  control={
                    <Checkbox
                      color="primary"
                      checked={formRegisterToMarketplace}
                      disabled={template?.marketplaceId !== undefined || !isCurrentTeamLeader()}
                      onChange={(event) => {
                        onFormDirty(true);
                        setFormRegisterToMarketplace(event.target.checked);
                      }}
                    />
                  }
                />
              </span>
            </Tooltip>
          )}
        </HeadInputFields>
      </HeadSection>
      <TeamRoster
        leader={formSelectedTeamLeader}
        members={formSelectedTeamMembers}
        // hideCommentButton={true}
        commentsToolTip={""}
        allowEmptyLeader={true}
        navigate={navigate}
        onLeaderUpdated={handleLeaderReplaced}
        onMemberAdded={handleMemberAdded}
        onMemberRemoved={handleMemberRemoved}
        onMarketplaceTeamSelected={handleMarketplaceTeamSelected}
      />
      <SaveButton
        color="primary"
        variant="contained"
        disabled={!formValidated || saving}
        size="large"
        loading={saving}
        startIcon={<SaveIcon/>}
        onClick={handleSaveTeamClick}
      >
        Save Team
      </SaveButton>
    </Wrapper>
  );
}
