import Guid from "common/values/guid/guid";
import Date from "common/values/date/date";
import Individual from "marketplace/entities/individual/individual";
import Session from "users/session/session";
import EntityRepresentative from "work/entities/entity-representative/entity-representative";
import EntityVendorRepresentative from "work/entities/entity-vendor-representative/entity-vendor-representative";
import ProposalRedline, {RedlinedDates, RedlinedDocuments,} from "work/entities/proposal/redlining/proposal-redline";
import WorkAgreement from "work/entities/work-agreement/work-agreement";
import {ProposalAction, ProposalFieldName, ProposalStatus,} from "work/values/constants";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import ProposalReviewer from "work/values/proposal-reviewer";
import DetailedTeam from "work/values/team/detailed-team";
import User from "users/entities/user/user";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import UserInfo from "work/values/user-info/user-info";
import WorkDocument from "work/values/work-document/work-document";
import Percent from "common/values/percent/percent";
import FieldRedline, {FieldRedlineArray, IRedlineableField,} from "work/entities/proposal/redlining/field-redline";
import AHBoolean from "common/values/boolean/boolean";
import {FeeScheduleRedline} from "work/entities/proposal/redlining/fee-schedule-redline/fee-schedule-redline";
import {Audience} from "work/entities/proposal/proposal-forum-topic-context";
import _ from "lodash";
import Team from "../../values/team/team";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";

export default class Proposal {
  public replacedBy?: Guid = undefined;
  private readonly _RFPId?: Guid;
  private readonly _store: IProposalStore;
  private readonly _id?: Guid;
  private readonly _name?: ProjectName;
  private readonly _description?: ProjectDescription;
  private readonly _creator?: EntityRepresentative;
  private readonly _creatorInfo?: UserInfo;
  private readonly _createdDate?: Date;
  private readonly _lastUpdated?: Date;
  private readonly _workAgreement: WorkAgreement;
  private readonly _negotiable: boolean = true;
  private readonly _responseDueBy?: Date;
  private readonly _status: ProposalStatus = ProposalStatus.AwaitingSubmission;
  private readonly _availableActions: { [key in ProposalAction]: Guid[] };
  private readonly _details?: ProposalDetails;
  private readonly _detailed: boolean = false;
  private readonly _currentUser: User;
  private _redlining?: ProposalRedline;
  private _originalProposal: Proposal;
  private _lastSavedRedline: ProposalRedline | undefined;

  constructor(
    store: IProposalStore,
    currentUser: User,
    spec: CompleteProposalSpec,
    originalProposal?: Proposal,
    metaInfo?: ProposalMetaInfo,
    details?: ProposalDetails,
    isDetailed: boolean = false,
    createRedline: boolean = true
  ) {
    this._store = store;
    this._currentUser = currentUser;

    this._name = spec.name;
    this._description = spec.description;

    const workAgreement = new WorkAgreement();
    workAgreement.RFPId = metaInfo?.RFPId;
    workAgreement.client = spec.client;
    workAgreement.vendors = spec.vendors;
    workAgreement.name = spec.name;
    workAgreement.description = spec.description;
    workAgreement.startDate = spec.startDate;
    workAgreement.endDate = spec.endDate;
    workAgreement.team = spec.team;
    workAgreement.teamRestricted = spec.teamRestricted;
    workAgreement.feeSchedule = spec.feeSchedule ?? [];
    workAgreement.clientPolicyDocuments = spec.clientPolicyDocuments ?? [];
    workAgreement.vendorPolicyDocuments = spec.vendorPolicyDocuments ?? [];
    workAgreement.conflictsDocuments = spec.conflictsDocuments ?? [];
    workAgreement.clientTeamTemplateIds = spec.clientTeamTemplateIds ?? [];
    workAgreement.vendorTeamTemplateIds = spec.vendorTeamTemplateIds ?? [];
    workAgreement.clientFeeScheduleTemplateIds =
      spec.clientFeeScheduleTemplateIds ?? [];
    workAgreement.vendorFeeScheduleTemplateIds =
      spec.vendorFeeScheduleTemplateIds ?? [];
    workAgreement.conflictsCheckWaived =
      spec.conflictsCheckWaived ?? new AHBoolean(false);
    workAgreement.discount = spec.discount ?? new Percent(0);

    this._workAgreement = workAgreement;

    this._negotiable = spec.negotiable;
    this._responseDueBy = spec.responseDueBy;
    this._clientReviewers = spec.clientReviewers ?? [];
    this._vendorReviewers = spec.vendorReviewers ?? [];

    this._RFPId = metaInfo?.RFPId;
    this._id = metaInfo?.id;
    this._createdDate = metaInfo?.createdDate;
    this._lastUpdated = metaInfo?.lastUpdated;
    this._creator = metaInfo?.creator;
    this._creatorInfo = metaInfo?.creatorInfo;
    this._supersededById = metaInfo?.supersededById ?? null;
    this._status = metaInfo?.status ?? ProposalStatus.AwaitingSubmission;
    this._availableActions = metaInfo?.availableActions ?? {
      [ProposalAction.Submit]: [],
      [ProposalAction.Approve]: [],
      [ProposalAction.Reject]: [],
      [ProposalAction.Revise]: [],
      [ProposalAction.Hire]: [],
      [ProposalAction.Delete]: [],
      [ProposalAction.Cancel]: [],
      [ProposalAction.Edit]: [],
      [ProposalAction.Manage]: [],
      [ProposalAction.Review]: [],
    };
    this.replacedBy = metaInfo?.replacedBy;
    this._supersedes = details?.supersedes;
    this._details = details;
    if (createRedline) {
      this.createRedline(this.details?.redlining);
    }
    this._detailed = isDetailed;
    this._originalProposal = originalProposal ?? this;
  }

  private _supersedes?: Proposal;

  public get supersedes(): Proposal | null {
    return this._supersedes ?? null;
  }

  private _supersededById: Guid | null;

  public get supersededById(): Guid | null {
    return this._supersededById ?? null;
  }

  private _clientReviewers: ProposalReviewer[] = [];

  public get clientReviewers(): ProposalReviewer[] {
    return [...this._clientReviewers];
  }

  private _vendorReviewers: ProposalReviewer[] = [];

  public get vendorReviewers(): ProposalReviewer[] {
    return [...this._vendorReviewers];
  }

  public get RFPId(): Guid | undefined {
    return this._RFPId;
  }

  public get id(): Guid | null {
    return this._id ? this._id?.clone() : null;
  }

  public get creator(): EntityRepresentative | null {
    return this._creator?.clone() ?? null;
  }

  public get creatorInfo(): UserInfo | null {
    return this._creatorInfo?.clone() ?? null;
  }

  public get createdDate(): Date | null {
    return this._createdDate?.clone() ?? null;
  }

  public get lastUpdated(): Date | null {
    return this._lastUpdated?.clone() ?? null;
  }

  public get status(): ProposalStatus {
    return this._status;
  }

  public get isApprovedByBothParties(): boolean {
    return [
      ProposalStatus.AwaitingApprovalByTeam,
      ProposalStatus.AwaitingHire,
      ProposalStatus.AwaitingApprovalByVendors,
    ].includes(this.status);
  }

  public get name(): ProjectName | undefined {
    return this._name?.clone();
  }

  public get description(): ProjectDescription | undefined {
    return this._description?.clone();
  }

  public get negotiable(): boolean {
    return this._negotiable;
  }

  public get responseDueBy(): Date | undefined {
    return this._responseDueBy?.clone();
  }

  public get reviewersKey(): string {
    const reviewerKeys: string[] = [];
    this._clientReviewers.forEach((reviewer) => {
      reviewerKeys.push(`client-${reviewer.userId.value}-${reviewer.canEdit}`);
    });

    this._vendorReviewers.forEach((reviewer) => {
      reviewerKeys.push(`vendor-${reviewer.userId.value}-${reviewer.canEdit}`);
    });
    return reviewerKeys.toSorted((a, b) => a.localeCompare(b)).join(",");
  }

  public get areReviewersModified(): boolean {
    return (
      !_.isEqual(
        this.clientReviewers,
        this._originalProposal.clientReviewers
      ) ||
      !_.isEqual(
        this.vendorReviewers,
        this._originalProposal.vendorReviewers
      )
    );
  }

  public get client(): EntityClientRepresentative | null {
    return this._workAgreement.client?.clone() ?? null;
  }

  public get vendors(): EntityVendorRepresentative[] | null {
    return this._workAgreement.vendors.map((vendor) => vendor.clone()) ?? null;
  }

  public get team(): Team | DetailedTeam | null {
    return this._workAgreement.team?.clone() ?? null;
  }

  public set team(team: Team | DetailedTeam | null) {
    this._workAgreement.team = team?.clone();
  }

  public get teamRestricted(): AHBoolean {
    return this._workAgreement.teamRestricted?.clone() ?? new AHBoolean(false);
  }

  public get feeSchedule(): FeeScheduleCategory[] {
    return (
      this._workAgreement.feeSchedule?.map((category) => category.clone()) ?? []
    );
  }

  public get startDate(): Date | undefined {
    return this._workAgreement.startDate?.clone();
  }

  public get endDate(): Date | undefined {
    return this._workAgreement.endDate?.clone();
  }

  public get discount(): Percent {
    return this._workAgreement.discount;
  }

  public get clientPolicyDocuments(): WorkDocument[] {
    return this._workAgreement.clientPolicyDocuments.map((doc) => doc.clone());
  }

  public get vendorPolicyDocuments(): WorkDocument[] {
    return this._workAgreement.vendorPolicyDocuments.map((doc) => doc.clone());
  }

  public get conflictsDocuments(): WorkDocument[] {
    return this._workAgreement.conflictsDocuments.map((doc) => doc.clone());
  }

  public get clientTeamTemplateIds(): Guid[] {
    const templates = this._workAgreement.clientTeamTemplateIds;
    return templates.map((id) => id.clone());
  }

  public get vendorTeamTemplateIds(): Guid[] {
    const templates = this._workAgreement.vendorTeamTemplateIds;
    return templates.map((id) => id.clone());
  }

  public get clientFeeScheduleTemplateIds(): Guid[] {
    const templates = this._workAgreement.clientFeeScheduleTemplateIds;
    return templates.map((id) => id.clone());
  }

  public get vendorFeeScheduleTemplateIds(): Guid[] {
    const templates = this._workAgreement.vendorFeeScheduleTemplateIds;
    return templates.map((id) => id.clone());
  }

  public get conflictsCheckWaived(): AHBoolean {
    return this._workAgreement.conflictsCheckWaived ?? new AHBoolean(false);
  }

  public get availableActions(): { [key in ProposalAction]: Guid[] } {
    return this._availableActions;
  }

  public get details(): ProposalDetails | undefined {
    return this._details;
  }

  public get isDetailed(): boolean {
    return this._detailed;
  }

  public get isArchived(): boolean {
    return this._status === ProposalStatus.Archived;
  }

  public get isSubmitted(): boolean {
    return this._status !== ProposalStatus.AwaitingSubmission;
  }

  public get spec(): CompleteProposalSpec {
    const workAgreement = this._workAgreement.clone();

    return {
      client: workAgreement.client,
      name: this._name?.clone(),
      description: this._description?.clone(),
      negotiable: this._negotiable,
      responseDueBy: this._responseDueBy?.clone() ?? undefined,
      clientReviewers: this.clientReviewers.map((reviewer) => reviewer.clone()),
      vendorReviewers: this.vendorReviewers.map((reviewer) => reviewer.clone()),
      vendors: workAgreement.vendors,
      teamRestricted:
        workAgreement.teamRestricted?.clone() ?? new AHBoolean(false),
      feeSchedule: workAgreement.feeSchedule ?? [],
      startDate: workAgreement.startDate?.clone(),
      endDate: workAgreement.endDate?.clone(),
      team: (workAgreement.team instanceof DetailedTeam) ? workAgreement.team : undefined,
      conflictsCheckWaived:
        workAgreement.conflictsCheckWaived?.clone() ?? new AHBoolean(false),
      conflictsDocuments: workAgreement.conflictsDocuments,
      clientPolicyDocuments: workAgreement.clientPolicyDocuments,
      vendorPolicyDocuments: workAgreement.vendorPolicyDocuments,
      clientTeamTemplateIds: workAgreement.clientTeamTemplateIds,
      vendorTeamTemplateIds: workAgreement.vendorTeamTemplateIds,
      clientFeeScheduleTemplateIds: workAgreement.clientFeeScheduleTemplateIds,
      vendorFeeScheduleTemplateIds: workAgreement.vendorFeeScheduleTemplateIds,
      discount: workAgreement.discount ?? new Percent(0),
    };
  }

  public get metaInfo(): ProposalMetaInfo {
    if (!this._id || !this._createdDate || !this._creator)
      throw new Error(
        "Cannot get meta info for a proposal that has not been saved."
      );
    return {
      RFPId: this._RFPId,
      id: this._id?.clone(),
      supersededById: this._supersededById?.clone(),
      createdDate: this._createdDate.clone(),
      lastUpdated: this._lastUpdated?.clone() ?? this._createdDate.clone(),
      creator: this._creator?.clone(),
      creatorInfo: this._creatorInfo?.clone(),
      status: this._status,
      availableActions: this._availableActions,
      replacedBy: this.replacedBy
    };
  }

  public get redline(): ProposalRedline | undefined {
    return this._redlining ?? undefined;
  }

  public set redline(newRedline: ProposalRedline | undefined) {
    this._redlining = newRedline;
  }

  public get userIsCreator(): boolean {
    return Boolean(this._currentUser.id?.isEqualTo(this._creator?.userId));
  }

  public get userIsLatestSubmittingParty(): boolean {
    if (this.creator?.userId.isEqualTo(this._currentUser.id)) return true;

    const creatorIsClient = this.client?.userId.isEqualTo(this.creator?.userId);
    if (creatorIsClient) {
      return this._clientReviewers.some((reviewer) =>
        reviewer.userId.isEqualTo(this._currentUser.id));
    }

    const creatorIsTeamLeader = this.team?.leader?.userId.isEqualTo(this.creator?.userId);
    if (creatorIsTeamLeader) {
      return this._vendorReviewers.some((reviewer) =>
        reviewer.userId.isEqualTo(this._currentUser.id));
    }
    return false;
  };

  public get isModified(): boolean {
    if (this.hasUnsavedRedlineChanges) {
      return true;
    }
    let reviewersModified = false;
    if (this.client && this._currentUser.id?.isEqualTo((this.client.userId))) {
      reviewersModified = this._clientReviewers.length !== this._originalProposal.clientReviewers.length ||
        this._clientReviewers.some(
          (latestReviewer) =>
            !this._originalProposal.clientReviewers.some(
              (originalReviewer) => originalReviewer.isEqualTo(latestReviewer)))
    } else if (this.team && this._currentUser.id?.isEqualTo((this.team.leader?.userId))) {
      reviewersModified = this._vendorReviewers.length !== this._originalProposal.vendorReviewers.length ||
        this._vendorReviewers.some((latestReviewer) =>
          !this._originalProposal.vendorReviewers.some((originalReviewer) =>
            originalReviewer.isEqualTo(latestReviewer)))
    }
    return !this.isEquivalentTo(this._originalProposal) || reviewersModified;
  }

  public get redlineJSON(): object | undefined {
    if (!this._redlining) return undefined;

    function makeReplacer() {
      let isInitial = true;

      return (key: string, value: any) => {
        if (isInitial) {
          isInitial = false;
          return value;
        }
        if (key === "parent") return undefined;
        return value;
      };
    }

    const replacer = makeReplacer();
    this._redlining.clearSessionHistory();
    const redliningJSON = this._redlining.toJSON();
    JSON.stringify(
      redliningJSON,
      replacer
    );
    return this.redlineJSON;
  }

  public get redlinedRevision(): Proposal {
    if (!this._redlining?.isResolved)
      throw new Error("Cannot load an unresolved redline revision.");
    const revisionSpec = this.spec;

    if (this._redlining.name.currentEntry) {
      revisionSpec.name = this._redlining.name.currentEntry;
    }

    if (this._redlining.description.currentEntry) {
      revisionSpec.description = this._redlining.description.currentEntry;
    }

    revisionSpec.responseDueBy =
      this._redlining.responseDueBy.currentEntry?.clone();
    revisionSpec.startDate = this._redlining.startDate.currentEntry?.clone();
    revisionSpec.endDate = this._redlining.endDate.currentEntry?.clone();

    if (!this.team?.leader)
      throw new Error("Cannot load a redlined revision without a team leader.");

    const teamMembers: Individual[] = []
    for (const teamRedline of this._redlining.team.redlines) {
      if (!teamRedline.currentEntry) continue;
      teamMembers.push(teamRedline.currentEntry);
    }
    if (this.team.leader instanceof Individual) {
      revisionSpec.team = new DetailedTeam(
        this.team.leader,
        teamMembers
      )
    }
    revisionSpec.teamRestricted = this._redlining.teamRestricted.currentEntry ?? new AHBoolean(false);
    revisionSpec.feeSchedule = [];
    for (const redlineCategory of this._redlining.feeSchedule.redlines) {
      if (!redlineCategory.currentEntry) continue;
      revisionSpec.feeSchedule.push(redlineCategory.currentEntry);
    }

    revisionSpec.conflictsCheckWaived =
      this._redlining.conflictsCheckWaived.currentEntry ?? new AHBoolean(false);
    revisionSpec.conflictsDocuments =
      this._redlining.conflictsDocuments.redlines
        .filter((redline) => redline.currentEntry)
        .map((redline) => redline.currentEntry) as WorkDocument[];
    revisionSpec.clientPolicyDocuments =
      this._redlining.clientPolicyDocuments.redlines
        .filter((redline) => redline.currentEntry)
        .map((redline) => redline.currentEntry) as WorkDocument[];
    revisionSpec.vendorPolicyDocuments =
      this._redlining.vendorPolicyDocuments.redlines
        .filter((redline) => redline.currentEntry)
        .map((redline) => redline.currentEntry) as WorkDocument[];
    revisionSpec.discount =
      this._redlining.discount.currentEntry ?? new Percent(0);
    const revision = new Proposal(
      this._store,
      this._currentUser,
      revisionSpec,
      this._originalProposal,
      this.metaInfo,
      this.details,
      undefined,
      false
    );
    revision._clientReviewers = this._clientReviewers;
    revision._vendorReviewers = this._vendorReviewers;
    revision._redlining = this._redlining?.clone();
    revision._workAgreement.clientTeamTemplateIds = this.clientTeamTemplateIds;
    revision._workAgreement.vendorTeamTemplateIds = this.vendorTeamTemplateIds;
    revision._workAgreement.clientFeeScheduleTemplateIds = this.clientFeeScheduleTemplateIds;
    revision._workAgreement.vendorFeeScheduleTemplateIds = this.vendorFeeScheduleTemplateIds;
    return revision;
  }

  public clone(): Proposal {
    const clonedProposal = new Proposal(
      this._store,
      this._currentUser,
      this.spec,
      this._originalProposal,
      this.metaInfo,
      this.details
    );
    clonedProposal._originalProposal = this._originalProposal || this;
    clonedProposal._redlining = this._redlining?.clone();
    clonedProposal._lastSavedRedline = this._lastSavedRedline?.clone();
    return clonedProposal;
  }

  public hasFee(category: FeeScheduleCategory): boolean {
    return (
      this._workAgreement.feeSchedule?.some((cat) =>
        cat.name?.isEqualTo(category.name)
      ) ?? false
    );
  }

  public isAssociatedWithUserId(id: Guid): boolean {
    if (this._creator?.userId.isEqualTo(id)) return true;
    if (this._clientReviewers.some((reviewer) => reviewer.userId.isEqualTo(id)))
      return true;
    if (this._vendorReviewers.some((reviewer) => reviewer.userId.isEqualTo(id)))
      return true;
    if (this._status !== ProposalStatus.AwaitingSubmission) {
      if (this._workAgreement.client?.userId.isEqualTo(id)) return true;
      if (this._workAgreement.team?.leader?.userId.isEqualTo(id)) return true;
      if (
        this._workAgreement.team?.memberUserIds.some((userId) =>
          userId.isEqualTo(id)
        )
      )
        return true;
    }
    return false;
  }

  public getReviewer(userId?: Guid): ProposalReviewer | undefined {
    if (!userId) return undefined;
    return (
      this._clientReviewers.find((reviewer) =>
        reviewer.userId.isEqualTo(userId)
      ) ||
      this._vendorReviewers.find((reviewer) =>
        reviewer.userId.isEqualTo(userId)
      )
    );
  }

  public isModifiedByBuilder(builder?: ProposalBuilder): boolean {
    if (!builder) return false;
    const currentSpec = builder.currentSpec;
    if (!currentSpec) return false;
    if (!currentSpec.teamRestricted.isEqualTo(this.teamRestricted)) {
      return true;
    }
    if (!currentSpec.team && this.team) {
      return true;
    } else if (currentSpec.team && !currentSpec.team.isEqualTo(this.team)) {
      return true;
    }

    if (!currentSpec.name && this.name) {
      return true;
    } else if (currentSpec.name && !currentSpec.name.isEqualTo(this.name)) {
      return true;
    }

    if (!currentSpec.description && this.description) {
      return true;
    } else if (currentSpec.description && !currentSpec.description.isEqualTo(this.description)) {
      return true;
    }

    if (!currentSpec.responseDueBy && this.responseDueBy) {
      return true;
    } else if (currentSpec.responseDueBy && !currentSpec.responseDueBy.isEqualTo(this.responseDueBy)) {
      return true;
    }

    if (!currentSpec.startDate && this.startDate) {
      return true;
    } else if (currentSpec.startDate && !currentSpec.startDate.isEqualTo(this.startDate)) {
      return true;
    }
    if (!currentSpec.endDate && this.endDate) {
      return true;
    } else if (currentSpec.endDate && !currentSpec.endDate.isEqualTo(this.endDate)) {
      return true;
    }

    if (currentSpec.feeSchedule.length !== this.feeSchedule.length) {
      return true;
    }
    if (!currentSpec.feeSchedule.every((category) => this.hasFee(category))) {
      return true;
    }

    if (!currentSpec.conflictsCheckWaived.isEqualTo(this.conflictsCheckWaived)) {
      return true;
    }
    if (!currentSpec.discount.isEqualTo(this.discount)) {
      return true;
    }


    if (currentSpec.clientPolicyDocuments.length !== this.clientPolicyDocuments.length) {
      return true;
    }
    if (!currentSpec.clientPolicyDocuments.every((doc) =>
      this.clientPolicyDocuments.some((d) => d.isEqualTo(doc)))) {
      return true;
    }

    if (currentSpec.vendorPolicyDocuments.length !== this.vendorPolicyDocuments.length) {
      return true;
    }
    if (!currentSpec.vendorPolicyDocuments.every((doc) =>
      this.vendorPolicyDocuments.some((d) => d.isEqualTo(doc)))) {
      return true;
    }

    if (currentSpec.conflictsDocuments.length !== this.conflictsDocuments.length) {
      return true;
    }
    if (!currentSpec.conflictsDocuments.every((doc) =>
      this.conflictsDocuments.some((d) => d.isEqualTo(doc)))) {
      return true;
    }

    if (currentSpec.clientTeamTemplateIds.length !== this.clientTeamTemplateIds.length) {
      return true;
    }
    if (!currentSpec.clientTeamTemplateIds.every((id) =>
      this.clientTeamTemplateIds.some((i) => i.isEqualTo(id)))) {
      return true;
    }

    if (currentSpec.vendorTeamTemplateIds.length !== this.vendorTeamTemplateIds.length) {
      return true;
    }
    if (!currentSpec.vendorTeamTemplateIds.every((id) =>
      this.vendorTeamTemplateIds.some((i) => i.isEqualTo(id)))) {
      return true;
    }

    if (currentSpec.clientFeeScheduleTemplateIds.length !== this.clientFeeScheduleTemplateIds.length) {
      return true;
    }
    if (!currentSpec.clientFeeScheduleTemplateIds.every((id) =>
      this.clientFeeScheduleTemplateIds.some((i) => i.isEqualTo(id)))) {
      return true;
    }

    if (currentSpec.vendorFeeScheduleTemplateIds.length !== this.vendorFeeScheduleTemplateIds.length) {
      return true;
    }
    if (!currentSpec.vendorFeeScheduleTemplateIds.every((id) =>
      this.vendorFeeScheduleTemplateIds.some((i) => i.isEqualTo(id)))) {
      return true;
    }

    if (currentSpec.clientReviewers?.length !== this.clientReviewers.length) {
      return true;
    }
    if (!currentSpec.clientReviewers?.every((reviewer) =>
      this.clientReviewers.some((r) => r.isEqualTo(reviewer)))) {
      return true;
    }

    if (currentSpec.vendorReviewers?.length !== this.vendorReviewers.length) {
      return true;
    }
    if (!currentSpec.vendorReviewers?.every((reviewer) =>
      this.vendorReviewers.some((r) => r.isEqualTo(reviewer)))) {
      return true;
    }

    return false;
  }

  public async save(session: Readonly<Session>, proposalBuilderJSON ?: object):
    Promise<{
      proposal: Proposal,
      userProposalBuilder?: ProposalBuilder,
      userRedlining?: ProposalRedline
    }> {
    if (this._id !== undefined
    ) {
      if (!this._originalProposal) {
        throw new Error(
          "Cannot save a proposal without the original proposal."
        );
      }

      const proposalResponse = await this._store.saveProposal(
        this,
        proposalBuilderJSON
      );
      if (proposalResponse.proposal) {
        proposalResponse.proposal.updateLastSavedRedline();
      }
      return proposalResponse;
    } else {
      const savedProposal = await this._store.createProposal(
        this,
        session
      );
      return {
        proposal: savedProposal
      }
    }
  }

  public async share(session: Readonly<Session>):
    Promise<Proposal> {
    if (this._id !== undefined
    ) {
      if (!this._originalProposal) {
        throw new Error(
          "Cannot save a proposal without the original proposal."
        );
      }
      return await this._store.shareProposal(
        this._originalProposal,
        this
      );
    } else {
      return await this._store.createProposal(
        this,
        session
      );
    }
  }

  public async submit(userId ?: Guid)
    :
    Promise<Proposal> {
    if (!
      userId
    ) {
      throw new Error("Cannot submit a proposal without a user id.");
    }
    if (!this.creator?.userId.isEqualTo(userId)) {
      throw new Error("Cannot submit a proposal that you did not create.");
    }
    if (this._status !== ProposalStatus.AwaitingSubmission) {
      throw new Error(
        "Cannot submit a proposal that has already been submitted."
      );
    }
    if (this._workAgreement.team === undefined) {
      throw new Error("Cannot submit a proposal without a team.");
    }

    return await this._store.submitProposal(this);
  }

  public async approve(userId ?: Guid): Promise<Proposal> {
    if (!
      userId
    ) {
      throw new Error("Cannot approve a proposal without a user id.");
    }
    if (!this.isAssociatedWithUserId(userId)) {
      throw new Error(
        "Cannot approve a proposal that you are not associated with."
      );
    }
    if (
      this.status !== ProposalStatus.AwaitingApprovalByClient &&
      this.status !== ProposalStatus.AwaitingApprovalByTeam &&
      this.status !== ProposalStatus.AwaitingApprovalByTeamLeader &&
      this.status !== ProposalStatus.AwaitingApprovalByVendors
    ) {
      throw new Error(
        "Cannot approve a proposal that is not awaiting approval."
      );
    }
    if (
      !this._workAgreement.feeSchedule ||
      this._workAgreement.feeSchedule.length === 0
    ) {
      throw new Error("Cannot approve a proposal without a fee schedule.");
    }
    if (this._workAgreement.feeSchedule.some((category) => !category.fee)) {
      throw new Error("Cannot approve a proposal with deferred fees.");
    }
    if (!this._workAgreement.team) {
      throw new Error("Cannot approve a proposal without a team.");
    }
    if (
      !this._workAgreement.conflictsCheckWaived &&
      this._workAgreement.conflictsDocuments.length === 0
    ) {
      throw new Error(
        "Cannot approve a proposal without a conflicts check or waiver."
      );
    }

    return await this._store.approveProposal(this);
  }

  public async reject(userId ?: Guid): Promise<Proposal> {
    if (!
      userId
    ) {
      throw new Error("Cannot reject a proposal without a user id.");
    }
    if (!this.isAssociatedWithUserId(userId)) {
      throw new Error(
        "Cannot reject a proposal that you are not associated with."
      );
    }
    if (!this.isSubmitted || this.isArchived) {
      throw new Error(
        "Cannot reject a proposal that is not awaiting approval."
      );
    }

    return await this._store.rejectProposal(this);
  }

  public async hire(userId ?: Guid)
    :
    Promise<Proposal> {
    if (!
      userId
    ) {
      throw new Error("Cannot hire a proposal without a user id.");
    }
    if (this._workAgreement.hireDate !== undefined) {
      throw new Error("Cannot hire a proposal that has already been hired.");
    }
    if (this.status !== ProposalStatus.AwaitingHire) {
      throw new Error("Cannot hire a proposal that is not awaiting hire.");
    }

    return await this._store.hireProposal(this);
  }

  public async delete(userId ?: Guid): Promise<void> {
    if (!
      userId
    ) {
      throw new Error("Cannot delete a proposal without a user id.");
    }
    if (this.creator?.userId.value !== userId.value) {
      throw new Error("Cannot delete a proposal that you did not create.");
    }
    if (this.status !== ProposalStatus.AwaitingSubmission) {
      throw new Error(
        "Cannot delete a proposal that has already been submitted."
      );
    }

    return await this._store.deleteProposal(this);
  }

  public async cancel(userId ?: Guid): Promise<Proposal> {
    if (!
      userId
    ) {
      throw new Error("Cannot cancel a proposal without a user id.");
    }
    if (this.creator?.userId.value !== userId.value) {
      throw new Error("Cannot cancel a proposal that you did not create.");
    }

    return await this._store.cancelProposal(this);
  }

  userCanEdit(user ?: User | null)
    :
    boolean {
    if (!user?.id) return false;
    if (!this.id) return true;
    return this.availableActions[ProposalAction.Edit].some((id) =>
      id.isEqualTo(user.id)
    );
  }

  userCanRevise(user ?: User | null)
    :
    boolean {
    if (!user?.id) return false;
    return this.availableActions[ProposalAction.Revise].some((id) =>
      id.isEqualTo(user.id)
    );
  }

  userCanRedline(user ?: User | null)
    :
    boolean {
    if (!user?.id) return false;
    if (this.userCanRevise(user)) return true;

    return this.clientReviewers.some(r => r.canEdit && r.userId.isEqualTo(user.id)) ||
      this.vendorReviewers.some(r => r.canEdit && r.userId.isEqualTo(user.id));
  }

  userCanApprove(user ?: User | null)
    :
    boolean {
    if (!user?.id) return false;
    if (
      !this._workAgreement.conflictsCheckWaived &&
      this._workAgreement.conflictsDocuments.length === 0
    )
      return false;
    return this.availableActions[ProposalAction.Approve].some((id) =>
      id.isEqualTo(user.id)
    );
  }

  userCanReject(user ?: User | null)
    :
    boolean {
    if (!user?.id) return false;
    return this.availableActions[ProposalAction.Reject].some((id) =>
      id.isEqualTo(user.id)
    );
  }

  userCanHire(user ?: User | null)
    :
    boolean {
    if (!user?.id) return false;
    return this.availableActions[ProposalAction.Hire].some((id) =>
      id.isEqualTo(user.id)
    );
  }

  toJSON()
    :
    object {
    return {
      name: this._name?.value,
      description: this._description?.value,
      negotiable: this._negotiable,
      workAgreement: this._workAgreement.toJSON(),
      responseDueBy: this._responseDueBy?.value,
      clientReviewers: this._clientReviewers.map((reviewer) =>
        reviewer.toJSON()
      ),
      vendorReviewers: this._vendorReviewers.map((reviewer) =>
        reviewer.toJSON()
      ),
      redlining: this._redlining?.toJSON(),
    };
  }

  public updateRedline(redline: ProposalRedline
  ):
    Proposal {
    const newProposal = this.clone();
    newProposal._redlining = redline;
    return newProposal;
  }

  public updateClientTeamTemplateIds(ids: Guid[]
  ):
    Proposal {
    const newProposal = this.clone();
    newProposal._workAgreement.clientTeamTemplateIds = ids;
    return newProposal;
  }

  public updateVendorTeamTemplateIds(ids: Guid[]
  ):
    Proposal {
    const newProposal = this.clone();
    newProposal._workAgreement.vendorTeamTemplateIds = ids;
    return newProposal;
  }

  public updateClientFeeScheduleTemplateIds(ids: Guid[]
  ):
    Proposal {
    const newProposal = this.clone();
    newProposal._workAgreement.clientFeeScheduleTemplateIds = ids;
    return newProposal;
  }

  public updateVendorFeeScheduleTemplateIds(ids: Guid[]
  ):
    Proposal {
    const newProposal = this.clone();
    newProposal._workAgreement.vendorFeeScheduleTemplateIds = ids;
    return newProposal;
  }

  public updateClientReviewers(reviewers: ProposalReviewer[]
  ):
    Proposal {
    const newProposal = this.clone();
    newProposal._clientReviewers = reviewers;
    return newProposal;
  }

  public updateVendorReviewers(reviewers: ProposalReviewer[]
  ):
    Proposal {
    const newProposal = this.clone();
    newProposal._vendorReviewers = reviewers;
    return newProposal;
  }

  /**
   * Takes a redline shared between the same party (e.g. Client Reviewers + Client) and merges based on the original
   * state of the sharedRedline.
   *
   * This is so when a member of the same party saves redline changes, you do not lose your changes
   * unless it's a field they have also changed.
   * @param existingRedline the existing redline to merge
   */
  public mergeSharedRedline(existingRedline: ProposalRedline, invert?: boolean): Proposal {
    if (!this._redlining) throw new Error("Cannot merge a redline without a redline.");
    const nameRedline = existingRedline.name.originalRedline.isEqualTo(this._redlining.name) || invert
      ? existingRedline.name
      : this._redlining.name;
    const descriptionRedline = existingRedline.description.originalRedline.isEqualTo(this._redlining.description) ||
    invert
      ? existingRedline.description : this._redlining.description;
    const responseDueByRedline = existingRedline.responseDueBy.originalRedline.isEqualTo(this._redlining.responseDueBy) ||
    invert
      ? existingRedline.responseDueBy : this._redlining.responseDueBy;
    const startDateRedline = existingRedline.startDate.originalRedline.isEqualTo(this._redlining.startDate) || invert
      ? existingRedline.startDate : this._redlining.startDate;
    const endDateRedline = existingRedline.endDate.originalRedline.isEqualTo(this._redlining.endDate) || invert
      ? existingRedline.endDate : this._redlining.endDate;
    const teamRestrictedRedline = existingRedline.teamRestricted.originalRedline.isEqualTo(
      this._redlining.teamRestricted) || invert
      ? existingRedline.teamRestricted : this._redlining.teamRestricted;

    const teamRedline = this._redlining.team.mergeSharedRedlineArray(
      existingRedline.team,
      invert
    );

    const feeScheduleRedline = this._redlining.feeSchedule.mergeSharedFeeScheduleRedline(
      existingRedline.feeSchedule,
      invert
    );
    const conflictsDocumentsRedline = this._redlining.conflictsDocuments.mergeSharedRedlineArray(
      existingRedline.conflictsDocuments,
      invert
    );
    const clientPolicyDocumentsRedline = this._redlining.clientPolicyDocuments.mergeSharedRedlineArray(
      existingRedline.clientPolicyDocuments,
      invert
    );
    const vendorPolicyDocumentsRedline = this._redlining.vendorPolicyDocuments.mergeSharedRedlineArray(
      existingRedline.vendorPolicyDocuments,
      invert
    );

    const conflictsCheckWaivedRedline = existingRedline.conflictsCheckWaived.originalRedline
      .isEqualTo(this._redlining.conflictsCheckWaived) || invert
      ? existingRedline.conflictsCheckWaived : this._redlining.conflictsCheckWaived;
    const discountRedline = existingRedline.discount.originalRedline
      .isEqualTo(this._redlining.discount) || invert
      ? existingRedline.discount : this._redlining.discount;
    const mergedRedline = new ProposalRedline(
      nameRedline,
      descriptionRedline,
      {
        responseDueBy: responseDueByRedline,
        startDate: startDateRedline,
        endDate: endDateRedline
      },
      teamRedline,
      feeScheduleRedline,
      conflictsCheckWaivedRedline,
      teamRestrictedRedline,
      {
        conflictsDocuments: conflictsDocumentsRedline,
        clientPolicyDocuments: clientPolicyDocumentsRedline,
        vendorPolicyDocuments: vendorPolicyDocumentsRedline
      },
      discountRedline,
      new Date()
    );
    return this.updateRedline(mergedRedline);
  }

  public async requestRevision(session: Readonly<Session>):
    Promise<Proposal> {
    const newRevision = await this._store.requestRevision(
      this,
      session
    );
    return newRevision;
  }

  public getCommentAudienceAndSubscriberIds(
    isExternal:
    boolean,
    session
    :
    Session
  ):
    {
      audience: Audience;
      subscriberIds: Guid[]
    } {
    let audience: Audience;
    const subscriberIds: Guid[] = [];
    if (session.user?.id) subscriberIds.push(session.user.id);

    if (isExternal) {
      audience = Audience.AllReviewers;

      if (this.creator?.userId) subscriberIds.push(this.creator.userId);

      if (this.client?.userId) subscriberIds.push(this.client.userId);
      subscriberIds.push(
        ...this.clientReviewers.map((reviewer) => reviewer.userId)
      );

      if (this.team?.leader?.userId)
        subscriberIds.push(this.team.leader.userId);
      subscriberIds.push(
        ...this.vendorReviewers.map((reviewer) => reviewer.userId)
      );
    } else if (session.context?.viewingAsVendor) {
      audience = Audience.VendorReviewers;

      if (this.team?.leader?.userId)
        subscriberIds.push(this.team.leader.userId);
      subscriberIds.push(
        ...this.vendorReviewers.map((reviewer) => reviewer.userId)
      );
    } else {
      audience = Audience.ClientReviewers;

      if (this.client?.userId) subscriberIds.push(this.client.userId);
      subscriberIds.push(
        ...this.clientReviewers.map((reviewer) => reviewer.userId)
      );
    }
    return {
      audience,
      subscriberIds: _.uniqBy(
        subscriberIds,
        (id) => id.value
      ),
    };
  }

  public isEquivalentTo(other: Proposal
  ):
    boolean {
    return (this.client?.isEqualTo(other.client) &&
      this.team?.isEqualTo(other.team) &&
      this.teamRestricted.isEqualTo(other.teamRestricted) &&
      this.feeSchedule.length === other.feeSchedule.length &&
      this.feeSchedule?.every((category, index) => category.isEqualTo(other.feeSchedule[index])) &&
      this.conflictsCheckWaived.isEqualTo(other.conflictsCheckWaived) &&
      this.conflictsDocuments.length === other.conflictsDocuments.length &&
      this.conflictsDocuments.every((doc, index) => doc.isEqualTo(other.conflictsDocuments[index])) &&
      this.clientPolicyDocuments.length === other.clientPolicyDocuments.length &&
      this.clientPolicyDocuments.every((doc, index) => doc.isEqualTo(other.clientPolicyDocuments[index])) &&
      this.vendorPolicyDocuments.length === other.vendorPolicyDocuments.length &&
      this.vendorPolicyDocuments.every((doc, index) => doc.isEqualTo(other.vendorPolicyDocuments[index])) &&
      this.discount.isEqualTo(other.discount) &&
      this.name?.isEqualTo(other.name) &&
      this.description?.isEqualTo(other.description) &&
      this.negotiable === other.negotiable &&
      ((!this.responseDueBy && !other.responseDueBy) || this.responseDueBy?.isEqualTo(other.responseDueBy)) &&
      ((!this.startDate && !other.startDate) || this.startDate?.isEqualTo(other.startDate)) &&
      ((!this.endDate && !other.endDate) || this.endDate?.isEqualTo(other.endDate))) ?? false;
  }

  public createRedline(originalRedline ?: ProposalRedline): void {
    if (!
      this.id || !this.name || !this.description || !this.details || !this.isSubmitted
    )
      return;
    let previousVersion = this._supersedes ?? this;
    if (this.isApprovedByBothParties) {
      previousVersion = this;
    }

    const nameRedline = this.createFieldRedline(
      ProposalField.Name,
      previousVersion.name ?? null,
      this.name,
      ProjectName.Prototype,
      true,
      originalRedline?.name,
    )
    const descriptionRedline = this.createFieldRedline(
      ProposalField.Description,
      previousVersion.description ?? null,
      this.description,
      ProjectDescription.Prototype,
      true,
      originalRedline?.description
    );
    const teamRedline = this.createTeamRedline(
      previousVersion.details?.team,
      originalRedline?.team
    );
    const feeScheduleRedline = this.createFeeScheduleRedline(
      previousVersion.feeSchedule,
      originalRedline?.feeSchedule
    );
    const teamRestrictedRedline = this.createFieldRedline(
      ProposalField.TeamRestriction,
      previousVersion.teamRestricted ?? null,
      this.teamRestricted,
      AHBoolean.Prototype,
      false,
      originalRedline?.teamRestricted
    );

    const conflictsCheckWaivedRedline = this.createFieldRedline(
      ProposalField.WaiveConflictsCheck,
      previousVersion.conflictsCheckWaived ?? null,
      this.conflictsCheckWaived,
      AHBoolean.Prototype,
      false,
      originalRedline?.conflictsCheckWaived
    );
    const documentsRedline: RedlinedDocuments = {
      clientPolicyDocuments: this.createDocumentsRedline(
        ProposalField.ClientPolicies,
        ProposalField.ClientPolicyDocument,
        this.clientPolicyDocuments,
        previousVersion.clientPolicyDocuments,
        originalRedline?.clientPolicyDocuments
      ),
      vendorPolicyDocuments: this.createDocumentsRedline(
        ProposalField.VendorPolicies,
        ProposalField.VendorPolicyDocument,
        this.vendorPolicyDocuments,
        previousVersion.vendorPolicyDocuments,
        originalRedline?.vendorPolicyDocuments
      ),
      conflictsDocuments: this.createDocumentsRedline(
        ProposalField.Conflicts,
        ProposalField.ConflictsDocument,
        this.conflictsDocuments,
        previousVersion.conflictsDocuments,
        originalRedline?.conflictsDocuments
      ),
    };

    if (
      conflictsCheckWaivedRedline.isAccepted &&
      conflictsCheckWaivedRedline.currentEntry?.isEqualTo(new AHBoolean(true))
    ) {
      documentsRedline.conflictsDocuments =
        documentsRedline.conflictsDocuments.acceptAll();
    }

    const datesRedline: RedlinedDates = {
      responseDueBy: this.createFieldRedline(
        ProposalField.ResponseDueBy,
        previousVersion.responseDueBy ?? null,
        this.responseDueBy ?? null,
        Date.Prototype,
        false,
        originalRedline?.responseDueBy
      ),
      startDate: this.createFieldRedline(
        ProposalField.StartDate,
        previousVersion.startDate ?? null,
        this.startDate ?? null,
        Date.Prototype,
        false,
        originalRedline?.startDate
      ),
      endDate: this.createFieldRedline(
        ProposalField.EndDate,
        previousVersion.endDate ?? null,
        this.endDate ?? null,
        Date.Prototype,
        false,
        originalRedline?.endDate
      )
    };
    const discountRedline = this.createFieldRedline(
      ProposalField.Discount,
      previousVersion.discount ?? null,
      this.discount,
      Percent.Prototype,
      false,
      originalRedline?.discount
    );

    this._redlining = new ProposalRedline(
      nameRedline,
      descriptionRedline,
      datesRedline,
      teamRedline,
      feeScheduleRedline,
      conflictsCheckWaivedRedline,
      teamRestrictedRedline,
      documentsRedline,
      discountRedline,
      new Date()
    );
  }

  private createFieldRedline<T extends IRedlineableField>(
    field: ProposalField,
    supersededProposalValue: T | null,
    currentProposalValue: T | null,
    prototype: T,
    diffWords: boolean,
    originalRedline?: FieldRedline<T>,
  ):
    FieldRedline<T> {
    const originalRedlineValue = supersededProposalValue ?? null;
    let revisedRedlineValue = currentProposalValue;
    let currentRedlineValue = undefined;

    // Invert the redlining to bluelining
    if (!
      this.isApprovedByBothParties && this.userIsLatestSubmittingParty
    ) {
      revisedRedlineValue = supersededProposalValue ?? null;
      currentRedlineValue = currentProposalValue;
    }

    let newRedline = new FieldRedline<T>(
      prototype,
      field,
      originalRedlineValue,
      revisedRedlineValue ?? null,
      undefined,
      diffWords,
      originalRedline
    );
    if (currentRedlineValue !== undefined) {
      newRedline = newRedline.edit(
        currentRedlineValue,
        true
      );
      newRedline.updateDiff()
    }
    newRedline.clearSessionHistory();

    if (originalRedline?.isCurrentWith(newRedline)) {
      return originalRedline;
    }

    return newRedline;
  }

  private createTeamRedline(
    previousTeam ?: DetailedTeam,
    originalRedlineArray ?: FieldRedlineArray<Individual>
  ): FieldRedlineArray<Individual> {
    const newRedlineArray = new FieldRedlineArray<Individual>(
      Individual.Prototype,
      ProposalField.Team,
      ProposalField.TeamMember,
      (!this.isApprovedByBothParties ? previousTeam?.members : this.details?.team?.members) ?? [],
      this.details?.team?.members ?? []
    );

    newRedlineArray.clearSessionHistory();
    return originalRedlineArray ? newRedlineArray.mergeOldRedlineArray(
      originalRedlineArray,
      this.userIsLatestSubmittingParty
    ) : newRedlineArray;
  }

  private createFeeScheduleRedline(
    previousFeeSchedule: FeeScheduleCategory[],
    originalRedline?: FeeScheduleRedline
  ) {
    const newFeeScheduleRedline = new FeeScheduleRedline(
      (!this.isApprovedByBothParties ? previousFeeSchedule : this.feeSchedule) ?? [],
      this.feeSchedule
    );

    newFeeScheduleRedline.clearSessionHistory();
    return originalRedline ? newFeeScheduleRedline.mergeOldFeeScheduleRedline(
      originalRedline,
      this.userIsLatestSubmittingParty
    ) : newFeeScheduleRedline;
  }

  private createDocumentsRedline(
    arrayField: ProposalField,
    arrayEntryFieldConstructor: (id: Guid) => ProposalField,
    currentDocuments: WorkDocument[],
    previousDocuments?: WorkDocument[],
    originalRedlineArray?: FieldRedlineArray<WorkDocument>,
  ) {
    let newRedlineArray = new FieldRedlineArray<WorkDocument>(
      WorkDocument.Prototype,
      arrayField,
      arrayEntryFieldConstructor,
      (!this.isApprovedByBothParties ? previousDocuments : currentDocuments) ?? [],
      currentDocuments
    );

    newRedlineArray.clearSessionHistory();
    return originalRedlineArray ? newRedlineArray.mergeOldRedlineArray(
      originalRedlineArray,
      this.userIsLatestSubmittingParty,
    ) : newRedlineArray;
  }

  updateLastSavedRedline() {
    this._lastSavedRedline = this._redlining?.clone();
  }

  public get hasUnsavedRedlineChanges(): boolean {
    if (!this._redlining && !this._lastSavedRedline) {
      return false;
    }
    if (this._redlining && !this._lastSavedRedline) {
      return true;
    }
    if (!this._redlining && this._lastSavedRedline) {
      return true;
    }
    if (!this._redlining?.isEqualTo(this._lastSavedRedline)) {
      return true;
    }
    return false;
  }
}

export interface IProposalStore {
  createProposal(
    proposal: Proposal,
    session: Readonly<Session>
  ): Promise<Proposal>;

  saveProposal(updatedProposal: Proposal, proposalBuilderJSON?: object): Promise<{
    proposal: Proposal,
    userProposalBuilder?: ProposalBuilder,
    userRedlining?: ProposalRedline
  }>

  shareProposal(
    originalProposal: Proposal,
    updatedProposal: Proposal
  ): Promise<Proposal>;

  deleteProposal(proposal: Proposal): Promise<void>;

  hireProposal(proposal: Proposal): Promise<Proposal>;

  cancelProposal(proposal: Proposal): Promise<Proposal>;

  submitProposal(proposal: Proposal): Promise<Proposal>;

  approveProposal(proposal: Proposal): Promise<Proposal>;

  rejectProposal(proposal: Proposal): Promise<Proposal>;

  requestRevision(
    proposal: Proposal,
    session: Readonly<Session>
  ): Promise<Proposal>;
}

export type CompleteProposalSpec = {
  RFPId?: Guid;
  vendors: EntityVendorRepresentative[];
  teamRestricted: AHBoolean;
  feeSchedule: FeeScheduleCategory[];
  conflictsCheckWaived: AHBoolean;
  conflictsDocuments: WorkDocument[];
  clientPolicyDocuments: WorkDocument[];
  vendorPolicyDocuments: WorkDocument[];
  clientTeamTemplateIds: Guid[];
  vendorTeamTemplateIds: Guid[];
  clientFeeScheduleTemplateIds: Guid[];
  vendorFeeScheduleTemplateIds: Guid[];
  discount: Percent;
  team?: DetailedTeam;
  client?: EntityClientRepresentative;
  name?: ProjectName;
  description?: ProjectDescription;
  negotiable: boolean;
  responseDueBy?: Date;
  startDate?: Date;
  endDate?: Date;
  clientReviewers?: ProposalReviewer[];
  vendorReviewers?: ProposalReviewer[];
};

export type ProposalMetaInfo = {
  RFPId?: Guid;
  id: Guid;
  createdDate: Date;
  lastUpdated: Date;
  creator: EntityRepresentative;
  creatorInfo?: UserInfo;
  status: ProposalStatus;
  supersededById?: Guid;
  availableActions: { [key in ProposalAction]: Guid[] };
  replacedBy?: Guid;
};

export type ProposalDetails = {
  client: Individual;
  team?: DetailedTeam;
  redlining?: ProposalRedline;
  redliningUpdated?: Date;
  supersedes?: Proposal;
  supersededById?: Guid;
};

export type FreelyPatchableFields = {
  reviewers: ProposalReviewer[];
  teamTemplateIds: Guid[];
  feeScheduleTemplateIds: Guid[];
};

export enum ProposalFieldCategory {
  Details = "Details",
  Team = "Team",
  FeeSchedule = "Fee Schedule",
  Conflicts = "Conflicts",
  Policies = "Policies",
  Discount = "Discount",
}

export class ProposalField {
  static readonly General: ProposalField = new ProposalField(
    ProposalFieldName.General,
    ProposalFieldCategory.Details
  );
  static readonly Client: ProposalField = new ProposalField(
    ProposalFieldName.Client,
    ProposalFieldCategory.Details
  );
  static readonly Name: ProposalField = new ProposalField(
    ProposalFieldName.Name,
    ProposalFieldCategory.Details
  );
  static readonly Description: ProposalField = new ProposalField(
    ProposalFieldName.Description,
    ProposalFieldCategory.Details
  );
  static readonly Negotiable: ProposalField = new ProposalField(
    ProposalFieldName.Negotiable,
    ProposalFieldCategory.Details
  );
  static readonly ResponseDueBy: ProposalField = new ProposalField(
    ProposalFieldName.ResponseDueBy,
    ProposalFieldCategory.Details
  );
  static readonly StartDate: ProposalField = new ProposalField(
    ProposalFieldName.StartDate,
    ProposalFieldCategory.Details
  );
  static readonly EndDate: ProposalField = new ProposalField(
    ProposalFieldName.EndDate,
    ProposalFieldCategory.Details
  );

  static readonly Team: ProposalField = new ProposalField(
    ProposalFieldName.Team,
    ProposalFieldCategory.Team
  );
  static readonly TeamRestriction: ProposalField = new ProposalField(
    ProposalFieldName.TeamRestriction,
    ProposalFieldCategory.Team,
    undefined,
    ProposalField.Team
  );
  static readonly TeamLeader: ProposalField = new ProposalField(
    ProposalFieldName.TeamLeader,
    ProposalFieldCategory.Team,
    undefined,
    ProposalField.Team
  );
  static readonly FeeSchedule: ProposalField = new ProposalField(
    ProposalFieldName.FeeSchedule,
    ProposalFieldCategory.FeeSchedule
  );
  static readonly Conflicts: ProposalField = new ProposalField(
    ProposalFieldName.Conflicts,
    ProposalFieldCategory.Conflicts
  );
  static readonly WaiveConflictsCheck: ProposalField = new ProposalField(
    ProposalFieldName.WaiveConflictsCheck,
    ProposalFieldCategory.Conflicts,
    undefined,
    ProposalField.Conflicts
  );
  static readonly Policies: ProposalField = new ProposalField(
    ProposalFieldName.Policies,
    ProposalFieldCategory.Policies
  );
  static readonly ClientPolicies: ProposalField = new ProposalField(
    ProposalFieldName.ClientPolicies,
    ProposalFieldCategory.Policies
  );
  static readonly VendorPolicies: ProposalField = new ProposalField(
    ProposalFieldName.VendorPolicies,
    ProposalFieldCategory.Policies
  );
  static readonly Discount: ProposalField = new ProposalField(
    ProposalFieldName.Discount,
    ProposalFieldCategory.Discount
  );
  public readonly name: ProposalFieldName;
  public readonly category: ProposalFieldCategory;
  public readonly id: Guid | undefined;
  private readonly _commentFieldOverride?: ProposalField;

  private constructor(
    name: ProposalFieldName,
    category: ProposalFieldCategory,
    id?: Guid,
    commentField?: ProposalField
  ) {
    this.name = name;
    this.category = category;
    this.id = id;
    this._commentFieldOverride = commentField;
  }

  public get key(): string {
    let key = `${this.commentField}.${this.name}`;
    if (this.id) {
      key = `${key}.${this.id}`;
    }
    return key;
  }

  public get commentField(): ProposalField {
    return this._commentFieldOverride ?? this;
  }

  static TeamMember(id: Guid): ProposalField {
    return new ProposalField(
      ProposalFieldName.Team,
      ProposalFieldCategory.Team,
      id,
      ProposalField.Team
    );
  }

  static FeeScheduleCategory(id: Guid): ProposalField {
    return new ProposalField(
      ProposalFieldName.FeeSchedule,
      ProposalFieldCategory.FeeSchedule,
      id,
      ProposalField.FeeSchedule
    );
  }

  static ConflictsDocument(id: Guid): ProposalField {
    return new ProposalField(
      ProposalFieldName.Conflicts,
      ProposalFieldCategory.Conflicts,
      id,
      ProposalField.Conflicts
    );
  }

  static ClientPolicyDocument(id: Guid): ProposalField {
    return new ProposalField(
      ProposalFieldName.ClientPolicies,
      ProposalFieldCategory.Policies,
      id,
      ProposalField.ClientPolicies
    );
  }

  static VendorPolicyDocument(id: Guid): ProposalField {
    return new ProposalField(
      ProposalFieldName.VendorPolicies,
      ProposalFieldCategory.Policies,
      id,
      ProposalField.VendorPolicies
    );
  }

  public static fromJSON(obj: any): ProposalField | undefined {
    if (!obj) return undefined;
    let commentField: ProposalField | undefined;
    if (obj === "general") {
      return ProposalField.General;
    } else if (obj.commentField) {
      commentField = ProposalField.fromJSON(obj.commentField);
    }
    return new ProposalField(
      obj.name,
      obj.category,
      Guid.fromJSON(obj.id),
      commentField
    );
  }

  public isEqualTo(field?: ProposalField | null): boolean {
    if (!field) return false;
    return (
      this.name === field.name &&
      (this.id?.isEqualTo(field.id) || (!this.id && !field.id))
    );
  }

  public toJSON(): object {
    return {
      name: this.name.toString(),
      category: this.category.toString(),
      id: this.id?.toJSON(),
      commentField: this._commentFieldOverride?.toJSON(),
    };
  }

  public toString(): string {
    return this.name.toString();
  }
}
