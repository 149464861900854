import Address from "common/values/address/address";

export default class AddressAPIResponse {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  coordinates?: {
    "type": "Point",
    "coordinates": [number, number]
  };

  deserialize(): Address {
    return new Address(
      this.address1 ?? '',
      this.address2 ?? '',
      this.city ?? '',
      this.state ?? '',
      this.postalCode ?? '',
      this.country ?? '',
      this.coordinates ? {
        latitude: this.coordinates.coordinates[0].toString(),
        longitude: this.coordinates.coordinates[1].toString()
      } : undefined
    );
  }
}
