import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import PhoneNumber from "common/values/phone-number/phone-number";
import EntityName from "legal-entities/values/entity-name/entity-name";
import Individual from "marketplace/entities/individual/individual";
import BillingRateInfo from "marketplace/values/billing-rate-info/billing-rate-info";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";
import RegistrationNumber from "marketplace/values/registration-number/registration-number";
import TechnicalBackgroundInfo from "marketplace/values/technical-background-info/technical-background-info";
import Address from "common/values/address/address";

export default class EntityMember {
  public readonly userId: Guid;
  public readonly memberId: Guid;
  public readonly individualId: Guid;
  public readonly name: Name;
  public readonly entityId: Guid;
  public readonly entityName: EntityName;
  public readonly email: EmailAddress
  public readonly isAdmin: boolean;
  public readonly isOfficer: boolean;
  public readonly isActive: boolean;
  public readonly entityVendorFlag?: boolean;
  public readonly entityClientFlag?: boolean;
  public readonly created?: Date;
  public readonly avatarId?: Guid;
  public readonly description?: string;
  public readonly location?: Address;
  public readonly school?: string;
  public readonly phoneNumber?: string;
  public readonly resumeId?: Guid;
  public readonly professionalRegistration?: string;
  public readonly technicalBackground?: string;
  public readonly numberOfApplicationsDraftedProsecuted?: number;
  public readonly numberOfAllowedApplications?: number;
  public readonly barAdmissionYear?: number;
  public readonly isOnCall?: boolean;
  public readonly insuredAmount?: number;
  public readonly billingRate?: BillingRateInfo;
  public readonly potentialDiversityCandidate?: boolean;
  public readonly collaborates?: boolean;
  public readonly additionalInfo?: string;
  public readonly categories: string[];
  public readonly links: string[];
  public readonly professionalPublications: string[];
  public readonly sampleApplications: string[];
  public readonly fluentLanguages: string[];
  public readonly technicalLanguages: string[];
  public readonly regionsRegistered: string[];
  public readonly regionsLicensed: string[];
  public readonly degree?: string;
  public readonly insured?: string;
  public readonly administrativeRegions: string[];

  /**
   * Returns the user id.
   */
  public get id(): Guid {
    return this.memberId;
  }

  /**
   * Returns true if the user is an admin and/or an officer.
   */
  public get isManager(): boolean {
    return this.isAdmin || this.isOfficer;
  }

  constructor(values: Partial<EntityMember>) {
    if (!values.userId) throw new InvalidEntityMemberInfoError({id: 'userId is required'});
    if (!values.memberId) throw new InvalidEntityMemberInfoError({memberId: 'memberId is required'});
    if (!values.individualId) throw new InvalidEntityMemberInfoError({individualId: 'individualId is required'});
    if (!values.name) throw new InvalidEntityMemberInfoError({name: 'name is required'});
    if (!values.entityId) throw new InvalidEntityMemberInfoError({entityId: 'entityId is required'});
    if (!values.entityName) throw new InvalidEntityMemberInfoError({entityName: 'entityName is required'});
    if (!values.email) throw new InvalidEntityMemberInfoError({email: 'email is required'});
    if (values.isAdmin === undefined) throw new InvalidEntityMemberInfoError({isAdmin: 'isAdmin is required'});
    if (values.isOfficer === undefined) throw new InvalidEntityMemberInfoError({isOfficer: 'isOfficer is required'});
    if (values.isActive === undefined) throw new InvalidEntityMemberInfoError({isActive: 'isActive is required'});

    this.userId = values.userId;
    this.memberId = values.memberId;
    this.individualId = values.individualId;
    this.name = values.name;
    this.entityId = values.entityId;
    this.entityName = values.entityName;
    this.email = values.email;
    this.isAdmin = values.isAdmin;
    this.isOfficer = values.isOfficer;
    this.isActive = values.isActive;
    this.entityVendorFlag = values.entityVendorFlag;
    this.entityClientFlag = values.entityClientFlag;
    this.created = values.created;
    this.avatarId = values.avatarId;
    this.description = values.description;
    this.location = values.location;
    this.school = values.school;
    this.phoneNumber = values.phoneNumber;
    this.resumeId = values.resumeId;
    this.professionalRegistration = values.professionalRegistration;
    this.technicalBackground = values.technicalBackground;
    this.numberOfApplicationsDraftedProsecuted = values.numberOfApplicationsDraftedProsecuted;
    this.numberOfAllowedApplications = values.numberOfAllowedApplications;
    this.barAdmissionYear = values.barAdmissionYear;
    this.isOnCall = values.isOnCall;
    this.insuredAmount = values.insuredAmount;
    this.billingRate = values.billingRate;
    this.potentialDiversityCandidate = values.potentialDiversityCandidate;
    this.collaborates = values.collaborates;
    this.additionalInfo = values.additionalInfo;
    this.categories = values.categories ?? [];
    this.links = values.links ?? [];
    this.professionalPublications = values.professionalPublications ?? [];
    this.sampleApplications = values.sampleApplications ?? [];
    this.fluentLanguages = values.fluentLanguages ?? [];
    this.technicalLanguages = values.technicalLanguages ?? [];
    this.regionsRegistered = values.regionsRegistered ?? [];
    this.regionsLicensed = values.regionsLicensed ?? [];
    this.degree = values.degree;
    this.insured = values.insured;
    this.administrativeRegions = values.administrativeRegions ?? [];
  }

  public asIndividual(): Individual {
    const profile = new IndividualProfile(
      this.userId,
      this.name.firstName ?? "",
      this.name.lastName ?? ""
    );
    profile.additionalInfo = this.additionalInfo;
    profile.administrativeRegions = this.administrativeRegions;
    profile.avatarId = this.avatarId;
    profile.barAdmissionYear = this.barAdmissionYear;
    profile.billingRate = this.billingRate;
    profile.categories = this.categories;
    profile.collaborates = this.collaborates ?? false;
    profile.description = this.description;
    profile.email = this.email;
    profile.entityId = this.entityId;
    profile.entityName = this.entityName.value;
    profile.location = this.location;
    profile.phoneNumber = this.phoneNumber ? new PhoneNumber(this.phoneNumber) : undefined;
    profile.professionalRegistration =
      this.professionalRegistration ? new RegistrationNumber(this.professionalRegistration) : undefined;
    profile.resumeId = this.resumeId;
    profile.school = this.school;
    profile.degree = this.degree;
    profile.technicalBackground =
      this.technicalBackground ? new TechnicalBackgroundInfo(this.technicalBackground) : undefined;
    profile.numberOfApplicationsDraftedProsecuted = this.numberOfApplicationsDraftedProsecuted;
    profile.numberOfAllowedApplications = this.numberOfAllowedApplications;
    profile.isOnCall = this.isOnCall ?? false;
    profile.potentialDiversityCandidate = this.potentialDiversityCandidate ?? false;
    profile.links = this.links;
    profile.professionalPublications = this.professionalPublications;
    profile.sampleApplications = this.sampleApplications;
    profile.fluentLanguages = this.fluentLanguages;


    return new Individual(
      this.individualId,
      this.userId,
      profile
    )
  }

  toJSON(): any {
    return {
      userId: this.userId?.toJSON(),
      memberId: this.memberId?.toJSON(),
      individualId: this.individualId?.toJSON(),
      entityId: this.entityId?.toJSON(),
      entityName: this.entityName,
      firstName: this.name.firstName,
      lastName: this.name.lastName,
      description: this.description,
      location: this.location,
      phoneNumber: this.phoneNumber,
      avatarId: this.avatarId?.toJSON(),
      email: this.email?.toJSON(),
      school: this.school,
      degree: this.degree,
      resumeId: this.resumeId?.toJSON(),
      professionalRegistration: this.professionalRegistration,
      technicalBackground: this.technicalBackground,
      numberOfApplicationsDraftedProsecuted: this.numberOfApplicationsDraftedProsecuted,
      numberOfAllowedApplications: this.numberOfAllowedApplications,
      barAdmissionYear: this.barAdmissionYear,
      isOnCall: this.isOnCall,
      insured: this.insured,
      billingRate: this.billingRate,
      potentialDiversityCandidate: this.potentialDiversityCandidate,
      collaborates: this.collaborates,
      additionalInfo: this.additionalInfo,
      categories: this.categories,
      links: this.links,
      professionalPublications: this.professionalPublications,
      sampleApplications: this.sampleApplications,
      fluentLanguages: this.fluentLanguages,
      technicalLanguages: this.technicalLanguages,
      administrativeRegions: this.administrativeRegions,
      regionsRegistered: this.regionsRegistered,
      regionsLicensed: this.regionsLicensed,
      isAdmin: this.isAdmin,
      isOfficer: this.isOfficer,
      isActive: this.isActive
    };
  }

  public static fromJSON(json: any): EntityMember {
    return new EntityMember({
      userId: Guid.fromJSON(json.userId),
      memberId: Guid.fromJSON(json.memberId),
      individualId: Guid.fromJSON(json.individualId),
      name: new Name(
        json.firstName,
        json.lastName
      ),
      entityId: Guid.fromJSON(json.entityId),
      entityName: new EntityName(json.entityName),
      email: EmailAddress.fromJSON(json.email),
      isAdmin: json.isAdmin,
      isOfficer: json.isOfficer,
      isActive: json.isActive,
      entityVendorFlag: json.entityVendorFlag,
      entityClientFlag: json.entityClientFlag,
      created: json.created,
      avatarId: Guid.fromJSON(json.avatarId),
      description: json.description,
      location: json.location,
      school: json.school,
      phoneNumber: json.phoneNumber,
      resumeId: Guid.fromJSON(json.resumeId),
      professionalRegistration: json.professionalRegistration,
      technicalBackground: json.technicalBackground,
      numberOfApplicationsDraftedProsecuted: json.numberOfApplicationsDraftedProsecuted,
      numberOfAllowedApplications: json.numberOfAllowedApplications,
      barAdmissionYear: json.barAdmissionYear,
      isOnCall: json.isOnCall,
      insuredAmount: json.insuredAmount,
      billingRate: BillingRateInfo.fromJSON(json.billingRate),
      potentialDiversityCandidate: json.potentialDiversityCandidate,
      collaborates: json.collaborates,
      additionalInfo: json.additionalInfo,
      categories: json.categories,
      links: json.links,
      professionalPublications: json.professionalPublications,
      sampleApplications: json.sampleApplications,
      fluentLanguages: json.fluentLanguages,
      technicalLanguages: json.technicalLanguages,
      regionsRegistered: json.regionsRegistered,
      regionsLicensed: json.regionsLicensed,
      degree: json.degree,
      insured: json.insured,
      administrativeRegions: json.administrativeRegions
    });
  }

  clone(): EntityMember {
    return new EntityMember({
      userId: this.userId,
      memberId: this.memberId,
      individualId: this.individualId,
      name: this.name,
      entityId: this.entityId,
      entityName: this.entityName,
      email: this.email,
      isAdmin: this.isAdmin,
      isOfficer: this.isOfficer,
      isActive: this.isActive,
      entityVendorFlag: this.entityVendorFlag,
      entityClientFlag: this.entityClientFlag,
      created: this.created,
      avatarId: this.avatarId,
      description: this.description,
      location: this.location,
      school: this.school,
      phoneNumber: this.phoneNumber,
      resumeId: this.resumeId,
      professionalRegistration: this.professionalRegistration,
      technicalBackground: this.technicalBackground,
      numberOfApplicationsDraftedProsecuted: this.numberOfApplicationsDraftedProsecuted,
      numberOfAllowedApplications: this.numberOfAllowedApplications,
      barAdmissionYear: this.barAdmissionYear,
      isOnCall: this.isOnCall,
      insuredAmount: this.insuredAmount,
      billingRate: this.billingRate,
      potentialDiversityCandidate: this.potentialDiversityCandidate,
      collaborates: this.collaborates,
      additionalInfo: this.additionalInfo,
      categories: this.categories,
      links: this.links,
      professionalPublications: this.professionalPublications,
      sampleApplications: this.sampleApplications,
      fluentLanguages: this.fluentLanguages,
      technicalLanguages: this.technicalLanguages,
      regionsRegistered: this.regionsRegistered,
      regionsLicensed: this.regionsLicensed,
      degree: this.degree,
      insured: this.insured,
      administrativeRegions: this.administrativeRegions,

    });
  }
}

export class InvalidEntityMemberInfoError extends Error {
  issues: any;

  constructor(issues: any) {
    super(`Invalid entity member info.`);
    this.issues = issues;
  }
}

export class UnauthorizedEntityMemberAccessError extends Error {
  constructor(entityId: Guid) {
    super(`Unauthorized access to legal entity members for entity ${entityId}`);
  }
}

export class EntityMemberNotFoundException extends Error {
  constructor(memberId: Guid) {
    super(`Entity member ${memberId} not found`);
  }
}
