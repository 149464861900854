import moment, {Moment} from 'moment';
import Guid from "common/values/guid/guid";

import ReadReceipt from 'messaging/values/read-receipts/read-receipt';
import CommentThread from 'work/entities/comment-thread/comment-thread';
import Message from 'messaging/entities/message/message';

export default class Comment {
  id: Guid;
  senderId: Guid | null;
  thread: CommentThread;
  content: string;
  created?: Moment;
  published?: Moment;
  readReceipts: ReadReceipt[] = [];
  issues: string[] = [];
  deleted?: Moment;

  isAutoGenerated: boolean = false;
  markedForCreation?: boolean;
  markedForPublish?: boolean;
  markedForEdit?: boolean;
  markedForReadReceipt?: boolean;
  markedForUnread?: boolean;
  markedForDeletion?: boolean;

  constructor(
    thread: CommentThread,
    content: string,
    senderId: Guid | null,
    isAutoGenerated: boolean = false,
    id?: Guid,
    created?: Moment,
    published?: Moment,
    issues?: string[],
    readReceipts?: ReadReceipt[],
    deleted?: Moment
  ) {
    if (!content) throw new EmptyCommentError();

    this.thread = thread;
    this.id = id ?? Guid.generate();
    this.senderId = senderId;
    this.isAutoGenerated = isAutoGenerated;
    this.content = content;
    this.created = created;
    this.published = published;

    this.issues = issues ?? [];
    this.readReceipts = readReceipts ?? [];
    this.deleted = deleted;

    if (!id) {
      this.markedForCreation = true;
    }
  }

  public get isPending(): boolean {
    return Boolean(
      !this.published ||
      this.markedForEdit ||
      this.markedForReadReceipt !== undefined ||
      this.markedForUnread !== undefined ||
      this.markedForDeletion
    )
  }

  public edit(content: string): void {
    this.content = content;
    this.markedForEdit = true;
  }

  public setDeleted(): void {
    this.deleted = moment();
    this.markedForDeletion = true;
  }

  public setUndeleted(): void {
    this.deleted = undefined;
    this.markedForDeletion = false;
  }

  public markForReadReceipt(): void {
    if (this.markedForUnread) {
      this.markedForUnread = false;
      this.markedForReadReceipt = false;
    } else {
      this.markedForReadReceipt = true
    }
  }

  public markForUnreadReceipt(): void {
    if (this.markedForReadReceipt) {
      this.markedForReadReceipt = false;
      this.markedForUnread = false;
    } else {
      this.markedForUnread = true;
    }
  }

  public publish(): void {
    this.markedForPublish = true;
  }

  /** Soft delete flag */
  public get isDeleted(): boolean {
    return !!this.deleted;
  }

  public isReadByUser(userId?: Guid): boolean {
    if (!userId) return false;
    return this.readReceipts.some((receipt) => receipt.userId.isEqualTo(userId));
  }

  public static fromMessage(message: Message): Comment {
    return new Comment(
      CommentThread.fromForum(message.forum),
      message.content,
      message.senderId,
      message.isAutoGenerated,
      message.id,
      message.created,
      message.published,
      message.issues,
      message.readReceipts,
      message.deleted
    );
  }

  public toMessage(isViewingAsVendor: boolean): Message {
    const message = new Message(
      this.thread.toForum(isViewingAsVendor),
      this.content,
      this.senderId,
      undefined,
      this.created,
      this.published,
      undefined,
      this.id,
      this.issues,
      this.readReceipts,
      this.deleted
    );

    message.isAutoGenerated = this.isAutoGenerated;
    message.markedForCreation = this.markedForCreation;
    message.markedForEdit = this.markedForEdit;
    message.markedRead = this.markedForReadReceipt;
    message.markedUnread = this.markedForUnread;
    message.markedForDeletion = this.markedForDeletion;
    message.markedForPublish = this.markedForPublish;

    return message;
  }

  public clone(): Comment {
    const clone = new Comment(
      this.thread,
      this.content,
      this.senderId,
      this.isAutoGenerated,
      this.id,
      this.created,
      this.published,
      this.issues,
      this.readReceipts,
      this.deleted
    )
    clone.markedForCreation = this.markedForCreation;
    clone.markedForEdit = this.markedForEdit;
    clone.markedForReadReceipt = this.markedForReadReceipt;
    clone.markedForUnread = this.markedForUnread;
    clone.markedForDeletion = this.markedForDeletion;
    return clone;
  }
}

export class EmptyCommentError extends Error {
  constructor() {
    super(`Comment cannot be empty`);
  }
}
