import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import moment from "moment";
import User from "users/entities/user/user";
import PendingUserEntityMemberInvitation from "users/values/pending-user-entity-member-invitation/pending-user-entity-member-invitation";

export default class PendingUserEntityMemberInvitationAPIResponse {
  id?: string;
  userId?: string;
  emailAddress?: string;
  expires?: string;
  createClientRep?: boolean;
  createVendorRep?: boolean;
  entityId?: string;
  entityName?: string;
  isAdmin?: boolean;

  deserialize(): PendingUserEntityMemberInvitation {
    if (!this.id)
      throw new Error(
        "UserEntityMemberInvitationAPIResponse.deserialize(): id is required"
      );
    if (!this.userId)
      throw new Error(
        "UserEntityMemberInvitationAPIResponse.deserialize(): userId is required"
      );
    if(!this.entityId){
        throw new Error(
            "UserEntityMemberInvitationAPIResponse.deserialize(): entityId or entityName is required"
        );
    }
    if (!this.emailAddress)
      throw new Error(
        "UserEntityMemberInvitationAPIResponse.deserialize(): emailAddress is required"
      );

    const id = new Guid(this.id);

    const userId = new Guid(this.userId);
    const emailAddress = new EmailAddress(this.emailAddress);
    const user = new User(new Name("John", "Doe"), emailAddress, userId);

    const expires = moment(this.expires);
    const createClientRep = this.createClientRep ?? false;
    const createVendorRep = this.createVendorRep ?? false;
    const entityId = new Guid(this.entityId);
    const entityName = this.entityName ?? "";

    return new PendingUserEntityMemberInvitation(
      id,
      user,
      entityId,
      entityName,
      expires,
      createClientRep,
      createVendorRep,
      this.isAdmin ?? false
    );
  }
}
