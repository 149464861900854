import Guid from "common/values/guid/guid";
import WorkVendorRepresentativeAPIResponse
  from "work/entities/entity-vendor-representative/api/response-contracts/work-vendor-representative-api-response";
import Team from "work/values/team/team";

export default class SimpleWorkTeamAPIResponse {
  leader?: WorkVendorRepresentativeAPIResponse;
  members?: string[];
  marketplaceId?: string;

  deserialize(): Team {
    return new Team(
      this.leader ? Object.assign(
        new WorkVendorRepresentativeAPIResponse(),
        this.leader
      ).deserialize() : undefined,
      this.members?.map(member => new Guid(member)) ?? [],
      this.marketplaceId ? new Guid(this.marketplaceId) : undefined
    );
  }
}
