import Company from "marketplace/entities/company/company";
import AddressAPIRequest from "common/api/request-contracts/address-api-request";

export default class MarketplaceCompanyAPIRequest {
  name: string;
  yearEstablished?: number;
  numberOfEmployees?: number;
  description?: string;
  address?: AddressAPIRequest;
  website?: string;
  categories: string[] = [];
  isVisible: boolean;
  contactId?: string;

  constructor(company: Company) {
    if (!company.profile?.name) throw new Error("Company name is required");
    this.name = company.profile.name;
    this.yearEstablished = company.profile.yearEstablished;
    this.numberOfEmployees = company.profile.numberOfEmployees;
    this.description = company.profile.description;
    this.address = company.profile.location ? new AddressAPIRequest(company.profile.location) : undefined;
    this.website = company.profile.website?.toString();
    this.categories = company.profile.categories;
    this.isVisible = company.isVisible;
    this.contactId = company.contactId?.value;
  }
}
