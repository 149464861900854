import {createAsyncThunk, createSlice, PayloadAction, SerializedError,} from "@reduxjs/toolkit";
import {RootState} from "app/realtime-store/redux-store";
import Guid from "common/values/guid/guid";
import _ from "lodash";
import MessagingAPIService from "messaging/api/messaging-api-service";
import {useSelector} from "react-redux";

import Session from "users/session/session";
import CommentThread from "work/entities/comment-thread/comment-thread";
import Comment from "work/entities/comment/comment";
import Proposal, {ProposalField} from "work/entities/proposal/proposal";

type CommentStoreState = {
  entries: Record<string, Comment[]>;
  loading: Record<string, boolean>;
  error: Record<string, SerializedError | null>;
};

const initialState: CommentStoreState = {
  entries: {},
  loading: {},
  error: {},
};

export const populateThreadComments = createAsyncThunk(
  "comments/getCommentsByThread",
  async (
    {session, thread}: { session: Session; thread: CommentThread },
    thunkAPI
  ) => {
    try {
      const apiService = new MessagingAPIService(session);
      const commentMessages = await apiService.getMessagesByForum(
        thread.toForum(session.context?.viewingAsVendor ?? false)
      );
      return commentMessages.map(Comment.fromMessage);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const saveDraftComments = createAsyncThunk(
  "comments/saveDraftComments",
  async (session: Session, thunkAPI) => {
    try {
      const state: { comments: CommentStoreState } = thunkAPI.getState() as any;

      const pendingComments: Comment[] = Object.values(
        state.comments.entries
      ).flatMap((comments) =>
        comments.filter((comment) =>
          !comment.created && !comment.isAutoGenerated));
      if (!pendingComments.length) {
        return [];
      }

      const apiService = new MessagingAPIService(session);
      const messagesToSave = pendingComments.map((comment) =>
        comment.toMessage(session.context?.viewingAsVendor ?? false)
      );
      const messages = await apiService.createBulkMessages(messagesToSave);
      return messages.map(Comment.fromMessage);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const publishComments = createAsyncThunk(
  "comments/publishComments",
  async (session: Session, thunkAPI) => {
    try {
      const state: { comments: CommentStoreState } = thunkAPI.getState() as any;

      const unpublishedComments: Comment[] = Object.values(
        state.comments.entries
      ).flatMap((comments) => comments.filter((comment) => !comment.published));
      unpublishedComments.forEach((comment) => {
        comment.publish();
      });

      const apiService = new MessagingAPIService(session);
      const messagesToSave = unpublishedComments.map((comment) =>
        comment.toMessage(session.context?.viewingAsVendor ?? false)
      );
      const messages = await apiService.createBulkMessages(messagesToSave);
      return messages.map(Comment.fromMessage);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    addComment: (state, action: PayloadAction<Comment>) => {
      const fieldKey = action.payload.thread.field.key;
      const proposalId = action.payload.thread.proposalId;
      if (!fieldKey || !proposalId) {
        return;
      }
      const commentFieldKey = `${proposalId.value}-${fieldKey}`;

      const existingThreadComments =
        state.entries[commentFieldKey]?.filter(
          (existingComment) => !existingComment.id?.isEqualTo(action.payload.id)
        ) ?? [];

      existingThreadComments.push(action.payload);
      state.entries[commentFieldKey] = existingThreadComments;
    },
    removeComment: (state, action: PayloadAction<Guid>) => {
      Object.keys(state.entries).forEach((fieldCommentsKey) => {
        state.entries[fieldCommentsKey] = state.entries[
          fieldCommentsKey
          ]?.filter((comment) => !comment.id?.isEqualTo(action.payload));
      });
    },

    clearUnsavedCommentsByProposalId(state, action: PayloadAction<Guid>) {
      Object.keys(state.entries).forEach((fieldCommentsKey) => {
        state.entries[fieldCommentsKey] = state.entries[
          fieldCommentsKey
          ]?.filter(
          (comment) =>
            !(comment.thread.proposalId.isEqualTo(action.payload) && !comment.created)
        );
      });
    },
    updateAutoGeneratedComments: (state, action: PayloadAction<Comment[]>) => {
      const autoGeneratedComments = action.payload;
      const existingCommentsFieldKeys: string[] = _.uniq(
        autoGeneratedComments.map(
          (comment) =>
            `${comment.thread.proposalId.value}-${comment.thread.field?.key}`
        )
      );

      Object.keys(state.entries).forEach((commentsFieldKey) => {
        const currentComments = state.entries[commentsFieldKey] ?? [];
        const newComments = currentComments.filter(
          (comment) => !(comment.isAutoGenerated && !comment.created)
        );
        state.entries[commentsFieldKey] = newComments;
      });

      existingCommentsFieldKeys.forEach((commentsFieldKey) => {
        const newComments = state.entries[commentsFieldKey] ?? [];
        const threadAutoComments = autoGeneratedComments.filter(
          (comment) =>
            `${comment.thread.proposalId.value}-${comment.thread.field?.key}` ===
            commentsFieldKey
        );
        threadAutoComments.forEach((comment) => {
          newComments.push(comment);
        });
        state.entries[commentsFieldKey] = newComments;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      populateThreadComments.pending,
      (state, action) => {
        const fieldKey = action.meta.arg.thread.field.key;
        const proposalId = action.meta.arg.thread.proposalId;
        if (!fieldKey || !proposalId) {
          return;
        }
        const commentFieldKey = `${proposalId.value}-${fieldKey}`;

        state.loading[commentFieldKey] = true;
        state.error[commentFieldKey] = null;
      }
    );
    builder.addCase(
      populateThreadComments.fulfilled,
      (state, action) => {
        const fieldKey = action.meta.arg.thread.field.key;
        const proposalId = action.meta.arg.thread.proposalId;
        if (!fieldKey || !proposalId) {
          return;
        }
        const commentFieldKey = `${proposalId.value}-${fieldKey}`;

        state.loading[commentFieldKey] = false;
        let existingThreadComments = state.entries[commentFieldKey] || [];

        action.payload.forEach((comment) => {
          if (
            existingThreadComments.find((existingComment) =>
              existingComment.id?.isEqualTo(comment.id)
            )
          ) {
            existingThreadComments = existingThreadComments.filter(
              (existingComment) => !existingComment.id?.isEqualTo(comment.id)
            );
          }
          existingThreadComments.push(comment);
          state.entries[commentFieldKey] = existingThreadComments;
        });
      }
    );
    builder.addCase(
      populateThreadComments.rejected,
      (state, action) => {
        const fieldKey = action.meta.arg.thread.field.key;
        const proposalId = action.meta.arg.thread.proposalId;
        if (!fieldKey || !proposalId) {
          return;
        }
        const commentFieldKey = `${proposalId.value}-${fieldKey}`;

        state.loading[commentFieldKey] = false;
        state.error[commentFieldKey] = action.error;
      }
    );
    builder.addCase(
      saveDraftComments.pending,
      (state) => {
        for (const [commentFieldKey, comments] of Object.entries(state.entries)) {
          if (comments.some((comment) => comment.isPending && !comment.isAutoGenerated)) {
            state.loading[commentFieldKey] = true;
            state.error[commentFieldKey] = null;
          }
        }
      }
    );
    builder.addCase(
      saveDraftComments.fulfilled,
      (state, action) => {
        action.payload.forEach((comment) => {
          const fieldKey = comment.thread.field.key;
          const proposalId = comment.thread.proposalId;
          if (!fieldKey || !proposalId) {
            return;
          }
          const commentFieldKey = `${proposalId.value}-${fieldKey}`;

          let existingThreadComments =
            state.entries[`${proposalId.value}-${fieldKey}`] || [];
          existingThreadComments = existingThreadComments.filter(
            (existingComment) => !existingComment.id?.isEqualTo(comment.id)
          );
          existingThreadComments.push(comment);
          state.entries[commentFieldKey] = existingThreadComments;
          state.loading[commentFieldKey] = false;
          state.error[commentFieldKey] = null;
        });
      }
    );
    builder.addCase(
      saveDraftComments.rejected,
      (state, action) => {
        for (const [commentFieldKey, comments] of Object.entries(state.entries)) {
          if (comments.some((comment) => comment.isPending)) {
            state.loading[commentFieldKey] = false;
            state.error[commentFieldKey] = action.error;
          }
        }
      }
    );
  },
});

export const {
  addComment,
  removeComment,
  clearUnsavedCommentsByProposalId,
  updateAutoGeneratedComments,
} = commentsSlice.actions;

export const getCommentsByField = (
  proposalId?: Guid | null,
  field?: ProposalField
) =>
  useSelector((state: RootState) => {
    if (!proposalId || !field?.key) return [];
    return (
      state.comments.entries[`${proposalId.value}-${field?.key}`] ?? undefined
    );
  });
export const getIsLoadingComments = (
  proposalId?: Guid | null,
  field?: ProposalField
) =>
  useSelector((state: RootState) => {
    if (!proposalId || !field?.key) return false;
    return state.comments.loading[`${proposalId.value}-${field?.key}`] ?? false;
  });
export const getArePendingComments = (proposal?: Proposal | null) =>
  useSelector((state: RootState) => {
    if (!proposal?.id?.value) return false;
    return Object.values(state.comments?.entries)
      .flat()
      .some(
        (comment) =>
          comment.thread.proposalId.isEqualTo(proposal.id) && comment.isPending
      );
  });
export const getAreUnsavedComments = (proposal?: Proposal | null) =>
  useSelector((state: RootState) => {
    if (!proposal?.id?.value) return false;
    return Object.values(state.comments?.entries)
      .flat()
      .some(
        (comment) =>
          comment.thread.proposalId.isEqualTo(proposal.id) && !comment.created && !comment.isAutoGenerated
      );
  });

export default commentsSlice;
