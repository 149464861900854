import Text from "common/values/text/text";

export default class ProjectName extends Text {
  public override clone(): ProjectName {
    return new ProjectName(this.value);
  }

  public static get Prototype(): ProjectName {
    return new ProjectName('Prototype');
  }

  public get isEmpty(): boolean {
    return this.value === '';
  }

  public static fromJSON(object: any): ProjectName {
    if (!object) return object;
    if (typeof object !== 'string') throw new Error('ProjectDescription.fromJSON requires a string');
    return new ProjectName(object);
  }

  public override fromJSON(object: any): ProjectName | null {
    return ProjectName.fromJSON(object);
  }

  public toJSON(): any {
    return this.value;
  }
}
