import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  IconButton,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import * as Constants from "common/helpers/constants";
import Percent from "common/values/percent/percent";
import React, {useEffect} from "react";
import {
  NumberFormatValues,
  NumericFormat,
  SourceInfo,
} from "react-number-format";
import CommentThread from "work/entities/comment-thread/comment-thread";
import {ProposalField, ProposalFieldCategory} from "work/entities/proposal/proposal";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";
import CommentsButton from "work/entities/comment-thread/view/comments-button";

const ComponentContainer = styled("div")(
  ({theme}) => ({
    margin: theme.spacing(
      2,
      0,
      4,
      0
    ),
  }));
const HelperText = styled(Typography)(
  ({theme}) => ({
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

const discountDescription =
  "You can share the AttorneyHub fees you incur from a project by establishing " +
  'an agreed upon "discount" from your vendor. 2.912% is the value to cover the cost.';

type DiscountTabProps = {
  activeTab: ProposalFieldCategory;
  proposalBuilder: ProposalBuilder;
  issues?: ProposalIssues;
  disableEditing?: boolean;
  commentThreads: CommentThread[];
  commentsToolTip: string;
  onCommentsClicked?: (
    field: ProposalField,
    title?: string
  ) => void;
  onProposalBuilderUpdated: (updatedProposalBuilder: ProposalBuilder) => void;
};

export default function DiscountTab(props: Readonly<DiscountTabProps>) {
  const {
    activeTab,
    proposalBuilder,
    disableEditing,
    commentThreads,
    commentsToolTip,
    onCommentsClicked,
    onProposalBuilderUpdated,
  } = props;

  const [discount, setDiscount] = React.useState(0);
  const [isEditing, setIsEditing] = React.useState(false);

  useEffect(
    () => {
      if (activeTab !== ProposalFieldCategory.Discount) return;
      initializeDiscount();
    },
    [
      proposalBuilder,
      activeTab
    ]
  );

  function initializeDiscount() {
    const discount =
      proposalBuilder.currentSpec.discount.numeral ??
      0;
    setDiscount(discount);
  }

  /**
   * Handles the change event for the discount textfield
   * @param {NumberFormatValues} values The new values
   * @param {SourceInfo} sourceInfo The source of the change
   */
  async function handleDiscountChange(
    values: NumberFormatValues,
    sourceInfo: SourceInfo
  ) {
    if (sourceInfo.source === "prop") return;

    let updatedDiscount = (values.floatValue ?? 0) / 100;
    // Clamp to between 0 and 100%
    if (updatedDiscount < 0) updatedDiscount = 0;
    if (updatedDiscount > 1) updatedDiscount = 1;

    setDiscount(updatedDiscount);
  }

  function renderTextFieldButtons(
    isEditing: boolean,
    setter: (value: React.SetStateAction<boolean>) => void,
    callback: () => void
  ) {
    return (
      <>
        {(!disableEditing && !isEditing) && (
          <Tooltip title="Edit">
            <span>
              <IconButton
                tabIndex={-1}
                onClick={(event) => {
                  event.stopPropagation();
                  setter(true);
                }}
              >
                <EditIcon/>
              </IconButton>
            </span>
          </Tooltip>
        )}
        {isEditing && (
          <>
            <Tooltip title="Submit">
              <span>
                <IconButton onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  callback()
                }}>
                  <CheckIcon/>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Cancel">
              <span>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    setter(false);
                    const revertedDiscount = proposalBuilder.currentSpec.discount.numeral ?? 0;
                    updateProposalDiscount(revertedDiscount);
                  }}
                >
                  <CloseIcon/>
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}
      </>
    );
  }

  function updateProposalDiscount(discountOverride?: number) {
    onProposalBuilderUpdated(proposalBuilder.setDiscount(new Percent(discountOverride ?? discount)));
    onCommentsClicked?.(
      ProposalField.Discount,
      "Discount"
    );
  }

  if (activeTab !== ProposalFieldCategory.Discount) return null;

  return (
    <ComponentContainer>
      <NumericFormat
        name="discount"
        label="Discount"
        customInput={TextField}
        defaultValue={Constants.defaultDiscount * 100}
        allowNegative={false}
        value={Number(discount * 100)}
        suffix="%"
        type="text"
        thousandSeparator={false}
        decimalScale={2}
        fixedDecimalScale={true}
        disabled={disableEditing}
        isAllowed={(values) => {
          const {floatValue} = values;
          return (
            floatValue == null || (floatValue >= 0 && floatValue <= 100)
          );
        }}
        slotProps={{
          input: {
            endAdornment: (
              <>
                {renderTextFieldButtons(
                  isEditing,
                  setIsEditing,
                  updateProposalDiscount
                )}
                <CommentsButton
                  commentThreads={commentThreads}
                  field={ProposalField.Discount}
                  toolTip={commentsToolTip}
                  onCommentsClicked={
                    onCommentsClicked ? () => {
                      onCommentsClicked(
                        ProposalField.Discount,
                        "Discount"
                      );
                    } : undefined
                  }
                />
              </>
            )
          }
        }}
        onFocus={() => {
          setIsEditing(true);
          onCommentsClicked?.(
            ProposalField.Discount,
            "Discount"
          );
        }}
        onBlur={() => {
          updateProposalDiscount();
          setIsEditing(false);
        }}
        onValueChange={handleDiscountChange}
        onKeyDown={(event) => {
          if (event.key === "Enter" || event.key === "Tab") {
            updateProposalDiscount();
            setIsEditing(false);
          } else if (event.key === "Escape") {
            const revertedDiscount = proposalBuilder.currentSpec.discount.numeral ?? 0;
            updateProposalDiscount(revertedDiscount);
            setIsEditing(false);
          } else {
            setIsEditing(true);
          }
        }}
      />
      <HelperText>
        A discount of <b>3%</b> will cover subscription costs.
        <Tooltip
          disableFocusListener
          title={discountDescription}
          placement="right"
          arrow
        >
          <IconButton size="small">
            <InfoIcon/>
          </IconButton>
        </Tooltip>
      </HelperText>
    </ComponentContainer>
  );
}
