import {
  styled
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {CanceledError} from "axios";
import Guid from "common/values/guid/guid";
import IndividualAPIService from "marketplace/entities/individual/api/individual-api-service";
import Individual from "marketplace/entities/individual/individual";
import {enqueueSnackbar} from "notistack";
import React, {useEffect} from "react";
import {useSession} from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import Proposal, {
  ProposalField,
  ProposalFieldCategory,
} from "work/entities/proposal/proposal";
import TeamReadonly from "work/values/team/view/team-readonly";

const ListContainer = styled(Grid)(
  () => ({
    minHeight: "20rem",
  }));
const TeamReview = styled(TeamReadonly)(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(2),
    },
    marginLeft: theme.spacing(2),
  }));

type TeamTabArchivedProps = {
  entityId: Guid;
  activeTab: ProposalFieldCategory;
  proposal: Proposal;
  commentThreads: CommentThread[];
  onCommentsClicked: (
    field: ProposalField,
    name?: string
  ) => void;
};

export default function TeamTabArchived(props: Readonly<TeamTabArchivedProps>) {
  const {
    activeTab,
    proposal,
    commentThreads,
    onCommentsClicked,
  } = props;

  const [selectedLeader, setSelectedLeader] = React.useState<Individual | null>(null);
  const [selectedMembers, setSelectedMembers] = React.useState<Individual[]>([]);

  const session = useSession();

  useEffect(
    () => {
      const fetchTeamMembers = async () => {
        if (activeTab !== ProposalFieldCategory.Team) return null;
        if (!proposal.team?.leader) return;

        try {
          const individualService = new IndividualAPIService(session);
          const individualIdsToFetch = [
            proposal.team.leader.userId,
            ...proposal.team.memberUserIds
          ];

          let abortController = new AbortController();
          const response = await individualService.getUsersProfileInfo(
            individualIdsToFetch,
            abortController
          );

          const leader = response.find((ind: Individual) => ind.userId.isEqualTo(proposal.team?.leader?.userId)) ||
            null;
          setSelectedLeader(leader);

          const members = response.filter((ind: Individual) => !ind.userId.isEqualTo(proposal.team?.leader?.userId)) ||
            [];
          setSelectedMembers(members);

          return () => {
            abortController.abort();
            abortController = new AbortController();
          };

        } catch (error) {
          if (!(error instanceof CanceledError)) {
            enqueueSnackbar(
              "Failed to load team member information",
              {variant: "error"}
            );
          }
        }
      };

      fetchTeamMembers();
    },
    [activeTab]
  );

  if (activeTab !== ProposalFieldCategory.Team) return null;

  return (
    <ListContainer container direction="row">
      <Grid size="grow">
        <TeamReview
          leader={selectedLeader}
          members={selectedMembers}
          commentThreads={commentThreads}
          onCommentsClicked={onCommentsClicked}
        />
      </Grid>
    </ListContainer>
  );
}
