import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import {
  Badge,
  Drawer,
  IconButton,
  Portal,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Guid from "common/values/guid/guid";
import React from "react";
import { useSession } from "users/session/session-context";
import DetailsArchivedTab from "work/entities/proposal/archive/view/tabs/details-archived-tab";
import TeamTabArchived from "work/entities/proposal/archive/view/tabs/team-archived-tab";
import { ProposalField, ProposalFieldCategory, } from "work/entities/proposal/proposal";

import { getCommentThreadsByProposal } from "work/entities/comment-thread/store/comment-thread-redux-slice";
import CommentsReadonly from "work/entities/comment/view/comments-readonly";
import ArchivedBadge from "work/entities/proposal/archive/view/badge-archived";
import ConflictsArchivedTab from "work/entities/proposal/archive/view/tabs/conflicts-archived-tab";
import DiscountArchivedTab from "work/entities/proposal/archive/view/tabs/discount-archived-tab";
import FeeScheduleArchivedTab from "work/entities/proposal/archive/view/tabs/fee-schedule-archived-tab";
import PoliciesArchivedTab from "work/entities/proposal/archive/view/tabs/policies-archived-tab";
import {
  getProposalById
} from "work/entities/proposal/store/proposals-redux-slice";
import { HumanReadableProposalFieldName } from "work/values/constants";

const Header = styled("section")(
  ({theme}) => ({
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
    position: "sticky",
    top: "0px",
    zIndex: 10,
  }));
const TabsContainer = styled(Tabs)(
  ({theme}) => ({
    "&.MuiTabs-root": {
      overflow: "hidden",
      "& .MuiTabs-scroller": {
        paddingTop: theme.spacing(0.5),
      },
    },
  }));
const Content = styled("section")(
  () => ({
    alignItems: 'center',
    display: "flex",
    flex: 1,
    flexDirection: "row",
    height: '25rem',
    width: '72rem'
  }));
const TabContent = styled("section")(
  ({theme}) => ({
    alignItems: "stretch",
    flexDirection: "column",
    display: "flex",
    flex: 1,
    margin: theme.spacing(
      2,
      0
    ),
    overflow: "visible",
    width: "100%",
  }));
const ActionsContainer = styled("section")(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
    backgroundColor: theme.palette.background.default,
    bottom: "0px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingBottom: theme.spacing(2.5),
    position: "sticky",
    width: "100%",
    zIndex: 10,
  }));
const SecondaryActions = styled("section")(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    width: "100%",
  }));
const DialogTab = styled(Tab)(
  () => ({
    "&.MuiTab-root": {
      overflow: "visible",
    },
  }));
const MessageButtons = styled("section")(
  ({theme}) => ({
    alignContent: "center",
    marginLeft: theme.spacing(2),
  }));
const ButtonContainer = styled("div")(
  () => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  }));
const MessageButton = styled(IconButton)(
  () => ({
    paddingBottom: 0,
  }));
const MessageButtonLabel = styled(Typography)(
  () => ({
    fontSize: "0.6em",
  }));
const SidePanel = styled(Drawer)(
  ({theme}) => ({
    position: "fixed",
    zIndex: theme.zIndex.modal + 1,
  }));
const SidePanelContainer = styled("div")(
  () => ({
    flex: 1,
    height: "100%",
  }));
const SidePanelContent = styled("div")(
  ({theme}) => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
    width: "400px",
  }));
const TitleBar = styled("div")(
  ({theme}) => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingBottom: theme.spacing(1),
  }));

type ProposalArchiveDialogProps = {
  proposalId?: Guid;
  navigate: (url: string) => void;
};

export default function ProposalArchiveDialog(
  props: Readonly<ProposalArchiveDialogProps>
) {
  const {proposalId} = props;
  const session = useSession();
  const [activeTab, setActiveTab] = React.useState<ProposalFieldCategory>(ProposalFieldCategory.Details);
  const [currentCommentField, setCurrentCommentField] = React.useState<ProposalField | undefined>();
  const [sidePanelTitle, setSidePanelTitle] = React.useState<string | undefined>();
  const [isSidePanelOpen, setIsSidePanelOpen] = React.useState<boolean>(false);
  const proposal = getProposalById(proposalId);
  const commentThreads = getCommentThreadsByProposal(proposal);

  function handleTabChange(
    _event: React.SyntheticEvent | null,
    newTab: ProposalFieldCategory
  ) {
    setActiveTab(newTab);
  }

  function handleCloseCommentsPanel() {
    setIsSidePanelOpen(false);
    adjustDialogPosition(true);
  }

  async function handleOpenCommentsPanel(
    field: ProposalField,
    sidePanelTitle?: string
  ) {
    //on archived proposals, do not open comments panel 
    // if there are no comments
    const threads = commentThreads?.some((thread) =>
      thread.field.isEqualTo(field)
    );
    if (!threads) return;

    setCurrentCommentField(field);
    setSidePanelTitle(sidePanelTitle);
    setIsSidePanelOpen(true);
    adjustDialogPosition(false);
  }

  function adjustDialogPosition(panelOpen: boolean) {
    for (const dialog of document.getElementsByClassName("MuiDialog-root")) {
      dialog.setAttribute(
        "style",
        `padding-right: ${panelOpen ? "0px" : "400px"
        }; transition: padding-right 225ms;`
      );
    }
  }

  function getSidePanelTitle(): string {
    if (sidePanelTitle) return `${sidePanelTitle} Comments`;
    return `${HumanReadableProposalFieldName[
    currentCommentField?.name ?? ProposalField.General.name
      ]
    } Comments`;
  }

  function renderComments() {
    if (proposal?.id && commentThreads) {
      return (
        <CommentsReadonly
          proposal={proposal}
          field={currentCommentField ?? ProposalField.General}
          commentThreads={commentThreads}
        />
      );
    }
  }

  return (
    <>
      <Header>
        <TabsContainer
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          value={activeTab}
          onChange={handleTabChange}
        >
          <DialogTab
            value={ProposalFieldCategory.Details}
            label={
              <ArchivedBadge
                category={ProposalFieldCategory.Details}
              />
            }
          />
          <DialogTab
            value={ProposalFieldCategory.Team}
            label={
              <ArchivedBadge
                category={ProposalFieldCategory.Team}
              />
            }
          />
          <DialogTab
            value={ProposalFieldCategory.FeeSchedule}
            label={
              <ArchivedBadge
                category={ProposalFieldCategory.FeeSchedule}
              />
            }
          />
          <DialogTab
            value={ProposalFieldCategory.Conflicts}
            label={
              <ArchivedBadge
                category={ProposalFieldCategory.Conflicts}
              />
            }
          />
          <DialogTab
            value={ProposalFieldCategory.Policies}
            label={
              <ArchivedBadge
                category={ProposalFieldCategory.Policies}
              />
            }
          />
          <DialogTab
            value={ProposalFieldCategory.Discount}
            label={
              <ArchivedBadge
                category={ProposalFieldCategory.Discount}
              />
            }
          />
        </TabsContainer>
      </Header>
      <Content>
        <TabContent>
          {proposal && (
            <>
              <DetailsArchivedTab
                commentThreads={commentThreads ?? []}
                proposal={proposal}
                activeTab={activeTab}
                onCommentsClicked={handleOpenCommentsPanel}
              />
              <TeamTabArchived
                entityId={session.currentEntity.entityId}
                proposal={proposal}
                activeTab={activeTab}
                commentThreads={commentThreads ?? []}
                onCommentsClicked={handleOpenCommentsPanel}
              />
              <FeeScheduleArchivedTab
                proposal={proposal}
                activeTab={activeTab}
                commentThreads={commentThreads}
                onCommentsClicked={handleOpenCommentsPanel}
              />
              <ConflictsArchivedTab
                proposal={proposal}
                activeTab={activeTab}
                commentThreads={commentThreads}
                onCommentsClicked={handleOpenCommentsPanel}
              />
              <PoliciesArchivedTab
                proposal={proposal}
                activeTab={activeTab}
                commentThreads={commentThreads}
                onCommentsClicked={handleOpenCommentsPanel}
              />
              <DiscountArchivedTab
                proposal={proposal}
                activeTab={activeTab}
                commentThreads={commentThreads}
                onCommentsClicked={handleOpenCommentsPanel}
              />
            </>
          )}
        </TabContent>
      </Content>
      <ActionsContainer>
        <SecondaryActions>
        </SecondaryActions>
        <MessageButtons>
          <ButtonContainer>
            <MessageButton
              size="medium"
              color="primary"
              disabled={
                !proposal?.id || !proposal || !commentThreads
              }
              onClick={() =>
                handleOpenCommentsPanel(ProposalField.General)
              }
            >
              <Badge
                variant="standard"
                color="secondary"
                overlap="rectangular"
                invisible={
                  !commentThreads?.some((thread) =>
                    thread.field.isEqualTo(ProposalField.General)
                  )
                }
              >
                <CommentIcon fontSize="medium"/>
              </Badge>
            </MessageButton>
            <MessageButtonLabel
              variant="button"
              color={proposal?.id ? "primary" : "darkgray"}
            >
              Comments
            </MessageButtonLabel>
          </ButtonContainer>
        </MessageButtons>
        <Portal>
          <SidePanel
            open={isSidePanelOpen}
            anchor="right"
            variant="persistent"
          >
            <SidePanelContainer>
              <SidePanelContent>
                <TitleBar>
                  <Typography variant="h5">{getSidePanelTitle()}</Typography>
                  <IconButton onClick={handleCloseCommentsPanel}>
                    <CloseIcon/>
                  </IconButton>
                </TitleBar>
                {renderComments()}
              </SidePanelContent>
            </SidePanelContainer>
          </SidePanel>
        </Portal>
      </ActionsContainer>
    </>
  )
}
