import Guid from "common/values/guid/guid";
import DocumentName from "documents/values/document-name";
import DocumentTopic from "documents/values/document-topic";
import {DocumentType} from "documents/values/document-type";
import moment, {Moment} from "moment";

export default class Document {
  id: Guid;
  name: DocumentName | undefined;
  creatorId: Guid | null;
  fileSize: number;
  fileType: DocumentType;
  created: Moment | undefined;
  readAccess?: Guid[];
  writeAccess?: Guid[];
  topics: DocumentTopic[];
  templateIds: Guid[] = [];
  hasSystemPermissions: boolean = false;

  constructor(
    id: Guid,
    name: DocumentName | undefined,
    creatorId: Guid | null,
    fileSize: number,
    fileType: DocumentType,
    created: Moment | undefined,
    readAccess: Guid[] | undefined,
    writeAccess: Guid[] | undefined,
    topics: DocumentTopic[],
    templateIds: Guid[],
    hasSystemPermissions: boolean
  ) {

    this.id = id;
    this.name = name;
    this.creatorId = creatorId;
    this.fileSize = fileSize;
    this.fileType = fileType;
    this.created = created;
    this.readAccess = readAccess;
    this.writeAccess = writeAccess;
    this.topics = topics;
    this.templateIds = templateIds;
    this.hasSystemPermissions = hasSystemPermissions;
  }

  public fromJSON(object: any): Document | null {
    if (!object) return null;

    const id = Guid.fromJSON(object.id);
    const creatorId = Guid.fromJSON(object.creatorId) ?? null;
    const name = object.name ? DocumentName.fromJSON(object.name) : undefined;
    const fileSize = parseInt(object.fileSize);
    const fileType = object.fileType as DocumentType;
    const created = object.created ? moment(object.created) : undefined;
    const readAccess = object.readAccess.map((id: any) => Guid.fromJSON(id));
    const writeAccess = object.writeAccess.map((id: any) => Guid.fromJSON(id));
    const topics = object.topics.map((topic: any) => DocumentTopic.fromJSON(topic));
    const templateIds = object.templateIds.map((id: any) => Guid.fromJSON(id));
    const hasSystemPermissions = Boolean(object.hasSystemPermissions);

    if (!id) throw new Error("Document Id is required");

    return new Document(
      id,
      name,
      creatorId,
      fileSize,
      fileType,
      created,
      readAccess,
      writeAccess,
      topics,
      templateIds,
      hasSystemPermissions
    );
  }

  public static fromJSON(object: any): Document | null {
    if (!object) return null;

    const id = Guid.fromJSON(object.id);
    const creatorId = Guid.fromJSON(object.creatorId) ?? null;
    const name = object.name ? DocumentName.fromJSON(object.name) : undefined;
    const fileSize = parseInt(object.fileSize);
    const fileType = object.fileType as DocumentType;
    const created = object.created ? moment(object.created) : undefined;
    const readAccess = object.readAccess.map((id: any) => Guid.fromJSON(id));
    const writeAccess = object.writeAccess.map((id: any) => Guid.fromJSON(id));
    const topics = object.topics.map((topic: any) => DocumentTopic.fromJSON(topic));
    const templateIds = object.templateIds.map((id: any) => Guid.fromJSON(id));
    const hasSystemPermissions = Boolean(object.hasSystemPermissions);

    if (!id) throw new Error("Document Id is required");

    return new Document(
      id,
      name,
      creatorId,
      fileSize,
      fileType,
      created,
      readAccess,
      writeAccess,
      topics,
      templateIds,
      hasSystemPermissions
    );
  }

  public toJSON(): any {
    return {
      id: this.id.toJSON(),
      name: this.name?.toJSON(),
      creatorId: this.creatorId?.toJSON(),
      fileSize: this.fileSize,
      fileType: this.fileType,
      created: this.created?.toISOString(),
      readAccess: this.readAccess?.map((id) => id.toJSON()),
      writeAccess: this.writeAccess?.map((id) => id.toJSON()),
      topics: this.topics.map((topic) => topic.toJSON()),
      templateIds: this.templateIds.map((id) => id.toJSON()),
      hasSystemPermissions: this.hasSystemPermissions
    };
  }

  public get isTemplate(): boolean {
    return this.topics.some((topic) => topic.context?.toLowerCase().includes("template"));
  }

  public static get Prototype(): Document {
    return new Document(
      Guid.generate(),
      undefined,
      Guid.generate(),
      0,
      DocumentType.UNKNOWN,
      moment(),
      [],
      [],
      [],
      [],
      false
    );
  }

  public isUsingTemplate(templateId: Guid): boolean {
    return this.templateIds.some((id) => id.isEqualTo(templateId));
  }
}
