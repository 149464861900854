import axios, {
  AxiosHeaders,
  CanceledError
} from "axios";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import { UserMemberInvitationNotFoundError } from "legal-entities/entities/user-entity-member-invitation/user-entity-member-invitation";
import Session from "users/session/session";
import OpenEntityMemberInvitation from "../open-entity-member-invitation";
import AcceptOpenEntityMemberInvitationAPIRequest from "./request-contracts/accept-open-entity-member-invitation-api-request";
import OpenMemberInvitationAPIResponse from "./response-contracts/open-member-invitation-api-response";

export default class OpenEntityMemberInvitationAPIService {

  private headers: AxiosHeaders = new AxiosHeaders();

  constructor(session: Readonly<Session>) {
    if (session.authToken){
      this.headers.set("Authorization", `Bearer ${session.authToken.value}`);
    }
  }

  async createOpenMemberInvitation(): Promise<OpenEntityMemberInvitation> {
    const url = new URL(`/legal-entities/open-member-invitations`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers.concat({ 'Content-Type': 'application/json' })
        }
      );
      return Object.assign(new OpenMemberInvitationAPIResponse(), response.data).deserialize();
    } catch (error: any) {
      if (error.response.status === 400)
        throw new InvalidInvitationError(error.response.data);
      throw new OpenEntityMemberInvitationAPIServiceError("createOpenMemberInvitation", error);
    }
  }

  async getAllOpenEntityMemberInvitationAsync(entity: LegalEntity, abortController?: AbortController): Promise<OpenEntityMemberInvitation[]> {
    const url = new URL(`/legal-entities/${entity.id.value}/open-member-invitations`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const invitations: OpenEntityMemberInvitation[] = [];
      response.data.invitations.forEach((element: OpenMemberInvitationAPIResponse) => {
        invitations.push(Object.assign(new OpenMemberInvitationAPIResponse(), element).deserialize());
      });
      return invitations;
    } catch (error: any) {
      if(error instanceof CanceledError)
        throw error;  
      throw new OpenEntityMemberInvitationAPIServiceError("getAllOpenEntityMemberInvitationAsync", error);
    }
  }

  async getOpenMemberInvitationById(id: Guid, abortController?: AbortController): Promise<OpenEntityMemberInvitation> {
    const url = new URL(`/legal-entities/open-member-invitations/${id.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      return Object.assign(new OpenMemberInvitationAPIResponse(), response.data).deserialize();
    } catch (error: any) {
      if (error.response.status === 404)
        throw new UserMemberInvitationNotFoundError(id);
      if(error instanceof CanceledError)
        throw error;   
      throw new OpenEntityMemberInvitationAPIServiceError("getOpenMemberInvitationById", error);
    }
  }

  async acceptOpenEntityMemberInvitationAsync(invitation: OpenEntityMemberInvitation, email: EmailAddress): Promise<void> {
    if (!invitation.id)
      throw new Error("Invitation ID is required to accept an invitation.");

    const url = new URL(`/legal-entities/open-member-invitations/${invitation.id.value}/accept`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.post(
        url.toString(),
        new AcceptOpenEntityMemberInvitationAPIRequest(email),
        {
          headers: this.headers.concat({ 'Content-Type': 'application/json' })
        }
      );
    } catch (error: any) {
      if (error?.response?.status === 404) 
        throw new UserMemberInvitationNotFoundError(invitation.id);
      if (error?.response?.status === 400)
        throw new InvalidInvitationError(error.response.data);
      throw new OpenEntityMemberInvitationAPIServiceError("acceptOpenEntityMemberInvitationAsync", error, invitation);
    }
  }

  async deleteOpenMemberInvitation(invitation: OpenEntityMemberInvitation): Promise<void> {
    if (!invitation.id)
      throw new Error("Invitation ID is required to delete an invitation.");

    const url = new URL(`/legal-entities/${invitation.entity.id.value}/open-member-invitations/${invitation.id.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.delete(
        url.toString(),
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      if (error?.response?.status === 404) 
        throw new UserMemberInvitationNotFoundError(invitation.id);
      throw new OpenEntityMemberInvitationAPIServiceError("deleteOpenMemberInvitation", error, invitation);
    }
  }
}

export class InvalidInvitationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "InvalidInvitationError";
  }
}

export class OpenEntityMemberInvitationAPIServiceError extends Error {
  invitation?: OpenEntityMemberInvitation;
  method: string;
  error: any;
  constructor(method: string, error: any, invitation?: OpenEntityMemberInvitation) {
    super(`OpenEntityMemberInvitationAPIService: ${error.message}`);
    this.method = method;
    this.error = error;
    this.invitation = invitation;
  }
}