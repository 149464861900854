import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  Chip,
  Grow,
  List,
  ListItem,
  ListItemText
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useAttorneyHubDispatch} from "app/realtime-store/redux-store";
import Loader from "common/components/loader";
import * as Constants from "common/helpers/constants";
import Guid from "common/values/guid/guid";
import _ from "lodash";
import {populateIndividuals} from "marketplace/entities/individual/realtime-store/individuals-redux-slice";
import React, {useEffect, useRef} from "react";
import {useSession} from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import Comment from "work/entities/comment/comment";
import {
  getCommentsByField,
  getIsLoadingComments,
  populateThreadComments
} from "work/entities/comment/store/comments-redux-slice";

import CommentItemReadonly from "work/entities/comment/view/comment-item-readonly";
import Proposal, {ProposalField} from "work/entities/proposal/proposal";

const CommentsContainer = styled("div")(
  () => ({
    display: "contents",
    height: "100%",
  }));
const CommentsList = styled(List)(
  () => ({
    overflowY: "auto",
  }));
const BackToTopChipContainer = styled("div")(
  () => ({
    display: "flex",
    height: 0,
    justifyContent: "center",
    position: "relative",
    width: "100%",
    zIndex: 1,
  }));
const BackToTopChip = styled(Chip)(
  ({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    top: "20px",
    boxShadow:
      "0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px 0 rgb(0 0 0 / 20%)",
    color: "white",
    position: "absolute",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  }));

type CommentsReadonlyProps = {
  className?: string;
  proposal: Proposal;
  commentThreads: CommentThread[];
  field: ProposalField;
};

export default function CommentsReadonly(props: Readonly<CommentsReadonlyProps>) {
  const {className, proposal, commentThreads, field} = props;

  const topRef = useRef<HTMLDivElement>(null);
  const commentsListRef = useRef<HTMLUListElement>(null);

  const [backToTopVisible, setBackToTopVisible] = React.useState(false);

  const session = useSession();

  const fieldComments = getCommentsByField(
    proposal.id,
    field
  );
  const isLoadingComments = getIsLoadingComments(
    proposal.id,
    field
  );
  const dispatch = useAttorneyHubDispatch();

  const handleWindowScrolled = (_event: any) => {
    if (!commentsListRef?.current) return;
    const scrollPercent = Math.round(
      (commentsListRef?.current.scrollTop /
        (commentsListRef?.current.scrollHeight -
          commentsListRef?.current.clientHeight)) *
      100.0
    );
    setBackToTopVisible(scrollPercent > 5);
  };

  const throttledScrollEventHandler = _.throttle(
    handleWindowScrolled,
    Constants.backToTopThrottleDelay
  );

  useEffect(
    () => {
      scrollToBottom();
      window.setTimeout(
        () => {
          commentsListRef?.current?.addEventListener(
            "scroll",
            throttledScrollEventHandler
          );
        },
        1000
      );

      const subscriberIds = _.uniqBy(
        [
          ...(fieldComments?.map((comment: Comment) => comment.senderId).filter((id) => id) ?? []),
          ...commentThreads.flatMap(
            (thread: CommentThread) => thread.subscriberIds
          ),
        ],
        (id) => id?.value
      )

      dispatch(populateIndividuals({session, userIds: subscriberIds as Guid[]}));
    },
    []
  );

  useEffect(
    () => {
      if (fieldComments === undefined) {
        commentThreads.forEach((thread) => {
          if (field.isEqualTo(thread.field)) {
            dispatch(populateThreadComments({session, thread}));
          }
        });
      }
    },
    [
      fieldComments,
      field,
      commentThreads
    ]
  );

  const scrollToBottom = () => {
    window.setTimeout(
      () => {
        topRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      },
      500
    );
  };

  function sortCommentsByDate(comments: Comment[]): Comment[] {
    let sortedComments = [
      ..._.uniqBy(
        comments,
        (comment) => comment.id?.value
      ),
    ];
    return sortedComments.sort((a, b) => {
      if (a.published && b.published) {
        return b.published.diff(a.published);
      }
      return 0;
    });
  }

  return isLoadingComments ? (
    <Loader/>
  ) : (
    <CommentsContainer className={className}>
      {Boolean(fieldComments?.length && fieldComments.length > 0) && (
        <BackToTopChipContainer>
          <Grow in={backToTopVisible}>
            <BackToTopChip
              label="Jump to latest"
              icon={<ArrowUpwardIcon style={{color: "white"}}/>}
              onClick={() =>
                topRef?.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "nearest",
                })
              }
            />
          </Grow>
        </BackToTopChipContainer>
      )}
      <CommentsList ref={commentsListRef}>
        <div ref={topRef}/>
        {fieldComments?.length === 0 && (
          <ListItem key={Guid.generate().value} disableGutters>
            <ListItemText>No comments yet</ListItemText>
          </ListItem>
        )}
        {sortCommentsByDate(fieldComments).map((comment: Comment) => {
          return (
            <CommentItemReadonly
              key={comment.id?.value}
              comment={comment}
            />
          );
        })}
      </CommentsList>
    </CommentsContainer>
  );
}
