import { Box, styled } from "@mui/material";

const ArchivedBadgeContainer = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    gap: "16px",
}));

//props
type ArchivedBadgeProps = {
    title?: string;
    category: string;
};

//function
export default function ArchivedBadge(props: Readonly<ArchivedBadgeProps>) {
    const { title, category } = props;

    return (
        <ArchivedBadgeContainer>
            <span>{title ?? category.toString()}</span>
        </ArchivedBadgeContainer>
    );
}