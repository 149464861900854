import Guid from "common/values/guid/guid";
import EntityRepresentative from "work/entities/entity-representative/entity-representative";

export default class EntityVendorRepresentative implements EntityRepresentative {
  userId: Guid;
  entityId: Guid;

  constructor(userId: Guid, entityId: Guid) {
    this.userId = userId;
    this.entityId = entityId;
  }

  public isEqualTo(other: EntityVendorRepresentative | null | undefined): boolean {
    if (!other) return false;
    return this.userId.isEqualTo(other.userId) && this.entityId.isEqualTo(other.entityId);
  }

  public clone(): EntityVendorRepresentative {
    return new EntityVendorRepresentative(
      this.userId.clone(),
      this.entityId.clone()
    );
  }

  toJSON(): object {
    return {
      userId: this.userId.toJSON(),
      entityId: this.entityId.toJSON(),
    };
  }

  static fromJSON(json: any): EntityVendorRepresentative | undefined {
    if (!json.userId || !json.entityId) {
      return undefined
    }
    const userId = Guid.fromJSON(json.userId);
    const entityId = Guid.fromJSON(json.entityId);
    if (!userId || !entityId) {
      return undefined
    }
    return new EntityVendorRepresentative(
      userId,
      entityId
    );
  }
}
