import {Badge, Box, styled, Tooltip} from "@mui/material";
import {ProposalFieldCategory} from "work/entities/proposal/proposal";
import ProposalIssues, {ProposalIssueLevel} from "work/values/proposal-issues/proposal-issues";
import CommentThread from "work/entities/comment-thread/comment-thread";
import {ProposalFieldName} from "work/values/constants";

const BadgeContainer = styled(Box)(
  () => ({
    display: "flex",
    alignItems: "center",
    gap: "16px",
  }));

const StyledBadge = styled(Badge)(
  () => ({
    "& .MuiBadge-badge": {
      fontSize: "0.6rem",
      height: "16px",
      minWidth: "16px",
    },
  }));

type ProposalIssuesBadgeProps = {
  issues?: ProposalIssues;
  commentThreads?: CommentThread[];
  category: ProposalFieldCategory;
  title?: string;
};

export default function ProposalIssuesBadge(
  props: Readonly<ProposalIssuesBadgeProps>
) {
  const {
    issues,
    commentThreads,
    category,
    title
  } = props;

  const filteredThreads = commentThreads?.filter((comment) =>
    comment.field.category === category &&
    comment.field.name !== ProposalFieldName.General
  );
  const filteredIssues = issues?.entries.filter(
    (issue) => issue.field.category === category
  );
  const numErrors = filteredIssues?.filter(
    (issue) => issue.level === ProposalIssueLevel.Critical
  ).length ?? 0;
  const numWarnings = filteredIssues?.filter(
    (issue) => issue.level === ProposalIssueLevel.Warning
  ).length ?? 0;
  const numComments = filteredThreads?.reduce(
    (acc, thread) => acc + (thread.numUnread ?? 0),
    0
  ) ?? 0;

  function getToolTip() {
    if (!filteredIssues && !filteredThreads) return "";

    const messages: React.ReactNode[] = [];

    filteredIssues?.forEach((issue) => {
      messages.push(
        <p key={issue.field.name + (issue.field.id?.toString() ?? "")}>
          {issue.description}
        </p>
      );
    });

    filteredThreads?.forEach((thread) => {
      messages.push(
        <p key={thread.id?.value}>
          {thread.numUnread} new comment{thread.numUnread && thread.numUnread > 1 && "s"}.
        </p>
      );
    })

    return messages;
  }

  return (
    <BadgeContainer>
      <span>{title ?? category.toString()}</span>
      <Tooltip title={getToolTip()} placement="bottom">
        <BadgeContainer>
          {numErrors > 0 && (
            <StyledBadge color="error" badgeContent={numErrors}/>
          )}
          {numWarnings > 0 && (
            <StyledBadge color="warning" badgeContent={numWarnings}/>
          )}
          {numComments > 0 && (
            <StyledBadge color="secondary" badgeContent={numComments}/>
          )}
        </BadgeContainer>
      </Tooltip>
    </BadgeContainer>
  );
}
