import {DialogComponent, DialogProps} from "common/components/dialog";
import Guid from "common/values/guid/guid";
import React, {createContext, useContext, useState} from "react";

type DialogContextType = {
  numDialogs: number;
  openDialog: (dialogProps: DialogProps, onCloseCallback?: () => void) => Guid;
  popDialog: (onCloseCallback?: () => void) => void;
  closeDialog: (onCloseCallback?: () => void) => void;
  updateDialogProps: (id: Guid, dialogProps: DialogProps) => void;
}

type DialogInfo = {
  id: Guid;
  dialog: React.ReactElement;
  open: boolean;
}

export const DialogContext = createContext<DialogContextType>(undefined!);

export default function DialogProvider({children}: Readonly<{ children: React.ReactNode }>) {
  const [dialogs, setDialogs] = useState<DialogInfo[]>([]);
  const numDialogs = dialogs.length;

  async function popDialog() {
    let updatedDialogs = [...dialogs];
    if (updatedDialogs.length < 1) {
      updatedDialogs = [];
    } else {
      updatedDialogs[0].open = true;
    }
    setDialogs(updatedDialogs);
  }

  async function handleCloseDialog() {
    setDialogs([]);
  }

  const openDialog =
    (newDialogProps: DialogProps) => {
      let updatedDialogs = [...dialogs];

      const newDialog: DialogInfo = {
        id: Guid.generate(),
        open: true,
        dialog: (
          <DialogComponent
            {...newDialogProps}
            onClose={() => {
              newDialogProps.onClose?.();
              handleCloseDialog();
            }}
            onBack={popDialog}
            hideBackButton={dialogs.length < 1 || newDialogProps.resetStack}
            hideCloseButton={dialogs.length > 0}
          />
        )
      };

      if (newDialogProps.resetStack) {
        updatedDialogs = [];
        updatedDialogs.push(newDialog);
      } else {
        if (updatedDialogs.length > 0) {
          updatedDialogs[0].open = false;
        }
        updatedDialogs.unshift(newDialog);
      }

      setDialogs(updatedDialogs);

      return newDialog.id;
    }

  const updateDialogProps = (id: Guid, newDialogProps: DialogProps) => {
    const updatedDialogs = [...dialogs];
    const dialogIndex = updatedDialogs.findIndex(dialog => dialog.id.isEqualTo(id));
    if (dialogIndex === -1) return;
    updatedDialogs[dialogIndex].dialog = (
      <DialogComponent
        {...newDialogProps}
        onClose={() => {
          newDialogProps.onClose?.();
          handleCloseDialog();
        }}
        onBack={popDialog}
        hideBackButton={dialogIndex === 0}
        hideCloseButton={dialogIndex !== 0}
      />
    );
    setDialogs(updatedDialogs);
  }

  const dialogState = {
    numDialogs,
    openDialog,
    popDialog,
    closeDialog: handleCloseDialog,
    updateDialogProps
  };

  return (
    <DialogContext.Provider value={dialogState}>
      {children}
      {dialogs.map((dialogInfo: DialogInfo) => (
        <span key={dialogInfo.id.value} style={{display: dialogInfo?.open ? 'unset' : 'none'}}>
          {dialogInfo?.dialog}
        </span>
      ))}
    </DialogContext.Provider>
  );
}

export function useDialog() {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
}
