import Guid from "common/values/guid/guid";
import Topic from "messaging/values/topic";
import { Moment } from "moment";


export default class Forum {
  id?: Guid;
  name?: string;
  topic?: Topic;
  subscriberIds: Guid[];
  creatorId?: Guid;
  createdDate?: Moment;
  isResolved?: boolean;
  numUnreadMessages?: number;

  constructor(
    name?: string,
    topic?: Topic,
    subscriberIds: Guid[] = [],
    id?: Guid,
    creatorId?: Guid,
    createdDate?: Moment,
    numUnreadMessages?: number
  ) {
    this.id = id;
    this.name = name;
    this.topic = topic;
    this.subscriberIds = subscriberIds;
    if (creatorId) {
      this.creatorId = creatorId;
    }
    if (createdDate) {
      this.createdDate = createdDate;
    }
    this.numUnreadMessages = numUnreadMessages ?? 0;
  }
}

export class ForumDoesNotExistError extends Error {
  constructor(id?: Guid | string) {
    if (id instanceof Guid) {
      super(`Forum ${id} does not exist`);
    } else {
      super(`Forum does not exist: ${id}`);
    }
  }
}
