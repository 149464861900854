import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InfoIcon from "@mui/icons-material/Info";
import UndoIcon from "@mui/icons-material/Undo";
import UploadIcon from "@mui/icons-material/Upload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  CircularProgress,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import {green, lightBlue} from "@mui/material/colors";
import {ConfirmResponse, useConfirmDialog,} from "app/providers/confirm-dialog";
import LoadingButton from "common/components/loading-button";
import {AccountType} from "common/values/account-type/account-type";
import Guid from "common/values/guid/guid";
import {Change, diffLines} from "diff";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import Document from "documents/entities/document/document";
import DocumentTopic from "documents/values/document-topic";
import {enqueueSnackbar} from "notistack";
import React from "react";
import {defaultStyles, FileIcon} from "react-file-icon";
import {useSession} from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import {ProposalField} from "work/entities/proposal/proposal";
import FieldRedline, {FieldRedlineArray,} from "work/entities/proposal/redlining/field-redline";
import WorkDocument, {WorkDocumentType,} from "work/values/work-document/work-document";
import CommentsButton from "work/entities/comment-thread/view/comments-button";

const ListContainer = styled("div")(
  ({theme}) => ({
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
    minWidth: "20rem",
    padding: theme.spacing(2),
    paddingTop: 0,
  }));
const DocumentList = styled(List)(
  () => ({
    minWidth: "20rem",
    width: "100%",
  }));
const ListSubhead = styled(ListSubheader)(
  () => ({
    backgroundColor: "#FFF",
    color: "#000",
    display: "flex",
    fontSize: "1.4em",
    justifyContent: "space-between",
    padding: 0,
    whiteSpace: "nowrap",
  }));
const ActionButton = styled(IconButton)(
  ({theme}) => ({
    padding: theme.spacing(0.5),
  }));
const DiffResult = styled("span")<{
  change?: FieldRedline<WorkDocument>;
  disabled?: boolean;
}>(
  ({change, disabled, theme}) => ({
    backgroundColor: (function () {
      if (disabled) return "none";
      if (change?.isAdded && !change.isResolved) return green[300];
      return "unset";
    })(),
    color: (function () {
      if (change?.isNewlyAdded) return lightBlue[700];
    })(),
    textDecoration: (function () {
      if (change?.isRemoved && !change.isResolved) return "line-through";
    })(),
    textDecorationColor: (function () {
      if (change?.isNewlyRemoved) return lightBlue[700];
      if (change?.isRemoved) return theme.palette.error.main;
      return "initial";
    })(),
  }));
const ModifiedDiffResult = styled("span")<{
  change?: Change;
  resolved?: boolean;
  accepted?: boolean;
}>(
  ({change, resolved, accepted, theme}) => ({
    backgroundColor: (function () {
      if (resolved) return "unset";
      if (change?.added) return green[300];
    })(),
    color: (function () {
      if (change?.added && resolved) return lightBlue[700];
      return theme.palette.text.primary;
    })(),
    textDecoration: (function () {
      if (change?.removed) return "line-through";
    })(),
    textDecorationColor: (function () {
      if (accepted) return "unset";
      if (change?.removed && resolved) return lightBlue[700];
      if (change?.removed) return theme.palette.error.main;
      return "initial";
    })(),
  }));
const DocumentLink = styled(Link)<{ disabled?: boolean }>(
  ({theme, disabled}) => ({
    color: disabled ? theme.palette.text.disabled : theme.palette.common.black,
    cursor: disabled ? "default" : "pointer",
    textDecoration: "underline",
    textDecorationColor: disabled
      ? theme.palette.text.disabled
      : theme.palette.common.black,
    pointerEvents: disabled ? "none" : "auto",
  })
);
const FileListItem = styled(ListItemText)(
  () => ({
    marginBottom: 0,
    maxWidth: "fit-content",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    "& .MuiListItemText-primary": {
      padding: 0,
    },
  }));
const NoRowsPlaceholder = styled(Typography)(
  ({theme}) => ({
    fontSize: "1.3em",
    paddingTop: theme.spacing(1),
  }));
const DocumentListItem = styled(
  ListItem,
  {
    shouldForwardProp: (prop) => prop !== "focused",
  }
)<{ focused: boolean }>(
  ({theme, focused}) => ({
    border: focused ? "2px solid" : 0,
    borderColor: theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
    flexDirection: "column",
    padding: focused ? theme.spacing(2) : theme.spacing(
      1,
      0
    ),
  }));
const DocumentIcon = styled(ListItemAvatar)(
  ({theme}) => ({
    marginRight: theme.spacing(1),
    minWidth: "2rem",
    maxWidth: "2rem",
  }));
const DocumentName = styled("div")(
  () => ({
    alignItems: "center",
    display: "flex",
    width: "100%",
  }));
const DocumentActions = styled("div")(
  () => ({
    width: "100%",
  }));
const HeaderContainer = styled("div")(
  () => ({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  }));

type DocumentRedlineSelectionProps = {
  className?: string;
  workDocumentType: WorkDocumentType;
  documentsRedline: FieldRedlineArray<WorkDocument>;
  downloadingFileId: Guid | null;
  accountType?: AccountType;
  commentThreads?: CommentThread[];
  disableEditing?: boolean;
  activeReviewField: ProposalField | undefined;
  commentsToolTip: string;
  onCommentsClicked: (field: ProposalField, name?: string) => void;
  onDownload: (documentId?: Guid) => void;
  onDocumentRedlineChange: (
    redline: FieldRedlineArray<WorkDocument>,
    traversalFieldOverride?: ProposalField | null
  ) => void;
  onBeginReplaceDocument: (documentId: Guid | undefined | null) => void;
  onDocumentClicked: (id: Guid) => void;
  onTraverseToNewField: (fieldInfo: ProposalField | undefined) => void;
};

export default function DocumentRedlineSelection(
  props: Readonly<DocumentRedlineSelectionProps>
) {
  const {
    className,
    workDocumentType,
    documentsRedline,
    downloadingFileId,
    accountType,
    commentThreads,
    disableEditing,
    activeReviewField,
    commentsToolTip,
    onCommentsClicked,
    onDownload,
    onDocumentRedlineChange,
    onBeginReplaceDocument,
    onDocumentClicked,
  } = props;

  const fileUploaderRef = React.useRef<HTMLInputElement | null>(null);
  const [isUploading, setIsUploading] = React.useState(false);

  const confirm = useConfirmDialog();
  const session = useSession();

  function getShouldShowBadge(
    field?: ProposalField
  ): boolean {
    if (!field) return false;
    return commentThreads?.some((thread) => thread.field.isEqualTo(field)) ?? false;
  }

  function getActionButtonTooltipText(
    isAdded: boolean,
    isRemoved: boolean,
    accept: boolean
  ) {
    const verb = accept ? "Accept " : "Reject ";

    if (isAdded) return `${verb} New Document`;
    if (isRemoved) return `${verb} Removed Document`;
    return `${verb} All Changes`;
  }

  async function handleDocumentRemoved(
    documentRedline: FieldRedline<WorkDocument>
  ) {
    if (!documentRedline.field.id) {
      throw new Error("Field ID is missing");
    }
    const response = await confirm({
      title: "Remove Document?",
      message: `Do you want to remove this document?`,
      okButtonText: "Remove",
    });
    if (response === ConfirmResponse.Cancel) return;
    try {
      onDocumentRedlineChange?.(
        documentsRedline.removeEntryByFieldId(documentRedline.field.id)
      );
      onCommentsClicked(
        documentsRedline.field,
        undefined
      );
    } catch (error) {
      enqueueSnackbar(
        "Failed to remove document",
        {variant: "error"}
      );
    }
  }

  function getDiffResult(documentRedline: FieldRedline<WorkDocument>) {
    let previousDocument: WorkDocument | null;
    let revisedDocument: WorkDocument | null;

    if (documentRedline.wasRedlined && !documentRedline.isResolved) {
      previousDocument = documentRedline.originalEntry;
      revisedDocument = documentRedline.revisedEntry;
    } else if (documentRedline.isRemoved && documentRedline.isAccepted) {
      previousDocument = documentRedline.originalEntry;
      revisedDocument = null;
    } else {
      previousDocument = documentRedline.revisedEntry;
      revisedDocument = documentRedline.currentEntry ?? null;
    }

    const diff = diffLines(
      previousDocument?.name?.toString() ?? "",
      revisedDocument?.name?.toString() ?? ""
    );

    return (
      <span>
        {downloadingFileId?.isEqualTo(documentRedline.field.id) && (
          <CircularProgress
            size={16}
            thickness={4}
            color="inherit"
            sx={{
              color: "text.disabled",
              marginRight: 1,
            }}
          />
        )}
        <DocumentLink
          disabled={downloadingFileId?.isEqualTo(documentRedline.field.id)}
          onClick={() => {
            onDownload(documentRedline.currentEntry?.id ?? documentRedline.revisedEntry?.id ??
              documentRedline.originalEntry?.id)
          }}
        >
          {diff.map((change: Change) => {
            return (
              <ModifiedDiffResult
                key={JSON.stringify(change)}
                change={change}
                resolved={documentRedline.isResolved}
                accepted={documentRedline.isAccepted}

              >
                {change.value}
              </ModifiedDiffResult>
            );
          })}
        </DocumentLink>
      </span>
    );
  }

  function getUnchangedResult(documentRedline: FieldRedline<WorkDocument>) {
    return (
      <span>
        {downloadingFileId?.isEqualTo(documentRedline.field.id) && (
          <CircularProgress
            size={16}
            thickness={4}
            color="inherit"
            sx={{
              color: "text.disabled",
              marginRight: 1,
            }}
          />
        )}
        <DocumentLink
          disabled={downloadingFileId?.isEqualTo(documentRedline.field.id)}
          onClick={() =>
            onDownload(
              documentRedline.currentEntry?.id ??
              documentRedline.revisedEntry?.id ??
              documentRedline.originalEntry?.id ??
              undefined
            )
          }
        >
          <DiffResult
            disabled={downloadingFileId?.isEqualTo(documentRedline.field.id)}
            change={documentRedline}
          >
            {documentRedline.currentEntry?.name?.value ??
              documentRedline.revisedEntry?.name?.value ??
              documentRedline.originalEntry?.name?.value}
          </DiffResult>
        </DocumentLink>
      </span>
    );
  }

  function renderDocumentRedline(
    documentRedline: FieldRedline<WorkDocument>
  ) {
    if (!documentRedline) return;
    const document =
      documentRedline.currentEntry ??
      documentRedline.revisedEntry ??
      documentRedline.originalEntry;
    const key = documentRedline.field.id?.value ?? Guid.generate().value;
    return (
      <DocumentListItem
        key={key}
        focused={
          activeReviewField?.id?.isEqualTo(documentRedline.field.id) ?? false
        }
        onClick={() => {
          if (!documentRedline.field.id) return;
          onDocumentClicked(documentRedline.field.id);
        }}
        disableGutters
      >
        <DocumentName>
          <DocumentIcon>
            <FileIcon
              extension={document?.fileType}
              {...defaultStyles[
                document?.fileType?.toLowerCase() as keyof typeof defaultStyles
                ]}
            />
          </DocumentIcon>
          <FileListItem
            primary={
              documentRedline.wasRedlined ||
              documentRedline.isRevised
                ? getDiffResult(documentRedline)
                : getUnchangedResult(documentRedline)
            }
            secondary={document?.created?.format("MM/DD/YY hh:mm A")}
            slotProps={{
              primary: {
                overflow: "hidden",
                paddingRight: 8,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }
            }}
          />
        </DocumentName>
        <DocumentActions>
          {!disableEditing && (
            <>
              {!documentRedline.canBeUndone && (
                <>
                  <Tooltip title="Replace Document">
                    <span>
                      <ActionButton
                        onClick={(event) => {
                          event.stopPropagation();
                          onBeginReplaceDocument(documentRedline.field.id);
                        }}
                      >
                        <UploadFileIcon/>
                      </ActionButton>
                    </span>
                  </Tooltip>
                  {!documentRedline.isRemoved &&
                    !(
                      documentRedline.isAdded && !documentRedline.isResolved
                    ) && (
                      <Tooltip title="Remove Document">
                        <span>
                          <ActionButton
                            onClick={(event) => {
                              event.stopPropagation();
                              if (
                                documentRedline.currentEntry !== null &&
                                documentRedline.field.id
                              ) {
                                handleDocumentRemoved(documentRedline);
                              }
                            }}
                          >
                            <DeleteOutlineIcon/>
                          </ActionButton>
                        </span>
                      </Tooltip>
                    )}
                </>
              )}
              {documentRedline.canBeUndone && (
                <Tooltip title="Undo Changes">
                  <span>
                    <ActionButton
                      onClick={(event) => {
                        event.stopPropagation();
                        try {
                          if (!documentRedline.field.id)
                            throw new Error("Field ID is missing");
                          onDocumentRedlineChange(
                            documentsRedline.undoRedlineById(
                              documentRedline.field.id
                            ),
                            !documentRedline.isNewlyAdded
                              ? documentRedline.field
                              : null
                          );
                          onCommentsClicked(
                            documentRedline.field,
                            documentRedline.currentEntry?.name?.value ?? documentsRedline.field.name
                          );
                        } catch (error) {
                          console.error(
                            "Failed to undo changes",
                            error
                          );
                          enqueueSnackbar(
                            "Failed to undo changes",
                            {
                              variant: "error",
                            }
                          );
                        }
                      }}
                    >
                      <UndoIcon/>
                    </ActionButton>
                  </span>
                </Tooltip>
              )}
              {!documentRedline.isResolved && (
                <>
                  <Tooltip
                    title={getActionButtonTooltipText(
                      !!documentRedline.isAdded,
                      !!documentRedline.isRemoved,
                      true
                    )}
                  >
                    <span>
                      <ActionButton
                        onClick={(event) => {
                          event.stopPropagation();
                          try {
                            if (!documentRedline.field.id)
                              throw new Error("Field ID is missing");
                            onDocumentRedlineChange(
                              documentsRedline.acceptRedlineById(
                                documentRedline.field.id
                              )
                            );
                            onCommentsClicked(
                              documentRedline.field,
                              documentRedline.currentEntry?.name?.value ?? documentsRedline.field.name
                            );
                          } catch (error) {
                            console.error(
                              "Failed to accept changes",
                              error
                            );
                            enqueueSnackbar(
                              "Failed to accept changes",
                              {
                                variant: "error",
                              }
                            );
                          }
                        }}
                      >
                        <CheckIcon color="success"/>
                      </ActionButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={getActionButtonTooltipText(
                      !!documentRedline.isAdded,
                      !!documentRedline.isRemoved,
                      false
                    )}
                  >
                    <span>
                      <ActionButton
                        onClick={(event) => {
                          event.stopPropagation();
                          try {
                            if (!documentRedline.field.id)
                              throw new Error("Field ID is missing");
                            onDocumentRedlineChange(
                              documentsRedline.rejectRedlineById(
                                documentRedline.field.id
                              )
                            );
                            onCommentsClicked(
                              documentRedline.field,
                              documentRedline.currentEntry?.name?.value ?? documentsRedline.field.name
                            );
                          } catch (error) {
                            console.error(
                              "Failed to reject changes",
                              error
                            );
                            enqueueSnackbar(
                              "Failed to reject changes",
                              {
                                variant: "error",
                              }
                            );
                          }
                        }}
                      >
                        <CloseIcon color="error"/>
                      </ActionButton>
                    </span>
                  </Tooltip>
                </>
              )}
            </>
          )}
          <CommentsButton
            field={documentRedline.field}
            commentThreads={commentThreads}
            toolTip={commentsToolTip}
            onCommentsClicked={onCommentsClicked ? () => {
              onCommentsClicked(
                documentRedline.field,
                documentRedline.currentEntry?.name?.value ?? documentRedline.revisedEntry?.name?.value
              )
            } : undefined}
          />
        </DocumentActions>
      </DocumentListItem>
    );
  }

  async function handleFileUpload(
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    if (!event.currentTarget.files) {
      return;
    }
    const file: File = event.currentTarget.files[0];

    try {
      setIsUploading(true);

      let context: string | undefined;
      let workDocumentType: WorkDocumentType | undefined;
      switch (documentsRedline.field) {
        case ProposalField.ClientPolicies:
          context = "Policy";
          workDocumentType = WorkDocumentType.ClientPolicy;
          break;
        case ProposalField.VendorPolicies:
          context = "Policy";
          workDocumentType = WorkDocumentType.VendorPolicy;
          break;
        case ProposalField.Conflicts:
          context = "Conflicts";
          workDocumentType = WorkDocumentType.Conflicts;
          break;
      }
      if (!workDocumentType) throw new Error("Work Document Type is missing");

      const service = new DocumentAPIService(session);
      const document = await service.createDocument(
        file,
        file.name,
        [
          new DocumentTopic(
            undefined,
            undefined,
            context
          ),
        ]
      );
      if (!(document instanceof Document)) {
        throw new Error("Failed to upload document");
      }
      const workDocument = WorkDocument.fromDocument(
        document,
        workDocumentType
      );
      onDocumentRedlineChange?.(documentsRedline.addEntry(workDocument));
      onCommentsClicked(
        documentsRedline.field,
        undefined
      );
      enqueueSnackbar(
        "Uploaded file",
        {variant: "success"}
      );
    } catch (error: any) {
      console.error(
        "Failed to upload file",
        error
      );
      if (error.response && error.response.status === 415) {
        enqueueSnackbar(
          error.response.data,
          {variant: "error"}
        );
      } else if (error.response && error.response.status === 422) {
        enqueueSnackbar(
          "Unsupported file type",
          {variant: "error"}
        );
      } else {
        enqueueSnackbar(
          "Unknown error occurred during upload",
          {
            variant: "error",
          }
        );
      }
    } finally {
      event.target.value = "";
      setIsUploading(false);
    }
  }

  function renderListHeader() {
    return (
      <>
        {workDocumentType === WorkDocumentType.Conflicts && (
          <span>
            Conflict Documents
            <Tooltip
              disableFocusListener
              title="Conflicts document(s) establish potential conflicts checks required by participating clients/vendors"
              placement="bottom"
              arrow
            >
              <IconButton size="large">
                <InfoIcon/>
              </IconButton>
            </Tooltip>
            <span>{renderListHeaderActions(WorkDocumentType.Conflicts)}</span>
          </span>
        )}
        {workDocumentType === WorkDocumentType.ClientPolicy && (
          <HeaderContainer>
            <span>
              Client Policies
              <Tooltip
                disableFocusListener
                title="Client policies establish the terms and conditions that the client requires for the vendor to adhere to"
                placement="bottom"
                arrow
              >
                <IconButton size="large">
                  <InfoIcon/>
                </IconButton>
              </Tooltip>
              <span>{renderListHeaderActions(WorkDocumentType.ClientPolicy)}</span>
            </span>
            <CommentsButton
              field={ProposalField.ClientPolicies}
              toolTip={commentsToolTip}
              commentThreads={commentThreads}
              onCommentsClicked={onCommentsClicked ? () => {
                onCommentsClicked(
                  ProposalField.ClientPolicies
                );
              } : undefined}
            />
          </HeaderContainer>
        )}
        {workDocumentType === WorkDocumentType.VendorPolicy && (
          <HeaderContainer>
            <span>
              Vendor Policies
              <Tooltip
                disableFocusListener
                title="Vendor policies establish the terms and conditions that the vendor requires for the client to adhere to"
                placement="bottom"
                arrow
              >
                <IconButton size="large">
                  <InfoIcon/>
                </IconButton>
              </Tooltip>
              <span>{renderListHeaderActions(WorkDocumentType.VendorPolicy)}</span>
            </span>
            <CommentsButton
              field={ProposalField.VendorPolicies}
              toolTip={commentsToolTip}
              commentThreads={commentThreads}
              onCommentsClicked={onCommentsClicked ? () => {
                onCommentsClicked(
                  ProposalField.VendorPolicies
                );
              } : undefined}
            />
          </HeaderContainer>
        )}
      </>
    );
  }

  function renderListHeaderActions(documentType: WorkDocumentType) {
    if (disableEditing) return null;
    return (
      <>
        {documentsRedline?.isResolved === false && (
          <>
            <Tooltip title="Accept All Changes">
              <span>
                <ActionButton
                  onClick={() => {
                    try {
                      onDocumentRedlineChange(documentsRedline.acceptAll());
                      onCommentsClicked(
                        documentsRedline.field,
                        undefined
                      );
                    } catch (error) {
                      console.error(
                        "Failed to accept changes",
                        error
                      );
                      enqueueSnackbar(
                        "Failed to accept changes",
                        {
                          variant: "error",
                        }
                      );
                    }
                  }}
                >
                  <CheckIcon color="success"/>
                </ActionButton>
              </span>
            </Tooltip>
            <Tooltip title="Reject All Changes">
              <span>
                <ActionButton
                  onClick={() => {
                    try {
                      onDocumentRedlineChange(documentsRedline.rejectAll());
                      onCommentsClicked(
                        documentsRedline.field,
                        undefined
                      );
                    } catch (error) {
                      console.error(
                        "Failed to reject changes",
                        error
                      );
                      enqueueSnackbar(
                        "Failed to reject changes",
                        {
                          variant: "error",
                        }
                      );
                    }
                  }}
                >
                  <CloseIcon color="error"/>
                </ActionButton>
              </span>
            </Tooltip>
          </>
        )}
        {documentsRedline?.redlines.some((r) => r.canBeUndone) && (
          <Tooltip title="Undo All">
            <span>
              <ActionButton
                onClick={() => {
                  try {
                    onDocumentRedlineChange(
                      documentsRedline.undoAll(),
                      null
                    );
                    onCommentsClicked(
                      documentsRedline.field,
                      undefined
                    );
                  } catch (error) {
                    console.error(
                      "Failed to undo changes",
                      error
                    );
                    enqueueSnackbar(
                      "Failed to undo changes",
                      {
                        variant: "error",
                      }
                    );
                  }
                }}
              >
                <UndoIcon/>
              </ActionButton>
            </span>
          </Tooltip>
        )}
      </>
    );
  }

  return (
    <ListContainer className={className}>
      <DocumentList>
        <ListSubhead>{renderListHeader()}</ListSubhead>
        {accountType === AccountType.Client && (
          <>
            {!session.context?.viewingAsVendor && !disableEditing && (
              <LoadingButton
                color="primary"
                loading={isUploading}
                startIcon={<UploadIcon/>}
                onClick={() => fileUploaderRef.current?.click()}
              >
                Upload Client Policy Document
              </LoadingButton>
            )}
            {documentsRedline.redlines.length === 0 && (
              <ListItem disableGutters>
                <NoRowsPlaceholder>None</NoRowsPlaceholder>
              </ListItem>
            )}
            {documentsRedline.redlines.map(
              (documentRedline: FieldRedline<WorkDocument>) =>
                renderDocumentRedline(
                  documentRedline
                )
            )}
          </>
        )}
        {accountType === AccountType.Vendor && (
          <>
            {session.context?.viewingAsVendor && (
              <LoadingButton
                color="primary"
                loading={isUploading}
                startIcon={<UploadIcon/>}
                onClick={() => fileUploaderRef.current?.click()}
              >
                Upload Vendor Policy Document
              </LoadingButton>
            )}
            {documentsRedline.redlines.length === 0 && (
              <ListItem disableGutters>
                <NoRowsPlaceholder>None</NoRowsPlaceholder>
              </ListItem>
            )}
            {documentsRedline.redlines.map(
              (document: FieldRedline<WorkDocument>) =>
                renderDocumentRedline(document)
            )}
          </>
        )}
        {documentsRedline.field === ProposalField.Conflicts && (
          <>
            {!disableEditing && (
              <LoadingButton
                color="primary"
                loading={isUploading}
                startIcon={<UploadIcon/>}
                onClick={() => fileUploaderRef.current?.click()}
              >
                Upload Conflicts Check Document
              </LoadingButton>
            )}
            {documentsRedline.redlines.length === 0 && (
              <ListItem disableGutters>
                <NoRowsPlaceholder>None</NoRowsPlaceholder>
              </ListItem>
            )}
            {documentsRedline.redlines.map(
              (document: FieldRedline<WorkDocument>) =>
                renderDocumentRedline(document)
            )}
          </>
        )}
      </DocumentList>
      <input
        type="file"
        hidden={true}
        ref={fileUploaderRef}
        onChange={handleFileUpload}
      />
    </ListContainer>
  );
}
