import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Button, Popover, TextField } from "@mui/material";
import Guid from "common/values/guid/guid";

const MessageField = styled(TextField)(({ theme }) => ({
  minWidth: theme.spacing(40),
  width: "100%"
}));
const ButtonContainer = styled("div")(({ theme }) => ({
  width: "100%"
}));

type ReviewerMessagePopoverProps = {
  className?: string;
  anchorEl: HTMLElement | null;
  userId?: Guid;
  message?: string;
  onCanceled: () => void;
  onMessageChanged: (userId?: Guid, message?: string) => void;
};

export default function ReviewerMessagePopover(props: Readonly<ReviewerMessagePopoverProps>) {
  const { 
    className, 
    anchorEl, 
    userId, 
    onCanceled, 
    onMessageChanged 
  } = props;
  
  const [message, setMessage] = React.useState<string>(props.message ?? '');
  
  useEffect(() => {
    setMessage(props.message ?? '');
  }, [props.message]);

  return (
    <Popover
      className={className}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
    >
      <MessageField
        placeholder="Message To Reviewer (Optional)"
        value={message}
        multiline
        minRows={3}
        onChange={event => setMessage(event.target.value)}
      />
      <ButtonContainer>
        <Button
          color="primary"
          onClick={() => onMessageChanged(userId, message)}
        >
          OK
        </Button>
        <Button
          color="primary"
          onClick={() => onCanceled()}
        >
          Cancel
        </Button>
      </ButtonContainer>
    </Popover>
  );
}
