import Address from "common/values/address/address";

export default class AddressAPIRequest {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  coordinates?: {
    "type": "Point",
    "coordinates": [number, number]
  };

  constructor(address: Address) {
    this.address1 = address.address1;
    this.address2 = address.address2;
    this.city = address.city;
    this.state = address.state;
    this.postalCode = address.postalCode;
    this.country = address.country;
    this.coordinates = address.coordinates ? {
      type: "Point",
      coordinates: [
        parseFloat(address.coordinates.latitude),
        parseFloat(address.coordinates.longitude)
      ]
    } : undefined;
  }
}
