import Guid from "common/values/guid/guid";

import axios, {AxiosHeaders, AxiosResponse, CanceledError} from "axios";
import AttorneyHubAPIService from "common/services/api/attorney-hub-api-service";
import Individual from "marketplace/entities/individual/individual";
import ForumSubscriberInvitationApiRequest
  from "messaging/entities/forum-invitations/api/request-contracts/forum-subscriber-invitation-api-request";
import CreateForumAPIRequest from "messaging/entities/forum/api/request-contracts/create-forum-api-request";
import ForumAPIResponse from "messaging/entities/forum/api/response-contracts/forum-api-response";
import MarketplaceSubscriberInfoAPIResponse
  from "messaging/entities/forum/api/response-contracts/marketplace-subscriber-info-api-response";
import Forum, {ForumDoesNotExistError} from "messaging/entities/forum/forum";
import CreateMessageAPIRequest from "messaging/entities/message/api/request-contracts/create-message-api-request";
import MessageAPIResponse from "messaging/entities/message/api/response-contracts/message-api-response";
import MessageCollectionAPIResponse
  from "messaging/entities/message/api/response-contracts/message-collection-api-response";
import Message from "messaging/entities/message/message";
import ForumInvitation, {ForumInvitationDoesNotExistError} from "messaging/entities/forum-invitations/forum-invitation";
import BulkMessageAPIRequest from "messaging/entities/message/api/request-contracts/bulk-message-api-request";
import Session from "users/session/session";

export default class MessagingAPIService {

  private readonly authHeaders: AxiosHeaders = new AxiosHeaders();

  private headersWithJson(): AxiosHeaders {
    return this.authHeaders.concat({'Content-Type': 'application/json'});
  }

  private headersWithFormData(): AxiosHeaders {
    return this.authHeaders.concat({'Content-Type': 'multipart/form-data'});
  }

  constructor(session: Readonly<Session>) {
    if (!session.authToken?.value)
      throw new Error("Session must have an authToken to create a MessagingAPIService");
    this.authHeaders.set(
      "Authorization",
      `Bearer ${session.authToken.value}`
    );
  }

  async getForums(
    entityClass?: string,
    entityId?: Guid,
    context?: string,
    abortController?: AbortController
  ): Promise<Forum[]> {
    let url = new URL(
      "/messaging/forums",
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      entityClass && url.searchParams.append(
        "entityClass",
        entityClass
      );
      entityId && url.searchParams.append(
        "entityId",
        entityId.value
      );
      context && url.searchParams.append(
        "context",
        context
      );
      const response = await axios.get(
        url.toString(),
        {
          headers: this.authHeaders,
          signal: abortController?.signal
        }
      );
      return response.data.map((forumData: Partial<ForumAPIResponse>) =>
        Object.assign(
          new ForumAPIResponse(),
          forumData
        ).deserialize()
      );
    } catch (error: any) {
      if (error.response?.status === 400)
        throw new ForumDoesNotExistError(error.response?.data);
      if (error instanceof CanceledError)
        throw error;
      throw new MessagingAPIServiceError(
        "getForums",
        error
      );
    }
  }

  async getMessagesByForum(forum: Forum, abortController?: AbortController): Promise<Message[]> {
    if (!forum.id) throw new MessagingAPIServiceError(
      "getMessagesByForum",
      "Forum must have an id to get messages"
    );
    const url = new URL(
      `/messaging/forums/${forum.id.value}/messages`,
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.authHeaders,
          signal: abortController?.signal
        }
      );
      const messageCollection = Object.assign(
        new MessageCollectionAPIResponse(),
        response.data
      );
      return messageCollection.deserialize();
    } catch (error: any) {
      if (error.response?.status === 400)
        throw new ForumDoesNotExistError(error.response.data);
      if (error instanceof CanceledError)
        throw error;
      throw new MessagingAPIServiceError(
        "getMessagesByForum",
        error
      );
    }
  }

  async getForumSubscriberInfo(forum: Forum, abortController?: AbortController): Promise<Individual[]> {
    if (!forum.id) throw new MessagingAPIServiceError(
      "getForumSubscriberInfo",
      "Forum must have an id to get subscribers"
    );
    const url = new URL(
      `/messaging/forums/${forum.id.value}/subscribers`,
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.authHeaders,
          signal: abortController?.signal
        }
      );
      const responseData: MarketplaceSubscriberInfoAPIResponse[] =
        response.data.map((info: Partial<MarketplaceSubscriberInfoAPIResponse>) => {
          return Object.assign(
            new MarketplaceSubscriberInfoAPIResponse(),
            info
          );
        });
      return responseData.map((info) => info.deserialize());
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new ForumDoesNotExistError(error.response.data);
      if (error instanceof CanceledError) {
        throw error;
      }
      throw new MessagingAPIServiceError(
        "getForumSubscriberInfo",
        error
      );
    }
  }

  async getMessageImages(message: Message, abortController?: AbortController): Promise<Blob[]> {
    if (!message.id || !message.forum?.id)
      throw new MessagingAPIServiceError(
        "getMessageImages",
        "Message must have an id and forum id to get images"
      );
    const url = new URL(
      `/messaging/forums/${message.forum.id.value}/messages/${message.id.value}/images`,
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.authHeaders,
          signal: abortController?.signal
        }
      );
      return response.data;
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new ForumDoesNotExistError(error.response.data);
      if (error instanceof CanceledError) {
        throw error;
      }
      throw new MessagingAPIServiceError(
        "getMessageImages",
        error
      );
    }
  }

  async getMessageAttachmentByIndex(
    message: Message, index: number, abortController: AbortController): Promise<AxiosResponse> {
    if (!message.id || !message.forum?.id) throw new MessagingAPIServiceError(
      "getMessageAttachmentByIndex",
      "Message must have an id and forum id to get attachments"
    );
    const url = new URL(
      `/messaging/forums/${message.forum.id.value}/messages/${message.id.value}/attachment/${index}`,
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      return await axios.get(
        url.toString(),
        {
          headers: this.authHeaders,
          responseType: 'blob',
          signal: abortController.signal
        }
      );
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new ForumDoesNotExistError(error.response.data);
      throw new MessagingAPIServiceError(
        "getMessageAttachmentByIndex",
        error
      );
    }
  }

  async createMessage(message: Message): Promise<Message> {
    if (!message.forum?.id) throw new MessagingAPIServiceError(
      "createMessage",
      "Message must have a forum id to be created"
    );
    const request = new CreateMessageAPIRequest(message);
    const url = new URL(
      `/messaging/forums/${message.forum.id.value}/messages`,
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      const response = await axios.post(
        url.toString(),
        request.formData,
        {
          headers: this.headersWithFormData()
        }
      );
      const responseData: MessageAPIResponse = Object.assign(
        new MessageAPIResponse(),
        response.data
      );
      return responseData.deserialize();
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new ForumDoesNotExistError(error.response.data);
      throw new MessagingAPIServiceError(
        "createMessage",
        error
      );
    }
  }

  async createBulkMessages(messages: Message[]): Promise<Message[]> {
    if (!messages.length) {
      console.warn("No messages to create");
      return messages;
    }
    const request = new BulkMessageAPIRequest(messages);
    const url = new URL(
      `/messaging/bulk-messages`,
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      const response = await axios.post(
        url.toString(),
        request.payload,
        {
          headers: this.headersWithJson()
        }
      );
      const responseData: MessageAPIResponse[] = response.data.map(
        (messageData: MessageAPIResponse) =>
          Object.assign(
            new MessageAPIResponse(),
            messageData
          )
      );
      return responseData.map((messageData) => messageData.deserialize());
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new ForumDoesNotExistError(error.response.data);
      throw new MessagingAPIServiceError(
        "createBulkMessages",
        error
      );
    }
  }

  async createForum(forum: Forum): Promise<Forum> {
    const request: CreateForumAPIRequest = new CreateForumAPIRequest(forum);
    const url = new URL(
      "/messaging/forums",
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      const response = await axios.post(
        url.toString(),
        request,
        {
          headers: this.headersWithJson()
        }
      );
      const responseData: ForumAPIResponse = Object.assign(
        new ForumAPIResponse(),
        response.data
      );
      return responseData.deserialize();
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new ForumDoesNotExistError(error.response.data);
      throw new MessagingAPIServiceError(
        "createForum",
        error
      );
    }
  }

  async updateForum(forum: Forum): Promise<Forum> {
    throw new MessagingAPIServiceError(
      "updateForum",
      "Method not implemented."
    );
  }

  async resolveForum(forum: Forum) {
    if (!forum.id) throw new MessagingAPIServiceError(
      "resolveForum",
      "Forum must have an id to be resolved"
    );
    const url = new URL(
      `/messaging/forums/${forum.id.value}/resolve`,
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      await axios.post(
        url.toString(),
        {},
        {
          headers: this.authHeaders
        }
      );
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new ForumDoesNotExistError(error.response.data);
      throw new MessagingAPIServiceError(
        "resolveForum",
        error
      );
    }
  }

  async reopenForum(forum: Forum) {
    if (!forum.id) throw new MessagingAPIServiceError(
      "reopenForum",
      "Forum must have an id to be reopened"
    );
    const url = new URL(
      `/messaging/forums/${forum.id.value}/reopen`,
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      await axios.post(
        url.toString(),
        {},
        {
          headers: this.authHeaders
        }
      );
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new ForumDoesNotExistError(error.response.data);
      throw new MessagingAPIServiceError(
        "reopenForum",
        error
      );
    }
  }

  async updateSubscribersForForum(forum: Forum) {
    if (!forum.id) throw new MessagingAPIServiceError(
      "updateSubscribersForForum",
      "Forum must have an id to update subscribers"
    );
    const url = new URL(
      `/messaging/forums/${forum.id.value}/subscribers`,
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      await axios.put(
        url.toString(),
        {
          subscribers: forum.subscriberIds.map((id) => id.value)
        },
        {
          headers: this.headersWithJson()
        }
      );
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new ForumDoesNotExistError(error.response.data);
      throw new MessagingAPIServiceError(
        "updateSubscribersForForum",
        error
      );
    }
  }

  async inviteSubscriberToForum(
    forum: Forum,
    invitee: Guid,
    invitationMessage?: string
  ) {
    if (!forum.id) throw new MessagingAPIServiceError(
      "inviteSubscriberToForum",
      "Forum must have an id to invite a subscriber"
    );
    const url = new URL(
      `/messaging/forums/${forum.id.value}/invite-subscriber`,
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      await axios.post(
        url.toString(),
        new ForumSubscriberInvitationApiRequest(
          invitee,
          invitationMessage
        ),
        {
          headers: this.headersWithJson()
        }
      );
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new ForumDoesNotExistError(error.response.data);
      throw new MessagingAPIServiceError(
        "inviteSubscriberToForum",
        error
      );
    }
  }

  async acceptForumInvitation(forumInvitation: ForumInvitation) {
    const url = new URL(
      `/messaging/forum-invitations/${forumInvitation.id.value}/accept`,
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      await axios.post(
        url.toString(),
        {},
        {
          headers: this.authHeaders
        }
      );
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new ForumInvitationDoesNotExistError(error.response.data);
      throw new MessagingAPIServiceError(
        "acceptForumInvitation",
        error
      );
    }
  }

  async deleteMessage(message: Message) {
    if (!message.id)
      throw new MessagingAPIServiceError(
        "deleteMessage",
        "Message must have an id to be deleted"
      );
    if (!message.forum?.id)
      throw new MessagingAPIServiceError(
        "deleteMessage",
        "Message must have a forum id to be deleted"
      );
    const url = new URL(
      `/messaging/forums/${message.forum.id.value}/messages/${message.id.value}`,
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      await axios.delete(
        url.toString(),
        {
          headers: this.authHeaders
        }
      );
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new MessageNotFoundError(error.response.data);
      throw new MessagingAPIServiceError(
        "deleteMessage",
        error
      );
    }
  }

  async deleteForum(forum: Forum) {
    if (!forum.id)
      throw new MessagingAPIServiceError(
        "deleteForum",
        "Forum must have an id to be deleted"
      );

    const url = new URL(
      `/messaging/forums/${forum.id.value}`,
      AttorneyHubAPIService.apiBaseUrl
    );
    try {
      await axios.delete(
        url.toString(),
        {
          headers: this.authHeaders
        }
      );
    } catch (error: any) {
      if (error.response?.status === 404)
        throw new ForumDoesNotExistError(error.response.data);
      throw new MessagingAPIServiceError(
        "deleteForum",
        error
      );
    }
  }
}

export class MessagingAPIServiceError extends Error {
  method: string;
  error: any;

  constructor(method: string, error: any) {
    super(`Error calling ${method}: ${error}`);
    this.method = method;
    this.error = error;
  }
}

export class MessageNotFoundError extends Error {
  constructor(messageId: Guid) {
    super(`Message ${messageId} not found`);
  }
}

export class AttachmentNotFoundError extends Error {
  constructor(url: string) {
    super(`Attachment(s) not found at ${url}`);
  }
}
