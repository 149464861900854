export default class PaginationParameters {
  pageIndex: number;
  pageSize: number;

  constructor(pageIndex: number, pageSize: number) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
  }

  public asSearchParams(): URLSearchParams {
    const params = new URLSearchParams();
    params.append(
      "pageIndex",
      this.pageIndex.toString()
    );
    params.append(
      "pageSize",
      this.pageSize.toString()
    );
    return params;
  }

  public isEqualTo(other: PaginationParameters | undefined): boolean {
    if (!other) {
      return false;
    }
    return this.pageIndex === other.pageIndex && this.pageSize === other.pageSize;
  }
}