import MessageIcon from "@mui/icons-material/Message";
import ShareIcon from "@mui/icons-material/Share";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Link,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useConfirmDialog } from "app/providers/confirm-dialog";
import { Hit } from "common/contracts/search-results";
import { MarketplaceResultType } from "common/helpers/constants";
import Guid from "common/values/guid/guid";
import MarketplaceSearchInfoAPIResponse from "marketplace/api/response-contracts/marketplace-search-info-api-response";
import CompanyAPIService from "marketplace/entities/company/api/company-api-service";
import { BookmarkType } from "marketplace/values/bookmark/bookmark";
import MarketplaceCompanyInfoAPIResponse from "marketplace/values/company-profile/api/response-contracts/marketplace-company-info-api-response";
import ViewCompanyProfile from "marketplace/values/company-profile/view/view-company-profile";
import BookmarkButton from "marketplace/view/bookmark-button";
import Forum from "messaging/entities/forum/forum";
import Chat from "messaging/entities/forum/view/components/chat";
import { enqueueSnackbar } from "notistack";
import React from "react";
import NetworkButton from "users/entities/user-network-connection/view/components/network-button";
import { useSession } from "users/session/session-context";

const ResultCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    display: "block",
  },
  display: "flex",
  flex: "1 0 auto",
  justifyContent: "space-between",
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  "&:first-of-type": {
    marginTop: 0,
  },
}));
const ResultHeader = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "grid",
  columnGap: theme.spacing(2),
  gridTemplateColumns: "auto 1fr",
}));
const ResultCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: "inherit",
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(3),
}));
const ResultCardActions = styled(CardActions)(({ theme }) => ({
  [theme.breakpoints.down("xl")]: {
    alignContent: "flex-end",
    alignItems: "unset",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.down("lg")]: {
    alignContent: "flex-start",
    alignItems: "unset",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(2),
  },
  alignContent: "center",
  alignItems: "flex-end",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "space-evenly",
  paddingRight: theme.spacing(3),
}));
const ResultName = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  marginRight: theme.spacing(1),
  textDecoration: "underline",
  "& em": {
    backgroundColor: "rgba(255, 255, 0, 0.25)",
    fontStyle: "normal",
  },
}));
const TypeChip = styled(Chip)(({ theme }) => ({
  borderColor: "#CA0",
  borderStyle: "dashed",
  color: "#CA0",
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(0.5),
  opacity: 0.66,
}));
const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2),
  minWidth: theme.spacing(30),
}));
const StyledNetworkButton = styled(NetworkButton)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2),
  minWidth: theme.spacing(30),
}));
const StyledBookmarkButton = styled(BookmarkButton)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2),
  minWidth: theme.spacing(30),
}));
const Subtitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));
const ChatCardContent = styled(CardContent)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  width: "100%",
}));
const Details = styled("div")(({ theme }) => ({
  [theme.breakpoints.up(1920)]: {
    columnCount: 2,
  },
  [theme.breakpoints.down(1920)]: {
    columnCount: 1,
  },
  [theme.breakpoints.down("xl")]: {
    columnCount: 2,
  },
  [theme.breakpoints.down("lg")]: {
    columnCount: 2,
  },
  [theme.breakpoints.down("md")]: {
    columnCount: 1,
  },
  columnGap: theme.spacing(2),
  columnFill: "balance",
  marginTop: theme.spacing(2),
  "& em": {
    backgroundColor: "rgba(255, 255, 0, 0.25)",
    fontStyle: "normal",
  },
}));
const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.black,
  cursor: "pointer",
  textDecoration: "underline",
})) as typeof Link;
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  cursor: "pointer",
  height: theme.spacing(10),
  width: theme.spacing(10),
}));
const ResultAvatarCardMedia = styled(CardMedia)(({ theme }) => ({
  cursor: "pointer",
  height: "auto",
  width: "15vw",
})) as typeof CardMedia;
const ResultAvatar = styled(Avatar)(({ theme }) => ({
  cursor: "pointer",
  height: "auto",
  width: "15vw",
}));
const GridRow = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  marginBottom: theme.spacing(1),
}));

export interface CompanyResultProps {
  hit: Hit<MarketplaceSearchInfoAPIResponse>;
  result: MarketplaceCompanyInfoAPIResponse;
  isNetworked: boolean;
  getText: (
    result: Hit<MarketplaceSearchInfoAPIResponse>,
    fieldNames: string[],
    base?: unknown,
    noCommas?: boolean
  ) => { __html: string | TrustedHTML };
  handleResultClicked: (
    event: React.MouseEvent,
    profileId: Guid,
    profileType: MarketplaceResultType,
    component: React.JSX.Element,
    fullWidth: boolean
  ) => void;
}

export default function CompanyResult(props: Readonly<CompanyResultProps>) {
  const { hit, result, isNetworked, getText, handleResultClicked } = props;

  const [avatar, setAvatar] = React.useState<string>();
  const [showChat, setShowChat] = React.useState<boolean>(false);
  const [forum, setForum] = React.useState<Forum>();

  const isXlDownDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl")
  );
  const isXlUpDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("xl")
  );
  const confirm = useConfirmDialog();
  const session = useSession();

  const companyProfile = result?.deserializeToProfile();

  const contactPersonProfileLink = getProfileLink(
    ["firstName", "lastName"],
    result?.contact,
    result?.contact?.id,
    true
  );

  React.useEffect(() => {
    async function loadAvatar() {
      if (!result?.id) return;
      try {
        const companyId = new Guid(result?.id);
        const service = new CompanyAPIService(session);
        const returnedAvatar = await service.getCompanyAvatar(
          companyId,
          new AbortController()
        );
        setAvatar(returnedAvatar);
      } catch (error: any) {
        console.error(error);
      }
    }

    if (result?.avatar) loadAvatar();
  }, [result]);

  async function contactCompany() {
    if (!result?.id) return;
    try {
      const companyId = new Guid(result?.id);
      const service = new CompanyAPIService(session);
      const forum = await service.contactCompany(companyId);
      setForum(forum);
      setShowChat(true);
    } catch (error: any) {
      console.error(error);
    }
  }

  function getProfileLink(
    fields: string[],
    base: unknown,
    profileId: string | undefined,
    noCommas: boolean = false,
    secondary: boolean = false
  ) {
    if (!profileId) return null;
    return (
      <StyledLink
        style={{
          fontSize: secondary ? "0.85em" : "1em",
          opacity: secondary ? 0.6 : 1,
        }}
        component={Typography}
        dangerouslySetInnerHTML={getText(hit, fields, base, noCommas)}
        onClick={(event) => openProfile(event, profileId)}
      />
    );
  }

  function openProfile(event: React.MouseEvent, id: string | undefined) {
    if (!id) return;
    const profileId = new Guid(id);
    handleResultClicked(
      event,
      profileId,
      MarketplaceResultType.Company,
      <ViewCompanyProfile companyId={profileId} confirmDialog={confirm} />,
      true
    );
  }

  return (
    <React.Fragment>
      {showChat && (
        <ResultCard raised={false} variant="outlined">
          <ChatCardContent>
            <Chat
              forums={forum ? [forum] : []}
              autoFocus={true}
              onClose={() => setShowChat(false)}
            />
          </ChatCardContent>
        </ResultCard>
      )}
      {!showChat && (
        <ResultCard raised={false} variant="outlined">
          {isXlUpDisplaySize && (
            <>
              <ResultAvatarCardMedia
                style={{ display: !avatar ? "none" : undefined }}
                component="img"
                image={avatar}
                onClick={(event) => openProfile(event, result?.id)}
              />
              <ResultAvatar
                style={{ display: avatar ? "none" : undefined }}
                variant="square"
                onClick={(event) => openProfile(event, result?.id)}
              />
            </>
          )}
          <ResultCardContent>
            <ResultHeader>
              {isXlDownDisplaySize && (
                <span>
                  <SmallAvatar
                    style={{ display: !avatar ? "none" : undefined }}
                    variant="circular"
                    src={avatar}
                    onClick={(event) => openProfile(event, result?.id)}
                  />
                  <SmallAvatar
                    style={{ display: avatar ? "none" : undefined }}
                    variant="circular"
                    onClick={(event) => openProfile(event, result?.id)}
                  />
                </span>
              )}
              <span>
                <ResultName
                  variant="h5"
                  dangerouslySetInnerHTML={getText(hit, ["name"], result, true)}
                  onClick={(event) => openProfile(event, result?.id)}
                />
                <TypeChip label="Company" variant="outlined" size="small" />
              </span>
            </ResultHeader>
            <Details>
              {result?.description && (
                <GridRow>
                  <Subtitle variant="body1">Who We Are:</Subtitle>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: (() => {
                      const description = getText(hit, ["description"]).__html as string;
                      return description.length > 250
                        ? `${description.substring(0, 250)}...`
                        : description;
                      })(),
                    }}
                  />
                </GridRow>
              )}
              {result?.contact?.firstName && result?.contact?.lastName && (
                <GridRow>
                  <Subtitle variant="body1">Contact Person:</Subtitle>
                  {contactPersonProfileLink}
                </GridRow>
              )}
            </Details>
          </ResultCardContent>
          <ResultCardActions disableSpacing>
            {companyProfile.contactUserId && (
              <StyledNetworkButton userId={companyProfile.contactUserId} />
            )}
            <StyledBookmarkButton
              type={BookmarkType.Company}
              vendorId={result?.id ? new Guid(result?.id) : undefined}
            />
            <Tooltip
              title={
                !isNetworked
                  ? "You must be in a company's network before contacting them"
                  : ""
              }
            >
              <div style={{ display: "contents", width: "100%" }}>
                <StyledButton
                  startIcon={<MessageIcon />}
                  variant="outlined"
                  disabled={!isNetworked}
                  onClick={contactCompany}
                >
                  Send Message
                </StyledButton>
              </div>
            </Tooltip>
            <StyledButton
              aria-label="share"
              variant="outlined"
              startIcon={<ShareIcon />}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/marketplace/company/${result?.id}`
                );
                enqueueSnackbar("Link copied to clipboard", {
                  variant: "info",
                });
              }}
            >
              Share
            </StyledButton>
          </ResultCardActions>
        </ResultCard>
      )}
    </React.Fragment>
  );
}
