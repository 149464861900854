import DetailedWorkClientRepresentativeAPIResponse
  from "work/entities/entity-client-representative/api/response-contracts/detailed-work-client-representative-api-response";
import DetailedWorkTeamAPIResponse from "work/values/team/api/response-contracts/detailed-work-team-api-response";
import WorkProposalAPIResponse, {
  InvalidProposalAPIResponse,
} from "work/entities/proposal/api/response-contracts/work-proposal-api-response";
import Proposal, {IProposalStore, ProposalDetails,} from "work/entities/proposal/proposal";
import Guid from "common/values/guid/guid";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";
import User from "users/entities/user/user";
import Date from "common/values/date/date";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import moment from "moment";

export default class DetailedWorkProposalAPIResponse extends WorkProposalAPIResponse {
  declare client?: DetailedWorkClientRepresentativeAPIResponse;
  declare supersedes?: WorkProposalAPIResponse;
  declare supersededById?: string;
  declare partyRedliningJSON?: string;
  declare partyRedliningUpdated?: string;
  declare userBuilderJSON?: string;
  declare userBuilderUpdated?: string;
  declare userRedliningJSON?: string;
  declare userRedliningUpdated?: string;
  declare team?: DetailedWorkTeamAPIResponse;

  deserializeDetailed(
    apiService: IProposalStore,
    currentUser: User | null,
    withRedline: boolean = true
  ): {
    proposal: Proposal,
    userProposalBuilder?: ProposalBuilder,
    userRedlining?: ProposalRedline
  } {
    if (!currentUser) throw new Error("Current user not provided.");
    const undetailedProposal = super.deserialize(
      apiService,
      currentUser,
      this.team
        ? Object.assign(
          new DetailedWorkTeamAPIResponse(),
          this.team
        ).deserializeDetailed()
        : undefined,
      withRedline
    );
    if (!this.client) {
      throw new InvalidProposalAPIResponse("Client not returned by api.");
    }
    const client = Object.assign(
      new DetailedWorkClientRepresentativeAPIResponse(),
      this.client
    );
    const clientDetails = client.deserialize();
    if (!clientDetails.company?.entityId)
      throw new InvalidProposalAPIResponse(
        "Client entity id not returned by api."
      );

    const partyRedlining = this.partyRedliningJSON ?
      ProposalRedline.fromJSON(this.partyRedliningJSON) : undefined;
    partyRedlining?.clearSessionHistory();
    const partyRedliningUpdated = this.partyRedliningUpdated ?
      new Date(moment(this.partyRedliningUpdated)) : undefined;
    let supersedes: Proposal | undefined = undefined;
    if (this.supersedes) {
      const supersedesData = Object.assign(
        new DetailedWorkProposalAPIResponse(),
        this.supersedes
      ).deserializeDetailed(
        apiService,
        currentUser,
        false
      );
      supersedes = supersedesData.proposal;
    }

    const detailedTeam = Object.assign(
      new DetailedWorkTeamAPIResponse(),
      this.team
    ).deserializeDetailed();
    const details: ProposalDetails = {
      client: clientDetails,
      team: detailedTeam,
      redlining: partyRedlining,
      redliningUpdated: partyRedliningUpdated,
      supersedes: supersedes,
      supersededById: this.supersededById
        ? new Guid(this.supersededById)
        : undefined,
    };

    const detailedProposal = new Proposal(
      apiService,
      currentUser,
      undetailedProposal.spec,
      undefined,
      undetailedProposal.metaInfo,
      details,
      true,
      withRedline
    );

    const userRedlining = this.userRedliningJSON ? ProposalRedline.fromJSON(this.userRedliningJSON) : undefined;
    if (userRedlining) {
      userRedlining.lastUpdated = new Date(moment(this.userRedliningUpdated));
    }
    const userBuilder = this.userBuilderJSON ? ProposalBuilder.fromJSON(this.userBuilderJSON) : undefined;
    userBuilder?.setLastSavedIndex();
    return {
      proposal: detailedProposal,
      userRedlining: userRedlining,
      userProposalBuilder: userBuilder,
    };
  }
}
