import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SaveIcon from "@mui/icons-material/Save";
import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Checkbox,
  Chip,
  FormControlLabel,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import LoadingButton from "common/components/loading-button";
import UrlList from "common/components/url-list";
import * as Constants from "common/helpers/constants";
import {Currency} from "common/values/currency/currency";
import EmailAddress from "common/values/email-address/email-address";
import Guid from "common/values/guid/guid";
import PhoneNumber from 'common/values/phone-number/phone-number';
import {E164Number} from 'libphonenumber-js/core';
import IndividualAPIService from "marketplace/entities/individual/api/individual-api-service";
import Individual from "marketplace/entities/individual/individual";
import BillingRateInfo from "marketplace/values/billing-rate-info/billing-rate-info";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";
import IndividualProfileCategories from "marketplace/values/individual-profile/view/individual-profile-categories";
import IndividualProfileEditName from "marketplace/values/individual-profile/view/individual-profile-edit-name";
import InsuranceInfo from "marketplace/values/insurance-info/insurance-info";
import RegistrationNumber from "marketplace/values/registration-number/registration-number";
import TechnicalBackgroundInfo from "marketplace/values/technical-background-info/technical-background-info";
import AvatarUpload from "marketplace/view/avatar-upload";
import ResumeUpload from "marketplace/view/resume-upload";
import {enqueueSnackbar} from "notistack";
import {forwardRef, useCallback, useEffect, useState} from "react";
import {NumberFormatValues, NumericFormat} from "react-number-format";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {useSession} from "users/session/session-context";

const MainContainer = styled("div")(
  () => ({
    display: "grid",
    gridTemplateRows: "repeat(4, auto)",
    width: "100%",
  }));
const HeaderContainer = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      "& > *": {
        margin: 0,
        padding: theme.spacing(
          0,
          0,
          3,
          0
        ),
        width: "100%",
      },
      "&:not(last-child)": {
        paddingBottom: theme.spacing(0.5),
      },
    },
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "row",
    gridColumn: "1 / auto",
    gridRowStart: "1",
    justifyContent: "center",
    padding: theme.spacing(
      3,
      0,
      4,
      0
    ),
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 10,
  }));
const ContentContainer = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down("sm")]: {
      columnCount: 1,
    },
    [theme.breakpoints.up("md")]: {
      columnCount: 2,
    },
    [theme.breakpoints.up("lg")]: {
      columnCount: 3,
    },
    columnFill: "balance",
    columnGap: theme.spacing(4),
    gridColumn: "1 / auto",
    gridRowStart: "2",
    paddingTop: theme.spacing(0.75),
  }));
const FieldContainer = styled("div")(
  () => ({
    display: "inline-block",
    width: "100%",
  }));
const CheckboxContainer = styled("div")(
  ({theme}) => ({
    display: "inline-block",
    marginBottom: theme.spacing(2),
    width: "100%",
  }));
const GridContainer = styled("div")(
  ({theme}) => ({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: theme.spacing(2),
  }));
const AboutYou = styled('section')(
  ({theme}) => ({
    gridColumn: '1 / span 3',
    gridRowStart: '3',
    marginTop: theme.spacing(1)
  }));
const AboutYouTextField = styled(TextField)(
  () => ({
    width: '100%'
  }));
const AvatarNameContainer = styled("div")(
  () => ({
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  }));
const StyledTextField = styled(TextField)(
  ({theme}) => ({
    marginBottom: theme.spacing(2),
    width: "100%",
  }));
const StyledAutocomplete = styled(Autocomplete)(
  ({theme}) => ({
    marginBottom: theme.spacing(2),
    width: "100%",
  }));
const InsuredAmountField = styled(NumericFormat)<TextFieldProps>(
  ({theme}) => ({
    marginBottom: theme.spacing(2),
  })
) as typeof NumericFormat;
const BillingRateField = styled(NumericFormat)<TextFieldProps>(
  ({theme}) => ({
    marginBottom: theme.spacing(2),
  })) as typeof NumericFormat;
const BillingUnitField = styled(TextField)(
  ({theme}) => ({
    marginBottom: theme.spacing(2),
    width: "100%",
  }));
const ContactInfoContainer = styled("div")(
  ({theme}) => ({
    alignItems: "center",
    display: "inline-flex",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
    width: "100%",
  }));
const ContactDetails = styled("div")(
  () => ({
    flex: 1,
    width: "100%",
  }));
const PhoneField = styled(PhoneInput)(
  ({theme}) => ({
    marginBottom: theme.spacing(2),
    width: "100%",
  }));
const EmailField = styled(TextField)(
  () => ({
    width: "100%",
  }));
const OnCallCheckbox = styled(FormControlLabel)(
  () => ({
    margin: 0,
    position: "relative",
  }));
const HelpIcon = styled(HelpOutlineIcon)(
  ({theme}) => ({
    color: "rgba(0, 0, 0, 0.5)",
    cursor: "help",
    height: "25px",
    marginLeft: theme.spacing(0.75),
    width: "25px",
  }));
const TitleContainer = styled("div")(
  () => ({
    alignItems: "center",
    display: "flex",
  }));
const ButtonContainer = styled("div")(
  ({theme}) => ({
    backgroundColor: theme.palette.background.paper,
    bottom: 0,
    gridColumn: "1",
    gridRowStart: "4",
    justifySelf: "flex-start",
    padding: theme.spacing(
      2,
      0
    ),
    position: "sticky",
    width: "100%",
    zIndex: 10,
  }));

const TextFieldComponent = forwardRef((props, ref) => (
  <TextField {...props} inputRef={ref}/>
));

export interface EditIndividualProfileProps {
  individualId?: Guid;
  initialProfile?: IndividualProfile;
  onFormDirty?: (isDirty?: boolean) => void;
  onSave?: (profile: IndividualProfile) => void;
}

export default function EditIndividualProfile(props: Readonly<EditIndividualProfileProps>) {
  const {
    onFormDirty,
    onSave
  } = props;

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [individualId, setIndividualId] = useState<Guid | undefined>(
    props.individualId
  );
  const [currentProfile, setCurrentProfile] = useState<
    IndividualProfile | undefined
  >(props.initialProfile);
  const [updatedProfile, setUpdatedProfile] = useState<
    IndividualProfile | undefined
  >(props.initialProfile);
  const [avatar, setAvatar] = useState<File | string>();
  const [resume, setResume] = useState<File | Guid | undefined>();

  const [formEmail, setFormEmail] = useState<string>("");
  const [formPhoneNumber, setFormPhoneNumber] = useState<E164Number | undefined>();
  const [formSchool, setFormSchool] = useState<string>("");
  const [formDegree, setFormDegree] = useState<string>("");
  const [formRegionsRegistered, setFormRegionsRegistered] = useState<string[]>([]);
  const [formRegionsLicensed, setFormRegionsLicensed] = useState<string[]>([]);
  const [
    formNumberOfApplicationsDraftedProsecuted,
    setFormNumberOfApplicationsDraftedProsecuted
  ] = useState<string>("");
  const [
    formNumberOfAllowedApplications,
    setFormNumberOfAllowedApplications
  ] = useState<string>("");
  const [formBarAdmissionYear, setFormBarAdmissionYear] = useState<string>("");
  const [formProfessionalRegistration, setFormProfessionalRegistration] = useState<string>("");
  const [formTechnicalBackground, setFormTechnicalBackground] = useState<string>("");
  const [formProfessionalPublications, setFormProfessionalPublications] = useState<string[]>([]);
  const [formSampleApplications, setFormSampleApplications] = useState<string[]>([]);
  const [formLinks, setFormLinks] = useState<string[]>([]);
  const [formTechnicalLanguages, setFormTechnicalLanguages] = useState<string[]>([]);
  const [formFluentLanguages, setFormFluentLanguages] = useState<string[]>([]);
  const [formInsuredAmount, setFormInsuredAmount] = useState<number>(0);
  const [formBillingRate, setFormBillingRate] = useState<string>("");
  const [formBillingUnit, setFormBillingUnit] = useState<string>("");
  const [formValidated, setFormValidated] = useState(false);
  const [profileHash, setProfileHash] = useState<string>("");

  const session = useSession();

  const validateProfile = (profile: IndividualProfile) => {
    if (!profile) return;
    const hasCategories: boolean = profile.categories?.length > 0;
    const hasName: boolean =
      profile.firstName.length > 0 && profile.lastName.length > 0;
    setFormValidated(hasCategories && hasName);
  };

  const dirty = (): boolean => {
    if (!updatedProfile) {
      onFormDirty?.(false);
      return false;
    }
    const hash = JSON.stringify(updatedProfile.toJSON());
    const isDirty = hash !== profileHash;
    onFormDirty?.(isDirty);
    return isDirty;
  }

  const canSave = (): boolean =>
    !loading &&
    !saving &&
    dirty() &&
    formValidated &&
    (formEmail.length === 0 || EmailAddress.isValid(formEmail.toString()));

  const loadProfile = useCallback(
    async () => {
      let abortController = new AbortController();
      try {
        setLoading(true);

        if (!individualId) return;

        const service = new IndividualAPIService(session);
        const returnedIndividual = await service.getIndividualById(
          individualId,
          abortController
        );

        if (!returnedIndividual)
          throw new Error("Individual not found");
        if (!returnedIndividual.profile)
          throw new Error("Individual profile not found");

        const hash = JSON.stringify(returnedIndividual.profile.toJSON());
        setProfileHash(hash);

        setCurrentProfile(returnedIndividual.profile);
        setFormValues(returnedIndividual.profile);
        if (returnedIndividual.profile.avatarId) {
          const returnedAvatar = await service.getIndividualAvatar(
            individualId,
            abortController
          );
          setAvatar(returnedAvatar);
        }
        if (returnedIndividual.profile.resumeId) {
          setResume(returnedIndividual.profile.resumeId);
        }

        setUpdatedProfile(returnedIndividual.profile);
        validateProfile(returnedIndividual.profile);
      } catch (error: any) {
        console.error(error);
        enqueueSnackbar(
          "Unable to load profile",
          {variant: "error"}
        );
      } finally {
        setLoading(false);
      }
      return () => {
        abortController.abort();
        abortController = new AbortController();
      };
    },
    [individualId]
  );

  function setFormValues(profile: IndividualProfile) {
    setFormEmail(profile.email?.value ?? '');
    setFormPhoneNumber(profile.phoneNumber?.toE164Number());
    setFormSchool(profile.school ?? '');
    setFormDegree(profile.degree ?? '');
    setFormProfessionalRegistration(profile.professionalRegistration?.value ?? '');
    setFormTechnicalBackground(profile.technicalBackground?.value ?? '');
    setFormProfessionalPublications(profile.professionalPublications ?? []);
    setFormLinks(profile.links ?? []);
    setFormTechnicalLanguages(profile.technicalLanguages ?? []);
    setFormFluentLanguages(profile.fluentLanguages ?? []);
    setFormRegionsRegistered(profile.regionsRegistered ?? []);
    setFormRegionsLicensed(profile.regionsLicensed ?? []);
    setFormNumberOfApplicationsDraftedProsecuted(profile.numberOfApplicationsDraftedProsecuted?.toString() ?? '');
    setFormNumberOfAllowedApplications(profile.numberOfAllowedApplications?.toString() ?? '');
    setFormBarAdmissionYear(profile.barAdmissionYear?.toString() ?? '');
    setFormInsuredAmount(profile.insured?.insuredAmount ?? 0);
    setFormBillingRate(profile.billingRate?.billingRate ?? '');
    setFormBillingUnit(profile.billingRate?.billingUnit ?? '');
    setFormSampleApplications(profile.sampleApplications ?? []);
  }

  useEffect(
    () => {
      // Creating a new profile
      if (!currentProfile && !individualId) {
        const newProfile = new IndividualProfile(
          session.user?.id,
          "New",
          "Profile"
        );
        setCurrentProfile(newProfile);
        setUpdatedProfile(newProfile);
        return;
      }
      // Loading existing profile
      loadProfile();
    },
    []
  );

  function constructIndividual(): Individual {
    if (!session.user?.id) throw new Error("User not found");
    if (!updatedProfile) throw new Error("Individual profile not found");
    return new Individual(
      individualId ?? Guid.generate(),
      session.user?.id,
      updatedProfile
    );
  }

  async function handleSaveButtonClick() {
    if (individualId) await updateProfile();
    else await createProfile();
  }

  async function updateProfile() {
    if (!individualId || !currentProfile || !updatedProfile) return;

    try {
      setSaving(true);
      const service = new IndividualAPIService(session);
      const returnedProfile =
        await service.updateIndividualProfile(
          individualId,
          currentProfile,
          updatedProfile
        );
      setCurrentProfile(returnedProfile);
      setUpdatedProfile(returnedProfile);
      enqueueSnackbar(
        "Profile changes saved",
        {variant: "success"}
      );
      onSave?.(returnedProfile);
    } catch (error: any) {
      console.error(error);
      enqueueSnackbar(
        "Unable to update profile",
        {variant: "error"}
      );
    } finally {
      setSaving(false);
    }
  }

  async function createProfile() {
    try {
      setSaving(true);
      const service = new IndividualAPIService(session);
      const individualToCreate = constructIndividual();
      const individual = await service.createIndividual(
        individualToCreate
      );
      setIndividualId(individual.id);
      setUpdatedProfile(individual.profile);
      if (avatar) await createAvatar(
        individual.id,
        avatar as File
      );
      if (resume) await createResume(
        individual.id,
        resume as File
      );
      const updatedProfileHash = JSON.stringify(individual.profile?.toJSON());
      setProfileHash(updatedProfileHash);
    } catch (error: any) {
      console.error(
        `There was a problem creating the individual profile: ${error}`
      );
      enqueueSnackbar(
        `Problem while creating profile ${error.message}`,
        {
          variant: "error",
        }
      );
    } finally {
      setSaving(false);
    }
  }

  async function createAvatar(individualId: Guid, avatar: File) {
    if (!individualId || !avatar) return;

    try {
      const service = new IndividualAPIService(session);
      await service.createIndividualAvatar(
        individualId,
        avatar
      );
    } catch (error: any) {
      console.error(error);
      throw new Error("Unable to create avatar");
    }
  }

  async function createResume(individualId: Guid, resume: File) {
    if (!individualId || !resume) return;

    try {
      const service = new IndividualAPIService(session);
      await service.createIndividualResume(
        individualId,
        resume
      );
    } catch (error: any) {
      console.error(error);
      throw new Error("Unable to create resume");
    }
  }

  function getTags(
    value: unknown[],
    getTagProps: AutocompleteRenderGetTagProps
  ) {
    return value.map((option, index) => (
      <Chip {...getTagProps({index})} key={option as string} label={option as string}/>
    ));
  }

  function hideIfNotIPAttorney() {
    return {
      display: updatedProfile?.categories?.includes("IPAttorney")
        ? "inline-flex"
        : "none",
    };
  }

  return (
    <MainContainer>
      <HeaderContainer>
        <AvatarNameContainer>
          <AvatarUpload
            accept="image/jpeg, image/png"
            id={individualId}
            type="individual"
            avatar={avatar}
            onUpload={(updatedAvatar) => setAvatar(updatedAvatar)}
            session={session}
          />
          <IndividualProfileEditName
            firstName={updatedProfile?.firstName ?? ""}
            lastName={updatedProfile?.lastName ?? ""}
            onFirstNameChange={(firstName: string) => {
              if (!updatedProfile) return;
              if (firstName === updatedProfile.firstName) return;
              let updatedProfileClone = updatedProfile.clone();
              updatedProfileClone.firstName = firstName;
              setUpdatedProfile(updatedProfileClone);
              validateProfile(updatedProfileClone);
            }}
            onLastNameChange={(lastName: string) => {
              if (!updatedProfile) return;
              if (lastName === updatedProfile.lastName) return;
              let updatedProfileClone = updatedProfile.clone();
              updatedProfileClone.lastName = lastName;
              setUpdatedProfile(updatedProfileClone);
              validateProfile(updatedProfileClone);
            }}
          />
        </AvatarNameContainer>
        <IndividualProfileCategories
          categories={updatedProfile?.categories ?? []}
          onChange={async (updatedCategories: string[]) => {
            if (!updatedProfile) return;
            if (updatedCategories === updatedProfile.categories) return;
            let updatedProfileClone = updatedProfile.clone();
            updatedProfileClone.categories = updatedCategories;
            setUpdatedProfile(updatedProfileClone);
            validateProfile(updatedProfileClone);
          }}
        />
      </HeaderContainer>
      <ContentContainer>
        <StyledTextField
          id="registrationNumber"
          variant="outlined"
          size="small"
          sx={hideIfNotIPAttorney()}
          label="Professional Registration #"
          value={formProfessionalRegistration}
          onChange={(event) => {
            setFormProfessionalRegistration(event.target.value);
            if (!updatedProfile) return;
            const registrationNumberValue = new RegistrationNumber(event.target.value);
            if (registrationNumberValue === updatedProfile.professionalRegistration)
              return;
            let updatedProfileClone = updatedProfile.clone();
            updatedProfileClone.professionalRegistration = registrationNumberValue;
            setUpdatedProfile(updatedProfileClone);
          }}
        />
        <StyledAutocomplete
          id="patentRegions"
          multiple
          size="small"
          sx={hideIfNotIPAttorney()}
          value={formRegionsRegistered ?? []}
          options={Constants.countries.map((x) => x.name)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Regions Registered"
            />
          )}
          renderTags={getTags}
          onChange={(_event, value, _reason) => {
            setFormRegionsRegistered(value as string[]);
            if (!updatedProfile) return;
            if (value === updatedProfile.regionsRegistered) return;
            let updatedProfileClone = updatedProfile.clone();
            updatedProfileClone.regionsRegistered = value as string[];
            setUpdatedProfile(updatedProfileClone);
          }}
        />
        <StyledTextField
          id="technicalBackground"
          variant="outlined"
          size="small"
          label="Technical Background"
          value={formTechnicalBackground ?? ""}
          slotProps={{
            inputLabel: {
              shrink: formTechnicalBackground !== ""
            }
          }}
          onChange={(event) => {
            setFormTechnicalBackground(event.target.value);
            if (!updatedProfile) return;
            const technicalBackgroundValue = new TechnicalBackgroundInfo(event.target.value);
            if (technicalBackgroundValue === updatedProfile.technicalBackground) return;
            const updatedProfileClone = updatedProfile.clone();
            updatedProfileClone.technicalBackground = technicalBackgroundValue;
            setUpdatedProfile(updatedProfileClone);
          }}
        />
        <StyledTextField
          id="school"
          variant="outlined"
          size="small"
          label="Undergraduate School / Law School"
          value={formSchool ?? ""}
          slotProps={{
            inputLabel: {
              shrink: Boolean(updatedProfile?.school)
            }
          }}
          onChange={(event) => {
            setFormSchool(event.target.value);
            if (!updatedProfile) return;
            if (event.target.value === updatedProfile.school) return;
            let updatedProfileClone = updatedProfile.clone();
            updatedProfileClone.school = event.target.value;
            setUpdatedProfile(updatedProfileClone);
          }}
        />
        <StyledTextField
          id="degree"
          variant="outlined"
          size="small"
          label="Degree(s)"
          value={formDegree ?? ""}
          slotProps={{
            inputLabel: {
              shrink: Boolean(updatedProfile?.degree)
            }
          }}
          onChange={(event) => {
            setFormDegree(event.target.value);
            if (!updatedProfile) return;
            if (event.target.value === updatedProfile.degree) return;
            let updatedProfileClone = updatedProfile.clone();
            updatedProfileClone.degree = event.target.value;
            setUpdatedProfile(updatedProfileClone);
          }}
        />
        <StyledTextField
          id="numberDrafted"
          variant="outlined"
          type="number"
          size="small"
          sx={hideIfNotIPAttorney()}
          label="Number Applications Drafted / Prosecuted"
          slotProps={{
            inputLabel: {
              shrink: formNumberOfApplicationsDraftedProsecuted !== ''
            }
          }}
          value={formNumberOfApplicationsDraftedProsecuted ?? ''}
          onChange={(event) => {
            setFormNumberOfApplicationsDraftedProsecuted(event.target.value);
            if (!updatedProfile) return;
            let newValue: number | undefined;
            if (event.target.value !== '') {
              newValue = parseInt(event.target.value);
            }
            if (
              newValue === updatedProfile.numberOfApplicationsDraftedProsecuted
            )
              return;
            let updatedProfileClone = updatedProfile.clone();
            updatedProfileClone.numberOfApplicationsDraftedProsecuted =
              newValue;
            setUpdatedProfile(updatedProfileClone);
          }}
        />
        <StyledTextField
          id="numberAllowedApplications"
          variant="outlined"
          type="number"
          size="small"
          sx={hideIfNotIPAttorney()}
          label="Number Allowed Applications"
          slotProps={{
            inputLabel: {
              shrink: formNumberOfAllowedApplications !== ''
            }
          }}
          value={formNumberOfAllowedApplications ?? ''}
          onChange={(event) => {
            setFormNumberOfAllowedApplications(event.target.value);
            if (!updatedProfile) return;
            let newValue: number | undefined;
            if (event.target.value !== '') {
              newValue = parseInt(event.target.value);
            }
            if (newValue === updatedProfile.numberOfAllowedApplications) return;
            let updatedProfileClone = updatedProfile.clone();
            updatedProfileClone.numberOfAllowedApplications = newValue;
            setUpdatedProfile(updatedProfileClone);
          }}
        />
        <StyledTextField
          id="yearAdmitted"
          variant="outlined"
          size="small"
          sx={hideIfNotIPAttorney()}
          type="number"
          label="Year Of Bar Admission"
          slotProps={{
            inputLabel: {
              shrink: formBarAdmissionYear !== ''
            }
          }}
          value={formBarAdmissionYear ?? ''}
          onChange={(event) => {
            setFormBarAdmissionYear(event.target.value);
            if (!updatedProfile) return;
            let newValue: number | undefined;
            if (event.target.value !== '') {
              newValue = parseInt(event.target.value);
            }
            if (newValue === updatedProfile.barAdmissionYear) return;
            let updatedProfileClone = updatedProfile.clone();
            updatedProfileClone.barAdmissionYear = newValue;
            setUpdatedProfile(updatedProfileClone);
          }}
        />
        <StyledAutocomplete
          id="fluentLanguages"
          multiple
          size="small"
          onChange={(_event, value, _reason) => {
            setFormFluentLanguages(value as string[]);
            if (!updatedProfile) return;
            if (value === updatedProfile.fluentLanguages) return;
            let updatedProfileClone = updatedProfile.clone();
            updatedProfileClone.fluentLanguages = value as string[];
            setUpdatedProfile(updatedProfileClone);
          }}
          value={formFluentLanguages ?? []}
          options={Constants.languages.map((option) => option.name)}
          renderTags={getTags}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Fluent Languages"
            />
          )}
        />
        <StyledAutocomplete
          id="technicalLanguages"
          size="small"
          multiple
          onChange={(_event, value, _reason) => {
            setFormTechnicalLanguages(value as string[]);
            if (!updatedProfile) return;
            if (value === updatedProfile.technicalLanguages) return;
            let updatedProfileClone = updatedProfile.clone();
            updatedProfileClone.technicalLanguages = value as string[];
            setUpdatedProfile(updatedProfileClone);
          }}
          value={formTechnicalLanguages ?? []}
          options={Constants.languages.map((option) => option.name)}
          renderTags={getTags}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Technical Languages"
            />
          )}
        />
        <StyledAutocomplete
          id="states"
          size="small"
          sx={hideIfNotIPAttorney()}
          multiple
          onChange={(_event, value, _reason) => {
            setFormRegionsLicensed(value as string[]);
            if (!updatedProfile) return;
            if (value === updatedProfile.regionsLicensed) return;
            let updatedProfileClone = updatedProfile.clone();
            updatedProfileClone.regionsLicensed = value as string[];
            setUpdatedProfile(updatedProfileClone);
          }}
          value={formRegionsLicensed ?? []}
          options={Constants.states.map((option) => option.name)}
          renderTags={getTags}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="States Licensed In"
            />
          )}
        />
        <GridContainer>
          <InsuredAmountField
            id="insuredAmount"
            customInput={TextField}
            size="small"
            sx={{
              display: updatedProfile?.categories?.includes("IPAttorney")
                ? "inline-flex"
                : "none",
            }}
            variant="outlined"
            label="Insured Amount"
            value={formInsuredAmount ?? 0}
            prefix="$"
            thousandSeparator=","
            onValueChange={(value: NumberFormatValues) => {
              setFormInsuredAmount(value.floatValue ?? 0);
              if (!updatedProfile) return;
              if (
                value.floatValue ===
                updatedProfile.insured?.insuredAmount
              )
                return;
              let updatedProfileClone = updatedProfile.clone();
              updatedProfileClone.insured = new InsuranceInfo(
                value.floatValue ?? 0,
                Currency.USD
              );
              setUpdatedProfile(updatedProfileClone);
            }}
          />
        </GridContainer>
        <GridContainer>
          <BillingRateField
            id="billingRate"
            customInput={TextField}
            variant="outlined"
            label="Billing Rate"
            size="small"
            sx={{
              display: updatedProfile?.categories?.includes("IPAttorney")
                ? "inline-flex"
                : "none",
            }}
            value={formBillingRate}
            prefix="$"
            thousandSeparator=","
            onValueChange={(value: NumberFormatValues) => {
              setFormBillingRate(value.value);
              if (!updatedProfile) return;
              if (
                value.floatValue ===
                updatedProfile.billingRate?.billingRate
              )
                return;
              let updatedProfileClone = updatedProfile.clone();
              updatedProfileClone.billingRate = new BillingRateInfo(
                value.value ?? "0",
                updatedProfileClone.billingRate?.billingUnit ?? ""
              );
              setUpdatedProfile(updatedProfileClone);
            }}
          />
          <BillingUnitField
            id="billingUnit"
            variant="outlined"
            label="Billing Unit"
            size="small"
            sx={{
              display: updatedProfile?.categories?.includes("IPAttorney")
                ? "inline-flex"
                : "none",
            }}
            value={formBillingUnit}
            onChange={(event) => {
              setFormBillingUnit(event.target.value);
              if (!updatedProfile) return;
              if (event.target.value === updatedProfile.billingRate?.billingUnit)
                return;
              let updatedProfileClone = updatedProfile.clone();
              updatedProfileClone.billingRate = new BillingRateInfo(
                updatedProfileClone.billingRate?.billingRate ?? "0",
                event.target.value ?? ""
              );
              setUpdatedProfile(updatedProfileClone);
            }}
          />
        </GridContainer>
        <ContactInfoContainer>
          <ContactDetails>
            <PhoneField
              id="phoneNumber"
              international
              defaultCountry="US"
              countryCallingCodeEditable={false}
              size="small"
              variant="outlined"
              value={formPhoneNumber}
              label="Phone Number"
              autoComplete="tel"
              inputComponent={TextFieldComponent}
              onChange={(phoneNumber) => {
                setFormPhoneNumber(phoneNumber);
                if (!updatedProfile) return;
                if (phoneNumber === updatedProfile.phoneNumber?.toString())
                  return;
                let updatedProfileClone = updatedProfile.clone();
                updatedProfileClone.phoneNumber = phoneNumber ? new PhoneNumber(phoneNumber) : undefined;
                setUpdatedProfile(updatedProfileClone);
              }}
            />
            <EmailField
              id="email"
              size="small"
              variant="outlined"
              value={formEmail ?? ""}
              label="Email Address"
              autoComplete="email"
              error={formEmail.length > 0 && !EmailAddress.isValid(formEmail)}
              onChange={(event) => {
                setFormEmail(event.target.value);
                if (!updatedProfile) return;
                if (event.target.value === formEmail) return;
                let updatedProfileClone = updatedProfile.clone();
                if (EmailAddress.isValid(event.target.value)) {
                  updatedProfileClone.email = new EmailAddress(
                    event.target.value
                  );
                } else if (event.target.value.length === 0) {
                  updatedProfileClone.email = undefined;
                }
                setUpdatedProfile(updatedProfileClone);
              }}
            />
          </ContactDetails>
          <OnCallCheckbox
            id="onCallCheckbox"
            control={
              <Checkbox
                color="primary"
                checked={
                  (updatedProfile?.isOnCall &&
                    (Boolean(updatedProfile.phoneNumber) ||
                      Boolean(updatedProfile.email))) ??
                  false
                }
              />
            }
            label="On-Call"
            labelPlacement="end"
            disabled={!updatedProfile?.phoneNumber && !updatedProfile?.email}
            onChange={(event) => {
              if (!updatedProfile) return;
              const isChecked: boolean = (event.target as HTMLInputElement)
                .checked;
              if (isChecked === updatedProfile.isOnCall) return;
              let updatedProfileClone = updatedProfile.clone();
              updatedProfileClone.isOnCall = isChecked;
              setUpdatedProfile(updatedProfileClone);
            }}
          />
        </ContactInfoContainer>
        <CheckboxContainer>
          <FormControlLabel
            id="diversityCandidate"
            control={
              <Checkbox
                value="diversityCandidate"
                color="primary"
                checked={updatedProfile?.potentialDiversityCandidate ?? false}
              />
            }
            label={<Typography>Potential Diversity Candidate</Typography>}
            labelPlacement="end"
            onChange={(event) => {
              if (!updatedProfile) return;
              const isChecked: boolean = (event.target as HTMLInputElement)
                .checked;
              if (isChecked === updatedProfile.potentialDiversityCandidate)
                return;
              let updatedProfileClone = updatedProfile.clone();
              updatedProfileClone.potentialDiversityCandidate = isChecked;
              setUpdatedProfile(updatedProfileClone);
            }}
          />
          <FormControlLabel
            id="collaborate"
            control={
              <Checkbox
                value="willingToCollaborate"
                color="primary"
                checked={updatedProfile?.collaborates ?? false}
              />
            }
            label={<Typography>Collaborate With Other Individuals</Typography>}
            labelPlacement="end"
            onChange={(event) => {
              if (!updatedProfile) return;
              const isChecked: boolean = (event.target as HTMLInputElement)
                .checked;
              if (isChecked === updatedProfile.collaborates) return;
              let updatedProfileClone = updatedProfile.clone();
              updatedProfileClone.collaborates = isChecked;
              setUpdatedProfile(updatedProfileClone);
            }}
          />
        </CheckboxContainer>
        <FieldContainer>
          <TitleContainer>
            <Typography variant="h6">Professional Publications</Typography>
            <Tooltip title="Enter a URL to display publications you've written">
              <HelpIcon/>
            </Tooltip>
          </TitleContainer>
          <UrlList
            label="Website URL"
            initialLinks={formProfessionalPublications ?? []}
            onChange={(updatedList: string[]) => {
              setFormProfessionalPublications(updatedList);
              if (!updatedProfile) return;
              if (updatedList === updatedProfile.professionalPublications)
                return;
              let updatedProfileClone = updatedProfile.clone();
              updatedProfileClone.professionalPublications = updatedList;
              setUpdatedProfile(updatedProfileClone);
            }}
          />
        </FieldContainer>
        {updatedProfile?.categories?.includes("IPAttorney") && (
          <FieldContainer>
            <TitleContainer>
              <Typography variant="h6">Sample Applications</Typography>
              <Tooltip
                title="Enter a URL address from Espacenet, Google Patents, etc to display applications you've written">
                <HelpIcon/>
              </Tooltip>
            </TitleContainer>
            <UrlList
              label="Website URL or patent number"
              initialLinks={formSampleApplications ?? []}
              onChange={(value: string[]) => {
                setFormSampleApplications(value);
                if (!updatedProfile) return;
                if (value === updatedProfile.sampleApplications) return;
                let updatedProfileClone = updatedProfile.clone();
                updatedProfileClone.sampleApplications = value;
                setUpdatedProfile(updatedProfileClone);
              }}
            />
          </FieldContainer>
        )}
        <FieldContainer>
          <Typography variant="h6">Other Links</Typography>
          <UrlList
            label="Website URL"
            initialLinks={formLinks ?? []}
            onChange={(value: string[]) => {
              setFormLinks(value);
              if (!updatedProfile) return;
              if (value === updatedProfile.links) return;
              let updatedProfileClone = updatedProfile.clone();
              updatedProfileClone.links = value;
              setUpdatedProfile(updatedProfileClone);
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <Typography variant="h6">Resume</Typography>
          <ResumeUpload
            individualId={individualId}
            accept=".doc,.docx,.pdf,.rtf,.odt"
            resume={resume}
            userName={`${updatedProfile?.firstName} ${updatedProfile?.lastName}`}
            onUpload={(updatedResume) => setResume(updatedResume)}
            onDelete={() => setResume(undefined)}
            session={session}
          />
        </FieldContainer>
      </ContentContainer>
      <AboutYou>
        <Typography variant="h6">
          About You
        </Typography>
        <AboutYouTextField
          id="addtlInfo"
          value={updatedProfile?.description ?? ""}
          onChange={(event) => {
            if (!updatedProfile) return;
            if (event.target.value === updatedProfile.description) return;
            let updatedProfileClone = updatedProfile.clone();
            updatedProfileClone.description = event.target.value;
            setUpdatedProfile(updatedProfileClone);
          }}
          multiline={true}
          minRows={4}
          placeholder="Additional info displayed on your profile"
          variant="outlined"
        />
      </AboutYou>
      <ButtonContainer>
        <LoadingButton
          id="saveButton"
          variant="contained"
          startIcon={<SaveIcon/>}
          loading={saving}
          color="primary"
          disabled={!canSave()}
          onClick={handleSaveButtonClick}
        >
          {individualId ? "Save Changes" : "Create Profile"}
        </LoadingButton>
      </ButtonContainer>
    </MainContainer>
  );
}
