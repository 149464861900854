import {Box, Icon, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import Guid from "common/values/guid/guid";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from "material-react-table";
import {useSession} from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import {ProposalField} from "work/entities/proposal/proposal";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import CommentsButton from "work/entities/comment-thread/view/comments-button";

const ActionsContainer = styled(Box)(
  ({theme}) => ({
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  }));
const RowTitle = styled(Typography)(
  () => ({
    fontWeight: "500",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }));
const RowActionsContainer = styled("div")(
  () => ({
    whiteSpace: 'nowrap'
  }));

type FeeScheduleCategoriesReadonlyProps = {
  className?: string;
  categories: Array<FeeScheduleCategory>;
  commentThreads?: CommentThread[];
  onCommentsClicked?: (field: ProposalField, name?: string) => void;
  hideCommentButton?: boolean;
};

export default function FeeScheduleCategoriesReadonly(
  props: Readonly<FeeScheduleCategoriesReadonlyProps>
) {
  const {
    className,
    categories,
    commentThreads,
    onCommentsClicked,
    hideCommentButton
  } = props;

  const session = useSession();

  const columnHelper = createMRTColumnHelper<FeeScheduleCategory>();
  const columns = [
    columnHelper.accessor(
      (category) => <RowTitle>{category.name.value}</RowTitle>,
      {
        header: "Name",
        id: "name",
      }
    ),
    columnHelper.accessor(
      (category) => category.description?.value,
      {
        header: "Description",
        id: "description",
      }
    ),
    columnHelper.accessor(
      (category) => (!category?.fee?.unit ? "Yes" : "No"),
      {
        header: "Fixed?",
        id: "fixedFee",
        size: 50,
      }
    ),
    columnHelper.accessor(
      (category) => {
        const deferredTo = session.context?.viewingAsVendor === true ? "client" : "vendor";
        return !category.fee
          ? <Typography color="warning">{`Deferred to ${deferredTo}`}</Typography>
          : new Intl.NumberFormat(
            navigator.language,
            {
              style: "currency",
              currency: category.fee?.rate?.currency.toString() ?? "USD",
            }
          ).format(category.fee?.rate?.amount ?? 0);
      },
      {
        header: "Fee",
        id: "fee",
        size: 50
      }
    ),
    columnHelper.accessor(
      (category) => category.fee?.unit ?? <Icon>remove</Icon>,
      {
        header: "Per",
        id: "unit",
        size: 50
      }
    ),
    columnHelper.accessor(
      (category) =>
        category.billingCode?.value && category.billingCode.value.length > 0 ? (
          category.billingCode.value
        ) : (
          <Icon>remove</Icon>
        ),
      {
        header: "Code",
        id: "billingCode",
        size: 50
      }
    ),
  ];
  const table = useMaterialReactTable({
    columns,
    data: categories,
    enableRowSelection: false,
    enableTableHead: true,
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {right: ['mrt-row-actions']}
    },
    enableColumnPinning: true,
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    manualPagination: true,
    manualSorting: true,
    enableRowActions: true,
    muiTableBodyRowProps: () => ({
      sx: {
        cursor: "not-allowed"
      },
      title: "This is a read-only view of archived data"
    }),
    renderToolbarInternalActions: ({table}) => (
      <Box>
        <MRT_ToggleGlobalFilterButton table={table}/>
        <MRT_ToggleFiltersButton table={table}/>
        <MRT_ShowHideColumnsButton table={table}/>
        <MRT_ToggleDensePaddingButton table={table}/>
        <MRT_ToggleFullScreenButton table={table}/>
        {!hideCommentButton && (
          <CommentsButton
            commentThreads={commentThreads}
            hideUnreadCount={true}
            field={ProposalField.FeeSchedule}
            toolTip={
              commentThreads?.some((thread) =>
                thread.field.isEqualTo(ProposalField.FeeSchedule)) ?
                "Comments" : "No Comments"
            }
            onCommentsClicked={
              onCommentsClicked ? () => {
                onCommentsClicked(ProposalField.FeeSchedule);
              } : undefined
            }
          />
        )}
      </Box>
    ),
    renderRowActions: ({row}) => {
      return (
        <RowActionsContainer>
          <CommentsButton
            field={ProposalField.FeeScheduleCategory(row.original.id)}
            hideUnreadCount={true}
            commentThreads={commentThreads}
            toolTip={commentThreads?.some((thread) =>
              thread.field.isEqualTo(ProposalField.FeeScheduleCategory(row.original.id)))
              ? "Comments" : "No Comments"
            }
            onCommentsClicked={
              onCommentsClicked ? () => {
                onCommentsClicked(ProposalField.FeeScheduleCategory(row.original.id));
              } : undefined
            }
          />
        </RowActionsContainer>
      );
    },
    muiTableContainerProps: ({table}) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight ??
        0}px - ${table.refs.bottomToolbarRef.current?.offsetHeight ?? 0}px)`,
      },
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        height: "100%",
      },
    },
  });

  return (
    <div className={className}>
      <MaterialReactTable table={table}/>
    </div>
  );
}
