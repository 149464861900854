import React from "react";
import { Select, MenuItem, TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { CurrencySymbol, Currency as CurrencyType } from "common/values/currency/currency";
import Money from "common/values/money/money";

import { styled } from "@mui/material/styles";

const CurrencyInput = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row"
}));

type CurrencyProps = {
  className?: string;
  size?: "small" | "medium";
  variant?: "standard" | "outlined" | "filled";
  value?: Money;
  label?: string;
  readOnly?: boolean;
  onChange: (currency: CurrencyType, amount: number) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onBlurCapture?: React.FocusEventHandler<HTMLInputElement>;
}

export default function Currency(props: Readonly<CurrencyProps>) {
  const {
    className,
    size,
    variant,
    value, 
    label, 
    readOnly, 
    onChange,
    onKeyDown,
    onBlurCapture
  } = props;

  const [currency, setCurrency] = React.useState(value?.currency ?? CurrencyType.USD);
  const [amount, setAmount] = React.useState<number>(value?.amount ?? 0);

  return (
    <CurrencyInput className={className}>
      <NumericFormat
        prefix={CurrencySymbol(currency)}
        label={label}
        customInput={TextField}
        value={amount}
        type="text"
        size={size ?? 'medium'}
        variant={variant ?? "outlined"}
        style={{ minWidth: '66px'}}
        thousandSeparator={true}
        decimalScale={2}
        onValueChange={(values, _sourceInfo) => {
          setAmount(values.floatValue ?? 0);
          onChange(currency as CurrencyType, values.floatValue ?? 0);
        }}
        onKeyDown={onKeyDown}
        onBlurCapture={onBlurCapture}
      />
      <Select
        value={currency}
        size={size ?? 'medium'}
        variant={variant ?? "outlined"}
        style={{ fontSize: '0.95em' }}
        onChange={(event) => {
          if (readOnly) return;
          setCurrency(
            CurrencyType[event.target.value as keyof typeof CurrencyType]
          );
          onChange(
            CurrencyType[event.target.value as keyof typeof CurrencyType],
            amount
          );
        }}
        onBlurCapture={onBlurCapture}
      >
        {Object.values(CurrencyType).map((currencyType) => (
          <MenuItem key={currencyType} value={currencyType}>
            {currencyType} ({CurrencySymbol(currencyType)})
          </MenuItem>
        ))}
      </Select>
    </CurrencyInput>
  );
}
