import { AxiosResponse, CanceledError } from "axios";
import axios, {
  AxiosHeaders
} from "axios";
import Session from "users/session/session";
import SearchResults from "common/contracts/search-results";
import {
  default as AttorneyHubAPIService
} from "common/services/api/attorney-hub-api-service";
import Guid from "common/values/guid/guid";
import MarketplaceForumResultAPIResponse from "marketplace/api/response-contracts/marketplace-forum-result-api-response";
import MarketplaceIndividualAPIRequest from "marketplace/entities/individual/api/request-contracts/marketplace-individual-api-request";
import MarketplaceIndividualSearch from "marketplace/entities/individual/api/request-contracts/marketplace-individual-search";
import MarketplaceIndividualAPIResponse from "marketplace/entities/individual/api/response-contracts/marketplace-individual-api-response";
import IndividualSummariesAPIResponse from "marketplace/values/individual-summary/api/response-contracts/individual-summaries-api-response";
import IndividualSummaryAPIResponse from "marketplace/values/individual-summary/api/response-contracts/individual-summary-api-response";
import IndividualSummary from "marketplace/values/individual-summary/individual-summary";
import Individual from "marketplace/entities/individual/individual";
import IndividualProfile from "marketplace/values/individual-profile/individual-profile";
import UpdateMarketplaceIndividualProfileAPIRequest from "marketplace/values/individual-profile/api/request-contracts/update-marketplace-individual-profile-api-request";
import UpdateMarketplaceIndividualAPIRequest from "marketplace/entities/individual/api/request-contracts/update-marketplace-individual-api-request";

export default class IndividualAPIService {
  private readonly headers: AxiosHeaders = new AxiosHeaders();
  private retrievedIndividuals: Individual[] = [];

  constructor(session: Readonly<Session>) {
    if (!session.authToken) throw new Error("Cannot create IndividualAPIService without session.");
    this.headers.set("Authorization", `Bearer ${session.authToken.value}`);
  }

  async getIndividualVendors(abortController?: AbortController): Promise<Individual[]> {
    const url = new URL("/marketplace/individual-vendors", AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController?.signal
        }
      );
      const vendors: Array<Individual> = [];
      response.data.forEach((element: any) => {
        let vendor = Object.assign(
          new MarketplaceIndividualAPIResponse(),
          element
        );
        vendors.push(vendor.deserialize());
      });
      return vendors;
    } catch (error: any) {
      if (error instanceof CanceledError) throw error;
      throw new IndividualServiceError("getIndividualVendors", error);
    }
  }

  async getMarketplaceIndividualById(individualId: Guid, abortController: AbortController): Promise<MarketplaceIndividualAPIResponse> {
    const url = new URL(`/marketplace/individuals/${individualId.value}`, AttorneyHubAPIService.apiBaseUrl);
    let individual = this.retrievedIndividuals.find((i) => i.id.isEqualTo(individualId));
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController.signal
        }
      );
      return Object.assign(
        new MarketplaceIndividualAPIResponse(),
        response.data
      );
    } catch (error: any) {
      if (error instanceof CanceledError) throw error;
      switch (error.response?.status) {
        case 400: throw new IndividualHiddenError(error.response.data);
        case 404: throw new IndividualNotFoundError(error.response.data);
        default: throw new IndividualServiceError("getIndividualById", error);
      }
    }
  }

  async getIndividualById(individualId: Guid, abortController: AbortController, forceRefresh = false): Promise<Individual> {
    const url = new URL(`/marketplace/individuals/${individualId.value}`, AttorneyHubAPIService.apiBaseUrl);
    let individual = this.retrievedIndividuals.find((i) => i.id.isEqualTo(individualId));
    if (individual && !forceRefresh) return individual;
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController.signal
        }
      );
      const individualData = Object.assign(
        new MarketplaceIndividualAPIResponse(),
        response.data
      );
      individual = individualData.deserialize() as Individual;
      this.retrievedIndividuals = this.retrievedIndividuals.filter((i) => !i.id.isEqualTo(individualId));
      this.retrievedIndividuals.push(individual);
      return individual;

    } catch (error: any) {
      if (error instanceof CanceledError) throw error;
      switch (error.response?.status) {
        case 400: throw new IndividualHiddenError(error.response.data);
        case 404: throw new IndividualNotFoundError(error.response.data);
        default: throw new IndividualServiceError("getIndividualById", error);
      }
    }
  }

  async getUsersProfileInfo(userIds: Guid[], abortController?: AbortController, forceRefresh = false): Promise<Individual[]> {
    const url = new URL("/marketplace/get-users-profile-info", AttorneyHubAPIService.apiBaseUrl);
    const cachedIndividuals = this.retrievedIndividuals.filter((individual) => userIds.some((id) => id.isEqualTo(individual.userId)));
    if (!forceRefresh && cachedIndividuals.length === userIds.length) return cachedIndividuals;
    try {
      const response = await axios.post(
        url.toString(),
        userIds.map((id) => id.value),
        {
          headers: this.headers.concat({ "Content-Type": "application/json" }),
          signal: abortController?.signal
        }      
      );
      const profiles: Array<Individual> = [];
      response.data.forEach((element: any) => {
        const individualResponse = {
          ...element,
          id: element.profileId
        };
        let individualData = Object.assign(
          new MarketplaceIndividualAPIResponse(),
          individualResponse
        );
        const individual = individualData.deserialize();
        profiles.push(individual);
        this.retrievedIndividuals = this.retrievedIndividuals.filter((i) => !i.id.isEqualTo(individual.id));
        this.retrievedIndividuals.push(individual);
      });
      return profiles;
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      throw new IndividualServiceError("getUsersProfileInfo", error);
    }
  }

  async getIndividualsFromEntityId(entityId: Guid, abortController: AbortController): Promise<IndividualSummary[]> {
    const url = new URL(`/marketplace/individuals/entity/${entityId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController.signal
        }
      );
      const responseData = Object.assign(new IndividualSummariesAPIResponse(), {
        individuals: response.data.individuals.map((individual: any) =>
          Object.assign(new IndividualSummaryAPIResponse(), individual)
        ),
      });
      return responseData.deserialize();
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      switch (error.response?.status) {
        case 404:
          throw new EntityIndividualsNotFoundError(error.response.data);
        default:
          throw error;
      }
    }
  }

  async searchIndividuals(
    search: MarketplaceIndividualSearch,
    abortController: AbortController,
    limitToVendorReps: boolean = false
  ): Promise<SearchResults<MarketplaceIndividualAPIResponse>> {
    let params = new URLSearchParams();
    for (const [key, value] of Object.entries(search)) {
      if (value) {
        params.append(key, value.toString());
      }
    }
    params.append("limitToVendorReps", limitToVendorReps.toString());
    const url = new URL(`/marketplace/individuals?${params.toString()}`, AttorneyHubAPIService.apiBaseUrl)
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController.signal
        }
      );
      const result = new SearchResults<MarketplaceIndividualAPIResponse>(response.data);
      return result;
    } catch (error: any) {
      if (error instanceof CanceledError) 
        throw error;
      if (error.response?.data) {
        throw new IndividualSearchError(error.response.data);
      } else {
        throw error;
      }
    }
  }

  async getIndividualAvatar(individualId: Guid, abortController: AbortController): Promise<string> {
    const url = new URL(`/marketplace/individuals/${individualId.value}/avatar`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController.signal
        }
      );
      return response.data;
    } catch (error: any) {
      if (error instanceof CanceledError) throw error;
      switch (error.response?.status) {
        case 404: throw new IndividualAvatarNotFoundError(error.response.data);
        default: throw new IndividualServiceError("getIndividualAvatar", error);
      }
    }
  }

  async downloadIndividualResume(individualId: Guid, abortController: AbortController): Promise<AxiosResponse> {
    const url = new URL(`/marketplace/individuals/${individualId.value}/resume`, AttorneyHubAPIService.apiBaseUrl);
    try {
      return await axios.get(
        url.toString(),
        {
          headers: this.headers,
          signal: abortController.signal,
          responseType: "blob"
        }
      );
    } catch (error: any) {
      switch (error.response?.status) {
        case 404: throw new IndividualResumeNotFoundError(error.response.data);
        default: throw new IndividualServiceError("downloadIndividualResume", error);
      }
    }
  }

  async createIndividual(individual: Individual): Promise<Individual> {
    const url = new URL("/marketplace/individuals", AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        new MarketplaceIndividualAPIRequest(individual),
        {
          headers: this.headers.concat({ "Content-Type": "application/json" })
        }
      );
      const result = Object.assign(new MarketplaceIndividualAPIResponse(), response.data);
      return result.deserialize();
    } catch (error: any) {
      switch (error.response?.status) {
        case 400: throw new IndividualCreationError(error.response.data);
        default: throw new IndividualServiceError("createIndividual", error);
      }
    }
  }

  async updateIndividual(originalIndividual: Individual, updatedIndividual: Individual): Promise<Individual> {
    const url = new URL(`/marketplace/individuals/${originalIndividual.id.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.patch(
        url.toString(),
        new UpdateMarketplaceIndividualAPIRequest(originalIndividual, updatedIndividual).payload,
        {
          headers: this.headers.concat({ "Content-Type": "application/json" })
        }
      );
      const result = Object.assign(new MarketplaceIndividualAPIResponse(), response.data);
      return result.deserialize();
    } catch (error: any) {
      switch (error.response?.status) {
        case 400: throw new IndividualUpdateError(error.response.data);
        case 404: throw new IndividualNotFoundError(error.response.data);
        default: throw new IndividualServiceError("updateIndividual", error);
      }
    }
  }

  async updateIndividualProfile(
    individualId: Guid,
    originalProfile: IndividualProfile,
    updatedProfile: IndividualProfile
  ): Promise<IndividualProfile> {
    if (!originalProfile) throw new Error("Original profile is undefined");
    if (!updatedProfile) throw new Error("Updated profile is undefined");

    const url = new URL(`/marketplace/individuals/${individualId.value}`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.patch(
        url.toString(),
        new UpdateMarketplaceIndividualProfileAPIRequest(originalProfile, updatedProfile).payload,
        {
          headers: this.headers.concat({ "Content-Type": "application/json" })
        }
      );
      const result = Object.assign(new MarketplaceIndividualAPIResponse(), response.data);
      return result.deserialize().profile;
    } catch (error: any) {
      switch (error.response?.status) {
        case 400: throw new IndividualUpdateError(error.response.data);
        case 404: throw new IndividualNotFoundError(error.response.data);
        default: throw new IndividualServiceError("updateIndividualProfile", error);
      }
    }
  }

  async createIndividualAvatar(individualId: Guid, file: File): Promise<void> {
    const url = new URL(`/marketplace/individuals/${individualId.value}/avatar`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const formData = new FormData();
      formData.append("file", file);
      await axios.post(
        url.toString(),
        formData,
        {
          headers: this.headers.concat({ "Content-Type": "multipart/form-data" })
        }
      );
    } catch (error: any) {
      switch (error.response?.status) {
        case 404: throw new IndividualNotFoundError(error.response.data);
        case 422: throw new IndividualAvatarError(error.response.data);
        default: throw new IndividualServiceError("createIndividualAvatar", error);
      }
    }
  }

  async createIndividualResume(individualId: Guid, file: File): Promise<void> {
    const url = new URL(`/marketplace/individuals/${individualId.value}/resume`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const formData = new FormData();
      formData.append("file", file);
      await axios.post(
        url.toString(),
        formData,
        {
          headers: this.headers.concat({ "Content-Type": "multipart/form-data" }),
        }
      );
    } catch (error: any) {
      switch (error.response?.status) {
        case 404: throw new IndividualNotFoundError(error.response.data);
        case 422: throw new IndividualResumeError(error.response.data);
        default: throw new IndividualServiceError("createIndividualResume", error);
      }
    }
  }

  async deleteIndividualAvatar(individualId: Guid): Promise<void> {
    const url = new URL(`/marketplace/individuals/${individualId.value}/avatar`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.delete(
        url.toString(),
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      switch (error.response?.status) {
        case 404: throw new IndividualNotFoundError(error.response.data);
        case 422: throw new IndividualAvatarError(error.response.data);
        default: throw new IndividualServiceError("deleteIndividualAvatar", error);
      }
    }
  }

  async deleteIndividualResume(individualId: Guid): Promise<void> {
    const url = new URL(`/marketplace/individuals/${individualId.value}/resume`, AttorneyHubAPIService.apiBaseUrl);
    try {
      await axios.delete(
        url.toString(),
        {
          headers: this.headers
        }
      );
    } catch (error: any) {
      switch (error.response?.status) {
        case 404: throw new IndividualNotFoundError(error.response.data);
        case 422: throw new IndividualResumeError(error.response.data);
        default: throw new IndividualServiceError("deleteIndividualResume", error);
      }
    }
  }

  async contactIndividual(individualId: Guid): Promise<MarketplaceForumResultAPIResponse> {
    const url = new URL(`/marketplace/individuals/${individualId.value}/contact`, AttorneyHubAPIService.apiBaseUrl);
    try {
      const response = await axios.post(
        url.toString(),
        {},
        {
          headers: this.headers
        }
      );
      const result = Object.assign(
        new MarketplaceForumResultAPIResponse(),
        response.data
      );
      return result;
    } catch (error: any) {
      switch (error.response?.status) {
        case 400:
          throw new IndividualContactError(error.response.data);
        case 404:
          throw new IndividualNotFoundError(error.response.data);
        default:
          throw error;
      }
    }
  }
}

export class IndividualServiceError extends Error {
  method: string;
  error: any;
  constructor(method: string, error: any) {
    super(`Error in IndividualService.${method}: ${error}`);
    this.method = method;
    this.error = error;
  }
}

export class IndividualSearchError extends Error { }
export class IndividualNotFoundError extends Error { }
export class IndividualHiddenError extends Error { }
export class EntityIndividualsNotFoundError extends Error { }
export class IndividualCreationError extends Error { }
export class IndividualUpdateError extends Error { }
export class IndividualAvatarError extends Error { }
export class IndividualAvatarNotFoundError extends Error { }
export class IndividualResumeError extends Error { }
export class IndividualResumeNotFoundError extends Error { }
export class IndividualContactError extends Error { }
