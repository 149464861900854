import DeleteIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/Download";
import FilterListIcon from '@mui/icons-material/FilterList';
import UploadIcon from '@mui/icons-material/Upload';
import {Box, Chip, Container, IconButton, Link, Theme, Tooltip, Typography, useMediaQuery} from "@mui/material";
import {styled} from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {ConfirmResponse, useConfirmDialog} from 'app/providers/confirm-dialog';
import LoadingButton from 'common/components/loading-button';
import PaginationParameters from "common/contracts/pagination-parameters";
import {downloadFile} from "common/helpers/utils";
import Guid from 'common/values/guid/guid';
import DocumentAPIService, {DocumentQuery} from "documents/entities/document/api/document-api-service";
import Document from "documents/entities/document/document";
import DocumentTopic from "documents/values/document-topic";
import {filesize} from "filesize";
import _ from "lodash";
import {
  createMRTColumnHelper,
  MaterialReactTable,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable
} from 'material-react-table';
import {enqueueSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import {defaultStyles, FileIcon} from 'react-file-icon';
import {useNavigate} from 'react-router';
import {Params, useLoaderData, useLocation, useSearchParams} from "react-router-dom";
import {useSession} from 'users/session/session-context';
import {PageTab} from "app/routes/contracts/tabs";
import {useDialog} from "app/providers/dialog";

import {useAttorneyHubDispatch} from "app/realtime-store/redux-store";
import DocumentParameters, {
  DocumentFilterParameters,
  DocumentOrderField,
  DocumentOrderParameters,
  DocumentTopicParameters
} from "documents/entities/document/api/request-contracts/document-parameters";
import {
  getDocumentsByQuery,
  getDocumentsQueryByInterface,
  getErrorLoadingDocumentsByQuery,
  getIsLoadingDocumentsByQuery,
  populateDocumentsByQuery,
  removeDocument,
  updateDocumentsInterfaceQuery
} from "documents/entities/document/store/documents-redux-slice";


const ToolbarButtonsContainer = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down('md')]: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    display: "flex",
    alignItems: "end",
    flexDirection: "column",
    gap: theme.spacing(1),
  }));
const ToolbarButtons = styled("div")(
  ({theme}) => ({
    display: "flex",
    alignItems: "end",
    flexWrap: "nowrap"
  }));
const RowActionsContainer = styled(Box)(
  ({theme}) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    width: "inherit"
  }));
const NameLink = styled(Link)(
  ({theme}) => ({
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: "1em",
    fontWeight: "500",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    '&:hover': {
      textDecoration: 'underline'
    }
  }));
const NameContainer = styled('div')(
  ({theme}) => ({
    alignItems: 'center',
    columnGap: theme.spacing(1),
    display: 'flex',
    'svg': {
      width: '24px'
    }
  }));
const LabelChip = styled(Chip)(
  ({theme}) => ({
    color: '#777',
    marginRight: theme.spacing(0.4),
  }));
const TableTopToolbarContainer = styled("div")(
  ({theme}) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
  }));
const LowerTopToolbarContainer = styled("div")(
  ({theme}) => ({
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
    },
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    width: "100%",
  }));
const TableTitle = styled(Typography)(
  ({theme}) => ({
    paddingLeft: theme.spacing(1),
  }));
const FilterButtons = styled('div')(
  ({theme}) => ({
    marginTop: theme.spacing(1),
    "& > .MuiChip-root": {
      [theme.breakpoints.down('xl')]: {
        marginBottom: theme.spacing(0.5),
      },
      marginLeft: theme.spacing(0.5),
    }
  }));
const NewButton = styled(IconButton)(
  ({theme}) => ({
    padding: 0
  }));

type ContractsProps = {};

export default function Contracts(props: Readonly<ContractsProps>) {
  const documentFileUploaderRef = React.useRef<HTMLInputElement | null>(null);

  const [activeTab, setActiveTab] = React.useState(PageTab.Conflicts);
  const [uploading, setUploading] = React.useState(false);
  const [mine, setMine] = React.useState(true);
  const [clientVendor, setClientVendor] = React.useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [showAlertBanner, setShowAlertBanner] = useState(false);
  const [showProgressBars, setShowProgressBars] = useState(false);
  const [rowSelection, setRowSelection] = React.useState<
    Record<string, boolean>
  >({});
  const [showSkeletons, setShowSkeletons] = React.useState(false);

  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [sorting, setSorting] = useState<Array<{ id: string, desc: boolean }>>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });
  const [tableData, setTableData] = useState<Document[]>([]);
  const [rowCount, setRowCount] = useState(0);

  const navigate = useNavigate();
  const session = useSession();
  const routeParams = useLoaderData() as Params<string>;
  const [searchParams, setSearchParams] = useSearchParams();
  const confirm = useConfirmDialog();
  const isMediumDisplaySize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const interfaceName = "contractsIndex"

  const dispatch = useAttorneyHubDispatch();
  const documentQuery = getDocumentsQueryByInterface(
    interfaceName
  );
  const documentsResponse = getDocumentsByQuery(documentQuery);
  const queryIsLoading = getIsLoadingDocumentsByQuery(documentQuery);
  const errorLoadingQuery = getErrorLoadingDocumentsByQuery(documentQuery);

  const defaultQuery = new DocumentQuery(
    new DocumentParameters(
      new DocumentTopicParameters(
        undefined,
        undefined,
        'Conflicts',
      ),
      new DocumentOrderParameters(
        DocumentOrderField.Name,
        'asc'
      ),
      new DocumentFilterParameters(
        undefined,
        undefined,
        undefined
      )
    ),
    new PaginationParameters(
      0,
      25
    )
  );

  useEffect(
    () => {
      if (!documentQuery) {
        dispatch(updateDocumentsInterfaceQuery({interfaceName, query: defaultQuery}));
        return;
      }

      if (documentQuery && documentsResponse === undefined && !queryIsLoading) {
        dispatch(
          populateDocumentsByQuery({
            session: session,
            query: documentQuery,
          })
        );
      } else if (documentsResponse !== undefined) {
        setTableData(documentsResponse.data);
        setRowCount(documentsResponse.totalElements);
        setIsLoading(false);
      }
    },
    [
      documentsResponse,
      documentQuery
    ]
  );

  useEffect(
    () => {
      if (queryIsLoading && !isLoading && !showProgressBars) {
        setShowProgressBars(true);
      } else {
        setShowProgressBars(false);
      }
    },
    [queryIsLoading]
  );

  useEffect(
    () => {
      if (errorLoadingQuery) {
        setShowAlertBanner(true);
        setShowSkeletons(true)
        setShowProgressBars(false);
        setIsLoading(false);
        setTableData([]);
      } else {
        setShowAlertBanner(false);
      }
    },
    [errorLoadingQuery]
  );
  // Handle URL changes
  useEffect(
    () => {
      if (!session.user?.companyEntityId) return;

      if (routeParams.tab) {
        setActiveTab(routeParams.tab as PageTab);
      } else {
        navigate(`/contracts/${PageTab.Conflicts}`);
      }

      let context = searchParams.get("context");
      if (context === null && routeParams.tab === PageTab.Policies) {
        setSearchParams((prevParams) => {
          const params = new URLSearchParams(prevParams);
          params.set(
            "context",
            "mine"
          );
          return params;
        });
        context = "mine";
      } else if (context === null && routeParams.tab === PageTab.Conflicts) {
        setSearchParams((prevParams) => {
          const params = new URLSearchParams(prevParams);
          params.set(
            "context",
            "conflicts"
          );
          return params;
        })
        context = "conflicts";
      }
      setMine(context === "mine");
      setClientVendor(context === "clientVendor");
      const orderingField =
        sorting.find((sort) =>
          Object.values(DocumentOrderField).includes(sort.id as DocumentOrderField));

      const documentParams = new DocumentParameters(
        new DocumentTopicParameters(
          undefined,
          undefined,
          context ?? undefined
        ),
        new DocumentOrderParameters(
          orderingField ? orderingField.id as DocumentOrderField : DocumentOrderField.Created,
          orderingField?.desc ? 'desc' : 'asc'
        ),
        new DocumentFilterParameters(
          globalFilter && globalFilter !== '' ? globalFilter : undefined,
          undefined,
          undefined
        )
      );
      const paginationParams = new PaginationParameters(
        pagination.pageIndex,
        pagination.pageSize
      );
      const newQuery = new DocumentQuery(
        documentParams,
        paginationParams
      );
      if (newQuery.isEqualTo(documentQuery)) {
        return;
      }


      dispatch(
        updateDocumentsInterfaceQuery({
          interfaceName: interfaceName,
          query: newQuery,
        })
      );
    },
    [
      routeParams,
      searchParams,
      pagination,
      activeTab,
      sorting,
      globalFilter
    ]
  );

  /**
   * Uploads the document file
   * @param {React.ChangeEvent<HTMLInputElement>} event The file upload event
   * @returns {Promise<void>}
   */
  async function handleDocumentFileUpload(
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    if (!event.currentTarget.files) return;

    const file: File = event.currentTarget.files[0];

    const templateResponse = await confirm({
      title: "Create Template?",
      message: "Do you want to create a template or just upload the document?",
      okButtonText: "Create Template",
      alternativeOkText: "Just Upload",
      cancelButtonText: "Cancel"
    });

    if (templateResponse === ConfirmResponse.Cancel) return;

    try {
      setUploading(true);

      let context: string | undefined;
      if (templateResponse === ConfirmResponse.Ok) {
        context = activeTab === PageTab.Conflicts ? "ConflictsTemplate" : "PolicyTemplate";
      } else {
        context = activeTab === PageTab.Conflicts ? "Conflicts" : "Policy";
      }
      const service = new DocumentAPIService(session);
      await service.createDocument(
        file,
        file.name,
        [DocumentTopic.fromJSON({context})]
      );
      enqueueSnackbar(
        "Uploaded document file",
        {variant: "success"}
      );
    } catch (error: any) {
      if (error.response && error.response.status === 415) {
        enqueueSnackbar(
          error.response.data,
          {variant: "error"}
        );
      } else if (error.response && error.response.status === 422) {
        enqueueSnackbar(
          "Unsupported file type",
          {variant: "error"}
        );
      } else {
        enqueueSnackbar(
          "Unknown error occurred during upload",
          {
            variant: "error",
          }
        );
      }
    } finally {
      event.target.value = "";
      setUploading(false);
    }
  }

  /**
   * Downloads the document file with the given id
   * @param {Guid} id The id of the document file to download
   * @returns {Promise<void>}
   */
  async function handleDownload(id: Guid): Promise<void> {
    try {
      if (!id) Promise.reject(new Error("No document id provided"));
      const service = new DocumentAPIService(session);
      const response = await service.downloadDocument(id);
      downloadFile(response);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "Failed to download file",
        {variant: "error"}
      );
    }
  }

  /**
   * Deletes the document file with the given id
   * @param {Guid} id The id of the document file to delete
   * @returns {Promise<void>}
   */
  async function handleDelete(id: Guid): Promise<void> {
    if (!id) Promise.reject(new Error("No document id provided"));

    const response = await confirm({
      title: "Delete Document?",
      message: "Are you sure you want to delete this document?",
      okButtonText: "Delete",
    });

    if (response === ConfirmResponse.Cancel) return;

    try {
      const service = new DocumentAPIService(session);
      await service.deleteDocument(id);
      dispatch(removeDocument(id))
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "Failed to delete file",
        {variant: "error"}
      );
    }
  }

  const renderNameColumn = (contract: Document): React.ReactNode => {
    const fileType = contract?.fileType?.toString() as keyof typeof DocumentType;

    return (
      <NameContainer>
        <FileIcon
          extension={fileType}
          {...defaultStyles[fileType?.toLowerCase() as keyof typeof defaultStyles]}
        />
        <NameLink onClick={() => handleDownload(contract.id)}>
          {contract.name?.value}
        </NameLink>
      </NameContainer>
    );
  }

  const renderLabelsColumn = (contract: Document) => {
    const labels = _.uniq(contract?.topics?.map((topic) => topic.context));
    return labels.map((label) => {
      if (!label) return null;
      return (
        <LabelChip
          key={label}
          size="small"
          label={label}
        />
      );
    });
  }

  const columnHelper = createMRTColumnHelper<Document>();
  const columns = [
    columnHelper.accessor(
      renderNameColumn,
      {
        header: "Name",
        id: "name",
        enableColumnFilter: false
      }
    ),
    columnHelper.accessor(
      renderLabelsColumn,
      {
        header: "Labels",
        id: "labels",
        enableSorting: false,
        enableColumnFilter: false
      }
    ),
    columnHelper.accessor(
      (contract) => {
        return contract?.topics?.reduce(
          (acc, topic) => acc + (topic.entityClass === 'Work.Proposal' ? 1 : 0),
          0
        )
      },
      {
        header: "# of Proposals",
        id: "numberOfProposals",
        enableSorting: false,
        enableColumnFilter: false
      }
    ),
    columnHelper.accessor(
      (contract) => contract?.created?.format('MM/DD/YY hh:mm A'),
      {
        header: "Created",
        id: "created",
        enableColumnFilter: false
      }
    ),
    columnHelper.accessor(
      (contract) => filesize(contract?.fileSize),
      {
        header: "Size",
        id: "fileSize",
        enableSorting: false,
        enableColumnFilter: false
      }
    ),
  ];

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    enableRowSelection: false,
    enableTableHead: true,
    enableRowActions: true,
    enableColumnPinning: true,
    initialState: {
      columnPinning: {right: ['mrt-row-actions']}
    },
    renderToolbarInternalActions: ({table}) => (
      <>
        {!isMediumDisplaySize && (
          <ToolbarButtonsContainer>
            <ToolbarButtons>
              <MRT_ToggleGlobalFilterButton table={table}/>
              <MRT_ToggleFiltersButton table={table}/>
              <MRT_ShowHideColumnsButton table={table}/>
              <MRT_ToggleDensePaddingButton table={table}/>
              <MRT_ToggleFullScreenButton table={table}/>
            </ToolbarButtons>
            <LoadingButton
              loading={uploading}
              startIcon={<UploadIcon/>}
              onClick={() => documentFileUploaderRef.current?.click()}>
              {`Upload ${activeTab}`}
            </LoadingButton>
          </ToolbarButtonsContainer>
        )}
        {isMediumDisplaySize && (
          <NewButton
            aria-label="Upload"
            color="primary"
            onClick={() => documentFileUploaderRef.current?.click()}
          >
            <UploadIcon fontSize="large"/>
          </NewButton>
        )}
      </>
    ),
    renderTopToolbarCustomActions: () => {
      return (
        <TableTopToolbarContainer>
          <TableTitle variant="h4">
            Contracts
          </TableTitle>
          <LowerTopToolbarContainer>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_event, newValue) => navigate(`/contracts/${newValue}/`)}
            >
              <Tab value={PageTab.Conflicts} label="Conflicts"/>
              <Tab value={PageTab.Policies} label="Policies"/>
            </Tabs>
            {activeTab === PageTab.Policies && (
              <FilterButtons>
                <Chip
                  icon={<FilterListIcon/>}
                  label="My Policies"
                  color="primary"
                  variant={mine ? 'filled' : 'outlined'}
                  onClick={() => {
                    if (mine) return;
                    setMine(prevValue => !prevValue);
                    setSearchParams((prevParams) => {
                      const params = new URLSearchParams(prevParams);
                      if (!mine) {
                        params.set(
                          'context',
                          'mine'
                        );
                      } else {
                        params.set(
                          'context',
                          'clientVendor'
                        );
                      }
                      return params;
                    });
                  }}
                />
                <Chip
                  icon={<FilterListIcon/>}
                  label={`My ${session.context?.viewingAsVendor ? "Clients'" : "Vendors'"} Policies`}
                  color="primary"
                  variant={clientVendor ? 'filled' : 'outlined'}
                  onClick={() => {
                    if (clientVendor) return;
                    setClientVendor(prevValue => !prevValue);
                    setSearchParams((prevParams) => {
                      const params = new URLSearchParams(prevParams);
                      if (!clientVendor) {
                        params.set(
                          'context',
                          'clientVendor'
                        );
                      } else {
                        params.set(
                          'context',
                          'mine'
                        );
                      }
                      return params;
                    });
                  }}
                />
              </FilterButtons>
            )}
          </LowerTopToolbarContainer>
          {isMediumDisplaySize && (
            <ToolbarButtonsContainer>
              <ToolbarButtons>
                <MRT_ToggleGlobalFilterButton table={table}/>
                <MRT_ToggleFiltersButton table={table}/>
                <MRT_ShowHideColumnsButton table={table}/>
                <MRT_ToggleDensePaddingButton table={table}/>
                <MRT_ToggleFullScreenButton table={table}/>
              </ToolbarButtons>
            </ToolbarButtonsContainer>
          )}
        </TableTopToolbarContainer>
      );
    },
    renderRowActions: ({row, table}) => (
      <RowActionsContainer>
        <Tooltip title="Download">
          <span>
            <IconButton onClick={() => handleDownload(row.original.id)}>
              <DownloadIcon color="primary"/>
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
            title={row.original.hasSystemPermissions ?  "Unable to delete: file in use" : "Delete"}
        >
          <span>
            <IconButton
                onClick={() => handleDelete(row.original.id)}
                disabled={row.original.hasSystemPermissions}
            >
              <DeleteIcon
                  color={row.original.hasSystemPermissions ? "disabled" : "error"}
              />
            </IconButton>
          </span>
        </Tooltip>
      </RowActionsContainer>
    ),
    getRowId: (row) => row.id?.toString() ?? Guid.generate().toString(),
    manualFiltering: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnFilters: true,
    manualPagination: true,
    manualSorting: true,
    muiTableContainerProps: ({table}) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
      },
    }),
    muiTablePaperProps: {
      sx: {
        height: "100%",
      },
    },
    muiToolbarAlertBannerProps: showAlertBanner
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner,
      showProgressBars,
      sorting,
      rowSelection,
      showSkeletons
    },
  });

  if (!session.user?.companyEntityId) {
    return (
      <Container sx={{height: '100%', textAlign: 'center'}}>
        <Typography sx={{alignContent: 'center', height: '100%'}} variant="h4">
          You are not authorized to view this page
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <MaterialReactTable table={table}/>
      <input
        type="file"
        hidden={true}
        ref={documentFileUploaderRef}
        onChange={handleDocumentFileUpload}
      />
    </>
  );
}
