import {
    HttpTransportType,
    HubConnectionBuilder,
    LogLevel,
} from "@microsoft/signalr";
import * as Constants from "common/helpers/constants";
import reduxSignalRMiddleware, {
    withCallbacks,
} from "common/realtime/redux-signalr-middleware";
import Guid from "common/values/guid/guid";
import NotificationAPIResponse from "notifications/entities/notification/api/response-contracts/notification-api-response";
import { addNotification, removeNotification } from "notifications/entities/notification/store/notifications-redux-slice";
import Session from "users/session/session";
  
  export const notificationsHubConnection = new HubConnectionBuilder()
    .configureLogging(LogLevel.Debug)
    .withUrl(`${Constants.apiBaseUrl}/notificationHub`, {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
      accessTokenFactory: () =>
        Session.loadFromStorage(() => {}).authToken?.value ?? "",
    })
    .withAutomaticReconnect()
    .build();
  
  const callbacks = withCallbacks()
    .add("notification-received", (apiResponseObject: object) => (dispatch) => {
      const notification = Object.assign(
        new NotificationAPIResponse(),
        apiResponseObject
      ).deserialize();
      dispatch(addNotification(notification));
    })
    .add("notification-updated", (apiResponseObject: object) => (dispatch) => {
        const notification = Object.assign(
          new NotificationAPIResponse(),
          apiResponseObject
        ).deserialize();
        dispatch(addNotification(notification));
      })    
    .add("notification-deleted", (deletedNotificationMessage: {id: string}) => (dispatch) => {
      const deletedNotificationId = new Guid(deletedNotificationMessage.id)
      dispatch(removeNotification(deletedNotificationId));
    });
  
  export const notificationsSignalRReduxMiddleware = reduxSignalRMiddleware({
    callbacks,
    connection: notificationsHubConnection,
    shouldConnectionStartImmediately: true,
  });
  