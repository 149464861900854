import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GradingIcon from '@mui/icons-material/Grading';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import InfoIcon from '@mui/icons-material/Info';
import MessageIcon from '@mui/icons-material/Message';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import PageviewIcon from '@mui/icons-material/Pageview';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import WorkIcon from '@mui/icons-material/Work';
import {
  Badge, Button,
  Divider,
  IconButton, List,
  ListItemButton, ListItemIcon, ListItemText, Popover
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useAttorneyHubDispatch} from 'app/realtime-store/redux-store';
import PaginationParameters from 'common/contracts/pagination-parameters';
import * as Constants from 'common/helpers/constants';
import MessageNotification from 'notifications/entities/message-notification/message-notification';
import NotificationsAPIService, {
  NotificationQuery
} from 'notifications/entities/notification/api/notifications-api-service';
import NotificationParameters from 'notifications/entities/notification/api/request-contracts/notification-parameters';
import {NotificationLink, NotificationType} from 'notifications/entities/notification/notification-type';
import {
  addNotification,
  getNotificationsByQuery,
  markAllNotificationsAsSeen,
  populateNotificationsByQuery
} from 'notifications/entities/notification/store/notifications-redux-slice';
import Action from 'notifications/values/action/action';
import {BoxType} from 'notifications/values/box-type/box-type';
import TopicCategory from 'notifications/values/topic-category/topic-category';
import {enqueueSnackbar} from 'notistack';
import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useSession} from 'users/session/session-context';

const BellIconButton = styled(IconButton)(
  ({theme}) => ({
    color: '#fff',
    margin: theme.spacing(0.5)
  }));
const NotificationMenu = styled(Popover)(
  ({theme}) => ({
    zIndex: theme.zIndex.modal + 1
  }));
const MarkAllReadButton = styled(Button)(
  ({theme}) => ({
    margin: theme.spacing(0.4),
    marginLeft: theme.spacing(1.6)
  }));
const ViewAllButton = styled(Button)(
  () => ({
    borderRadius: 0,
    width: '100%'
  }));
const NotificationList = styled(List)(
  ({theme}) => ({
    maxHeight: theme.spacing(40),
    overflow: 'auto',
    padding: 0,
    width: theme.spacing(44)
  }));
const NotificationItem = styled(
  ListItemButton,
  {shouldForwardProp: (prop) => prop !== 'seen'}
)<{
  seen?: boolean;
}>(
  ({seen}) => ({
    backgroundColor: seen ? '#f3f3f3' : '#fff',
    '&:first-of-type': {
      borderTop: 'none'
    },
    '&:last-child': {
      borderBottom: 'none'
    },
    '&:hover': {
      backgroundColor: seen ? '#f3f3f3' : '#fff',
    }
  }));

type NotificationBellProps = {};

export default function NotificationBell(props: Readonly<NotificationBellProps>) {
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

  const session = useSession();
  const location = useLocation();
  const navigate = useNavigate();
  const defaultNotificationParams = new NotificationParameters(
    BoxType.Inbox,
    false,
    false,
  );
  const defaultPagination = new PaginationParameters(
    0,
    25
  );
  const defaultQuery = new NotificationQuery(
    defaultNotificationParams,
    defaultPagination
  );
  const notificationResponse = getNotificationsByQuery(defaultQuery);

  const dispatch = useAttorneyHubDispatch();

  const numUnread = () => notificationResponse?.data?.filter(n => !n.isSeen).length ?? 0;

  useEffect(
    () => {
      if (notificationResponse === undefined) {
        dispatch(populateNotificationsByQuery({session, query: defaultQuery}));
      }
    },
    [notificationResponse]
  );

  function handleBellClick(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchor(menuAnchor ? null : event.currentTarget);
  }

  async function handleNotificationClick(
    _event: React.MouseEvent<HTMLElement>,
    notification: NotificationType
  ) {
    try {
      const seenNotification = await new NotificationsAPIService(session)
        .markNotificationAsSeen(notification);
      dispatch(addNotification(seenNotification));

      if (!seenNotification.isSeen) {
        console.error(`Failed to mark notification ${notification.id} as seen.`);
      }

      if (
        notification?.topic?.id &&
        notification.topic?.category?.startsWith('Messaging') &&
        (notification as MessageNotification)?.messageInfo?.forum.entityClass.value !== 'Work.Proposal'
      ) {
        navigate(`/communications/forums/view/${(notification as MessageNotification)?.messageInfo?.forum.id}`);
        return;
      }

      const redirectUrl = NotificationLink(notification);
      if (redirectUrl === window.location.pathname) return;
      navigate(redirectUrl);
    } catch (error: any) {
      console.error(error);
    } finally {
      setMenuAnchor(null);
    }
  }

  async function handleMarkAllClick() {
    try {
      if (numUnread() < 1) return;
      await new NotificationsAPIService(session).markAllNotificationsAsSeen();
      dispatch(markAllNotificationsAsSeen());
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "Couldn't mark all notifications as read. Please try again.",
        {variant: 'error'}
      );
    }
  }

  function handleViewAllClick() {
    setMenuAnchor(null);
    if (location.pathname.startsWith('/communications')) return;
    navigate('/communications');
  }

  function getNotificationText(notification: NotificationType) {
    if (notification.topic?.category === TopicCategory.Messaging.Message) {
      return `Message from ${notification.messageInfo?.senderName?.value ?? 'unknown sender'}`;
    }
    return Constants.notificationTypes[(notification.topic?.category ??
      '') as keyof typeof Constants.notificationTypes] ?? 'Notification';
  }

  return (
    <React.Fragment>
      <BellIconButton
        aria-label="open notifications menu"
        onClick={handleBellClick}
        size="large">
        <Badge
          overlap="rectangular"
          badgeContent={numUnread() > 0 ? numUnread() : null}
          color="error">
          <NotificationsIcon/>
        </Badge>
      </BellIconButton>
      <NotificationMenu
        anchorEl={menuAnchor}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
        transformOrigin={{vertical: "top", horizontal: "center"}}
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}>
        <MarkAllReadButton
          color="primary"
          startIcon={<PlaylistAddCheckIcon/>}
          disabled={numUnread() < 1}
          onClick={handleMarkAllClick}>
          Mark All As Read
        </MarkAllReadButton>
        <Divider/>
        <NotificationList>
          {(!notificationResponse || notificationResponse.data.length < 1) && (
            <NotificationItem seen={true} disabled>
              <ListItemIcon>
                <NotificationsOffOutlinedIcon/>
              </ListItemIcon>
              <ListItemText>
                No Unread Notifications
              </ListItemText>
            </NotificationItem>
          )}
          {notificationResponse?.data?.map((notification: NotificationType) => (
            <NotificationItem
              key={notification.id.value}
              seen={Boolean(notification.isSeen)}
              divider
              onClick={(event) => handleNotificationClick(
                event,
                notification
              )}>
              <ListItemIcon>
                {[
                  TopicCategory.Messaging.Message,
                  TopicCategory.Messaging.Message
                ].includes(notification.topic?.category ?? '') && <MessageIcon/>}
                {notification.topic?.category === TopicCategory.Users.NetworkInvitation && <PersonAddIcon/>}
                {notification.topic?.category === TopicCategory.Users.NetworkConnection && <AccountTreeIcon/>}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired ===
                  Action.AcceptOrReject && <ThumbsUpDownIcon/>}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired ===
                  Action.Review && <PageviewIcon/>}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired ===
                  Action.DraftProposalReview && <PageviewIcon/>}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired ===
                  Action.DraftProposalReviewApproved && <GradingIcon/>}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired ===
                  Action.ProposalReview && <PageviewIcon/>}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired ===
                  Action.ProposalReviewApproved && <GradingIcon/>}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired ===
                  Action.ReviewOrReject && <PageviewIcon/>}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired ===
                  Action.ProposalRevisionReview && <AssignmentIcon/>}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired ===
                  Action.HireOrCancel && <WorkIcon/>}
                {notification.topic?.category === TopicCategory.Work.Proposal && notification.actionRequired ===
                  Action.Hired && <AssignmentIcon/>}
                {notification.topic?.category === TopicCategory.Work.Proposal && !notification.actionRequired &&
                    <InfoIcon/>}
                {notification.topic?.category === TopicCategory.Marketplace.Team && <GroupIcon/>}
                {notification.topic?.category === TopicCategory.LegalEntities.EntityMember && <PersonIcon/>}
                {notification.topic?.category === TopicCategory.Marketplace.TeamInvitation && <GroupAddIcon/>}
                {notification.topic?.category === TopicCategory.LegalEntities.LegalEntity && <SupervisorAccountIcon/>}
              </ListItemIcon>
              <ListItemText
                primary={getNotificationText(notification)}
                secondary={notification.message?.value}
              />
            </NotificationItem>
          ))}
        </NotificationList>
        <Divider/>
        <ViewAllButton
          color="primary"
          onClick={handleViewAllClick}>
          View All
        </ViewAllButton>
      </NotificationMenu>
    </React.Fragment>
  );
}
