import {BoxType} from "notifications/values/box-type/box-type";

export default class NotificationParameters {
  boxType: BoxType;
  filterByActionRequired: boolean;
  filterByUnread: boolean;
  orderByDescendingDate: boolean = true;
  topicId?: string;


  constructor(
    boxType: BoxType,
    filterByActionRequired: boolean,
    filterByUnread: boolean,
    orderByDescendingDate?: boolean,
    topicId?: string
  ) {
    this.boxType = boxType;
    this.filterByActionRequired = filterByActionRequired;
    this.filterByUnread = filterByUnread;
    this.topicId = topicId;
    if (orderByDescendingDate !== undefined) {
      this.orderByDescendingDate = orderByDescendingDate;
    }
  }

  public asSearchParams(): URLSearchParams {
    const searchParams = new URLSearchParams();
    if (this.boxType !== BoxType.Forums) {
      searchParams.append(
        "boxType",
        this.boxType.toString()
      );
    }

    searchParams.append(
      "filterByActionRequired",
      this.filterByActionRequired.toString()
    );
    searchParams.append(
      "filterByUnread",
      this.filterByUnread.toString()
    );
    if (this.boxType === BoxType.Forums) {
      searchParams.append(
        "filterByMessageNotifications",
        "true"
      );
    }

    if (this.topicId) {
      searchParams.append(
        "topicId",
        this.topicId
      );
    }
    searchParams.append(
      "orderByDescendingDate",
      this.orderByDescendingDate.toString()
    )
    return searchParams;
  }

  public isEqualTo(other: NotificationParameters | undefined): boolean {
    if (!other) {
      return false;
    }

    return this.boxType === other.boxType &&
      this.filterByActionRequired === other.filterByActionRequired &&
      this.filterByUnread === other.filterByUnread &&
      this.orderByDescendingDate === other.orderByDescendingDate &&
      this.topicId === other.topicId;
  }
}
