import InfoIcon from "@mui/icons-material/Info";
import {IconButton, Tooltip, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import Percent from "common/values/percent/percent";
import {enqueueSnackbar} from "notistack";
import CommentThread from "work/entities/comment-thread/comment-thread";
import {ProposalField} from "work/entities/proposal/proposal";
import FieldRedline from "work/entities/proposal/redlining/field-redline";
import ProposalRedline from "work/entities/proposal/redlining/proposal-redline";
import RedlinePercentInput from "work/entities/proposal/redlining/view/redline-field-inputs/percent-redline-input";
import {ProposalFieldName} from "work/values/constants";
import ProposalIssues from "work/values/proposal-issues/proposal-issues";

const ProposalDiscountContainer = styled("div")(
  ({theme}) => ({
    margin: theme.spacing(
      2,
      0,
      4,
      0
    ),
  }));
const HelperText = styled(Typography)(
  ({theme}) => ({
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

const discountDescription =
  "You can share the AttorneyHub fees you incur from a project by establishing " +
  'an agreed upon "discount" from your vendor. 2.912% is the value to cover the cost.';

type DiscountTabProps = {
  isOpen: boolean;
  proposalRedline: ProposalRedline;
  issues?: ProposalIssues;
  activeReviewField: ProposalField | undefined;
  disableEditing?: boolean;
  onProposalRedlineChange: (
    newRedline: ProposalRedline,
    traversalFieldOverride?: ProposalField | null
  ) => void;
  onTraverseToNewField: (fieldInfo: ProposalField | undefined) => void;
  commentThreads: CommentThread[];
  commentsToolTip: string;
  onCommentsClicked: (
    field: ProposalField,
    title?: string
  ) => void;
};

const DiscountTab = (props: Readonly<DiscountTabProps>) => {
  const {
    isOpen,
    proposalRedline,
    activeReviewField,
    disableEditing,
    onProposalRedlineChange,
    onTraverseToNewField,
    commentThreads,
    commentsToolTip,
    onCommentsClicked,
  } = props;

  if (!isOpen) return null;

  return (
    proposalRedline && (
      <ProposalDiscountContainer>
        <RedlinePercentInput
          focused={activeReviewField?.name === ProposalFieldName.Discount}
          readOnly={disableEditing}
          disabled={disableEditing}
          originalPercentFieldRedline={proposalRedline.discount}
          onCommentsClicked={() =>
            onCommentsClicked(ProposalField.Discount)
          }
          commentsToolTip={commentsToolTip}
          commentThreads={commentThreads}
          onPercentFieldRedlineChange={(
            updatedRedline: FieldRedline<Percent>,
            traversalFieldOverride?: ProposalField | null
          ) => {
            try {
              onProposalRedlineChange(
                proposalRedline.updateDiscountRedline(updatedRedline),
                traversalFieldOverride
              );
            } catch (error) {
              console.error(
                "Error updating discount",
                error
              );
              enqueueSnackbar(
                "Error updating discount",
                {variant: "error"}
              );
            }
          }}
          onTraverseIn={() => {
            onTraverseToNewField(ProposalField.Discount);
            onCommentsClicked(
              ProposalField.Discount,
              undefined
            );
          }}
        />
        <HelperText>
          A discount of <b>3%</b> will cover subscription costs.
          <Tooltip
            disableFocusListener
            title={discountDescription}
            placement="right"
            arrow
          >
            <IconButton size="small">
              <InfoIcon/>
            </IconButton>
          </Tooltip>
        </HelperText>
      </ProposalDiscountContainer>
    )
  );
};

export default DiscountTab;
