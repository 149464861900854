import CopyIcon from "@mui/icons-material/FileCopyOutlined";
import LinkIcon from "@mui/icons-material/Link";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import * as Constants from "common/helpers/constants";
import {enqueueSnackbar} from "notistack";
import {useEffect, useState} from "react";

import LegalEntity from "legal-entities/entities/legal-entity/legal-entity";
import OpenEntityMemberInvitationAPIService
  from "legal-entities/entities/open-entity-member-invitation/api/open-entity-member-invitation-api-service";
import OpenEntityMemberInvitation
  from "legal-entities/entities/open-entity-member-invitation/open-entity-member-invitation";
import {useSession} from "users/session/session-context";
import LoadingButton from "common/components/loading-button";
import Guid from "common/values/guid/guid";
import {ConfirmDialogType, ConfirmResponse} from "app/providers/confirm-dialog";
import EmailAddress from "common/values/email-address/email-address";
import UserEntityMemberInvitationAPIService from
    "legal-entities/entities/user-entity-member-invitation/api/user-entity-member-invitation-api-service";
import UserEntityMemberInvitation from
    "legal-entities/entities/user-entity-member-invitation/user-entity-member-invitation";
import RegistrationInfo from "legal-entities/values/registration-info";
import Name from "common/values/name/name";
import InvitationMessage from "legal-entities/values/invitation-message/invitation-message";

const FieldsContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  "& .MuiTextField-root": {
    width: "100%"
  }
}));
const PairedFieldsContainer = styled(Box)(({theme}) => ({
  display: "flex",
  flexDirection: "row",
  marginTop: theme.spacing(2),
  width: "100%",
  "& .MuiTextField-root": {
    width: "100%"
  }
}));
const SendButton = styled(LoadingButton)(({theme}) => ({
  marginTop: theme.spacing(2)
}));
const SectionDivider = styled(Divider)(({theme}) => ({
  margin: theme.spacing(4, 0)
}));
const InvitationLinkTable = styled(Table)(() => ({
  '& td': {
    '&:first-of-type': {
      paddingLeft: 0
    },
    '&:last-of-type': {
      paddingRight: 0
    },
    borderBottom: 'none'
  }
}));
const CreateLinkButton = styled(LoadingButton)(({theme}) => ({
  marginTop: theme.spacing(2)
}));

type EntityMemberInviteManagerProps = {
  className?: string;
  confirmDialog: ConfirmDialogType;
};

export default function EntityMemberInviteManager(props: Readonly<EntityMemberInviteManagerProps>) {
  const {className, confirmDialog} = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [invitation, setInvitation] = useState<OpenEntityMemberInvitation | null>(null);
  const [invitationUrl, setInvitationUrl] = useState<URL | null>(null);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [inviteMessage, setInviteMessage] = useState<string>("");
  const [creating, setCreating] = useState<boolean>(false);

  const session = useSession();

  const loadInvitations = async () => {
    try {
      setIsLoading(true);
      const entity = session.entities[0];
      const service = new OpenEntityMemberInvitationAPIService(session);
      const abortController = new AbortController();
      const invitations =
        await service.getAllOpenEntityMemberInvitationAsync(
          new LegalEntity(entity.entityId),
          abortController
        );
      if (invitations.length === 0) return;
      setInvitation(invitations[0]);
      setInvitationUrl(buildInvitationUrl(invitations[0].id));
    } catch (ex) {
      console.error(ex);
      enqueueSnackbar("Failed to get invitations", {variant: "error"});
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadInvitations();
  }, []);

  const handleDeleteInvite = async (invitation: OpenEntityMemberInvitation) => {
    try {
      const confirmResponse = await confirmDialog({
        title: "Delete Invitation",
        message: "Are you sure you want to delete this invitation?",
        okButtonText: "Delete",
        cancelButtonText: "Cancel",
      });

      if (confirmResponse === ConfirmResponse.Cancel) return;

      const service = new OpenEntityMemberInvitationAPIService(session);
      await service.deleteOpenMemberInvitation(invitation);

      setInvitation(null);

      enqueueSnackbar("Deleted invitation link", {variant: "success"});
    } catch (ex) {
      console.error(ex);
      enqueueSnackbar("Failed to delete invitation", {variant: "error"});
    }
  };

  const createInvitationLink = async () => {
    setCreating(true);
    try {
      const service = new OpenEntityMemberInvitationAPIService(session);
      const invitation = await service.createOpenMemberInvitation();
      setInvitationUrl(buildInvitationUrl(invitation.id));
      await loadInvitations();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Couldn't get invitation link. Please try again.", {
        variant: "error",
      });
    } finally {
      setCreating(false);
    }
  };

  function buildInvitationUrl(invitationId?: Guid): URL | null {
    if (!invitationId) return null;
    return new URL(`${Constants.entityMemberInvitationLink}/${invitationId.value}`);
  }

  async function handleSendInvitation() {
    try {
      setIsSending(true);
      const entity = new LegalEntity(
        session.entities[0].entityId,
        session.entities[0].entityName,
        session.entities[0].entityClientFlag,
        session.entities[0].entityVendorFlag
      );
      const name = new Name(firstName, lastName);
      const email = new EmailAddress(emailAddress);
      const registrationInfo = new RegistrationInfo(name, email);

      const payload = new UserEntityMemberInvitation(
        entity,
        email,
        registrationInfo
      );
      const service = new UserEntityMemberInvitationAPIService(session);
      await service.createUserEntityMemberInvitation(payload);

      setFirstName("");
      setLastName("");
      setEmailAddress("");
      setInviteMessage("");

      enqueueSnackbar("Invitation sent", {variant: "success"});
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to send invitation", {variant: "error"});
    } finally {
      setIsSending(false);
    }
  }

  const handleCopy = async (textToCopy?: string) => {
    if (!textToCopy) {
      enqueueSnackbar("No link to copy", {variant: "warning"});
      return;
    }
    await navigator.clipboard.writeText(textToCopy);
    enqueueSnackbar("Copied link to clipboard", {variant: "info"});
  };

  return (
    <div className={className}>
      <Typography>
        Invite a new member to your entity by entering their name and email address below
      </Typography>
      <FieldsContainer>
        <PairedFieldsContainer gap={1}>
          <TextField
            helperText="required"
            label="First Name"
            margin="normal"
            value={firstName}
            variant="outlined"
            required={true}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            helperText="required"
            label="Last Name"
            margin="normal"
            value={lastName}
            required={true}
            variant="outlined"
            onChange={(e) => setLastName(e.target.value)}
          />
        </PairedFieldsContainer>
        <TextField
          error={!EmailAddress.isValid(emailAddress) && emailAddress.length > 0}
          helperText={!EmailAddress.isValid(emailAddress) && emailAddress.length > 0 ? "Invalid email address" : "required"}
          label="Email Address"
          margin="dense"
          onChange={(e) => setEmailAddress(e.target.value)}
          value={emailAddress}
          required={true}
          variant="outlined"
        />
      </FieldsContainer>
      <SendButton
        color={"primary"}
        loading={isSending}
        disabled={isSending || !EmailAddress.isValid(emailAddress) || !firstName || !lastName}
        endIcon={<SendIcon/>}
        onClick={handleSendInvitation}
        variant={"contained"}
      >
        Send
      </SendButton>
      <SectionDivider/>
      <Typography>
        or create a link that you can give to anyone you want to invite
      </Typography>
      {invitation !== null && (
        <InvitationLinkTable>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell>
                  <CircularProgress size={24} thickness={5} color="primary"/>
                </TableCell>
              </TableRow>
            )}
            <TableRow key={invitation.id?.value}>
              <TableCell align="left">
                <Typography variant={"subtitle2"}>{invitationUrl?.toString()}</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant={"body2"}>Expires {invitation.expires?.format("M/D/YY h:mm A")}</Typography>
              </TableCell>
              <TableCell align="right">
                <Button
                  onClick={() => handleCopy(invitationUrl?.toString())}
                  color="primary"
                  endIcon={<CopyIcon/>}
                >
                  Copy
                </Button>
                <Button
                  onClick={() => handleDeleteInvite(invitation)}
                  color="error"
                  endIcon={<DeleteIcon/>}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </InvitationLinkTable>
      )}
      <CreateLinkButton
        variant="contained"
        color="primary"
        loading={creating}
        disabled={invitation !== null}
        startIcon={<LinkIcon/>}
        onClick={createInvitationLink}
      >
        Create Invite Link
      </CreateLinkButton>
    </div>
  );
}
