import moment from "moment";
import FeeScheduleTag from "work/values/fee-schedule-tag/fee-schedule-tag";
import Guid from "common/values/guid/guid";
import FeeScheduleCategoryName from "work/values/fee-schedule-category-name/fee-schedule-category-name";
import Fee from "work/values/fee/fee";
import Money from "common/values/money/money";
import { Currency } from "common/values/currency/currency";
import FeeScheduleBillingCode from "work/values/fee-schedule-billing-code/fee-schedule-billing-code";
import FeeScheduleCategoryDescription from "work/values/fee-schedule-category-description/fee-schedule-category-description";
import FeeScheduleTemplate from "work/entities/fee-schedule-template/fee-schedule-template";
import FeeScheduleCategory from "work/values/fee-schedule-category/fee-schedule-category";
import WorkFeeScheduleCategoryAPIResponse from "work/values/fee-schedule-category/api/response-contracts/work-fee-schedule-category-api-response";

export default class TemplateWorkFeeScheduleAPIResponse {
    id?: string;
    name?: string;
    tags: string[] = [];
    categories: WorkFeeScheduleCategoryAPIResponse[] = [];
    created?: string;
    archivedDate?: string | null;

    deserialize(): FeeScheduleTemplate {
        if(!this.id) throw new Error('Id not provided by backend for fee schedule template');
        if(!this.name) throw new Error('Name not provided by backend for fee schedule template');
        if(!this.created) throw new Error('Created date not provided by backend for fee schedule template');

        const template = new FeeScheduleTemplate(this.name);
        template.id = new Guid(this.id);
        template.createdDate = moment(this.created);
        template.archivedDate = this.archivedDate ? moment(this.archivedDate) : null;
        template.tags = this.tags.map(tag => new FeeScheduleTag(tag));

        template.categories = this.categories.map(category => {
            if(!category.id) console.warn('Category id not provided by backend for fee schedule category');
            if(!category.name) throw new Error('Name not provided by backend for fee schedule category');
            let fee: Fee | null = null;
            if(category.feeRate !== undefined) {
                let currency = Currency.USD;
                if(!category.feeCurrency) {
                    console.warn('Fee currency not provided by backend for fee schedule category');
                }
                fee = new Fee(new Money(category.feeRate, currency), category.feeUnit ?? null);
            }

            return new FeeScheduleCategory(
                new FeeScheduleCategoryName(category.name), 
                category.description ? new FeeScheduleCategoryDescription(category.description) : null,
                fee,
                category.billingCode ? new FeeScheduleBillingCode(category.billingCode) : null,
                category.id ? new Guid(category.id) : Guid.empty)
            }
        );
        
        return template;
    }
}
