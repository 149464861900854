import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import * as Constants from "common/helpers/constants";
import reduxSignalRMiddleware, {
  withCallbacks,
} from "common/realtime/redux-signalr-middleware";
import DocumentAPIResponse from "documents/entities/document/api/response-contracts/document-api-response";

import Session from "users/session/session";
import {addDocument, removeDocument} from "./documents-redux-slice";

export const documentsHubConnection = new HubConnectionBuilder()
  .configureLogging(LogLevel.Debug)
  .withUrl(
    `${Constants.apiBaseUrl}/documentHub`,
    {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
      accessTokenFactory: () =>
        Session.loadFromStorage(() => {
        }).authToken?.value ?? "",
    }
  )
  .withAutomaticReconnect()
  .build();

const callbacks = withCallbacks()
  .add(
    "document-created",
    (apiResponseObject: object) => (dispatch) => {
      const document = Object.assign(
        new DocumentAPIResponse(),
        apiResponseObject
      ).deserialize();
      dispatch(addDocument(document));
    }
  )
  .add(
    "document-updated",
    (apiResponseObject: object) => (dispatch) => {
      const document = Object.assign(
        new DocumentAPIResponse(),
        apiResponseObject
      ).deserialize();
      dispatch(addDocument(document));
    }
  )
  .add(
    "document-deleted",
    (apiResponseObject: object) => (dispatch) => {
      const document = Object.assign(
        new DocumentAPIResponse(),
        apiResponseObject
      ).deserialize();
      dispatch(removeDocument(document));
    }
  );

export const documentsSignalRReduxMiddleware = reduxSignalRMiddleware({
  callbacks,
  connection: documentsHubConnection,
  shouldConnectionStartImmediately: true,
});
