import * as Constants from 'common/helpers/constants';
import { ArrayChip, BoolChip, RangeChip, } from 'marketplace/components';
import { NumericChip, TextChip } from 'marketplace/view/filter-chip';
import { FilterChipType } from 'marketplace/view/filter-chips';

export const Filters: Array<FilterChipType> = [
  Object.assign(
    new TextChip('query', 'Search'),
    {
      enabled: false,
      hidden: true
    } as TextChip
  ),
  Object.assign(
    new BoolChip('hideCompanies', 'Hide Companies'),
    {
      enabled: false
    } as BoolChip
  ),
  Object.assign(
    new BoolChip('hideTeams', 'Hide Teams'),
    {
      enabled: false
    } as BoolChip
  ),
  Object.assign(
    new BoolChip('hideIndividuals', 'Hide Individuals'),
    {
      enabled: false
    } as BoolChip
  ),
  Object.assign(
    new NumericChip('applicationsDrafted', 'Applications Drafted'),
    {
      enabled: false,
      default: 0
    } as NumericChip
  ),
  Object.assign(
    new NumericChip('allowedApplications', 'Allowed Applications'),
    {
      enabled: false,
      default: 0
    } as NumericChip
  ),
  Object.assign(
    new TextChip('technicalBackground', 'Technical Background'),
    {
      enabled: false
    } as TextChip
  ),
  Object.assign(
    new TextChip('school', 'Undergrad/Law School'),
    {
      enabled: false
    } as TextChip
  ),
  Object.assign(
    new NumericChip('yearsExperience', 'Years Experience'),
    {
      enabled: false,
      default: 0
    } as NumericChip
  ),
  Object.assign(
    new BoolChip('diversityCandidate', 'Diversity Candidate'),
    {
      enabled: false
    } as BoolChip
  ),
  Object.assign(
    new ArrayChip('fluentLanguages', 'Fluent Languages'),
    {
      enabled: false,
      freeSolo: false,
      multiple: true,
      options: Constants.languages.map((option) => option.name)
    } as ArrayChip
  ),
  Object.assign(
    new ArrayChip('technicalLanguages', 'Technical Languages'),
    {
      enabled: false,
      freeSolo: false,
      multiple: true,
      options: Constants.languages.map((option) => option.name)
    } as ArrayChip
  ),
  Object.assign(
    new TextChip('location', 'Location'),
    {
      enabled: false
    } as TextChip
  ),
  Object.assign(
    new ArrayChip('statesLicensedIn', 'States Licensed In'),
    {
      enabled: false,
      freeSolo: false,
      multiple: true,
      options: Constants.states.map((option) => option.name)
    } as ArrayChip
  ),
  Object.assign(
    new ArrayChip('regionsRegisteredIn', 'Regions Registered In'),
    {
      enabled: false,
      freeSolo: false,
      multiple: true,
      options: Constants.countries.map((option) => option.name)
    } as ArrayChip
  ),
  Object.assign(
    new BoolChip('willingToCollaborate', 'Willing To Collaborate'),
    {
      enabled: false
    } as BoolChip
  ),
  Object.assign(
    new RangeChip('billingRate', 'Billing Rate'),
    {
      enabled: false,
      default: [0, 999],
      value: [0, 999],
      min: 0,
      max: 999,
      steps: 1
    } as RangeChip
  ),
  Object.assign(
    new BoolChip('onCall', 'On Call'),
    {
      enabled: false
    } as BoolChip
  )
];
