import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import * as Constants from "common/helpers/constants";
import reduxSignalRMiddleware, {
  withCallbacks,
} from "common/realtime/redux-signalr-middleware";
import Guid from "common/values/guid/guid";
import { addMessage, removeMessage } from "messaging/entities/forum/store/forums-redux-slice";
import MessageAPIResponse from "messaging/entities/message/api/response-contracts/message-api-response";
import Session from "users/session/session";

export const messageHubConnection = new HubConnectionBuilder()
  .configureLogging(LogLevel.Debug)
  .withUrl(`${Constants.apiBaseUrl}/messageHub`, {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
    accessTokenFactory: () => {
      const sessionPromise = new Promise<string>((resolve, reject) => {
        const authToken = Session.loadFromStorage(() => {}).authToken?.value
        if (authToken) {
          resolve(authToken)
        } else {
          reject(new Error('No token found'))
        }
      })
    
      return sessionPromise;
    }
  })
  .withAutomaticReconnect()
  .build();

const callbacks = withCallbacks()
  .add("message-received", (apiResponseObject: object) => (dispatch) => {
    const message = Object.assign(
      new MessageAPIResponse(),
      apiResponseObject
    ).deserialize();
    dispatch(addMessage(message));
  })
  .add("message-deleted", (messageDeletedResponse: {id: string}) => (dispatch) => {
    const id = new Guid(messageDeletedResponse.id);
    dispatch(removeMessage(id));
  });

export const messagesSignalRReduxMiddleware = reduxSignalRMiddleware({
  callbacks,
  connection: messageHubConnection,
  shouldConnectionStartImmediately: true
});
