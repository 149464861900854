import Guid from "common/values/guid/guid";
import moment from "moment";
import ProposalReviewer from "work/values/proposal-reviewer";

export default class ProposalReviewerAPIResponse {
  userId?: string;
  canEdit?: boolean;
  dateApproved?: string;
  customMessage?: string;

  public deserialize(): ProposalReviewer {
    if (!this.userId) throw new Error('userId is required, but not returned from the API');
    if (this.canEdit === undefined) throw new Error('canEdit is required, but not returned from the API');

    return new ProposalReviewer(
      new Guid(this.userId),
      this.canEdit,
      undefined,
      this.dateApproved ? moment(this.dateApproved) : undefined,
      this.customMessage
    );
  }
}
