import Guid from "common/values/guid/guid";
import Address from "common/values/address/address";

export default class CompanyProfile {
    name: string;
    description?: string;
    location?: Address;
    yearEstablished?: number;
    website?: string;
    numberOfEmployees?: number;
    categories: string[] = [];
    avatarId?: Guid;
    contactId?: Guid;
    contactUserId?: Guid;
    type?: string;

  constructor(
    name: string,
    description?: string,
    location?: Address,
    yearEstablished?: number,
    website?: string,
    numberOfEmployees?: number,
    categories?: string[],
    avatarId?: Guid,
    contactId?: Guid,
    contactUserId?: Guid,
  ) {
    this.name = name;
    this.description = description;
    this.location = location;
    this.yearEstablished = yearEstablished;
    this.website = website;
    this.numberOfEmployees = numberOfEmployees;
    this.categories = categories ?? [];
    this.avatarId = avatarId;
    this.contactId = contactId;
    this.contactUserId = contactUserId;
  }

  clone(): CompanyProfile {
    const newProfile = new CompanyProfile(this.name);
    Object.assign(newProfile, this);
    return newProfile;
  }

  updateName(name: string): CompanyProfile {
    return new CompanyProfile(
      name,
      this.description,
      this.location,
      this.yearEstablished,
      this.website,
      this.numberOfEmployees,
      this.categories,
      this.avatarId,
      this.contactId,
      this.contactUserId
    );
  }

  updateCategories(categories: string[]): CompanyProfile {
    return new CompanyProfile(
      this.name,
      this.description,
      this.location,
      this.yearEstablished,
      this.website,
      this.numberOfEmployees,
      categories,
      this.avatarId,
      this.contactId,
      this.contactUserId
    );
  }

  updateContactId(contactId: Guid): CompanyProfile {
    return new CompanyProfile(
      this.name,
      this.description,
      this.location,
      this.yearEstablished,
      this.website,
      this.numberOfEmployees,
      this.categories,
      this.avatarId,
      contactId,
      this.contactUserId
    );
  }

  updateYearEstablished(yearEstablished?: number): CompanyProfile {
    return new CompanyProfile(
      this.name,
      this.description,
      this.location,
      yearEstablished,
      this.website,
      this.numberOfEmployees,
      this.categories,
      this.avatarId,
      this.contactId,
      this.contactUserId
    );
  }

  updateNumberOfEmployees(numberOfEmployees?: number): CompanyProfile {
    return new CompanyProfile(
      this.name,
      this.description,
      this.location,
      this.yearEstablished,
      this.website,
      numberOfEmployees,
      this.categories,
      this.avatarId,
      this.contactId,
      this.contactUserId
    );
  }

  updateWebsite(website?: string): CompanyProfile {
    return new CompanyProfile(
      this.name,
      this.description,
      this.location,
      this.yearEstablished,
      website,
      this.numberOfEmployees,
      this.categories,
      this.avatarId,
      this.contactId,
      this.contactUserId
    );
  }

  updateDescription(description?: string): CompanyProfile {
    return new CompanyProfile(
      this.name,
      description,
      this.location,
      this.yearEstablished,
      this.website,
      this.numberOfEmployees,
      this.categories,
      this.avatarId,
      this.contactId,
      this.contactUserId
    );
  }

  updateLocation(location: Address | null): CompanyProfile {
    return new CompanyProfile(
      this.name,
      this.description,
      location ? location : undefined,
      this.yearEstablished,
      this.website,
      this.numberOfEmployees,
      this.categories,
      this.avatarId,
      this.contactId,
      this.contactUserId
    );
  }

  public static fromJSON(object: any): CompanyProfile {
    const profile = new CompanyProfile(object.name);
    profile.description = object.description;
    profile.location = object.location;
    profile.yearEstablished = object.yearEstablished;
    profile.website = object.website;
    profile.numberOfEmployees = object.numberOfEmployees;
    profile.categories = object.categories;
    profile.avatarId = object.avatarId ? Guid.fromJSON(object.avatarId) : undefined;
    profile.contactId = object.contactId ? Guid.fromJSON(object.contactId) : undefined;
    profile.contactUserId = object.contactUserId ? Guid.fromJSON(object.contactUserId) : undefined;
    return profile;
  }

  public toJSON(): any {
    return {
      name: this.name,
      description: this.description,
      location: this.location,
      yearEstablished: this.yearEstablished,
      website: this.website,
      numberOfEmployees: this.numberOfEmployees,
      categories: this.categories,
      avatarId: this.avatarId?.toJSON(),
      contactId: this.contactId?.toJSON(),
      contactUserId: this.contactUserId?.toJSON()
    };
  }
}
