import InfoIcon from "@mui/icons-material/InfoOutlined";
import {IconButton, TextField, Tooltip, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import React, {useEffect} from "react";
import {NumericFormat} from "react-number-format";
import CommentThread from "work/entities/comment-thread/comment-thread";
import Proposal, {ProposalField, ProposalFieldCategory} from "work/entities/proposal/proposal";
import CommentsButton from "work/entities/comment-thread/view/comments-button";
import {some} from "lodash";

const ComponentContainer = styled("div")(
  ({theme}) => ({
    margin: theme.spacing(
      2,
      0,
      4,
      0
    ),
  }));
const HelperText = styled(Typography)(
  ({theme}) => ({
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));


const discountDescription =
  "You can share the AttorneyHub fees you incur from a project by establishing " +
  'an agreed upon "discount" from your vendor. 2.912% is the value to cover the cost.';

type DiscountArchivedTabProps = {
  activeTab: ProposalFieldCategory;
  proposal: Proposal;
  commentThreads?: CommentThread[];
  onCommentsClicked: (
    field: ProposalField,
    title?: string
  ) => void;
};

export default function DiscountArchivedTab(props: Readonly<DiscountArchivedTabProps>) {
  const {
    activeTab,
    proposal,
    commentThreads,
    onCommentsClicked
  } = props;

  const [discount, setDiscount] = React.useState(0);

  useEffect(
    () => {
      if (activeTab !== ProposalFieldCategory.Discount) return;

      setDiscount(proposal.discount.numeral ?? 0);
    },
    [
      proposal,
      activeTab
    ]
  );

  if (activeTab !== ProposalFieldCategory.Discount) return null;

  return (
    <ComponentContainer>
      <NumericFormat
        name="discount"
        label="Discount"
        customInput={TextField}
        allowNegative={false}
        value={Number(discount * 100)}
        suffix="%"
        type="text"
        thousandSeparator={false}
        decimalScale={2}
        disabled={true}
        slotProps={{
          input: {
            endAdornment: (
              <CommentsButton
                commentThreads={commentThreads}
                field={ProposalField.Discount}
                toolTip={commentThreads?.some((thread) =>
                  thread.field.isEqualTo(ProposalField.Discount)) ? "Comments" : "No Comments"}
                onCommentsClicked={
                  onCommentsClicked ? () => {
                    onCommentsClicked(
                      ProposalField.Discount
                    );
                  } : undefined
                }
              />
            )
          }
        }}
      />
      <HelperText>
        Discounts help cover subscription costs.
        <Tooltip
          disableFocusListener
          title={discountDescription}
          placement="right"
          arrow
        >
          <IconButton size="small">
            <InfoIcon/>
          </IconButton>
        </Tooltip>
      </HelperText>
    </ComponentContainer>
  );
}
