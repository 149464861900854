import {Divider, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import {ConfirmResponse, useConfirmDialog,} from "app/providers/confirm-dialog";
import {downloadFile} from "common/helpers/utils";
import Guid from "common/values/guid/guid";
import DocumentAPIService from "documents/entities/document/api/document-api-service";
import Document from "documents/entities/document/document";
import DocumentSelector from "documents/view/document-selector";
import DocumentUploadOverlay from "documents/view/document-upload-overlay";
import {enqueueSnackbar} from "notistack";
import React from "react";
import {useSession} from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import {ProposalField, ProposalFieldCategory,} from "work/entities/proposal/proposal";
import ProposalBuilder from "work/entities/proposal/utils/proposal-builder";
import ProposalIssues, {ProposalIssueLevel} from "work/values/proposal-issues/proposal-issues";
import SelectedDocumentList from "work/values/work-document/view/selected-document-list";
import WorkDocument, {WorkDocumentType,} from "work/values/work-document/work-document";
import CommentsButton from "work/entities/comment-thread/view/comments-button";

const ListContainer = styled(Grid)(
  () => ({
    flexWrap: "nowrap",
    minHeight: "20rem",
    width: "100%",
  }));
const DocumentSelectorContainer = styled(Grid)(
  () => ({
    display: "flex",
  }));
const SelectedDocsList = styled(SelectedDocumentList)(
  ({theme}) => ({
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(2),
    },
    maxHeight: "60vh",
    marginLeft: theme.spacing(2),
    overflowY: "auto",
  }));
const HeadControls = styled("div")(
  ({theme}) => ({
    display: "flex",
    justifyContent: "end",
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  }));
const Uploader = styled(DocumentUploadOverlay)(
  () => ({
    backgroundColor: "rgba(250, 250, 250, 0.5)",
    backdropFilter: "blur(5px) saturate(200%)",
    position: "absolute",
    top: 0,
    left: 0,
    margin: "1rem",
    width: "calc(100% - 2rem)",
    height: "calc(100% - 2rem)",
    zIndex: 9999,
  }));
const IssueContainer = styled("div")(
  ({theme}) => ({
    marginTop: theme.spacing(1)
  }));

type PolicyUploadProps = {
  activeTab: ProposalFieldCategory;
  proposalBuilder: ProposalBuilder;
  issues?: ProposalIssues;
  disableCommenting?: boolean;
  commentsToolTip: string;
  commentThreads?: CommentThread[];
  disableEditing?: boolean;
  onCommentsClicked?: (field: ProposalField, name?: string) => void;
  onProposalBuilderUpdated: (updatedProposalBuilder: ProposalBuilder) => void;
};

export default function PoliciesTab(props: Readonly<PolicyUploadProps>) {
  const {
    activeTab,
    proposalBuilder,
    disableCommenting,
    commentsToolTip,
    commentThreads,
    disableEditing,
    onCommentsClicked,
    onProposalBuilderUpdated,
  } = props;

  const [creatingDocumentFromTemplate, setCreatingDocumentFromTemplate] =
    React.useState<Guid | null>(null);
  const [isDownloadingFile, setIsDownloadingFile] = React.useState<Guid | null>(
    null
  );

  const issues = React.useMemo(
    () => props.issues?.entries.filter(issue => [
      ProposalField.Policies,
      ProposalField.VendorPolicies,
      ProposalField.ClientPolicies,
      ProposalField.VendorPolicyDocument(issue.field.id ?? Guid.empty),
      ProposalField.ClientPolicyDocument(issue.field.id ?? Guid.empty)
    ].includes(issue.field)),
    [props.issues]
  );

  const confirm = useConfirmDialog();
  const session = useSession();

  async function handlePolicyDocumentRemoved(documentId: Guid): Promise<void> {
    const response = await confirm({
      title: "Remove Policy Document",
      message: "Are you sure you want to remove this policy document?",
    });

    if (response === ConfirmResponse.Cancel) return;

    let updatedProposalBuilder: ProposalBuilder;
    if (session.context?.viewingAsVendor) {
      updatedProposalBuilder = proposalBuilder.setVendorPolicyDocuments(
        [...(proposalBuilder.currentSpec.vendorPolicyDocuments ?? [])].filter(
          (doc) => !doc.id.isEqualTo(documentId)
        )
      );
    } else {
      updatedProposalBuilder = proposalBuilder.setClientPolicyDocuments(
        [...(proposalBuilder.currentSpec.clientPolicyDocuments ?? [])].filter(
          (doc) => !doc.id.isEqualTo(documentId)
        )
      );
    }

    onProposalBuilderUpdated(updatedProposalBuilder);
    onCommentsClicked?.(
      session.context?.viewingAsVendor ? ProposalField.VendorPolicies : ProposalField.ClientPolicies,
      undefined
    );
  }

  async function handleDownloadDocumentById(
    documentId: Guid,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    event?.stopPropagation();
    try {
      setIsDownloadingFile(documentId);
      const documentApiService = new DocumentAPIService(session);
      const policyDocument = await documentApiService.downloadDocument(
        documentId
      );
      downloadFile(policyDocument);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        "Failed to download policy document. Please try again",
        {
          variant: "error",
        }
      );
    } finally {
      setIsDownloadingFile(null);
    }
  }

  function handleDocumentSelectionChanged(
    selectedDocs: Document[],
    deselectedDocs: Document[]
  ) {
    let currentDocuments = session.context?.viewingAsVendor
      ? [...(proposalBuilder.currentSpec.vendorPolicyDocuments ?? [])]
      : [...(proposalBuilder.currentSpec.clientPolicyDocuments ?? [])];

    for (const selectedDoc of selectedDocs) {
      if (!currentDocuments.some((doc) => doc.id.isEqualTo(selectedDoc.id))) {
        const workDocument = WorkDocument.fromDocument(
          selectedDoc,
          session.context?.viewingAsVendor
            ? WorkDocumentType.VendorPolicy
            : WorkDocumentType.ClientPolicy
        );
        currentDocuments.push(workDocument);
      }
    }

    for (const deselectedDoc of deselectedDocs) {
      currentDocuments = currentDocuments.filter(
        (doc) => !doc.id.isEqualTo(deselectedDoc.id)
      );
    }

    let updatedProposalBuilder = proposalBuilder;
    if (session.context?.viewingAsVendor) {
      updatedProposalBuilder = updatedProposalBuilder.setVendorPolicyDocuments(currentDocuments);
    } else {
      updatedProposalBuilder = updatedProposalBuilder.setClientPolicyDocuments(currentDocuments);
    }

    onProposalBuilderUpdated(updatedProposalBuilder);
    onCommentsClicked?.(
      session.context?.viewingAsVendor ? ProposalField.VendorPolicies : ProposalField.ClientPolicies,
      undefined
    );
  }

  function handlePolicyDocumentAdded(policyDocument: Document) {
    let currentDocuments = session.context?.viewingAsVendor
      ? proposalBuilder.currentSpec.vendorPolicyDocuments ?? []
      : proposalBuilder.currentSpec.clientPolicyDocuments ?? [];
    currentDocuments = [...currentDocuments]; // Create a shallow copy

    const newDocument = WorkDocument.fromDocument(
      policyDocument,
      session.context?.viewingAsVendor
        ? WorkDocumentType.VendorPolicy
        : WorkDocumentType.ClientPolicy
    );
    currentDocuments.push(newDocument);

    let updatedProposalBuilder = proposalBuilder;
    if (session.context?.viewingAsVendor) {
      updatedProposalBuilder = updatedProposalBuilder.setVendorPolicyDocuments(currentDocuments);
    } else {
      updatedProposalBuilder = updatedProposalBuilder.setClientPolicyDocuments(currentDocuments);
    }

    setCreatingDocumentFromTemplate(null);
    onProposalBuilderUpdated(updatedProposalBuilder);
    onCommentsClicked?.(
      session.context?.viewingAsVendor ? ProposalField.VendorPolicies : ProposalField.ClientPolicies,
      undefined
    );
  }

  function getShouldShowBadge(documentId?: Guid): boolean {
    let field: ProposalField;

    if (session.context?.viewingAsVendor && documentId) {
      field = ProposalField.VendorPolicyDocument(documentId);
    } else if (session.context?.viewingAsVendor && !documentId) {
      field = ProposalField.VendorPolicies;
    } else if (!session.context?.viewingAsVendor && documentId) {
      field = ProposalField.ClientPolicyDocument(documentId);
    } else {
      field = ProposalField.ClientPolicies;
    }
    return commentThreads?.some((thread) => thread.field.isEqualTo(field)) ?? false;
  }

  function getNumUnreadComments(documentId?: Guid): number {
    let field: ProposalField;

    if (session.context?.viewingAsVendor && documentId) {
      field = ProposalField.VendorPolicyDocument(documentId);
    } else if (session.context?.viewingAsVendor && !documentId) {
      field = ProposalField.VendorPolicies;
    } else if (!session.context?.viewingAsVendor && documentId) {
      field = ProposalField.ClientPolicyDocument(documentId);
    } else {
      field = ProposalField.ClientPolicies;
    }

    return commentThreads?.filter((thread) =>
      thread.field.isEqualTo(field)
    ).reduce(
      (acc, thread) => acc + (thread.numUnread ?? 0),
      0
    ) ?? 0;
  }

  async function handleBeginCreateDocumentFromTemplate(
    templateId: Guid | undefined | null
  ) {
    if (!templateId) return;
    setCreatingDocumentFromTemplate(templateId);
    handleDownloadDocumentById(templateId);
  }

  function renderDocumentSelector() {
    let selectedTemplateIds: Array<Guid> = [];
    const selectedDocuments = session.context?.viewingAsVendor
      ? proposalBuilder.currentSpec.vendorPolicyDocuments ?? []
      : proposalBuilder.currentSpec.clientPolicyDocuments ?? [];
    selectedDocuments.forEach((doc) => {
      if (doc instanceof Document) {
        selectedTemplateIds = [
          ...selectedTemplateIds,
          ...doc.templateIds
        ];
      }
    });
    return (
      <DocumentSelector
        documentType="policy"
        selectedDocumentIds={selectedDocuments.map((doc) => doc.id)}
        selectedTemplateIds={selectedTemplateIds}
        onDocumentSelectionChanged={handleDocumentSelectionChanged}
        onCreateDocumentFromTemplate={handleBeginCreateDocumentFromTemplate}
      />
    );
  }

  if (activeTab !== ProposalFieldCategory.Policies) return null;
  let field: ProposalField;
  let selectedDocuments: WorkDocument[] = []
  if (session.context?.viewingAsVendor) {
    field = ProposalField.VendorPolicies;
    selectedDocuments = proposalBuilder.currentSpec.vendorPolicyDocuments ?? []
  } else {
    field = ProposalField.ClientPolicies;
    selectedDocuments = proposalBuilder.currentSpec.clientPolicyDocuments ?? []
  }
  return (
    <>
      <HeadControls>
        {(issues?.length ?? 0) > 0 && (
          <IssueContainer>
            {issues?.map((issue) => (
              <Typography
                key={issue.field?.key}
                color={issue.level === ProposalIssueLevel.Critical ? "error" : "warning"}
                variant="body1"
              >
                {issue.description}
              </Typography>
            ))}
          </IssueContainer>
        )}
        <CommentsButton
          field={field}
          toolTip={commentsToolTip}
          commentThreads={commentThreads}
          onCommentsClicked={onCommentsClicked ? () => {
            onCommentsClicked(field)
          } : undefined}
        />
      </HeadControls>
      <ListContainer container direction="row">
        <DocumentSelectorContainer size="grow">
          {!disableEditing && renderDocumentSelector()}
        </DocumentSelectorContainer>
        <Grid>
          <Divider orientation="vertical"/>
        </Grid>
        <Grid size={4}>
          <SelectedDocsList
            documents={selectedDocuments}
            documentType={
              session.context?.viewingAsVendor
                ? WorkDocumentType.VendorPolicy
                : WorkDocumentType.ClientPolicy
            }
            downloadingFileId={isDownloadingFile}
            commentsToolTip={commentsToolTip}
            commentThreads={commentThreads}
            disableEditing={disableEditing}
            onCommentsClicked={onCommentsClicked}
            onDownload={handleDownloadDocumentById}
            onDocumentRemoved={handlePolicyDocumentRemoved}
            onDocumentAdded={handlePolicyDocumentAdded}
          />
        </Grid>
      </ListContainer>
      {creatingDocumentFromTemplate && (
        <Uploader
          session={session}
          documentType={
            session.context?.viewingAsVendor
              ? WorkDocumentType.VendorPolicy
              : WorkDocumentType.ClientPolicy
          }
          templateId={creatingDocumentFromTemplate}
          onDocumentUploaded={handlePolicyDocumentAdded}
          onClose={() => setCreatingDocumentFromTemplate(null)}
        />
      )}
    </>
  );
}
