export interface Coordinates {
  latitude: string;
  longitude: string;
}

export default class Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  coordinates?: Coordinates;

  constructor(
    address1: string,
    address2: string,
    city: string,
    state: string,
    postalCode: string,
    country: string,
    coordinates?: Coordinates
  ) {
    this.address1 = address1;
    this.address2 = address2;
    this.city = city;
    this.state = state;
    this.postalCode = postalCode;
    this.country = country;
    this.coordinates = coordinates;

    Address.validate(
      this.address1,
      this.address2,
      this.city,
      this.state,
      this.postalCode,
      this.country,
      this.coordinates
    );
  }

  static validate(
    address1: string,
    address2: string,
    city: string,
    state: string,
    postalCode: string,
    country: string,
    coordinates?: Coordinates
  ) {
    console.debug(
      address1,
      address2,
      city,
      state,
      postalCode,
      country,
      coordinates
    );
    if (!address1 && !address2 && !city && !state && !postalCode && !country && !coordinates) {
      throw new InvalidAddressError("at least one of the fields must be non-empty.");
    }
  }

  public isEqualTo(other?: Address): boolean {
    if (!other) return false;
    return this.address1 === other.address1
      && this.address2 === other.address2
      && this.city === other.city
      && this.state === other.state
      && this.postalCode === other.postalCode
      && this.country === other.country
      && this.coordinates === other.coordinates;
  }

  public toString(): string {
    return `${this.address1}${this.address2 ? ' ' + this.address2 + ',' : ','} ${this.city}, ${this.state}, ${this.postalCode}, ${this.country}`;
  }
}


export class InvalidAddressError extends Error {
  constructor(value: string) {
    super(`Invalid address: ${value}`);
  }
}
