export default class Text {
  protected _value: string;

  constructor(value: string) {
    this._value = value;
  }

  public get isEmpty(): boolean {
    return this.value === '';
  }

  public get value(): string {
    return this._value;
  }

  public clone(): Text {
    return new Text(this._value);
  }

  public isEqualTo(other: Text | null | undefined): boolean {
    if (!other) {
      return false;
    }
    return this._value === other.value;
  }

  public static fromJSON(obj: any): Text | null {
    return new Text(obj);
  }

  public fromJSON(obj: any): Text | null {
    return Text.fromJSON(obj);
  }

  public toJSON(): any {
    return this.value;
  }

  public toString(): string {
    return this.value;
  }
}