import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import moment from "moment";
import EntityClientRepresentative from "work/entities/entity-client-representative/entity-client-representative";
import WorkAgreement from "work/entities/work-agreement/work-agreement";
import ProjectDescription from "work/values/project-description/project-description";
import ProjectName from "work/values/project-name/project-name";
import DetailedWorkClientRepresentativeAPIResponse
  from "work/entities/entity-client-representative/api/response-contracts/detailed-work-client-representative-api-response";
import DetailedWorkTeamAPIResponse from "work/values/team/api/response-contracts/detailed-work-team-api-response";
import WorkVendorRepresentativeAPIResponse
  from "work/entities/entity-vendor-representative/api/response-contracts/work-vendor-representative-api-response";
import WorkFeeScheduleCategoryAPIResponse
  from "work/values/fee-schedule-category/api/response-contracts/work-fee-schedule-category-api-response";
import ReplaceableDocumentAPIResponse
  from "work/entities/proposal/api/response-contracts/replaceable-document-api-response";
import {WorkDocumentType} from "work/values/work-document/work-document";
import Percent from "common/values/percent/percent";
import AHBoolean from "common/values/boolean/boolean";
import Date from "common/values/date/date";

export default class WorkAgreementAPIResponse {
  id?: string;
  RFPId?: string;
  name?: string;
  description?: string;

  client?: DetailedWorkClientRepresentativeAPIResponse;
  vendors: WorkVendorRepresentativeAPIResponse[] = [];
  team?: DetailedWorkTeamAPIResponse;
  feeSchedule: WorkFeeScheduleCategoryAPIResponse[] = [];
  startDate?: string;
  endDate?: string;
  discount?: Percent;

  clientPolicyDocuments: ReplaceableDocumentAPIResponse[] = [];
  vendorPolicyDocuments: ReplaceableDocumentAPIResponse[] = [];
  conflictsDocuments: ReplaceableDocumentAPIResponse[] = [];

  clientTeamTemplateIds: string[] = [];
  vendorTeamTemplateIds: string[] = [];
  clientFeeScheduleTemplateIds: string[] = [];
  vendorFeeScheduleTemplateIds: string[] = [];
  conflictsCheckWaived?: boolean | null;
  teamRestricted?: boolean;
  hireDate?: string;

  deserialize(): WorkAgreement {
    if (!this.name)
      throw new Error("Work agreement name is required");

    if (!this.client)
      throw new Error("Client is required");

    const clientAsAPIResponse = Object.assign(
      new DetailedWorkClientRepresentativeAPIResponse(),
      this.client
    );
    const individual = clientAsAPIResponse.deserialize();
    if (!individual.userId)
      throw new Error("Client user id is required");
    if (!individual.entityId)
      throw new Error("Client entity id is required");

    const projectName = new ProjectName(this.name);
    const clientName = new Name(
      individual.profile?.firstName,
      individual.profile?.lastName
    );
    const clientRep = new EntityClientRepresentative(
      individual.userId,
      individual.entityId,
      clientName
    );

    const agreement = new WorkAgreement();
    agreement.name = projectName;
    agreement.client = clientRep;
    agreement.id = this.id ? new Guid(this.id) : undefined;
    agreement.RFPId = this.RFPId ? new Guid(
      this.RFPId,
      true
    ) : undefined;
    agreement.description = this.description ? new ProjectDescription(this.description) : undefined;
    agreement.client = clientRep;
    agreement.vendors = this.vendors.map(
      vendor => Object.assign(
        new WorkVendorRepresentativeAPIResponse(),
        vendor
      ).deserialize()
    );
    agreement.team = this.team ?
      Object.assign(
        new DetailedWorkTeamAPIResponse(),
        this.team
      ).deserializeDetailed() : undefined;
    agreement.feeSchedule = this.feeSchedule.map(
      feeSchedule => Object.assign(
        new WorkFeeScheduleCategoryAPIResponse(),
        feeSchedule
      ).deserialize()
    );
    agreement.startDate = this.startDate ? new Date(moment(this.startDate)) : undefined;
    agreement.endDate = this.endDate ? new Date(moment(this.endDate)) : undefined;
    agreement.discount = this.discount ?? new Percent(0);
    agreement.clientPolicyDocuments = this.clientPolicyDocuments?.filter((document) => document.documentInfo).map(
      doc => Object.assign(
        new ReplaceableDocumentAPIResponse(),
        doc
      ).deserialize(WorkDocumentType.ClientPolicy)
    );
    agreement.vendorPolicyDocuments = this.vendorPolicyDocuments?.filter((document) => document.documentInfo).map(
      doc => Object.assign(
        new ReplaceableDocumentAPIResponse(),
        doc
      ).deserialize(WorkDocumentType.VendorPolicy)
    );
    agreement.conflictsDocuments = this.conflictsDocuments?.filter((document) => document.documentInfo).map(
      doc => Object.assign(
        new ReplaceableDocumentAPIResponse(),
        doc
      ).deserialize(WorkDocumentType.Conflicts)
    );

    agreement.clientTeamTemplateIds = this.clientTeamTemplateIds.map(id => new Guid(id));
    agreement.vendorTeamTemplateIds = this.vendorTeamTemplateIds.map(id => new Guid(id));
    agreement.clientFeeScheduleTemplateIds = this.clientFeeScheduleTemplateIds.map(id => new Guid(id));
    agreement.vendorFeeScheduleTemplateIds = this.vendorFeeScheduleTemplateIds.map(id => new Guid(id));
    agreement.conflictsCheckWaived = new AHBoolean(this.conflictsCheckWaived ?? false);
    agreement.teamRestricted = new AHBoolean(this.teamRestricted ?? false);
    agreement.hireDate = this.hireDate ? new Date(moment(this.hireDate)) : undefined;

    return agreement;
  }
}
