import Guid from "common/values/guid/guid";
import Name from "common/values/name/name";
import EntityRepresentative from "work/entities/entity-representative/entity-representative";

export default class EntityClientRepresentative implements EntityRepresentative {
  userId: Guid;
  entityId: Guid;
  name: Name;

  constructor(userId: Guid, entityId: Guid, name: Name) {
    this.userId = userId;
    this.entityId = entityId;
    this.name = name;
  }

  public isEqualTo(other: EntityClientRepresentative | null | undefined): boolean {
    if (!other) return false;
    return this.userId.isEqualTo(other.userId) && this.entityId.isEqualTo(other.entityId);
  }

  public clone(): EntityClientRepresentative {
    return new EntityClientRepresentative(
      this.userId.clone(),
      this.entityId.clone(),
      this.name
    );
  }

  public static fromJSON(json: any): EntityClientRepresentative {
    const userId = Guid.fromJSON(json.userId);
    const entityId = Guid.fromJSON(json.entityId);
    const name = Name.fromJSON(json.name);
    if (!userId || !entityId || !name) {
      throw new Error("Invalid JSON for EntityClientRepresentative");
    }
    return new EntityClientRepresentative(
      userId,
      entityId,
      name
    );
  }

  toJSON(): object {
    return {
      userId: this.userId.toJSON(),
      entityId: this.entityId.toJSON(),
      name: this.name.toJSON(),
    };
  }
}
