import GetAppIcon from "@mui/icons-material/GetApp";
import InfoIcon from "@mui/icons-material/Info";

import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from "@mui/material";
import {styled} from "@mui/material/styles";
import Guid from "common/values/guid/guid";
import Document from "documents/entities/document/document";
import UnavailableDocument from "documents/values/unavailable-document";
import moment from "moment";
import React from "react";
import {defaultStyles, FileIcon} from "react-file-icon";
import {useSession} from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import {ProposalField} from "work/entities/proposal/proposal";
import WorkDocument, {WorkDocumentType} from "work/values/work-document/work-document";
import CommentsButton from "work/entities/comment-thread/view/comments-button";

const DocumentList = styled(List)(
  () => ({
    "&.MuiList-root": {
      marginLeft: 0,
    },
    minWidth: "20rem",
  }));
const ListSubhead = styled(ListSubheader)(
  () => ({
    backgroundColor: "#FFF",
    color: "#000",
    display: "flex",
    fontSize: "1.4em",
    justifyContent: "space-between",
    paddingRight: 0,
    whiteSpace: "nowrap",
  }));
const NoRowsPlaceholder = styled(Typography)(
  ({theme}) => ({
    fontSize: "1.3em",
    paddingTop: theme.spacing(1),
  }));
const DocumentIcon = styled(ListItemAvatar)(
  ({theme}) => ({
    marginRight: theme.spacing(1),
    minWidth: "2rem",
    maxWidth: "2rem",
  }));

type DocumentListReadonlyProps = {
  className?: string;
  documents?: WorkDocument[];
  documentType: WorkDocumentType;
  downloadingFileId: Guid | null;
  commentThreads?: CommentThread[];
  conflictsCheckWaived?: boolean;
  onCommentsClicked: (field: ProposalField, name?: string) => void;
  disableCommentsPane?: boolean;
  onDownload: (documentId: Guid) => void;
};

export default function DocumentListReadonly(
  props: Readonly<DocumentListReadonlyProps>
) {
  const {
    className,
    documents,
    documentType,
    downloadingFileId,
    commentThreads,
    conflictsCheckWaived,
    onCommentsClicked,
    disableCommentsPane,
    onDownload
  } = props;

  const session = useSession();

  function getDocumentField(document: Document): ProposalField {
    if (documentType === WorkDocumentType.Conflicts) {
      return ProposalField.ConflictsDocument(document.id);
    } else {
      return session.context?.viewingAsVendor
        ? ProposalField.VendorPolicyDocument(document.id)
        : ProposalField.ClientPolicyDocument(document.id);
    }
  }

  function renderActionButtons(document: Document) {
    return (
      <>
        <Tooltip title="Download Document">
          <span>
            <IconButton
              edge="end"
              disabled={downloadingFileId !== null}
              onClick={() => onDownload(document.id)}
              size="medium"
            >
              {downloadingFileId?.isEqualTo(document.id) ? (
                <CircularProgress color="primary" size={24}/>
              ) : (
                <GetAppIcon color="primary"/>
              )}
            </IconButton>
          </span>
        </Tooltip>
        <CommentsButton
          commentThreads={commentThreads}
          field={getDocumentField(document)}
          toolTip={disableCommentsPane ? "Save proposal to enable commenting" : "Comments"}
          onCommentsClicked={
            onCommentsClicked ? () => {
              onCommentsClicked?.(getDocumentField(document));
            } : undefined
          }
        />
      </>
    );
  }

  return (
    <DocumentList disablePadding className={className}>
      <ListSubhead>
        {documentType === WorkDocumentType.Conflicts && (
          <span>
            Your Conflicts Checks
            <Tooltip
              disableFocusListener
              title="Conflicts document(s) establish potential conflicts checks required by participating clients/vendors"
              placement="bottom"
              arrow
            >
              <IconButton size="large" edge="end">
                <InfoIcon/>
              </IconButton>
            </Tooltip>
          </span>
        )}
        {(documentType === WorkDocumentType.ClientPolicy || documentType === WorkDocumentType.VendorPolicy) && (
          <span>
            Your Policies
            <Tooltip
              disableFocusListener
              title="Policy document(s) establish the policies required by participating clients/vendors"
              placement="bottom"
              arrow
            >
              <IconButton size="large" edge="end">
                <InfoIcon/>
              </IconButton>
            </Tooltip>
          </span>
        )}
      </ListSubhead>
      {!conflictsCheckWaived && (!documents || documents.length < 1) && (
        <ListItem key="noDocuments">
          <NoRowsPlaceholder>None</NoRowsPlaceholder>
        </ListItem>
      )}
      {!conflictsCheckWaived &&
        documents
          ?.filter((document) => !(document instanceof UnavailableDocument))
          .map((document: Document | UnavailableDocument) => {
            const availableDocument = document as Document;
            return (
              <ListItem
                key={document.id.value as React.Key}
                secondaryAction={
                  renderActionButtons(availableDocument)
                }
              >
                <DocumentIcon>
                  {document instanceof UnavailableDocument ? (
                    <FileIcon/>
                  ) : (
                    <FileIcon
                      extension={document.fileType}
                      {...defaultStyles[
                        document.fileType.toLowerCase() as keyof typeof defaultStyles
                        ]}
                    />
                  )}
                </DocumentIcon>
                <ListItemText
                  primary={availableDocument.name?.value}
                  secondary={moment(availableDocument.created).format(
                    "MM/DD/YY hh:mm A"
                  )}
                  slotProps={{
                    primary: {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "75%",
                    }
                  }}
                />
              </ListItem>
            );
          })}
    </DocumentList>
  );
}
