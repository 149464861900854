import DocumentTopic from "documents/values/document-topic";
import Guid from "common/values/guid/guid";
import Document from "documents/entities/document/document";
import { DocumentType } from "documents/values/document-type";
import moment from "moment";
import DocumentTopicApiResponse from "documents/entities/document/api/response-contracts/document-topic-api-response";
import UnavailableDocument from "documents/values/unavailable-document";
import DocumentName from "documents/values/document-name";

export default class DocumentAPIResponse {
  public id?: string;
  public creatorId?: string;
  public name?: string;
  public fileSize?: number;
  public fileType?: string;
  public created?: string;
  public readAccess?: string[] = [];
  public writeAccess?: string[] = [];
  public issue?: string;
  public topics?: DocumentTopicApiResponse[];
  public templateIds?: string[] = [];
  public hasSystemPermissions?: boolean

  deserialize(): Document | UnavailableDocument{
    if (!this.id) {
      throw new Error("DocumentAPIResponse is missing Id.");
    }
    if (this.issue) {
      return new UnavailableDocument(new Guid(this.id), this.issue);
    }
    const documentTopics: DocumentTopic[] = [];
    if (this.topics) {
      this.topics.forEach((topic) => {
        documentTopics.push(Object.assign(new DocumentTopicApiResponse(), topic).deserialize());
      });
    }
    let documentType: DocumentType = DocumentType.UNKNOWN;
    const documentTypes = Object.values(DocumentType);
    const documentTypeIndex = documentTypes.indexOf(this.fileType as DocumentType);

    if (documentTypeIndex > -1) {
      const documentTypeKeys = Object.keys(DocumentType);
      documentType = documentTypeKeys[documentTypeIndex] as DocumentType;
    }

    return new Document(
      new Guid(this.id),
      this.name ? new DocumentName(this.name) : undefined,
      this.creatorId ? new Guid(this.creatorId) : null,
      this.fileSize as number,
      documentType,
      moment(this.created),
      this.readAccess ? this.readAccess.map((id) => new Guid(id)) : [],
      this.writeAccess ? this.writeAccess.map((id) => new Guid(id)) : [],
      documentTopics,
      this.templateIds?.map((id) => new Guid(id)) ?? [],
        this.hasSystemPermissions ?? false
    );
  }
}
