import moment, {Moment} from 'moment';
import Guid from "common/values/guid/guid";

import Forum from 'messaging/entities/forum/forum';
import MessageFileInfo from 'messaging/values/attachment/message-file-info';
import User from 'users/entities/user/user';
import ReadReceipt from 'messaging/values/read-receipts/read-receipt';

export default class Message {
  id?: Guid;
  senderId: Guid | null;
  forum: Forum;
  content: string;
  subject?: string;
  created?: Moment;
  published?: Moment;
  attachments: MessageFileInfo[];
  readReceipts: ReadReceipt[] = [];
  issues: string[] = [];
  deleted?: Moment;
  isAutoGenerated?: boolean;

  markedForCreation?: boolean;
  markedForEdit?: boolean;
  markedRead?: boolean;
  markedUnread?: boolean;
  markedForDeletion?: boolean;
  markedForPublish?: boolean;

  constructor(
    forum: Forum,
    content: string,
    senderId?: Guid | null,
    subject?: string,
    created?: Moment,
    published?: Moment,
    attachments?: MessageFileInfo[],
    id?: Guid,
    issues?: string[],
    readReceipts?: ReadReceipt[],
    deleted?: Moment,
    isAutoGenerated?: boolean
  ) {
    this.id = id;
    this.senderId = senderId ?? null;
    this.forum = forum;
    this.content = content;
    this.subject = subject;
    this.created = created;
    this.published = published;
    this.attachments = attachments?.map((attachment) => new MessageFileInfo(
      attachment.id,
      attachment.name,
      attachment.fileSize,
      attachment.mimeType,
      attachment.created,
      attachment.rawData
    )) ?? [];
    this.issues = issues ?? [];
    this.readReceipts = readReceipts ?? [];
    this.deleted = deleted;
    this.isAutoGenerated = isAutoGenerated;
  }

  public setDeleted(): void {
    this.deleted = moment();
    this.markedForDeletion = true;
  }

  public setUndeleted(): void {
    this.deleted = undefined;
    this.markedForDeletion = false;
  }

  public setRead(): void {
    this.markedRead = true;
    this.markedUnread = false;
  }

  public setUnread(): void {
    this.markedUnread = true;
    this.markedRead = false;
  }

  /** Soft delete flag */
  public get isDeleted(): boolean {
    return !!this.deleted;
  }

  public isReadByUser(userId?: Guid): boolean {
    if (!userId) return false;
    return this.readReceipts.some((receipt) => receipt.userId.isEqualTo(userId));
  }

  static draft(
    forum: Forum,
    sender: User,
    content: string,
    subject?: string,
    attachments?: File[],
    id?: Guid,
    isAutoGenerated?: boolean
  ): Message {
    if (!content && (!attachments || attachments.length === 0)) throw new EmptyMessageError();
    if (!sender.id) throw new Error("Must be logged in to draft a message");

    let newMessage = new Message(
      forum,
      content,
      sender.id,
      subject
    );

    newMessage.isAutoGenerated = isAutoGenerated;

    if (attachments && attachments.length > 0) {
      newMessage.attachments = attachments.map((attachment: File) => {
        return MessageFileInfo.fromFile(attachment);
      });
    }
    newMessage.id = id;

    return newMessage;
  }

  public publish(): void {
    this.markedForPublish = true;
  }

  userIsSender(user: User): boolean {
    return !this.senderId ? false
      : this.senderId?.isEqualTo(user.id);
  }
}

export class EmptyMessageError extends Error {
  constructor() {
    super(`Message cannot be empty`);
  }
}
