import _ from "lodash";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, ButtonGroup } from "@mui/material";
import { useEffect, useMemo } from "react";
import { ProposalField, ProposalFieldCategory } from "work/entities/proposal/proposal";
import { ProposalFieldName } from "work/values/constants";

type IssueTraverserProps = {
  issueFields: ProposalField[];
  unreadCommentFields: ProposalField[];
  currentField: ProposalField | undefined;
  onTraverseToNewField: (newField: ProposalField) => void;
};

export default function IssueTraverser(props: Readonly<IssueTraverserProps>) {
  const {
    issueFields,
    unreadCommentFields,
    currentField,
    onTraverseToNewField,
  } = props;

  useEffect(() => initFirstPendingIssue(), []);

  const initFirstPendingIssue = (): void => {
    if (fieldsPendingReview.length > 0) {
      const firstField = fieldsPendingReview[0];
      onTraverseToNewField(firstField);
    }
  }

  const getPreviousFieldPendingReview = (): ProposalField | undefined => {
    if (!currentField || fieldsPendingReview.length < 1) return;

    const currentIndex = fieldsPendingReview.findIndex((field) =>
      field.name === currentField.name &&
      (field.id ? field.id?.isEqualTo(currentField.id) : true)
    );

    if (currentIndex < 0) return;

    let previousIndex = currentIndex - 1;

    if (previousIndex < 0) {
      previousIndex = fieldsPendingReview.length - 1;
    }

    return fieldsPendingReview[previousIndex];
  }

  const getNextFieldPendingReview = (): ProposalField | undefined => {
    if (!currentField || fieldsPendingReview.length < 1) return;

    const currentIndex = fieldsPendingReview.findIndex((field) =>
      field.name === currentField.name &&
      (field.id ? field.id?.isEqualTo(currentField.id) : true)
    );

    if (currentIndex < 0) return;

    let nextIndex = currentIndex + 1;

    if (nextIndex >= fieldsPendingReview.length) {
      nextIndex = 0;
    }

    return fieldsPendingReview[nextIndex];
  }

  const proposalFieldCategories = useMemo(() => Object.values(ProposalFieldCategory), []);
  const proposalFieldNames = useMemo(() => Object.values(ProposalFieldName), []);
  const fieldsPendingReview: ProposalField[] = useMemo(() => {
    const combined = [...unreadCommentFields, ...issueFields];
  
    // Remove duplicates and sort
    return _.chain(combined)
      .uniqBy((field) => `${field.category}-${field.name}-${field.id?.value ?? ''}`)
      .sortBy([
        (field) => proposalFieldCategories.indexOf(field.category),
        (field) => proposalFieldNames.indexOf(field.name)
      ])
      .value();
  }, [unreadCommentFields, issueFields, proposalFieldCategories, proposalFieldNames]);
  const previousField = useMemo(() => getPreviousFieldPendingReview(),
    [currentField, fieldsPendingReview]
  );
  const nextField = useMemo(() => getNextFieldPendingReview(),
    [currentField, fieldsPendingReview]
  );

  return (
    <ButtonGroup
      size="small"
      color="primary"
      variant="outlined"
      orientation="horizontal"
      onMouseDown={(event) => event.preventDefault()}
    >
      <Button
        id="previous-issue"
        disabled={!previousField || previousField.isEqualTo(currentField)}
        sx={{ whiteSpace: 'nowrap' }}
        onClick={() => {
          previousField && onTraverseToNewField(previousField)
        }}
      >
        <KeyboardArrowUpIcon />
        Prev Change
      </Button>
      <Button
        id="next-issue"
        disabled={!nextField || nextField.isEqualTo(currentField)}
        sx={{ whiteSpace: 'nowrap' }}
        onClick={() => {
          nextField && onTraverseToNewField(nextField)
        }}
      >
        Next Change
        <KeyboardArrowDownIcon />
      </Button>
    </ButtonGroup>
  );
}
