import { ArrowBack as ArrowBackIcon, Close as CloseIcon, Save as SaveIcon } from "@mui/icons-material";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogProps as MUIDialogProps,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import { styled, SxProps } from "@mui/material/styles";
import { ConfirmResponse, useConfirmDialog } from "app/providers/confirm-dialog";
import React, { MutableRefObject, ReactNode } from "react";


const Title = styled(DialogTitle)(
  ({theme}) => ({
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(
        1,
        2
      )
    },
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  }));
const TitleNav = styled('div')(
  () => ({
    display: 'flex',
    alignItems: 'center'
  }));
const BackButton = styled(IconButton)(
  ({theme}) => ({
    marginRight: theme.spacing(1),
    zIndex: 999
  }));
const CloseButton = styled(IconButton)(
  () => ({
    zIndex: 999
  }));
const Content = styled(DialogContent)(
  ({theme}) => ({
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  }));

export interface DialogProps {
  MuiProps?: Partial<MUIDialogProps>;
  component: React.ReactNode;
  title?: ReactNode | string;
  contentSxProps?: SxProps<Theme>;
  titleStyle?: React.CSSProperties;
  titleTypographyStyle?: React.CSSProperties;
  hideCloseButton?: boolean;
  hideBackButton?: boolean;
  hideFullScreenButton?: boolean;
  showSaveButton?: boolean;
  disableSave?: boolean;
  saveButtonTooltip?: string;
  onBack?: () => void;
  onClose?: () => void;
  isDirty?: boolean;
  isDirtyRef?: MutableRefObject<boolean>;
  canSave?: boolean;
  onSave?: (saveAbleItem?: any, preventNavigation?: boolean) => void;
  saveAbleItem?: any;
  resetStack?: boolean;
}

export const DialogComponent = React.forwardRef((
  props: Readonly<DialogProps>, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    MuiProps,
    component,
    title,
    contentSxProps,
    titleStyle,
    titleTypographyStyle,
    hideBackButton,
    hideFullScreenButton,
    hideCloseButton,
    showSaveButton,
    disableSave,
    saveButtonTooltip,
    saveAbleItem,
    isDirty,
    isDirtyRef,
    onBack,
    onClose,
    onSave
  } = props;

  const confirm = useConfirmDialog();

  const [isFullscreen, setIsFullscreen] = React.useState(false);

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleCloseClicked = async () => {
    if (isDirty || isDirtyRef?.current) {
      const response = await confirm({
        title: 'Discard changes?',
        message: 'Are you sure you want to discard changes?',
        okButtonText: 'Discard',
        alternativeOkText: !disableSave ? 'Save & Close' : undefined,
        cancelButtonText: 'Cancel',
      });

      if (response === ConfirmResponse.Cancel) {
        return;
      }
      if (response === ConfirmResponse.AltOk) {
        onSave?.(
          saveAbleItem,
          true
        );
        onClose?.();
        return;
      }
    }
    onClose?.();
  }

  return (
    <Dialog
      open
      fullScreen={isSmallScreen || isFullscreen}
      ref={ref}
      disablePortal
      closeAfterTransition={false}
      {...MuiProps}>
      <Title style={titleStyle}>
        <TitleNav>
          {!hideBackButton && (
            <Tooltip title="Back" placement="bottom">
              <span>
                <BackButton
                  aria-label="back"
                  onClick={onBack}
                  size="medium"
                  edge="start">
                  <ArrowBackIcon/>
                </BackButton>
              </span>
            </Tooltip>
          )}
          <Typography style={titleTypographyStyle} variant="h5">
            {title}
          </Typography>
        </TitleNav>
        <span>
          {!hideFullScreenButton && (
            <Tooltip title={isFullscreen ? "Restore" : "Fullscreen"} placement="bottom">
              <span>
                <IconButton
                  aria-label="full screen"
                  onClick={handleFullscreenToggle}
                  size="medium">
                  {isFullscreen ? <FullscreenExitIcon/> : <FullscreenIcon/>}
                </IconButton>
              </span>
            </Tooltip>)}
          {showSaveButton && (
            <Tooltip title={saveButtonTooltip} placement="bottom">
              <span>
                <IconButton
                  aria-label="Save"
                  color="primary"
                  disabled={disableSave}
                  onClick={() => onSave?.(saveAbleItem)}
                  size="medium">
                  <SaveIcon/>
                </IconButton>
              </span>
            </Tooltip>
          )}
          {!hideCloseButton && (
            <Tooltip title="Close" placement="bottom">
              <span>
                <CloseButton
                  aria-label="close"
                  onClick={handleCloseClicked}
                  size="medium"
                  edge="end">
                  <CloseIcon/>
                </CloseButton>
              </span>
            </Tooltip>
          )}
        </span>
      </Title>
      <Content sx={contentSxProps}>
        {component}
      </Content>
    </Dialog>
  );
});
