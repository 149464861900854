import Guid from "common/values/guid/guid";
import moment from "moment";

import BookmarkedCompany from "marketplace/entities/bookmarked-company/bookmarked-company";
import MarketplaceCompanyInfoAPIResponse from 'marketplace/values/company-profile/api/response-contracts/marketplace-company-info-api-response';
import CompanyProfile from "marketplace/values/company-profile/company-profile";


export default class MarketplaceCompanyBookmarkAPIResponse extends MarketplaceCompanyInfoAPIResponse {
  bookmarkId?: string;
  createdDate?: string;

  deserialize(): BookmarkedCompany {
    if (!this.bookmarkId) throw new Error('BookmarkId is required, was not returned by the API');
    if (!this.createdDate) throw new Error('CreatedDate is required, was not returned by the API');
    if (!this.name) throw new Error('Name is required, was not returned by the API');
    if (!this.id) throw new Error("Id is required, was not returned by the API");

    const vendorProfile = new CompanyProfile(
      this.name,
      this.description,
      this.location?.deserialize(),
      this.yearEstablished,
      this.website,
      this.numberOfEmployees,
      this.categories,
      this.avatar ? new Guid(this.avatar) : undefined,
      this.contact?.id !== undefined ? new Guid(this.contact.id) : undefined
    )

    return new BookmarkedCompany(
      new Guid(this.id),
      new Guid(this.bookmarkId),
      moment(this.createdDate),
      vendorProfile
    );
  }
}
