import {
  Box,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  TextField,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDialog } from "app/providers/dialog";
import Guid from "common/values/guid/guid";
import Individual from "marketplace/entities/individual/individual";
import ViewIndividualProfile from "marketplace/values/individual-profile/view/view-individual-profile";
import IndividualAvatar from "marketplace/view/individual-avatar";
import React, { useEffect } from "react";
import { useSession } from "users/session/session-context";
import CommentThread from "work/entities/comment-thread/comment-thread";
import CommentsButton from "work/entities/comment-thread/view/comments-button";
import { ProposalField } from "work/entities/proposal/proposal";
import TeamLeaderSelector from "work/view/components/team-leader-selector";

const MainContainer = styled(Box)(
  () => ({
    display: "flex",
    flexDirection: "column",
  }));
const TeamContainer = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  }));
const TeamList = styled(List)(
  ({ theme }) => ({
    margin: 0,
    marginTop: 0,
    minWidth: theme.spacing(40),
    paddingBottom: theme.spacing(3),
    "&:first-of-type": {
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down("lg")]: {
      "&:first-of-type": {
        marginRight: 0,
      },
    },
  }));
const TeamListSubheader = styled(ListSubheader)(
  ({ theme }) => ({
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    display: "flex",
    fontSize: "1.4em",
    justifyContent: "space-between",
    padding: 0,
    "& > button": {
      height: "fit-content",
      width: "fit-content",
    },
  }));
const NoRowsPlaceholder = styled(Typography)(
  ({ theme }) => ({
    fontSize: "1.3em",
    paddingTop: theme.spacing(1),
  }));
const TeamMemberLink = styled(Link)(
  ({ theme }) => ({
    color: "grey",
    cursor: "pointer",
    textDecorationColor: theme.palette.common.black,
  }));
const TeamLeaderInput = styled(TextField)(
  ({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
      width: "100%",
    },
    margin: theme.spacing(
      1,
      0
    ),
    minWidth: theme.spacing(25),
  }));

type TeamReadonlyProps = {
  className?: string;
  leader: Individual | null;
  members: Individual[];
  commentThreads?: CommentThread[];
  onCommentsClicked?: (proposalField: ProposalField, name?: string) => void;
};

export default function TeamReadonly(props: Readonly<TeamReadonlyProps>) {
  const {
    className,
    leader,
    members,
    commentThreads,
    onCommentsClicked
  } = props;

  const [selectedLeader, setSelectedLeader] = React.useState<Individual | null>(
    null
  );
  const [selectedMembers, setSelectedMembers] = React.useState<Individual[]>(
    []
  );
  const [teamLeaderSelectorAnchor, setTeamLeaderSelectorAnchor] =
    React.useState<HTMLDivElement>();

  const { openDialog } = useDialog();
  const session = useSession();

  useEffect(
    () => {
      setSelectedLeader(leader);
      setSelectedMembers(members);
    },
    [
      leader,
      members
    ]
  );

  /**
   * Opens the user profile dialog for the given individual
   *
   * @param individualId The id of the individual to open the dialog for
   */
  function openUserProfileDialog(individualId: Guid) {
    openDialog({
      component: <ViewIndividualProfile individualId={individualId} />,
      titleStyle: {
        position: "absolute",
        right: 0,
        top: 0,
      },
      contentSxProps: {
        display: "flex",
        overflowX: "hidden",
      },
      MuiProps: {
        maxWidth: "lg",
        fullWidth: true,
      },
    });
  }

  function renderLeader(leader: Individual) {
    return (
      <ListItem
        key={leader.userId.value ?? Guid.generate().value}
        disableGutters
      >
        <ListItemAvatar>
          <IndividualAvatar
            avatarId={leader.profile?.avatarId}
            individualId={leader.id}
            session={session}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography>
              <TeamMemberLink onClick={() => openUserProfileDialog(leader.id)}>
                {leader.profile?.firstName} {leader.profile?.lastName}
              </TeamMemberLink>
            </Typography>
          }
        ></ListItemText>
         <CommentsButton
            commentThreads={commentThreads}
            hideUnreadCount={true}
            field={ProposalField.TeamLeader}
            onCommentsClicked={onCommentsClicked ? () => {
              onCommentsClicked(
                ProposalField.TeamLeader,
                leader.getFullName()
              );
            } : undefined}
            toolTip={commentThreads?.some((thread) => thread.field.isEqualTo(ProposalField.TeamLeader)) ? "Comments"
              : "No Comments"}
          />
      </ListItem>
    );
  }

  /**
   * Renders a single team member's info as a list item
   *
   * @param member The member's info to render
   * @param allowDelete Whether or not to allow the member to be deleted
   */
  function renderMember(member: Individual) {
    return (
      <ListItem
        key={member.userId.value ?? Guid.generate().value}
        disableGutters
      >
        <ListItemAvatar>
          <IndividualAvatar
            avatarId={member.profile?.avatarId}
            individualId={member.id}
            session={session}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography>
              <TeamMemberLink onClick={() => openUserProfileDialog(member.id)}>
                {member.profile?.firstName} {member.profile?.lastName}
              </TeamMemberLink>
            </Typography>
          }
        ></ListItemText>
        <CommentsButton
            field={ProposalField.TeamMember(member.userId)}
            commentThreads={commentThreads}
            hideUnreadCount={true}
            toolTip={commentThreads?.some(thread => thread.field.isEqualTo(ProposalField.TeamMember(member.userId)))
              ? "Comments" : "No Comments"}
            onCommentsClicked={onCommentsClicked ? () => {
              onCommentsClicked(
                ProposalField.TeamMember(member.userId),
                member.getFullName()
              );
            } : undefined}
          />
      </ListItem>
    );
  }

  return (
    <MainContainer className={className}>
      <TeamContainer>
        <TeamList>
          <TeamListSubheader>
            <span>Team Leader</span>
          </TeamListSubheader>
          {!selectedLeader && !session.context?.viewingAsVendor && (
            <ListItem disableGutters>
              <NoRowsPlaceholder>None</NoRowsPlaceholder>
            </ListItem>
          )}
          {!session.context?.viewingAsVendor &&
            selectedLeader &&
            renderLeader(selectedLeader)}
          {session.context?.viewingAsVendor && (
            <>
              <TeamLeaderInput
                disabled={true}
                label={selectedLeader ? "Team Leader" : "Select Team Leader"}
                helperText="required"
                required
                error={!selectedLeader}
                onClick={() => {
                }}
                value={
                  selectedLeader
                    ? `${selectedLeader?.profile?.firstName} ${selectedLeader?.profile?.lastName}`
                    : ""
                }
                slotProps={{
                  inputLabel: {
                    shrink: selectedLeader !== undefined,
                  },
                }}
              />
              <TeamLeaderSelector
                popoverAnchor={teamLeaderSelectorAnchor}
                teamLeaderUserId={selectedLeader?.userId}
                onTeamLeaderUpdated={() => {
                }}
                onPopoverClose={() => {
                  setTeamLeaderSelectorAnchor(undefined);
                }}
              />
            </>
          )}
        </TeamList>
        <TeamList>
          <TeamListSubheader>
            <span>Team Members</span>
            <CommentsButton
              field={ProposalField.Team}
              hideUnreadCount={true}
              commentThreads={commentThreads?.filter((thread) =>
                thread.field.isEqualTo(ProposalField.Team))}
              toolTip={commentThreads?.some((thread) =>
                thread.field.isEqualTo(ProposalField.Team)) ?
                "Comments" : "No Comments"}
              onCommentsClicked={onCommentsClicked ? () => {
                onCommentsClicked(
                  ProposalField.Team
                );
              } : undefined}
            />
          </TeamListSubheader>
          {(!selectedMembers || selectedMembers.length < 1) && (
            <ListItem disableGutters>
              <NoRowsPlaceholder>None</NoRowsPlaceholder>
            </ListItem>
          )}
          {selectedMembers?.map((member: Individual) =>
            renderMember(member)
          )}
        </TeamList>
      </TeamContainer>
    </MainContainer>
  );
}
