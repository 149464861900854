import {ProposalField} from "work/entities/proposal/proposal";
import CommentThread from "work/entities/comment-thread/comment-thread";
import {Badge, IconButton, Tooltip} from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";

type CommentsButtonProps = {
  commentThreads?: CommentThread[];
  field: ProposalField;
  toolTip: string;
  onCommentsClicked?: () => void;
  hideUnreadCount?: boolean;
};

export default function CommentsButton(
  props: Readonly<CommentsButtonProps>
) {
  const {
    commentThreads,
    field,
    toolTip,
    onCommentsClicked,
    hideUnreadCount,
  } = props;

  const unreadCount = field ? commentThreads?.filter((thread) =>
    thread.field.isEqualTo(field)
  ).reduce(
    (acc, thread) => acc + (thread.numUnread ?? 0),
    0
  ) : 0;

  const hasComments = commentThreads?.some((thread) => thread.field.isEqualTo(field)) ?? false;

  const getBadgeVariant = () => {
    if (hideUnreadCount) {
      return "dot";
    }
    return unreadCount ? "standard" : "dot";
  }

  const getBadgeContent = () => {
    if (hideUnreadCount) {
      return undefined;
    }
    return unreadCount === 0 ? undefined : unreadCount;
  }

  return (
    <Tooltip
      title={toolTip}
    >
      <span>
        <IconButton
          disabled={!onCommentsClicked || (hideUnreadCount && !hasComments)}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onCommentsClicked?.();
          }}
        >
        <Badge
          variant={getBadgeVariant()}
          color="secondary"
          overlap="circular"
          badgeContent={getBadgeContent()}
          invisible={
            !(commentThreads?.some((thread) => thread.field.isEqualTo(field)))
          }
        >
          <CommentIcon fontSize="medium"/>
        </Badge>
        </IconButton>
      </span>
    </Tooltip>
  );
}